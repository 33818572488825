import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button as Btn, Form, Modal } from 'react-bootstrap';
import QRCode from 'qrcode'
import notp from 'notp';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Divider } from 'antd';
import getTokenConfig from '../../../methods/getTokenConfig';


const SetUpMFAWindow = ({
    showSetupMFAModal,
    setShowSetupMFAModal,
    setAttachmentsNeeded,
    showResetMFAModal,
    setShowResetMFAModal,
    setShowRequestMFAModal,
    setTotp,
    setMessageParent,
    resetMessageParent,
    setInEditMode,
}) => {
    const [showHelp, setShowHelp] = useState(false);
    const [url, setUrl] = useState('');
    const [base32, setBase32] = useState(null);
    const [keyAscii, setKeyAscii] = useState(null);
    const [qrCode, setQrCodeDataUrl] = useState(null);
    const [newToken, setNewToken] = useState(null);
    const [validated, setValidated] = useState(false);
    const [oldToken, setOldToken] = useState(null);
    const [oldTokenVerified, setOldTokenVerified] = useState(false);
    const [oldTokenNotVerifiedMsg, setOldTokenNotVerifiedMsg] = useState(null);

    const [msgType, setMsgType] = useState({ status: null, message: '' });

    const resetMessage = (time) => {
        setTimeout(() => {
            setMsgType({ status: '', message: '' });
        }, time);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/infrastructureInfo', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setUrl(res.data.url);
                }
            });
        if (url) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/generateNewTOTPKey/', getTokenConfig())
                .then(response => {
                    setBase32(response.data.base32);
                    setKeyAscii(response.data.key);
                    QRCode.toDataURL(`otpauth://totp/${url}/?secret=${response.data.base32}`)
                        .then(url => {
                            setQrCodeDataUrl(url);
                        })
                        .catch(err => {
                            setMsgType({
                                status: 'error',
                                message: err.response && err.response.data && err.response.data.msg,
                            });
                        })
                }).catch((error) => {
                    setMsgType({
                        status: 'error',
                        message: error.response && error.response.data && error.response.data.msg,
                    });
                    resetMessage(1800);
                });
        }
    }, [url]);

    const setNewTotpUser = (key) => {
        axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/setNewTotpUser',
            { key },
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setMessageParent({
                    status: 'success',
                    msg: res.data.msg,
                });
            }
            resetMessageParent(1800);
            setAttachmentsNeeded(true);
            setShowSetupMFAModal(false);
            setShowResetMFAModal(false);
            setTotp(res.data.newTotp);
            setInEditMode('');
        }).catch((err) => {
            setMessageParent({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            resetMessageParent(1800);
        });
    };

    const authenticateOldToken = async (oldTokenValue) => {
        await axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/verifyMfaLogin',
            { token: oldTokenValue },
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setOldTokenVerified(true);
            }
        }).catch((err) => {
            setOldTokenVerified(false);
            setOldTokenNotVerifiedMsg(err.response.data.msg);
        });
    };

    return (
        <Modal
            show={showSetupMFAModal || showResetMFAModal}
            onHide={() => {
                setShowSetupMFAModal(false);
                setShowResetMFAModal(false);
            }}
            size="lg"
        >
            <Modal.Header
                style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    color: "#ffffff",
                }}
            >
                <Modal.Title style={{ width: '100%' }}>
                    {showResetMFAModal ?
                        'Reset 2-Step Verification'
                        : 'Setup 2-Step Verification'
                    }
                    <Btn
                        size="xs"
                        variant="link"
                        onClick={() => setShowHelp(!showHelp)}
                        style={{ float: 'right' }}
                    >
                        <i className="fas fa-question-circle"></i>
                    </Btn>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ backgroundColor: "#e4e7eb" }}
            >
                {msgType.status === 'warning' ?
                    <Fade
                        enter={true}
                        exit={true}>
                        {msgType.status !== null && <Notification
                            style={{
                                width: '400px'
                            }}
                            type={{ style: 'error', icon: true }}
                            onClose={() => setMsgType({ status: null, message: '' })}
                        >
                            <span>{msgType.message}</span>
                        </Notification>}
                    </Fade>
                    : null}
                {showHelp ? (
                    <div style={{ marginBottom: 10 }}>
                        <Notification
                            type={{
                                style: "info",
                            }}
                            closable={true}
                            onClose={() => setShowHelp(false)}
                        >
                            <ul style={{ marginBottom: 0 }}>
                                <li>
                                    2-step verification requires an authentication app
                                    to generate time-based passwords.
                                </li>
                                <li>
                                    We recommend <a href="https://authy.com/">Authy</a>.
                                </li>
                            </ul>
                        </Notification>
                    </div>
                ) : null}
                {showResetMFAModal ?
                    <div>
                        <Divider orientation="center">Step 1</Divider>
                        <div style={{ marginBottom: 25 }}>
                            <Form.Group controlId="validationToken">
                                <Form.Label><b>Current Token:</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Current Token"
                                    required
                                    value={oldToken}
                                    onChange={(e) => {
                                        setOldToken(e.target.value);
                                        setOldTokenNotVerifiedMsg(null);
                                        if (e.target.value.length === 6)
                                            authenticateOldToken(e.target.value);
                                        else
                                            setOldTokenVerified(false);
                                    }}
                                    isInvalid={!oldTokenVerified}
                                    isValid={oldTokenVerified}
                                />
                                <Form.Text>
                                    To complete this action we require a current 6-digit token from your device.
                                </Form.Text>
                                {oldTokenNotVerifiedMsg ?
                                    <Form.Control.Feedback type="invalid">
                                        {oldTokenNotVerifiedMsg}
                                    </Form.Control.Feedback>
                                    : null
                                }
                            </Form.Group>
                            {!oldTokenVerified ?
                                <>
                                    <hr />
                                    <strong>
                                        If you do not have current 6-digit token, please
                                        <Btn
                                            variant="link"
                                            size="sm"
                                            onClick={() => {
                                                setShowSetupMFAModal(false);
                                                setShowResetMFAModal(false);
                                                setShowRequestMFAModal(true);
                                            }}
                                        >click here
                                        </Btn>to request reset.
                                    </strong>
                                </>
                                : null
                            }
                        </div>
                    </div>
                    : null
                }
                {(showResetMFAModal && oldTokenVerified) || !showResetMFAModal ?
                    <>
                        {oldTokenVerified ?
                            <Divider orientation="center">Step 2</Divider>
                            : null
                        }
                        <div className="text-center" style={{ marginBottom: 15 }}>
                            <div style={{ marginBottom: 5 }}><b>QR Code:</b></div>
                            <div style={{ marginBottom: 10 }}>
                                <img
                                    src={qrCode}
                                    alt="qrCode"
                                    height="300"
                                    width="300"
                                />
                            </div>
                            <div style={{ marginBottom: 5 }}><b>Key:</b></div>
                            <div><b>{base32}</b></div>
                        </div>
                        <p>
                            Once you have setup your authenticator using the above QR code or key,
                            enter the 6-digit token that it generates so we can be sure
                            you&#39;re setup correctly:
                        </p>
                        <Form.Group controlId="validationToken">
                            <Form.Label><b>Token:</b></Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter New Token"
                                required
                                value={newToken}
                                onChange={(e) => {
                                    setNewToken(e.target.value);
                                    const verified = notp.totp.verify(e.target.value, keyAscii, { window: 2 });
                                    if (verified)
                                        setValidated(true);
                                    else
                                        setValidated(false);
                                }}
                                isInvalid={!validated}
                                isValid={validated}
                            />
                        </Form.Group>
                    </>
                    : null
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="k-button"
                    onClick={() => {
                        setShowSetupMFAModal(false);
                        setShowResetMFAModal(false);
                    }}
                >Cancel
                </button>
                <button
                    type="button"
                    className="k-button"
                    style={{
                        backgroundColor: '#343a40',
                        color: 'white',
                    }}
                    disabled={!newToken || !validated}
                    onClick={() => {
                        const verified = notp.totp.verify(newToken, keyAscii, { window: 2 });
                        if (verified)
                            setValidated(true);
                        else
                            setValidated(false);

                        setNewTotpUser(keyAscii);
                    }}
                >
                    Enter
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default SetUpMFAWindow;
