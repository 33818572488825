import React, { useState } from "react";
import axios from 'axios';
import { Button as Btn, Form, Modal } from 'react-bootstrap';
import { Fade } from '@progress/kendo-react-animation';
import { Notification } from '@progress/kendo-react-notification';
import getTokenConfig from '../../../methods/getTokenConfig';
import auth from "../../../methods/auth";


const VerifyOldTokenModal = ({
    showVerifyOldTokenModal,
    setShowVerifyOldTokenModal,
    setShowRequestMFAModal,
    setAttachmentsNeeded,
    setTotp,
    setInEditMode,
    setMessageParent,
    resetMessageParent,
    history,
}) => {
    const [token, setToken] = useState(null);
    const [tokenVerified, setTokenVerified] = useState(false);
    const [tokenNotVerifiedMsg, setTokenNotVerifiedMsg] = useState(null);

    const [attemptWarning, setAttemptWarning] = useState(null);

    const authenticateToken = async (tokenValue) => {
        await axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/verifyMfaLogin',
            { token: tokenValue },
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setTokenVerified(true);
            }
        }).catch((err) => {
            if (err.response
                && err.response.data
                && (err.response.data.msg === 'LockedOut' || err.response.data.attempts > 4)) {
                if (err.response.data.attempts > 9) {
                    setAttemptWarning(`You are locked out from the system because of 10 unsuccessful attempts.
                            Please re-try after few minutes.`);
                    auth.logout(() => history.push('/signin'));
                } else {
                    setAttemptWarning(`You will be locked out after 10 unsuccessful attempts.
                        You have already completed ${err.response.data.attempts} attempts.`);
                }
            } else {
                setTokenVerified(false);
                setTokenNotVerifiedMsg(err.response.data.msg);
            }
        });
    };

    const turnOnAttachments = () => {
        axios.get(
            process.env.REACT_APP_BACKEND_CONN_IP + '/organization/turnOnAttachmentsNeeded',
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setMessageParent({
                    status: 'success',
                    msg: res.data.msg,
                });
            }
            resetMessageParent(1800);
            setAttachmentsNeeded(true);
            setShowVerifyOldTokenModal(false);
            setTotp(res.data.newTotp);
            setInEditMode('');
        }).catch((err) => {
            setMessageParent({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            resetMessageParent(1800);
        });
    };

    return (
        <Modal
            show={showVerifyOldTokenModal}
            onHide={() => {
                setShowVerifyOldTokenModal(false);
            }}
            size="lg"
        >
            <Modal.Header
                style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    color: "#ffffff",
                }}
            >
                <Modal.Title style={{ width: '100%' }}>
                    Verify Old Token
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ backgroundColor: "#e4e7eb" }}
            >
                <div style={{ marginBottom: 25 }}>
                    {attemptWarning ?
                        <Fade
                            enter={true}
                            exit={true}
                            style={{ width: '100%' }}
                            className="inline-notification"
                        >
                            <Notification
                                type={{ style: 'error', icon: true }}
                                style={{ width: '100%' }}
                            >
                                <span>
                                    {attemptWarning}
                                </span>
                            </Notification>
                        </Fade>
                        : null
                    }
                    <Form.Group controlId="validationToken">
                        <Form.Label><b>MFA Token:</b></Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Current Token"
                            required
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value);
                                setTokenNotVerifiedMsg(null);
                                if (e.target.value.length === 6)
                                    authenticateToken(e.target.value);
                                else
                                    setTokenVerified(false);
                            }}
                            isInvalid={!tokenVerified}
                            isValid={tokenVerified}
                        />
                        <Form.Text>
                            To complete this action we require a current 6-digit token from your device.
                        </Form.Text>
                        {tokenNotVerifiedMsg ?
                            <Form.Control.Feedback type="invalid">
                                {tokenNotVerifiedMsg}
                            </Form.Control.Feedback>
                            : null
                        }
                    </Form.Group>
                    {!tokenVerified ?
                        <>
                            <hr />
                            <strong>
                                If you do not have current 6-digit token, please
                                <Btn
                                    variant="link"
                                    size="sm"
                                    onClick={() => {
                                        setShowVerifyOldTokenModal(false);
                                        setShowRequestMFAModal(true);
                                    }}
                                >click here
                                </Btn>to request reset.
                            </strong>
                        </>
                        : null
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="k-button"
                    onClick={() => {
                        setShowVerifyOldTokenModal(false);
                    }}
                >Cancel
                </button>
                <button
                    type="button"
                    className="k-button"
                    style={{
                        backgroundColor: '#343a40',
                        color: 'white',
                    }}
                    disabled={!token || !tokenVerified}
                    onClick={() => {
                        turnOnAttachments();
                    }}
                >
                    Verify
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default VerifyOldTokenModal;
