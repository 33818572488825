import React, { Fragment, useState } from "react";
import axios from 'axios';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
    Card,
    Form,
    Modal,
} from "react-bootstrap";
import { Spin } from 'antd';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { isMobile } from 'react-device-detect';
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";

const AttachmentsPage = ({
    openAttachmentsPage,
    setOpenAttachmentsPage,
    screeningData,
    error,
    setError,
}) => {
    const [documentType, setDocumentType] = useState(null);
    const [file, onFileChange] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [questionString, setQuestionString] = useState('Select Document Type');

    const handleChange = (event) => {
        setDocumentType(event.target.value);
        const findObj = {
            documentType: event.target.value,
        };
        if (screeningData.employeeId) {
            findObj.employeeId = screeningData.employeeId;
        } else {
            findObj.visitorId = screeningData.visitorId;
        }
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/s3/getAttachment/', findObj)
            .then(response => {
                onFileChange(response.data);
            }).catch((err) => {
                setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
    };

    const uploadToS3 = async (event) => {
        const files = event.newState;
        try {
            files.forEach(async (file) => {
                const uploadObj = {
                    name: file.name,
                    organizationId: screeningData.organizationId,
                };
                if (screeningData.employeeId) {
                    uploadObj.employeeId = screeningData.employeeId;
                } else {
                    uploadObj.visitorId = screeningData.visitorId;
                }
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/s3/getS4Token/',
                    uploadObj)
                    .then(response => {
                        const policy = response.data;

                        const body = new FormData();
                        body.append('AWSAccessKeyId', policy.s3Key);
                        body.append('policy', policy.s3PolicyBase64);
                        body.append('signature', policy.s3Signature);
                        body.append('key', policy.key);
                        body.append('Content-Type', policy.mimeType);
                        body.append('acl', 'private');
                        body.append('x-amz-server-side-encryption', 'AES256');
                        body.append('file', file.getRawFile());

                        let s3Endpoint = process.env.REACT_APP_ENDPOINT;
                        // if doesn't end with a / add it.
                        if (!/\/$/.test(s3Endpoint)) {
                            s3Endpoint += '/';
                        }

                        const xhr = new XMLHttpRequest();
                        xhr.open('POST', `${s3Endpoint + policy.bucket}/`);
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === XMLHttpRequest.DONE) {
                                if (xhr.status === 200 || xhr.status === 204) {
                                    const fileToInsert = {
                                        id: file.uid.toString(),
                                        name: file.name,
                                        documentType: documentType,
                                        size: file.size,
                                        S3Bucket: policy.bucket,
                                        filePath: policy.key,
                                    };
                                    if (screeningData.employeeId) {
                                        fileToInsert.employeeId = screeningData.employeeId;
                                    } else {
                                        fileToInsert.visitorId = screeningData.visitorId;
                                    }
                                    axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/s3/addFileToDB/', fileToInsert)
                                        .then(response => {
                                            const newAttch = response.data
                                                && response.data.attachments
                                                && response.data.attachments[`${documentType}`];
                                            onFileChange(newAttch);
                                            setIsLoading(false);
                                            setQuestionString('Select Another Document');
                                        }).catch((err) => {
                                            setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                        });
                                } else {
                                    setError({ status: 'error', message: 'Something went wrong.' });
                                }
                            }
                        };
                        xhr.send(body);
                    }).catch((err) => {
                        setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                    });
            });

        } catch (err) {
            setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
        }
    };

    return (
        <Modal
            show={openAttachmentsPage}
            onHide={() => {
                setOpenAttachmentsPage(false);
            }}
            size={isMobile ? "lg" : "xl"}
        >
            <Modal.Header
                style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    color: "#ffffff",
                }}
            >
                <Modal.Title className={isMobile ? "text-center" : ""} style={{ width: '100%' }}>
                    Upload/Update Proof of Vaccination or Exemption (COVID related documents)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isEmpty(screeningData) ?
                    <NotificationGroup
                        style={{
                            right: 0,
                            top: 0,
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse'
                        }}
                    >
                        {error.hasError ?
                            <Fade
                                enter={true}
                                exit={true}>
                                <Notification
                                    type={{ style: 'error', icon: true }}
                                    closable={true}
                                    onClose={() => setError({ hasError: false, msg: '' })}
                                >
                                    <span>{error.msg}</span>
                                </Notification>
                            </Fade> : null}
                    </NotificationGroup>
                    : null
                }
                <div style={{ padding: 15 }}>
                    <Form.Group controlId="docTypes">
                        <Form.Label><b>{questionString}:</b></Form.Label>
                        <Form.Control
                            as="select"
                            custom
                            value={documentType}
                            onChange={handleChange}
                        >
                            <option>Select Document Type</option>
                            <option>Vaccine Shot - 1</option>
                            <option>Vaccine Shot - 2</option>
                            <option>Booster</option>
                            <option>Record of exemption</option>
                            <option>Record of educational module</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div style={{ padding: 15 }}>
                    {documentType ?
                        <Card
                            style={{ marginBottom: 15 }}
                            bg="Light"
                        >
                            <Card.Header style={{ fontSize: 15 }}>
                                <b>{documentType}</b>
                            </Card.Header>
                            <Card.Body>
                                <Spin size="large" spinning={isLoading} >
                                    <Card.Text>
                                        {file && !isEmpty(file) ?
                                            <Fragment>
                                                {file.name}
                                                <br />
                                                <small className="text-muted">
                                                    <i>Uploaded: {moment(file.created).format('lll')}</i>
                                                </small>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <i>No attachments added</i>
                                            </Fragment>
                                        }
                                    </Card.Text>
                                </Spin>
                            </Card.Body>
                            <Card.Footer>
                                <Upload
                                    batch={false}
                                    multiple={false}
                                    onAdd={(event) => {
                                        uploadToS3(event);
                                        setIsLoading(true);
                                    }}
                                    onRemove={(event) => {
                                        onFileChange(event.newState);
                                    }}
                                    autoUpload={false}
                                    showActionButtons={false}
                                    withCredentials={false}
                                />
                            </Card.Footer>
                        </Card>
                        : null
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <p className="text-right">
                    <Button
                        primary
                        onClick={() => { setOpenAttachmentsPage(false); }}
                    >Done&nbsp;<span className="k-icon k-i-arrow-chevron-right"></span>
                    </Button>
                </p>
            </Modal.Footer>
        </Modal>
    );
};

export default AttachmentsPage;
