import React, { useState } from "react";
import { Window } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import {
    Col,
    Container,
    Row,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { isMobile } from 'react-device-detect';

const SendVisNotificationDialog = ({
    visible,
    setVisible,
    setSelectedVis,
    setSelectedVisitors,
    setSendToAll,
    sendEmail,
    scheduleEmail,
    sendText,
    scheduleText,
    disableEmail,
    disableText,
}) => {
    const defaultValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        0, // Seconds
        0 // Milliseconds
    );

    const [notificationDateTime, setNotificationDateTime] = useState(defaultValue);
    const [scheduleNotification, setScheduleNotification] = useState(false);

    const [sendTodayUtc, setSendTodayUtc] = useState(false);

    return (
        <Window
            width={!isMobile ? 400 : '100%'}
            height={310}
            initialTop={0}
            style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
            title={'Send notification using...'}
            onClose={() => {
                setSelectedVis(null);
                setSelectedVisitors([]);
                setSendToAll(false);
                setVisible(false);
            }}
        >
            <Container>
                <p><strong>Notification Scheduling</strong></p>
                <Row className="mb-2 mt-1">
                    <Col>
                        {!scheduleNotification &&
                            <Button
                                primary
                                style={{ width: '100%' }}
                                onClick={() => {
                                    const utcDateTimeOfNotification =
                                        moment(defaultValue).add(30, 'minutes').utc();

                                    const utcDateTimeNow = moment().utc();

                                    setSendTodayUtc(moment(utcDateTimeOfNotification).isSame(utcDateTimeNow, 'day'));
                                    setScheduleNotification(true);
                                    setNotificationDateTime(new Date(moment(defaultValue).add(30, 'minutes')));
                                }}
                            ><strong>Click to Schedule Notification</strong>
                            </Button>}
                        {scheduleNotification &&
                            <DateTimePicker
                                width="100%"
                                min={new Date(moment(defaultValue).add(30, 'minutes'))}
                                value={notificationDateTime}
                                onChange={(e) => {
                                    const utcDateTimeOfNotification =
                                        moment(e.value).utc();

                                    const utcDateTimeNow = moment().utc();

                                    setSendTodayUtc(moment(utcDateTimeOfNotification).isSame(utcDateTimeNow, 'day'));
                                    // Need to check for 00:00 UTC and change to 00:00:45 UTC to prevent trying to scheduled a cron
                                    // job in the past when the mainCron runs at 00:00 UTC
                                    if (e.value.getHours() === 0 && e.value.getMinutes() === 0) {
                                        setNotificationDateTime(new Date(e.value.setSeconds(45)));
                                    } else {
                                        setNotificationDateTime(e.value);
                                    }
                                }}
                            />}
                    </Col>
                </Row>
                <br />
                <p>
                    <strong>Notification Type<i>{moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0 ? ' (Scheduled)' : ''}</i>
                        {sendTodayUtc && moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0 &&
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-disabled">
                                        <strong>
                                            WARNING!<br />
                                            <br />
                                            <i>
                                                This notification will be scheduled to go out today.<br />
                                                <br />
                                                It <u>cannot be cancelled</u> and if you schedule another one to go out today for this/these visitor(s), it will also go out.
                                            </i>
                                        </strong>
                                    </Tooltip>}
                            >
                                <i style={{ color: 'red' }} className="fas fa-info-circle" />
                            </OverlayTrigger>}
                    </strong>
                </p>
                <Row className="mb-2 mt-1">
                    <Col>
                        <Button
                            primary
                            disabled={disableEmail}
                            style={{ width: '100%' }}
                            onClick={() => {
                                if (moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0) {
                                    scheduleEmail(notificationDateTime);
                                } else {
                                    sendEmail();
                                }
                                setVisible(false);
                            }}
                        ><strong>{moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0 ? 'EMAIL' : 'EMAIL NOW'}</strong>
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            primary
                            disabled={disableText}
                            style={{ width: '100%' }}
                            onClick={() => {
                                if (moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0) {
                                    scheduleText(notificationDateTime);
                                } else {
                                    sendText();
                                }
                                setVisible(false);
                            }}
                        ><strong>{moment(notificationDateTime).diff(moment(defaultValue), 'seconds') > 0 ? 'TEXT' : 'TEXT NOW'}</strong>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Window>
    );
};

export default SendVisNotificationDialog;
