import React, { useEffect } from "react";
import './calenderCard.css';

const CalenderCard = ({ style }) => {
    
    useEffect(() => {
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        var newDate = new Date();
        newDate.setDate(newDate.getDate());

        setInterval(function () {
            const outerDiv = document.getElementsByClassName("outer")[0];

            if (outerDiv) {
                document.getElementsByClassName("month")[0].innerHTML = (monthNames[newDate.getMonth()]);
                document.getElementsByClassName("date")[0].innerHTML = (newDate.getDate());
                document.getElementsByClassName("day")[0].innerHTML = (dayNames[newDate.getDay()]);
            }
        }, 1000);
    });

    return (
        <div className="signboard outer withShadow" style={{...style}}>
            <div className="signboard front inner anim04c">
                <ul className="calendarMain anim04c">
                    <li className="month anim04c x">
                        <span></span>
                    </li>
                    <li className="date anim04c">
                        <span></span>
                    </li>
                    <li className="day anim04c">
                        <span></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CalenderCard;
