import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';
import { isEmpty, isNull } from "lodash";
import {
    Button as Btn,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    Form,
    Dropdown,
} from 'react-bootstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Divider } from 'antd';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { filterBy } from '@progress/kendo-data-query';
import { isMobile } from 'react-device-detect';
import AddNewEmployeeWindow from './addEditEmployeeWindow';
import ConfirmDialog from '../../util/confirmDialog';
import getTokenConfig from '../../../methods/getTokenConfig';
import SendEmpNotificationDialog from "./sendEmpNotificationDialog";
import EmployeeBulkLoadDialog from "./empBulkLoadDialog";
import AttachmentsPreviewModal from "../../util/attachmentsPreviewModal";
import '../../stylesheets/kendoGrid.css';

const initialDataState = {
  skip: 0,
  take: 100,
};

const DetailComponent = ({ dataItem }) => (
    <Container fluid>
        <Row>
            <Col>
                <p><strong>First Name:</strong> {dataItem.firstName}</p>
                <p><strong>Last Name:</strong> {dataItem.lastName}</p>
                {dataItem.userGroup &&
                    <p><strong>Group:</strong> {dataItem.userGroup.name}; <strong>Type:</strong> {dataItem.userGroup.type}</p>}
                <p><strong>Current Status:</strong> {dataItem.currentStatus}</p>
            </Col>
            <Col>
                <p><strong>Cell Number:</strong> {dataItem.cellNumber}</p>
                <p><strong>Email:</strong> {dataItem.email}</p>
                <p><strong>Next Notification Date:</strong> {dataItem.nextNotificationDate !== null && typeof (dataItem.nextNotificationDate) === 'string' ? moment(dataItem.nextNotificationDate).format("ddd, M/D/YYYY") : <i>Not assigned</i>}</p>
            </Col>
            {dataItem.qrCodeVerificationNeeded && dataItem.useCase === 'COVID Screening' && <Col>
                <p><strong>QR Code Verificaton:</strong> {dataItem.qrCodeVerification && dataItem.qrCodeVerification.vaccineVerified ? 'Verified' : 'Not Verified'}</p>
                <p><strong>QR Code Verified Date:</strong> {dataItem.qrCodeVerification && dataItem.qrCodeVerification.vaccineVerifiedDate ? dataItem.qrCodeVerification.vaccineVerifiedDate : ''}</p>
            </Col>}
        </Row>
        {dataItem.attachmentsNeeded && dataItem.useCase !== 'Sign In Management' &&
            <>
                <Divider orientation="left">Attachments</Divider>
                <Row>
                    <Col>
                        {dataItem.attachments && !isEmpty(dataItem.attachments)?
                            Object.keys(dataItem.attachments)
                                .map((attach, i) =>
                                    <p key={`${dataItem._id}-${i}`}>
                                        <strong>{attach}:</strong>&nbsp;
                                            <Btn
                                                variant="link"
                                                style={{ color: 'green' }}
                                                onClick={() => window.open(`/#/empPreview/${dataItem._id}/${dataItem.attachments[`${attach}`].id}`)}
                                            >
                                                {dataItem.attachments[`${attach}`].name}
                                            </Btn>
                                        <br/>
                                        <small className="text-muted">
                                            <b>Uploaded: </b>
                                            <i>{moment(dataItem.attachments[`${attach}`].created).format('lll')}</i>
                                        </small>
                                    </p>)
                            : <p><i>No attachments added yet</i></p>
                        }
                    </Col>
                </Row>
            </>}
    </Container>
);

const Employees = ({ history }) => {
    const [employeesList, setEmployeesList] = useState([]);
    const [employeesListDefault, setEmployeesListDefault] = useState([]);

    const [dateAccordionOpen, setDateAccordionOpen] = useState(false);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const [page, setPage] = useState(initialDataState);

    const [totalResults, setTotalResults] = useState(0);

    const [fetchingData, setFetchingData] = useState(true);

    const [showWindow, setShowWindow] = useState(false);
    const [sort, setSort] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [selectedEmps, setSelectedEmps] = useState([]);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [sendToAll, setSendToAll] = useState(false);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });
    const [showSendDialog, setShowSendDialog] = useState(false);
    const [bulkLoadDialog, setShowBulkLoadDialog] = useState(false);
    const [previewAttachments, setPreviewAttachments] = useState([]);
    const [qrCodeVerificationNeeded, setQrCodeVerificationNeeded] = useState(false);
    const [attachmentsNeeded, setAttachmentsNeeded] = useState(false);
    const [useCase, setUseCase] = useState('');

    const [filter, setFilter] = useState();
    const [showFilterOptions, setShowFilterOptions] = useState();

    const [dateToFilterOn, setDateToFilterOn] = useState('Last Notification');

    const [afterFilter, setAfterFilter] = useState(null);
    const [beforeFilter, setBeforeFilter] = useState(null);

    const handleDateAccordionToggle = () => {
        setDateAccordionOpen(!dateAccordionOpen);
    };

    const pageChange = (event) => {
      setPage(event.page);
    };

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }

        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        const orgId = localStorage.getItem('CheckN.orgId');
        setFetchingData(true);
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgIdCount/' + orgId, getTokenConfig())
            .then(res => {
                if (res.status === 200) {
                    setTotalResults(res.data.numOfResults);
                }
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
            .then(response => {
                const empData = response.data;
                empData.forEach(emp => {
                    emp.fullName = `${emp.firstName} ${emp.lastName}`;
                    emp.noOfAttachments =
                        emp.attachments ?
                            Object.keys(emp.attachments).length : 0;
                    if (emp.qrCodeVerificationNeeded && (!emp.qrCodeVerification ||
                        (emp.qrCodeVerification && !emp.qrCodeVerification.vaccineVerified))) {
                        emp.qrCodeVerification = {
                            vaccineVerified: false,
                        }
                    }
                });
                if (isNull(afterFilter) && isNull(beforeFilter)) {
                    setEmployeesList([...empData]);
                } else {
                    dateFiltering('empChange', null, [...empData]);
                }
                setEmployeesListDefault([...empData]);
                setQrCodeVerificationNeeded(empData && empData[0] ? empData[0].qrCodeVerificationNeeded : false);
                setAttachmentsNeeded(empData && empData[0] ? empData[0].attachmentsNeeded : false);
                setUseCase(empData && empData[0] ? empData[0].useCase : 'COVID Screening');
                setFetchingData(false);
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgIdMinimal/' + orgId, getTokenConfig())
            .then(response => {
                const userGroupData = response.data;
                setUserGroupOptions(userGroupData);
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
        if (mgsType.status !== null) {
            setTimeout(() => {
                setMgsType({ status: null, message: '' })
            }, 2500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, mgsType, dateToFilterOn, afterFilter, beforeFilter]);

    const expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        setForceUpdate(!forceUpdate);
    };

    const dateFiltering = (filterOn, dateValue = null, empList = []) => {
        if (filterOn === 'empChange') {
            let dateToFilterOnOverride;
            if (!isNull(dateValue)) {
                dateToFilterOnOverride = dateValue;
            } else {
                dateToFilterOnOverride = dateToFilterOn;
            }
            setEmployeesList(
                [...empList
                    .filter(empData => {
                        let filterValue;
                        if (dateToFilterOnOverride === 'Last Notification') {
                            if (empData.lastNotificationObj && empData.lastNotificationObj.createdAt) {
                                filterValue = new Date(empData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOnOverride === 'Employee Created') {
                            filterValue = new Date(empData.createdAt);
                        } else if (dateToFilterOnOverride === 'Employee Updated') {
                            filterValue = new Date(empData.updatedAt);
                        }
                        if (afterFilter && isNull(beforeFilter)) {
                            return filterValue > afterFilter;
                        } else if (beforeFilter && isNull(afterFilter)) {
                            return filterValue < beforeFilter;
                        } else if (afterFilter && beforeFilter) {
                            return filterValue > afterFilter &&
                                filterValue < beforeFilter;
                        } return false;
                    })
                ]
            );
        } else if ((beforeFilter && filterOn === 'after') || (afterFilter && filterOn === 'before')) {
            setEmployeesList(
                [...employeesListDefault
                    .filter(empData => {
                        let filterValue;
                        if (dateToFilterOn === 'Last Notification') {
                            if (empData.lastNotificationObj && empData.lastNotificationObj.createdAt) {
                                filterValue = new Date(empData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOn === 'Employee Created') {
                            filterValue = new Date(empData.createdAt);
                        } else if (dateToFilterOn === 'Employee Updated') {
                            filterValue = new Date(empData.updatedAt);
                        }
                        if (filterOn === 'after') {
                            return filterValue > dateValue
                                && filterValue < beforeFilter;
                        } else if (filterOn === 'before') {
                            return filterValue < dateValue
                                && filterValue > afterFilter;
                        } return false;
                    })
                ]
            );
        } else {
            setEmployeesList(
                [...employeesListDefault
                    .filter(empData => {
                        let filterValue;
                        if (dateToFilterOn === 'Last Notification') {
                            if (empData.lastNotificationObj && empData.lastNotificationObj.createdAt) {
                                filterValue = new Date(empData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOn === 'Employee Created') {
                            filterValue = new Date(empData.createdAt);
                        } else if (dateToFilterOn === 'Employee Updated') {
                            filterValue = new Date(empData.updatedAt);
                        }
                        if (filterOn === 'after') {
                            return filterValue > dateValue;
                        } else if (filterOn === 'before') {
                            return filterValue < dateValue;
                        } return false;
                    })
                ]
            );
        }
    };

    const deleteEmployee = () => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/delete/', { id: selectedEmp._id }, getTokenConfig())
            .then((response) => {
                const orgId = localStorage.getItem('CheckN.orgId');
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'Employee record deleted successfully.' });
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in deleting employee record.' });
                    });
                setSelectedEmp(null);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
    };

    const deleteFilteredEmployees = () => {
        if (selectedEmps.length > 0) {
            selectedEmps.forEach((emp) => {
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/delete', { id: emp._id }, getTokenConfig())
                    .catch((error) => {
                        setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
                    });
            });
            const orgId = localStorage.getItem('CheckN.orgId');
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                .then(response => {
                    setMgsType({ status: 'success', message: 'Employee record(s) deleted successfully.' });
                }).catch((error) => {
                    setMgsType({ status: 'error', message: 'Error in deleting employee record(s).' });
                });
            setSelectedEmps([]);
        };
    };

    const changeEmployeesStatus = ({ newStatus, selectedEmployeeIds }) => {
        if (selectedEmployeeIds.length > 0) {
            selectedEmployeeIds.forEach((employeeId) => {
                const employeeToUpdate = {
                    _id: employeeId,
                    newStatus,
                }
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/changeStatus', employeeToUpdate, getTokenConfig())
                    .catch((error) => {
                        setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
                    });
            });
            const orgId = localStorage.getItem('CheckN.orgId');
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                .then(response => {
                    setMgsType({ status: 'success', message: 'Employees status updated successfully.' });
                }).catch((error) => {
                    setMgsType({ status: 'error', message: 'Error in updating employees status.' });
                });
        };
    };

    const sendEmailNotification = async () => {
        if (sendToAll) {
            const totalEmailNotifications = employeesList.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                employeesList
                    .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'email',
                            email: employee.email,
                            count: i + 1,
                            total: totalEmailNotifications,
                            method: 'everyone',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
                }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedEmps.length > 0) {
            const totalEmailNotifications = selectedEmps.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                selectedEmps
                    .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'email',
                            email: employee.email,
                            count: i + 1,
                            total: totalEmailNotifications,
                            method: 'filtered',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSelectedEmps([]);
        } else {
            const notificationObj = {
                employeeId: selectedEmp._id,
                organizationId: selectedEmp.organizationId,
                type: 'email',
                email: selectedEmp.email,
                count: 1,
                total: 1,
                method: 'single',
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                notificationObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Email sent successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedEmp(null);
        }
    };

    const scheduleEmailNotification = async (notificationDateTime) => {
        if (sendToAll) {
            const totalEmailNotifications = employeesList.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    employeeIds: employeesList
                        .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '')
                        .map(emp => emp._id),
                    method: 'scheduleEveryone',
                    notificationDateTime,
                };
                await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedEmps.length > 0) {
            const totalEmailNotifications = selectedEmps.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    employeeIds: selectedEmps
                        .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.email !== '')
                        .map(emp => emp._id),
                    method: 'scheduledFiltered',
                    notificationDateTime,
                };
                await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSelectedEmps([]);
        } else {
            const notificationsToScheduleObj = {
                employeeIds: [selectedEmp._id],
                method: 'scheduledSingle',
                notificationDateTime,
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                notificationsToScheduleObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Email scheduled successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedEmp(null);
        }
    };

    const sendTextNotification = () => {
        if (sendToAll) {
            const totalTextNotifications = employeesList.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                employeesList
                    .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'text',
                            phone: employee.cellNumber,
                            count: i + 1,
                            total: totalTextNotifications,
                            method: 'everyone',
                        };
                        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedEmps.length > 0) {
            const totalTextNotifications = selectedEmps.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                selectedEmps
                    .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'text',
                            phone: employee.cellNumber,
                            count: i + 1,
                            total: totalTextNotifications,
                            method: 'filtered',
                        };
                        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSelectedEmps([]);
        } else {
            const notificationObj = {
                employeeId: selectedEmp._id,
                organizationId: selectedEmp.organizationId,
                type: 'text',
                phone: selectedEmp.cellNumber,
                count: 1,
                total: 1,
                method: 'single',
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                notificationObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Text sent successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedEmp(null);
        }
    };

    const scheduleTextNotification = (notificationDateTime) => {
        if (sendToAll) {
            const totalTextNotifications = employeesList.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    employeeIds: employeesList
                        .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '')
                        .map(emp => emp._id),
                    method: 'scheduleEveryone',
                    notificationDateTime,
                };
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedEmps.length > 0) {
            const totalTextNotifications = selectedEmps.filter(
                emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    employeeIds: selectedEmps
                        .filter(emp => emp.currentStatus !== 'Terminated' && emp.currentStatus !== 'On Hold' && emp.cellNumber !== '')
                        .map(emp => emp._id),
                    method: 'scheduledFiltered',
                    notificationDateTime,
                };
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSelectedEmps([]);
        } else {
            const notificationsToScheduleObj = {
                employeeIds: [selectedEmp._id],
                method: 'scheduledSingle',
                notificationDateTime,
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                notificationsToScheduleObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Text scheduled successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedEmp(null);
        }
    };

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Employees</span>
                </Card.Header>
            </Card>
            {mgsType.status !== 'warning' &&
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup>}
            <div style={{ padding: 15 }}>
                <Accordion defaultActiveKey="1" className="mb-3">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} onClick={() => handleDateAccordionToggle()}>
                            <strong>Date Filtering</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Container>
                                    <Row md={12}>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>Filter on Date of:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DropDownList
                                                    value={dateToFilterOn}
                                                    data={["Last Notification", "Employee Created", "Employee Updated"]}
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => {
                                                        setDateToFilterOn(e.value);
                                                        if (!isNull(afterFilter) || !isNull(beforeFilter)) {
                                                            dateFiltering('empChange', e.value);
                                                        }
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>On/After:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={afterFilter}
                                                    onChange={(event) => {
                                                        setAfterFilter(event.target.value);
                                                        dateFiltering('after', event.target.value);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>By/Before:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={beforeFilter}
                                                    onChange={(event) => {
                                                        setBeforeFilter(event.target.value);
                                                        dateFiltering('before', event.target.value);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setBeforeFilter(null);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    &nbsp;
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                        setBeforeFilter(null);
                                                        setEmployeesList(employeesListDefault);
                                                    }}
                                                >&nbsp;Clear Dates</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <div style={{ paddingBottom: isMobile ? 50 : undefined }}><Card>
                    <Card.Header>
                        <Row>
                            <Col md="auto"><strong>Employee List</strong></Col>
                            {fetchingData && <Col style={{ color: 'red' }} className="text-right"><strong>FETCHING DATA! Please wait...</strong></Col>}
                            <Col style={{ display: 'flex', justifyContent: 'right' }} className="input-group">
                            {filterBy(employeesList, filter).length !== employeesListDefault.length &&
                                <Dropdown id="actions">
                                    <Dropdown.Toggle name="history">
                                        Filtered Employee Actions
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            disabled={filterBy(employeesList, filter).filter(emp => emp.currentStatus !== 'Active').length === 0}
                                            onClick={() => {
                                                changeEmployeesStatus({
                                                    newStatus: 'Active',
                                                    selectedEmployeeIds: filterBy(employeesList, filter).map(emp => emp._id),
                                                });
                                            }}
                                        >
                                            <i className="fas fa-check-circle" style={{ color: 'green' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Active
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={filterBy(employeesList, filter).filter(emp => emp.currentStatus !== 'On Hold').length === 0}
                                            onClick={() => {
                                                changeEmployeesStatus({
                                                    newStatus: 'On Hold',
                                                    selectedEmployeeIds: filterBy(employeesList, filter).map(emp => emp._id),
                                                });
                                            }}
                                        >
                                            <i className="far fa-pause-circle"></i>&nbsp;&nbsp;&nbsp;Set Status to On Hold
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={filterBy(employeesList, filter).filter(emp => emp.currentStatus !== 'Terminated').length === 0}
                                            onClick={() => {
                                                changeEmployeesStatus({
                                                    newStatus: 'Terminated',
                                                    selectedEmployeeIds: filterBy(employeesList, filter).map(emp => emp._id),
                                                });
                                            }}
                                        >
                                            <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Terminated
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={filterBy(employeesList, filter).length === 0}
                                            onClick={() => {
                                                setShowDeleteDialog(true);
                                                setSelectedEmps(filterBy(employeesList, filter));
                                            }}
                                        >
                                            <i className="far fa-trash-alt" style={{ color: '#B01C2E' }}></i>&nbsp;&nbsp;&nbsp;Delete Employee(s)
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>}
                                &nbsp;
                                <Button
                                    icon={!showFilterOptions ? "filter" : "filter-clear"}
                                    onClick={() => {
                                        setFilter(null);
                                        setShowFilterOptions(!showFilterOptions);
                                    }}
                                    primary
                                ></Button>
                                &nbsp;
                                <Button
                                    title="Add new"
                                    className="k-button k-primary"
                                    onClick={() => setShowWindow(true)}
                                >
                                    <strong>&nbsp;Add New&nbsp;</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {showWindow &&
                        <AddNewEmployeeWindow
                            setShowWindow={setShowWindow}
                            selectedEmp={selectedEmp}
                            setSelectedEmp={setSelectedEmp}
                            setEmployeesList={setEmployeesList}
                            setMgsType={setMgsType}
                            qrCodeVerificationNeeded={qrCodeVerificationNeeded}
                            userGroupOptions={userGroupOptions}
                        />}
                    {showDeleteDialog &&
                        <ConfirmDialog
                            visible={showDeleteDialog}
                            setVisible={setShowDeleteDialog}
                            title="Delete Employee(s)"
                            message={`Are you sure you want to delete ${selectedEmps.length === 0 ? 'this' : 'these'} employee${selectedEmps.length > 0 ? 's' : ''}?`}
                            onConfirm={() => {
                                if (selectedEmps.length === 0) {
                                    deleteEmployee();
                                } else {
                                    deleteFilteredEmployees();
                                }
                            }}
                            onCancel={() => {
                                setSelectedEmp(null);
                                setSelectedEmps([]);
                            }}
                        />}
                    {showSendDialog &&
                        <SendEmpNotificationDialog
                            visible={showSendDialog}
                            setVisible={setShowSendDialog}
                            disableEmail={(selectedEmp && selectedEmp.email === '') || (selectedEmps && selectedEmps.length > 0 &&
                                selectedEmps.filter(emp => emp.email !== '').length === 0)}
                            disableText={(selectedEmp && selectedEmp.cellNumber === '') || (selectedEmps && selectedEmps.length > 0 &&
                                selectedEmps.filter(emp => emp.cellNumber !== '').length === 0)}
                            setSelectedEmp={setSelectedEmp}
                            setSelectedEmps={setSelectedEmps}
                            setSendToAll={setSendToAll}
                            sendEmail={sendEmailNotification}
                            scheduleEmail={scheduleEmailNotification}
                            sendText={sendTextNotification}
                            scheduleText={scheduleTextNotification}
                        />}
                    {bulkLoadDialog &&
                        <EmployeeBulkLoadDialog
                            setShowBulkLoadDialog={setShowBulkLoadDialog}
                            setEmployeesList={setEmployeesList}
                            setMgsType={setMgsType}
                        />}
                    {previewAttachments.length !== 0 &&
                        <AttachmentsPreviewModal
                            previewAttachments={previewAttachments}
                            setPreviewAttachments={setPreviewAttachments}
                        />}
                    <Grid
                        style={{
                            height: !dateAccordionOpen ? 'calc(100vh - 222px - 123px)' : 'calc(100vh - 222px - 230px)',
                            minHeight: 400,
                        }}
                        filterable={showFilterOptions}
                        filter={filter}
                        onFilterChange={(e) => {
                            if (filterBy(orderBy([...employeesList], sort), filter).length < page.skip) {
                                setPage({ skip: Math.floor(filterBy(orderBy([...employeesList], sort), filter).length / page.take) * page.take, take: page.take });
                            }
                            setFilter(e.filter);
                        }}
                        data={filterBy(orderBy([...employeesList], sort), filter).slice(page.skip, page.take + page.skip)}
                        skip={page.skip}
                        take={page.take}
                        total={filterBy(orderBy([...employeesList], sort), filter).length}
                        pageable={{ pageSizes: [50, 100, 250] }}
                        pageSize={page.pageSize}
                        onPageChange={pageChange}
                        sortable={{
                            allowUnsort: true,
                            mode: 'multiple',
                        }}
                        sort={sort}
                        onSortChange={(event) => {
                            setSort(event.sort);
                        }}
                        reorderable
                        detail={DetailComponent}
                        expandField="expanded"
                        onExpandChange={(event) => expandChange(event)}
                    >
                        {isNarrowScreen && <Column
                            field="fullName"
                            title="Name"
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={props.style}>
                                    {filterBy(employeesList, filter).length !== employeesListDefault.length && 'Filtered '}Total: {filterBy(employeesList, filter).length !== employeesListDefault.length ? filterBy(employeesList, filter).length : totalResults}
                                </td>
                            )}
                        />}
                        {!isNarrowScreen && <Column
                            field="firstName"
                            title="First Name"
                            cell={props => (
                                <td>
                                    {!isMobile && <i className="far fa-user" />}
                                    {!isMobile && <>&nbsp;&nbsp;&nbsp;</>}{props.dataItem['firstName']}
                                </td>
                            )}
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={props.style}>
                                    {filterBy(employeesList, filter).length !== employeesListDefault.length && 'Filtered '}Total: {filterBy(employeesList, filter).length !== employeesListDefault.length ? filterBy(employeesList, filter).length : totalResults}
                                </td>
                            )}
                        />}
                        {!isNarrowScreen && <Column
                            field="lastName"
                            title="Last Name"
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            field="userSite.name"
                            title="Site"
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            field="userDepartment.name"
                            title="Department"
                        />}
                        {!isNarrowScreen && <Column
                            field="currentStatus"
                            title="Current Status"
                            cell={props => {
                                const value = props.dataItem[props.field];
                                let icon = <i className="k-icon"></i>;
                                if (value === 'Active') {
                                    icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
                                } else if (value === 'On Hold') {
                                    icon = <i className="far fa-pause-circle"></i>
                                } else if (value === 'Terminated') {
                                    icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
                                }
                                return (
                                    <td>
                                        {!isMobile && !isNarrowScreen && icon}
                                        {!isMobile && !isNarrowScreen && <>&nbsp;&nbsp;&nbsp;</>}{props.dataItem[props.field]}
                                    </td>
                                );
                            }}
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            field="cellNumber"
                            title="Phone #"
                        />}
                        {!isMobile && !isNarrowScreen && <Column field="email" title="Email" />}
                        {!isMobile && !isNarrowScreen && qrCodeVerificationNeeded && useCase === 'COVID Screening' && <Column
                            field="qrCodeVerification.vaccineVerified"
                            title="Vaccine Verified"
                            filter="boolean"
                            cell={props =>
                                <td>{props?.dataItem?.qrCodeVerification?.vaccineVerified ? 'Verified' : ''}</td>}
                        />}
                        {!isMobile && !isNarrowScreen && attachmentsNeeded && useCase !== 'Sign In Management' && <Column
                            field="noOfAttachments"
                            title="Attachments"
                            cell={props => {
                                const value = props.dataItem[props.field];
                                return (
                                    <td>
                                        {value === 0 ?
                                            <p
                                                style={{
                                                    margin: 0,
                                                    paddingLeft: 12,
                                                }}
                                            >{value}
                                            </p> :
                                            <Btn
                                                variant="link"
                                                onClick={() => {
                                                    const attachmentsList = Object.values(props.dataItem['attachments'])
                                                    attachmentsList.forEach(att => att.empId = props.dataItem['_id']);
                                                    setPreviewAttachments(attachmentsList);
                                                }}
                                            >
                                                {value}
                                            </Btn>
                                        }
                                    </td>
                                );
                            }}
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            filterable={false}
                            field="lastNotificationObj.createdAt"
                            title="Last Notification"
                            cell={props => {
                                if (props.dataItem.lastNotificationObj && props.dataItem.lastNotificationObj.createdAt) {
                                    let backColor = "";
                                    if (props.dataItem.lastNotificationObj && props.dataItem.lastNotificationObj.completed)
                                        backColor = "#cce5ff";
                                    else if (!props.dataItem.lastNotificationObj.completed
                                        && props.dataItem.currentStatus !== 'Terminated')
                                        backColor = "#fff3cd";

                                    return (
                                        <td style={{ backgroundColor: backColor }}>
                                            <strong>Date:&nbsp;</strong>
                                            {moment(props.dataItem.lastNotificationObj["createdAt"]).format('L')}
                                            <br />
                                            <strong>Time:&nbsp;</strong>
                                            {moment(props.dataItem.lastNotificationObj["createdAt"]).format('LT')}
                                            {props.dataItem.lastNotificationObj["error"] &&
                                                <small style={{ color: '#B01C2E' }}>
                                                    <br />
                                                    <strong>Error:&nbsp;</strong>
                                                    <i>{props.dataItem.lastNotificationObj["error"]}</i>
                                                </small>}
                                        </td>
                                    );
                                } else {
                                    return (<td />);
                                }
                            }}
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={{ ...props.style, fontSize: '12px' }}>
                                    <i
                                        className="far fa-square"
                                        style={{ backgroundColor: '#cce5ff' }}
                                    /> &nbsp;Survey Submitted <br />
                                    <i
                                        className="far fa-square"
                                        style={{ backgroundColor: '#fff3cd' }}
                                    /> &nbsp;Survey Not Submitted
                                </td>
                            )}
                        />}
                        <Column
                            filterable={false}
                            width="200px"
                            headerCell={(props) => (
                                <div className="text-center">
                                    {filterBy(employeesList, filter).length === employeesListDefault.length ?
                                        <Button
                                            disabled={
                                                employeesListDefault
                                                    .filter(emp => emp.currentStatus !== 'Terminated' &&
                                                        emp.currentStatus !== 'On Hold')
                                                    .length === 0
                                            }
                                            onClick={() => {
                                                setSendToAll(true);
                                                setShowSendDialog(true);
                                            }}
                                        >
                                            Notify Everyone
                                        </Button> :
                                        <Button
                                            disabled={
                                                filterBy(employeesList, filter)
                                                    .filter(emp => emp.currentStatus !== 'Terminated' &&
                                                        emp.currentStatus !== 'On Hold')
                                                    .length === 0
                                            }
                                            onClick={() => {
                                                setSelectedEmps(filterBy(employeesList, filter)
                                                    .filter(emp => emp.currentStatus !== 'Terminated' &&
                                                        emp.currentStatus !== 'On Hold'));
                                                setShowSendDialog(true);
                                            }}
                                        >
                                            Notify Filtered
                                        </Button>}
                                </div>
                            )}
                            cell={props => (
                                <td>
                                    <ButtonGroup >
                                        <Button
                                            iconClass="far fa-edit"
                                            onClick={() => {
                                                setSelectedEmp(props.dataItem);
                                                setShowWindow(true);
                                            }}
                                        ></Button>
                                        <Button
                                            iconClass="far fa-trash-alt"
                                            style={{
                                                backgroundColor: '#B01C2E',
                                                borderColor: '#B01C2E',
                                                color: 'white',
                                            }}
                                            onClick={() => {
                                                setSelectedEmp(props.dataItem);
                                                setShowDeleteDialog(true);
                                            }}
                                        ></Button>
                                    </ButtonGroup>
                                    <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>
                                    <Button
                                        disabled={props.dataItem.currentStatus === 'On Hold' || props.dataItem.currentStatus === 'Terminated'}
                                        onClick={() => {
                                            setSelectedEmp(props.dataItem);
                                            setShowSendDialog(true);
                                        }}
                                    >
                                        Notify
                                    </Button>
                                </td>
                            )}
                            footerCell={(props) => {
                                if (isMobile || isNarrowScreen) {
                                    return null;
                                }
                                return (<td colSpan={props.colSpan} style={props.style} className="text-center">
                                    <Button
                                        onClick={() => {
                                            setShowBulkLoadDialog(true);
                                        }}
                                    >
                                        Upload Employees
                                    </Button>
                                </td>);
                            }}
                        />
                    </Grid>
                </Card></div>
            </div>
        </div>
    );
};

export default Employees;
