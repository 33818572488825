/* eslint-disable no-template-curly-in-string */
import React, { Fragment, useEffect, useState } from "react";
import {
    Accordion,
    Button as Btn,
    Card, Col, Row, Form, Container, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { isNull } from "lodash";
import { filterBy } from '@progress/kendo-data-query';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import Axios from "axios";
import getTokenConfig from "../../../methods/getTokenConfig.js";
import '../../stylesheets/kendoGrid.css';
import AttachmentsPreviewModal from "../../util/attachmentsPreviewModal.jsx";

const lastSevenDaysDefaultAfterFilter = new Date();
lastSevenDaysDefaultAfterFilter.setDate(lastSevenDaysDefaultAfterFilter.getDate() - 7);

let _pdfExport;

const initialDataState = {
  skip: 0,
  take: 250,
};

const DetailComponent = ({ dataItem }) => {
    const answers = dataItem.answers.filter(ans => ans.qId !== '');
    const userEmail = dataItem.employeeObj && dataItem.employeeObj.email ? dataItem.employeeObj.email :
        dataItem.visitorObj && dataItem.visitorObj.email ? dataItem.visitorObj.email : '';
    const userCellNumber = dataItem.employeeObj && dataItem.employeeObj.cellNumber ? dataItem.employeeObj.cellNumber :
        dataItem.visitorObj && dataItem.visitorObj.cellNumber ? dataItem.visitorObj.cellNumber : '';
    return (
        <section>
            {answers.map((ans, i) => (
                <div key={`q${i}`} className="mb-3">
                    <strong>
                        Q{i + 1}:&nbsp;&nbsp;&nbsp;
                        {/* Below just functions to replace the variables used in each question with the corresponding value
                          * when clicking the + to expand the detail component. If no variables are used or no values are associated
                          * with the variable, then it will just print ans.queObj.questionString. */}
                        {ans.queObj && ans.queObj.questionString &&
                            (!ans.queObj.questionString.includes('${Site}') || !ans.previousSite) &&
                            (!ans.queObj.questionString.includes('${Site}') || !dataItem.previousSite) &&
                            (!ans.queObj.questionString.includes('${Email}') || !userEmail) &&
                            (!ans.queObj.questionString.includes('${Cell Phone}') || !userCellNumber) ?
                            ans.queObj.questionString : ((ans.queObj.questionString.includes('${Site}') &&
                                ans.previousSite) || (ans.queObj.questionString.includes('${Site}') && dataItem.previousSite)) ?
                                ans.queObj.questionString.replace('${Site}', ans.previousSite ? ans.previousSite : dataItem.previousSite) :
                                ans.queObj.questionString.includes('${Email}') && userEmail ?
                                    ans.queObj.questionString.replace('${Email}', userEmail) :
                                    ans.queObj.questionString.replace('${Cell Phone}', userCellNumber)}
                    </strong>
                    {ans.queObj && ans.queObj.questionType === 'list' ?
                        <Fragment>
                            <br />
                            <ul
                                style={{
                                    listStyleType: 'none',
                                }}
                            >
                                {ans.queObj && ans.queObj.options
                                    .map((op, i) =>
                                        <li key={'li' + i}>
                                            {op}
                                        </li>)}
                            </ul>
                        </Fragment>
                        : <br />
                    }
                    <strong>Ans:</strong>&nbsp;
                    {new Date(ans.ans).toString() !== 'Invalid Date' && ans.queObj.questionType !== 'Yes/No' &&
                        <>{moment(ans.ans).format("ddd, M/D/YYYY")}</>
                    }
                    {new Date(ans.ans).toString() !== 'Invalid Date' && ans.queObj.questionType !== 'date' &&
                        <>Yes</>
                    }
                    {new Date(ans.ans).toString() === 'Invalid Date' && ans.queObj.questionType !== 'date' &&
                        <>{ans.ans}</>
                    }
                    {ans && ans.siteAttended && ans.siteAttended.trim() !== '' &&
                        <small><i>
                            <br />
                            <strong>
                                Allow Site Update:&nbsp;
                            </strong>
                            {ans.allowSiteUpdate ? 'Yes' : 'No'}
                        </i></small>}
                    {ans && ans.siteAttended && ans.siteAttended.trim() !== '' &&
                        <small><i>
                            <br />
                            <strong>
                                Site Attended:&nbsp;
                            </strong>
                            {ans.siteAttended}
                        </i></small>}
                    {ans && ans.notes && ans.notes.trim() !== '' &&
                        <small><i>
                            <br />
                            <strong>
                                {ans.queObj.queStringAboveNotesField ? `${ans.queObj.queStringAboveNotesField} (Notes):` : 'Notes:'}&nbsp;
                            </strong>
                            {ans.notes}
                        </i></small>}
                </div>
            ))}
            {(dataItem.displayUseCase === 'Sign In Management' || (dataItem.displayUseCase === 'ALL USE CASES' && dataItem.useCase === 'Sign In Management')) &&
                <div key={`q${answers.length}`} className="mb-3">
                    <strong>
                        Time Completed:
                    </strong><br />
                    <strong>{dataItem.timeCompleted}</strong>
                </div>}
        </section>
    );
}

const ReportsMain = ({ history }) => {
    const [filter, setFilter] = useState();
    const [showFilterOptions, setShowFilterOptions] = useState();

    const [dateAccordionOpen, setDateAccordionOpen] = useState(false);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const [dateToFilterOn, setDateToFilterOn] = useState('Screening Completed');

    const [isDefaultAfterFilter, setIsDefaultAfterFilter] = useState(true);

    const [initialFetchOrDateChange, setInitialFetchOrDateChange] = useState(true);

    const [fetchingData, setFetchingData] = useState(true);

    const [maxNumberAlreadyFetched, setMaxNumberAlreadyFetched] = useState(0);

    const [afterFilter, setAfterFilter] = useState(lastSevenDaysDefaultAfterFilter);
    const [beforeFilter, setBeforeFilter] = useState(null);

    const [screeningData, setScreeningData] = useState([]);
    const [screeningDefaultData, setScreeningDefaultData] = useState([]);
    const [excelScreeningData, setExcelScreeningData] = useState([]);

    const [mgsType, setMgsType] = useState({ status: null, message: '' });

    const [forceUpdate, setForceUpdate] = useState(false);

    const [previewAttachments, setPreviewAttachments] = useState([]);

    const [useCase, setUseCase] = useState('');
    const [displayUseCase, setDisplayUseCase] = useState('');

    const [page, setPage] = useState(initialDataState);

    const [totalResults, setTotalResults] = useState(0);

    const _excelExport = React.useRef(null);

    const _excelExportDetailed = React.useRef(null);

    const excelExport = () => {
        if (_excelExport.current !== null) {
            _excelExport.current.save();
        }
    };

    const excelExportDetailed = () => {
        if (_excelExportDetailed.current !== null) {
            _excelExportDetailed.current.save();
        }
    };

    const expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        setForceUpdate(!forceUpdate);
    };

    const pageChange = (event) => {
      setPage(event.page);
    };

    // This method adds rows below each "dataItem" that will replicate the functionality of when a dataItem is expanded.
    // Each row following a screening will have the question as well as the answer and any applicable notes
    const buildExcelReport = (screeningData) => {
        let excelData = [];
        let limitedScreeningData = [];
        if (screeningData.length <= 1000) {
            limitedScreeningData = screeningData;
        } else {
            limitedScreeningData = screeningData.slice(0, 1000);
        }
        limitedScreeningData.forEach((dataItem) => {
            excelData.push(dataItem);
            const answers = dataItem.answers.filter(ans => ans.qId !== '');
            const userEmail = dataItem.employeeObj && dataItem.employeeObj.email ? dataItem.employeeObj.email :
                dataItem.visitorObj && dataItem.visitorObj.email ? dataItem.visitorObj.email : '';
            const userCellNumber = dataItem.employeeObj && dataItem.employeeObj.cellNumber ? dataItem.employeeObj.cellNumber :
                dataItem.visitorObj && dataItem.visitorObj.cellNumber ? dataItem.visitorObj.cellNumber : '';

            answers.forEach((ans, j) => {
                if (!(ans.queObj && ans.queObj.questionString)) {
                    ans.queObj = { questionString: '' };
                }
                excelData.push({
                    timeCompleted: `Q${j + 1}:   ${ans.queObj && ans.queObj.questionString &&
                        (!ans.queObj.questionString.includes('${Site}') || !ans.previousSite) &&
                        (!ans.queObj.questionString.includes('${Site}') || !dataItem.previousSite) &&
                        (!ans.queObj.questionString.includes('${Email}') || !userEmail) &&
                        (!ans.queObj.questionString.includes('${Cell Phone}') || !userCellNumber) ?
                        ans.queObj.questionString : ((ans.queObj.questionString.includes('${Site}') &&
                            ans.previousSite) || (ans.queObj.questionString.includes('${Site}') && dataItem.previousSite)) ?
                            ans.queObj.questionString.replace('${Site}', ans.previousSite ? ans.previousSite : dataItem.previousSite) :
                            ans.queObj.questionString.includes('${Email}') && userEmail ?
                                ans.queObj.questionString.replace('${Email}', userEmail) :
                                ans.queObj.questionString.replace('${Cell Phone}', userCellNumber)}`
                });
                if (ans.queObj && ans.queObj.questionType === 'list' && ans.queObj.options) {
                    ans.queObj.options
                        .forEach((option) => {
                            excelData.push({ timeCompleted: option });
                        }
                        );
                }
                excelData.push({
                    timeCompleted: `A${j + 1}:   ${new Date(ans.ans).toString() !== 'Invalid Date' && ans.queObj.questionType !== 'Yes/No' ?
                        moment(ans.ans).format("ddd, M/D/YYYY") : ''}${new Date(ans.ans).toString() !== 'Invalid Date' && ans.queObj.questionType !== 'date' ?
                            'Yes' : ''}${new Date(ans.ans).toString() === 'Invalid Date' && ans.queObj.questionType !== 'date' ?
                                ans.ans : ''}`
                });
                if (ans && ans.siteAttended && ans.siteAttended.trim() !== '') {
                    excelData.push({ timeCompleted: `Allow Site Update: ${ans.allowSiteUpdate ? 'Yes' : 'No'}` });
                    excelData.push({ timeCompleted: `Site Attended: ${ans.siteAttended}` });
                }
                if (ans && ans.notes && ans.notes.trim() !== '') {
                    excelData.push({ timeCompleted: `${ans.queObj.queStringAboveNotesField ? `${ans.queObj.queStringAboveNotesField} (Notes):` : 'Notes:'} ${ans.notes}` });
                }
            });
        });
        setExcelScreeningData(excelData);
    }

    const dateFiltering = (filterOn, dateValue = null, screeningList = []) => {
        let result = [];
        if (filterOn === 'screeningChange') {
            let dateToFilterOnOverride;
            if (!isNull(dateValue)) {
                dateToFilterOnOverride = dateValue;
            } else {
                dateToFilterOnOverride = dateToFilterOn;
            }
            result = [...screeningList
                .filter(sd => {
                    let filterValue;
                    if (dateToFilterOnOverride === 'User Created') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.createdAt) :
                            new Date(sd.visitorObj.createdAt);
                    } else if (dateToFilterOnOverride === 'User Updated') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.updatedAt) :
                            new Date(sd.visitorObj.updatedAt);
                    }
                    if (afterFilter && isNull(beforeFilter)) {
                        return filterValue > afterFilter;
                    } else if (beforeFilter && isNull(afterFilter)) {
                        return filterValue < beforeFilter;
                    } else if (afterFilter && beforeFilter) {
                        return filterValue > afterFilter &&
                            filterValue < beforeFilter;
                    } return false;
                })
            ];
            setScreeningData(result);
            buildExcelReport(result);
        } else if ((beforeFilter && filterOn === 'after') || (afterFilter && filterOn === 'before')) {
            result = [...screeningDefaultData
                .filter(sd => {
                    let filterValue;
                    if (dateToFilterOn === 'User Created') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.createdAt) :
                            new Date(sd.visitorObj.createdAt);
                    } else if (dateToFilterOn === 'User Updated') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.updatedAt) :
                            new Date(sd.visitorObj.updatedAt);
                    }
                    if (filterOn === 'after') {
                        return filterValue > dateValue
                            && filterValue < beforeFilter;
                    } else if (filterOn === 'before') {
                        return filterValue < dateValue
                            && filterValue > afterFilter;
                    } return false;
                })
            ];
            setScreeningData(result);
            buildExcelReport(result);
        } else {
            result = [...screeningDefaultData
                .filter(sd => {
                    let filterValue;
                    if (dateToFilterOn === 'User Created') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.createdAt) :
                            new Date(sd.visitorObj.createdAt);
                    } else if (dateToFilterOn === 'User Updated') {
                        filterValue = sd.employeeObj ? new Date(sd.employeeObj.updatedAt) :
                            new Date(sd.visitorObj.updatedAt);
                    }
                    if (filterOn === 'after') {
                        return filterValue > dateValue;
                    } else if (filterOn === 'before') {
                        return filterValue < dateValue;
                    } return false;
                })
            ];
            setScreeningData(result);
            buildExcelReport(result);
        }
    };

    const mapResultsToGridData = ({ dataRes, allQuestions }) => {
        dataRes.forEach((d) => {
            if (d.employeeId) {
                d.firstName = d.employeeObj['firstName'];
                d.lastName = d.employeeObj['lastName'];
                d.fullName = `${d.employeeObj['firstName']} ${d.employeeObj['lastName']}`;
                d.allGroups = `${d.userSite && d.userSite['name'] ? d.userSite['name'] :
                    ''}${d.userDepartment && d.userDepartment['name'] ? d.userDepartment['name'] :
                    ''}${d.userGroup && d.userGroup['name'] ? d.userGroup['name'] : ''}`;
                d.cellNumber = d.employeeObj['cellNumber'];
                d.email = d.employeeObj['email'];
                if (displayUseCase !== 'Sign In Management') {
                    d.attachments = d.employeeObj['attachments'];
                }
                // below is not used
                d.currentStatus = d.employeeObj['currentStatus'];
                d.user = 'Employee';
                if (displayUseCase !== 'Sign In Management') {
                d.noOfAttachments =
                    d.employeeObj['attachments'] ?
                    Object.keys(d.employeeObj['attachments']).length : 0;
                }
                d.site = d.userSite && d.userSite['name'] ? d.userSite['name'] : '';
                d.department = d.userDepartment && d.userDepartment['name'] ? d.userDepartment['name'] : '';
                d.otherGroup = d.userGroup && d.userGroup['name'] ? d.userGroup['name'] : '';
            } else if (d.visitorId) {
                d.firstName = d.visitorObj['firstName'];
                d.lastName = d.visitorObj['lastName'];
                d.fullName = `${d.visitorObj['firstName']} ${d.visitorObj['lastName']}`;
                d.allGroups = 'N/A';
                d.cellNumber = d.visitorObj['cellNumber'];
                d.email = d.visitorObj['email'];
                d.attachments = d.visitorObj['attachments'];
                // Below is not used
                d.currentStatus = 'N/A';
                d.user = 'Visitor';
                if (displayUseCase !== 'Sign In Management') {
                    d.noOfAttachments =
                        d.visitorObj['attachments'] ?
                        Object.keys(d.visitorObj['attachments']).length : 0;
                }
            }
            d.displayUseCase = displayUseCase;
            d.useCase = useCase;
            d.timeCompleted = moment(d.screeningCompletedAt).format("h:mm a");
            d.excelResult = `${d.result[0].toUpperCase()}${d.result.substring(1)}`;
            // Below does not appear to be used
            d.notificationSentAt = new Date(d.notificationSentAt);
            d.screeningCompletedAt = new Date(d.screeningCompletedAt);
            d.answers.forEach((ans) => {
                ans.queObj = allQuestions.find(q => q._id === ans.qId);
            });
        });
        return dataRes;
    };

    const handleDateAccordionToggle = () => {
        setDateAccordionOpen(!dateAccordionOpen);
    };
    
    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }

        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
            setIsNarrowScreen(true);
            } else {
            setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
            setIsNarrowScreen(true);
            } else {
            setIsNarrowScreen(false);
            }
        });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/getOwnOrgDetails/', getTokenConfig())
            .then(res => {
                if (res.status === 200) {
                    setUseCase(res.data.useCase);
                    setDisplayUseCase(res.data.useCase);
                }
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
    }, [history]);

    useEffect(() => {
        if (displayUseCase && initialFetchOrDateChange) {
            if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
                history.push('/mfaAuth');
            if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
                history.push('/paymentSetup');
            setFetchingData(true);
            Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/reports/all/byOrgIdCount/', {
                params: {
                    displayUseCase,
                    afterFilter,
                    beforeFilter,
                    dateToFilterOn,
                }, headers: getTokenConfig().headers
            })
                .then(res => {
                    if (res.status === 200) {
                        setTotalResults(res.data.numOfResults);
                    }
                }).catch((error) => {
                    setMgsType({
                        status: 'error',
                        message: error.response && error.response.data && error.response.data.msg,
                    });
                });
            Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/reports/all/byOrgId/', {
                params: {
                    displayUseCase,
                    afterFilter,
                    beforeFilter,
                    dateToFilterOn,
                }, headers: getTokenConfig().headers
            })
                .then(res => {
                    if (res.status === 200) {
                        const dataRes = res.data.screeningResults;
                        const allQuestions = res.data.allQuestions;

                        const mappedResults = mapResultsToGridData({ dataRes, allQuestions });

                        // No need to use the dateFiltering method if the dateToFilterOn is 'Screening Completed' as that filtering happens server side
                        if ((isNull(afterFilter) && isNull(beforeFilter)) || dateToFilterOn === 'Screening Completed') {
                            setScreeningData(mappedResults);
                            buildExcelReport(mappedResults);
                        } else {
                            dateFiltering('screeningChange', null, mappedResults);
                        }
                        setScreeningDefaultData(mappedResults);
                        setPage(initialDataState);
                        setInitialFetchOrDateChange(false);
                        setMaxNumberAlreadyFetched(0);
                        setFetchingData(false);
                    }
                }).catch((error) => {
                    setMgsType({
                        status: 'error',
                        message: error.response && error.response.data && error.response.data.msg,
                    });
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, displayUseCase, dateToFilterOn, afterFilter, beforeFilter]);

    useEffect(() => {
        if (displayUseCase && page.skip > maxNumberAlreadyFetched && !initialFetchOrDateChange &&
            ((page.take === 100 && page.skip >= 9900 && page.skip % 10000 === 9900 && (page.skip + page.take) < totalResults) ||
            (page.take === 250 && page.skip >= 9750 && page.skip % 10000 === 9750 && (page.skip + page.take) < totalResults) ||
            (page.take === 500 && page.skip >= 9500 && page.skip % 10000 === 9500 && (page.skip + page.take) < totalResults))) {
            if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
                history.push('/mfaAuth');
            if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
                history.push('/paymentSetup');
            setFetchingData(true);
            Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/reports/all/byOrgId/', {
                params: {
                    displayUseCase,
                    afterFilter,
                    beforeFilter,
                    dateToFilterOn,
                    skip: page.skip,
                    take: page.take,
                }, headers: getTokenConfig().headers
            })
                .then(res => {
                    if (res.status === 200) {
                        const dataRes = res.data.screeningResults;
                        const allQuestions = res.data.allQuestions;

                        const mappedResults = mapResultsToGridData({ dataRes, allQuestions });

                        // No need to use the dateFiltering method if the dateToFilterOn is 'Screening Completed' as that filtering happens server side
                        if ((isNull(afterFilter) && isNull(beforeFilter)) || dateToFilterOn === 'Screening Completed') {
                            setScreeningData(screeningData.concat(mappedResults));
                            buildExcelReport(screeningData.concat(mappedResults));
                        } else {
                            dateFiltering('screeningChange', null, screeningData.concat(mappedResults));
                        }
                        setScreeningDefaultData(screeningData.concat(mappedResults));
                        setMaxNumberAlreadyFetched(page.skip);
                        setFetchingData(false);
                    }
                }).catch((error) => {
                    setMgsType({
                        status: 'error',
                        message: error.response && error.response.data && error.response.data.msg,
                    });
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, page.skip]);

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Reports</span>
                </Card.Header>
            </Card>
            {mgsType.status !== 'warning' &&
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup>}
            {previewAttachments.length !== 0 &&
                <AttachmentsPreviewModal
                    previewAttachments={previewAttachments}
                    setPreviewAttachments={setPreviewAttachments}
                />}
            <div style={{ padding: 15 }}>
                <Accordion defaultActiveKey="1" className="mb-3">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} onClick={() => handleDateAccordionToggle()}>
                            <strong>Date Filtering</strong>&nbsp;{isDefaultAfterFilter && <i><small>Showing results from the last 7 days...</small></i>}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Container>
                                    <Row md={12}>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>Filter on Date of:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DropDownList
                                                    value={dateToFilterOn}
                                                    data={["Screening Completed", "User Created", "User Updated"]}
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => {
                                                        setDateToFilterOn(e.value);
                                                        if (!isNull(afterFilter) || !isNull(beforeFilter)) {
                                                            dateFiltering('screeningChange', e.value, filterBy(screeningData, filter));
                                                        }
                                                        setIsDefaultAfterFilter(false);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>On/After:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={afterFilter}
                                                    onChange={(event) => {
                                                        setAfterFilter(event.target.value);
                                                        dateFiltering('after', event.target.value);
                                                        setIsDefaultAfterFilter(false);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                        setIsDefaultAfterFilter(false);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>By/Before:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={beforeFilter}
                                                    onChange={(event) => {
                                                        setBeforeFilter(event.target.value);
                                                        dateFiltering('before', event.target.value);
                                                        setIsDefaultAfterFilter(false);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setBeforeFilter(null);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    &nbsp;
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                        setBeforeFilter(null);
                                                        setScreeningData(screeningDefaultData);
                                                        setIsDefaultAfterFilter(false);
                                                        setInitialFetchOrDateChange(true);
                                                    }}
                                                >&nbsp;Clear Dates</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <div style={{ paddingBottom: isMobile ? 50 : undefined }}><Card>
                    <Card.Header>
                        <Row>
                            <Col md="auto"><strong>Results</strong></Col>
                            {fetchingData && <Col style={{ color: 'red' }} className="text-right"><strong>FETCHING DATA! Please wait...</strong></Col>}
                            <Col className="text-right">
                                <strong>View:</strong>&nbsp;
                                <DropDownList
                                    data={[useCase].concat(["COVID Screening", "General Survey", "Sign In Management", "ALL USE CASES"].filter(uC => useCase !== uC))}
                                    style={{ backgroundColor: 'white' }}
                                    value={displayUseCase}
                                    onChange={(e) => {
                                        setDisplayUseCase(e.value);
                                        setInitialFetchOrDateChange(true);
                                    }}
                                />
                                {!isNarrowScreen && <>
                                <span> | </span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                            {(page.take + maxNumberAlreadyFetched + 10000) >= totalResults ? 'Export to Excel the list of results.' : <>Currently, this will only download the first {page.take + maxNumberAlreadyFetched < 10000 ? 10000 : page.take + maxNumberAlreadyFetched + 10000} records.<br /><br />Please navigate to the final page of results to continue loading...</>}
                                            </i>
                                        </Tooltip>}
                                >
                                    <span><Button
                                        icon="download"
                                        onClick={excelExport}
                                        primary
                                    >Excel&nbsp;{(page.take + maxNumberAlreadyFetched + 10000) < totalResults && <i className="fas fa-info-circle" />}</Button></span>
                                </OverlayTrigger>
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                            {totalResults <= 1000 ? 'Export to Excel the list of results and all associated questions & answers.' : <>Currently, this will only print the first 1,000 records.<br /><br />Please limit results via Date Filtering OR column filtering to under 1,000 records OR download to Excel (not Detailed).</>}
                                            </i>
                                        </Tooltip>}
                                >
                                    <span><Button
                                        icon="download"
                                        onClick={excelExportDetailed}
                                        primary
                                    >Excel (Detailed)&nbsp;{totalResults > 1000 && <i className="fas fa-info-circle" />}</Button></span>
                                </OverlayTrigger>
                                &nbsp;
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                            {totalResults <= 500 ? 'Export to PDF.' : <>Currently, this will only print the first 500 records.<br /><br />Please limit results via Date Filtering OR column filtering to under 500 records OR download to Excel (not Detailed).</>}
                                            </i>
                                        </Tooltip>}
                                >
                                    <span><Button
                                        icon="download"
                                        onClick={() => _pdfExport.save()}
                                        primary
                                    >PDF&nbsp;{totalResults > 500 && <i className="fas fa-info-circle" />}</Button></span>
                                </OverlayTrigger>
                                <span> | </span>
                                {/* <ButtonGroup> */}
                                    <OverlayTrigger
                                        placement={showFilterOptions ? "left" : "top"}
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                <i>
                                                    {!showFilterOptions ? 'Enable' : 'Disable and clear'} filters.
                                                </i>
                                            </Tooltip>}
                                    >
                                        <Button
                                            icon={!showFilterOptions ? "filter" : "filter-clear"}
                                            onClick={() => {
                                                setFilter(null);
                                                setShowFilterOptions(!showFilterOptions);
                                            }}
                                            primary
                                        />
                                    </OverlayTrigger>
                                {/* </ButtonGroup> */}
                                </>}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Grid
                        style={{
                            height: !dateAccordionOpen ? 'calc(100vh - 222px - 123px)' : 'calc(100vh - 222px - 230px)',
                            minHeight: 450,
                        }}
                        data={filterBy(screeningData, filter).slice(page.skip, page.take + page.skip)}
                        skip={page.skip}
                        take={page.take}
                        total={filterBy(screeningData, filter).length}
                        pageable={{ pageSizes: [100, 250, 500] }}
                        pageSize={page.pageSize}
                        onPageChange={pageChange}
                        reorderable
                        filterable={showFilterOptions}
                        filter={filter}
                        onFilterChange={(e) => {
                            if (filterBy(screeningData, e.filter).length < page.skip) {
                                setPage({ skip: Math.floor(filterBy(screeningData, e.filter).length / page.take) * page.take, take: page.take });
                            }
                            setFilter(e.filter);
                            buildExcelReport(filterBy(screeningData, e.filter));
                        }}
                        detail={DetailComponent}
                        expandField="expanded"
                        onExpandChange={(event) => expandChange(event)}
                    >
                        <GridColumn
                            field="screeningCompletedAt"
                            title={displayUseCase === 'COVID Screening' ? "Screening Date" : "Completed Date"}
                            format="{0:d}"
                            filterable={false}
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={props.style}>
                                    {filterBy(screeningData, filter).length !== screeningDefaultData.length && 'Filtered '}Total: {
                                    filterBy(screeningData, filter).length !== screeningDefaultData.length ?
                                    filterBy(screeningData, filter).length : totalResults}
                                </td>
                            )}
                        />
                        {!isNarrowScreen && <GridColumn
                            field="user"
                            title="User Type"
                        />}
                        <GridColumn
                            field="fullName"
                            title="Name"
                        />
                        {!isMobile && !isNarrowScreen && <GridColumn
                            field="allGroups"
                            title="All Groups"
                            cell={props => (
                                <td>
                                    {props.dataItem['site'] &&
                                        <>
                                            <strong>Site:</strong> {props.dataItem['site']}
                                            <br />
                                        </>}
                                    {props.dataItem['department'] &&
                                        <>
                                            <strong>Department:</strong> {props.dataItem['department']}
                                        </>}
                                    {props.dataItem['otherGroup'] &&
                                        <>
                                        <strong>Other:</strong> {props.dataItem['otherGroup']}&nbsp;
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-disabled">
                                                    <i>
                                                        This field will not be displayed once the group type of 'Site' or 'Department' has been set for <strong>{props.dataItem['otherGroup']}</strong>.
                                                        <br /><br />
                                                        Once the group type is set, <strong>{props.dataItem['otherGroup']}</strong> will be displayed accordingly.
                                                    </i>
                                                </Tooltip>}
                                        >
                                            <i className="fas fa-info-circle" />
                                        </OverlayTrigger>
                                        </>}
                                    {!props.dataItem['site'] &&
                                        !props.dataItem['department'] &&
                                        !props.dataItem['otherGroup'] &&
                                        props.dataItem['allGroups'] !== 'N/A' &&
                                        <strong>No Groups Configured!</strong>}
                                    {props.dataItem['allGroups'] === 'N/A' &&
                                        <strong>{props.dataItem['allGroups']}</strong>}
                                </td>
                            )}
                        />}
                        {!isMobile && !isNarrowScreen && (displayUseCase === 'Sign In Management' || (displayUseCase === 'ALL USE CASES' && useCase === 'Sign In Management')) &&
                            <GridColumn
                                field="siteAttended"
                                title="Site Attended"
                            />}
                        {!isMobile && !isNarrowScreen && <GridColumn
                            field="cellNumber"
                            title="Cell #"
                        />}
                        {!isMobile && !isNarrowScreen && <GridColumn
                            field="email"
                            title="Email"
                        />}
                        {!isMobile && !isNarrowScreen && displayUseCase !== 'Sign In Management' && displayUseCase !== 'ALL USE CASES' && <GridColumn
                            field="noOfAttachments"
                            title="Attachments"
                            filter="numeric"
                            cell={props => {
                                const value = props.dataItem[props.field];
                                return (
                                    <td>
                                        {value === 0 ?
                                            <p
                                                style={{
                                                    margin: 0,
                                                    paddingLeft: 12,
                                                }}
                                            >{value}
                                            </p> :
                                            <Btn
                                                variant="link"
                                                onClick={() => {
                                                    const attachmentsList = Object.values(props.dataItem['attachments'])
                                                    props.dataItem['user'] === "Employee" ?
                                                        attachmentsList.forEach(att => att.empId = props.dataItem['employeeId'])
                                                        : attachmentsList.forEach(att => att.visId = props.dataItem['visitorId']);
                                                    setPreviewAttachments(attachmentsList);
                                                }}
                                            >
                                                {value}
                                            </Btn>
                                        }
                                    </td>
                                );
                            }}
                            />}
                        <GridColumn
                            field="result"
                            title={`${displayUseCase === 'COVID Screening' ? "Screening " : ""}Result`}
                            cell={(props) => {
                                const result = props.dataItem[props.field];
                                if (result === 'passed' || result === 'Completed' || result === 'Attending') {
                                    return (<td style={{ backgroundColor: "rgb(55, 180, 0,0.32)" }}>
                                        {result === 'passed' ? 'Passed' : result}
                                    </td>);
                                } else if (result === 'failed' || result === 'Not answered' || result === 'Not attending') {
                                    return (<td style={{ backgroundColor: "rgb(243, 23, 0, 0.32)" }}>
                                        {result === 'failed' ? 'Failed' : result}
                                    </td>);
                                } else {
                                    return (<td>
                                        {result}
                                    </td>);
                                }
                            }}
                        />
                    </Grid>
                    <GridPDFExport
                        ref={(element) => { _pdfExport = element; }}
                        margin="1cm"
                    >
                        {<Grid
                            data={totalResults <= 500 ? filterBy(screeningData, filter) : filterBy(screeningData, filter).slice(0, 500)}
                            detail={DetailComponent}
                            expandField="expanded"
                            onExpandChange={(event) => expandChange(event)}
                        >
                            <GridColumn
                                field="screeningCompletedAt"
                                title={displayUseCase === 'COVID Screening' ? "Screening Date" : "Completed Date"}
                                format="{0:d}"
                                filterable={false}
                            />
                            <GridColumn
                                field="timeCompleted"
                                title="Time Completed"
                            />
                            <GridColumn
                                field="user"
                                title="User Type"
                            />
                            <GridColumn
                                field="firstName"
                                title="First Name"
                            />
                            <GridColumn
                                field="lastName"
                                title="Last Name"
                            />
                            <GridColumn
                                field="site"
                                title="Site"
                            />
                            {(displayUseCase === 'Sign In Management' || (displayUseCase === 'ALL USE CASES' && useCase === 'Sign In Management')) &&
                                <GridColumn
                                    field="siteAttended"
                                    title="Site Attended"
                                />}
                            <GridColumn
                                field="department"
                                title="Department"
                            />
                            <GridColumn
                                field="otherGroup"
                                title="Other Group"
                            />
                            <GridColumn
                                field="cellNumber"
                                title="Cell #"
                            />
                            <GridColumn
                                field="email"
                                title="Email"
                            />
                            <GridColumn
                                field="result"
                                title={`${displayUseCase === 'COVID Screening' ? "Screening " : ""}Result`}
                                cell={(props) => {
                                    const result = props.dataItem[props.field];
                                    if (result === 'passed') {
                                        return (<td style={{ backgroundColor: "rgb(55, 180, 0,0.32)" }}>
                                            Passed
                                        </td>);
                                    } else if (result === 'failed') {
                                        return (<td style={{ backgroundColor: "rgb(243, 23, 0, 0.32)" }}>
                                            Failed
                                        </td>);
                                    } else {
                                        return (<td>
                                            {result}
                                        </td>);
                                    }
                                }}
                            />
                        </Grid>}
                    </GridPDFExport>
                    <ExcelExport
                        fileName={`Check'N ${displayUseCase === 'COVID Screening' ? "Screening" : "Survey"} Results.xlsx`}
                        data={filterBy(screeningData, filter)}
                        ref={_excelExport}
                    >
                        <ExcelExportColumn
                            field="screeningCompletedAt"
                            title={displayUseCase === 'COVID Screening' ? "Screening Date" : "Completed Date"}
                            width="90px"
                        />
                        <ExcelExportColumn
                            field="timeCompleted"
                            title="Time Completed"
                            width="90px"
                        />
                        <ExcelExportColumn
                            field="user"
                            title="User Type"
                        />
                        <ExcelExportColumn
                            field="firstName"
                            title="First Name"
                        />
                        <ExcelExportColumn
                            field="lastName"
                            title="Last Name"
                        />
                        <ExcelExportColumn
                            field="site"
                            title="Site"
                            width="192px"
                        />
                        {useCase === 'Sign In Management' &&
                            <ExcelExportColumn
                                field="siteAttended"
                                title="Site Attended"
                                width="192px"
                            />}
                        <ExcelExportColumn
                            field="department"
                            title="Department"
                            width="192px"
                        />
                        <ExcelExportColumn
                            field="otherGroup"
                            title="Other Group"
                            width="192px"
                        />
                        <ExcelExportColumn
                            field="cellNumber"
                            title="Cell #"
                        />
                        <ExcelExportColumn
                            field="email"
                            title="Email"
                        />
                        <ExcelExportColumn
                            field="excelResult"
                            title={`${useCase === 'COVID Screening' ? "Screening " : ""}Result`}
                        />
                    </ExcelExport>
                    <ExcelExport
                        fileName={`Check'N ${useCase === 'COVID Screening' ? "Screening" : "Survey"} Detailed Results.xlsx`}
                        data={excelScreeningData}
                        ref={_excelExportDetailed}
                    >
                        <ExcelExportColumn
                            field="screeningCompletedAt"
                            title={useCase === 'COVID Screening' ? "Screening Date" : "Completed Date"}
                            width="90px"
                        />
                        <ExcelExportColumn
                            field="timeCompleted"
                            title="Time Completed"
                            width="90px"
                        />
                        <ExcelExportColumn
                            field="user"
                            title="User Type"
                        />
                        <ExcelExportColumn
                            field="firstName"
                            title="First Name"
                        />
                        <ExcelExportColumn
                            field="lastName"
                            title="Last Name"
                        />
                        <ExcelExportColumn
                            field="site"
                            title="Site"
                            width="192px"
                        />
                        {(displayUseCase === 'Sign In Management' || (displayUseCase === 'ALL USE CASES' && useCase === 'Sign In Management')) &&
                            <ExcelExportColumn
                                field="siteAttended"
                                title="Site Attended"
                                width="192px"
                            />}
                        <ExcelExportColumn
                            field="department"
                            title="Department"
                            width="192px"
                        />
                        <ExcelExportColumn
                            field="otherGroup"
                            title="Other Group"
                            width="192px"
                        />
                        <ExcelExportColumn
                            field="cellNumber"
                            title="Cell #"
                        />
                        <ExcelExportColumn
                            field="email"
                            title="Email"
                        />
                        <ExcelExportColumn
                            field="excelResult"
                            title={`${displayUseCase === 'COVID Screening' ? "Screening " : ""}Result`}
                        />
                    </ExcelExport>
                </Card></div>
            </div>
        </div>
    );
};

export default ReportsMain;
