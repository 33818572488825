import React from "react";
import { Result } from 'antd';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap'
import { Button } from '@progress/kendo-react-buttons';
import { isMobile } from 'react-device-detect';
import { VerifiedIcon, FailedIcon, NotVerifiedIcon } from './verificationIcons';

const ResultsPage = ({
    resultObj,
    companyPhone,
    companyContactEmail,
    passMsg,
    failMsg,
    timesToVerify,
    qrResult,
    verifyProofOfVaccineNeeded,
    alreadyVerified,
    useCase,
}) => {
    let pageStatus = resultObj.status;
    if (resultObj && resultObj.screeningResult && resultObj.screeningResult === 'Completed') {
        pageStatus = 'success';
    }
    if (resultObj && resultObj.msg && resultObj.msg === 'Survey not answered today') {
        pageStatus = 'error';
    }
    let pageTitle = resultObj.msg;
    let qrCodeVerified = qrResult ? qrResult.qrCodeVerified : false;
    let qrCodeVerifiedIcon = '';
    const screeningTime = moment(resultObj.screeningTime).format(' h:mm a, MMMM Do YYYY');
    // eslint-disable-next-line no-unused-vars
    let prevResult;
    let screeningMsg = 'Reach out to your organization if you need any further instruction.';
    if (passMsg && pageTitle && (pageTitle === 'Screening passed' || pageTitle === 'Completed' || pageTitle === 'Attending')) {
        if (screeningTime) {
            pageTitle='';
            pageTitle = `${useCase !== 'Sign In Management' ? 'Completed' : 'Submitted'} at ${screeningTime}`;
        }
        screeningMsg = passMsg;
    } else if (failMsg && pageTitle && (pageTitle === 'Screening failed' ||
        (useCase !== 'COVID Screening' && pageTitle === 'No need for screening today') ||
        (useCase === 'General Survey' && pageTitle === 'Survey not answered today') || pageTitle === 'Not attending')) {
        if (screeningTime) {
            pageTitle = `${useCase !== 'Sign In Management' ? pageTitle : 'Submitted'}${useCase === 'General Survey' ?
                ' but was submitted' : ''} at ${screeningTime}`
        }
        screeningMsg = failMsg;
    } else if ((pageTitle && pageTitle === 'No need for screening today' && screeningTime) || pageTitle === 'Not attending') {
        pageTitle = `${useCase !== 'Sign In Management' ? pageTitle : 'Submitted'} at ${screeningTime}`
    } else if (pageTitle && pageTitle === 'Screening already completed' && screeningTime) {
        pageTitle = `Completed at ${screeningTime}`;
        if (resultObj.screeningResult) {
            const color = resultObj.screeningResult === 'failed' || resultObj.screeningResult === 'Not attending' ||
            resultObj.screeningResult === 'Not answered' ? 'red' : 'green';
            prevResult = <p>Screening Result: <span style={{ color: color }}>{resultObj.screeningResult}</span></p>;
        }
    }
    let qrCodeMsg =''
    if (qrCodeVerified || (resultObj?.qrCodeVerification?.vaccineVerified) || alreadyVerified){
        qrCodeVerifiedIcon = <VerifiedIcon/>;
        qrCodeMsg = '\xa0\xa0\xa0Verified';
    } else if ((!qrCodeVerified && timesToVerify === 0) || (resultObj?.qrCodeVerification?.vaccineVerified)){
        qrCodeVerifiedIcon = <FailedIcon />;
        qrCodeMsg ='Not Verified'
    } else {
        qrCodeVerifiedIcon = <NotVerifiedIcon />;
        qrCodeMsg = 'Not Verified'
    }
    return (
        <>
            {verifyProofOfVaccineNeeded && useCase === 'COVID Screening' ?
            <Row md='12'>
                <Col md='6' style={{textAlign:'end'}}>
                    <Result
                        style={{ textAlign: isMobile ? 'center ': 'right' }}
                        status={pageStatus}
                        subTitle={'Screening'}
                    />
                </Col>
                <Col md='6' >
                    <Result
                        style={{ textAlign: isMobile ? 'center ' : 'left' }}
                        icon={qrCodeVerifiedIcon}
                        subTitle={qrCodeMsg}
                    />
                </Col>
            </Row>
            :
            <Row md='12'>
                <Col md='12' >
                    <Result
                        style={{ textAlign: 'center '}}
                        status={pageStatus}
                        subTitle={useCase === 'COVID Screening' ? 'Screening' : useCase === 'General Survey' ?
                            'Survey Submitted' : useCase === 'Sign In Management' && pageStatus === 'success' ? 'Signed In' :
                            useCase === 'Sign In Management' && pageStatus !== 'success' ? 'Not Signed In' : 'Survey Submitted'}
                    />
                </Col>
            </Row>
            }
            <Row md='12'>
                <Col md='12' style={{ textAlign: 'center', fontSize: '24px', color:'black' }}>
                    {pageTitle}
                </Col>
            </Row>
            <br />
            <Row md='12'>
                <Col md='12' style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0,0,0,.45)' }}>
                    {screeningMsg}
                </Col>
            </Row>
            <br />
            {companyPhone && companyPhone !== '(+1) ___-___-____' && <Row md='12'>
                <Col md='12' style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0,0,0,.45)' }}>
                    <b>Phone Number:</b> {companyPhone}
                </Col>
            </Row>}
            {companyContactEmail && companyContactEmail !== 'username@domain.com' && <Row md='12'>
                <Col md='12' style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0,0,0,.45)' }}>
                    <b>Email:</b> {companyContactEmail}
                </Col>
            </Row>}
            <Row md='12'>
                <Col md='12' style={{ textAlign: 'center' }}>
                       <div>
                            <a
                                href="https://www.checkn.ca"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: "20px" }}
                            >For your free trial, go to checkn.ca</a>
                            <br />
                            <br />
                            <Button
                                primary={true}
                                className="ml-3"
                                onClick={() => window.print()}>
                                    Print this result
                            </Button>
                        </div>
                </Col>
            </Row>
        </>
    );
};

export default ResultsPage;
