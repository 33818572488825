import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import axios from 'axios';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Result } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import '../../stylesheets/kendoNotifications.css';
import createTokenConfig from "../../../methods/createTokenConfig";

const ResetPassword = ({ history, match }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [error, setError] = useState({
        hasError: false,
        message: '',
    });

    const [resetSuccess, setResetSuccess] = useState(false);

    const resetPass = async () => {
        if (!newPassword) {
            setError({ hasError: true, message: 'New password can not be empty' });
        } else if (!confirmPassword) {
            setError({ hasError: true, message: 'Confirm password can not be empty' });
        } else if (newPassword !== confirmPassword) {
            setError({ hasError: true, message: 'New password and confirm password should be same' });
        } else {
            axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/organization/reset-pass',
                { newPassword },
                createTokenConfig(match.params.token),
            ).then(res => {
                if (res.status === 200) {
                    setResetSuccess(true);
                }
            }).catch((error) => {
                setError({
                    hasError: true,
                    message: error.response && error.response.data && error.response.data.msg
                });
            });
        }
    };

    return (
        <div style={{ padding: 15 }}>
            {!resetSuccess ?
                <Row md="12" className="justify-content-md-center">
                    <Col md="2"></Col>
                    <Col md="8">
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                        >
                            <Card.Header
                                style={{
                                    fontSize: "30px",
                                    backgroundColor: '#343a40',
                                    color: 'white'
                                }}
                            >Reset Password
                        </Card.Header>
                            <Card.Body>
                                <form>
                                    {error.hasError ?
                                        <Fade
                                            enter={true}
                                            exit={true}
                                            style={{ width: '100%' }}
                                            className="inline-notification"
                                        >
                                            <Notification
                                                type={{ style: 'error', icon: true }}
                                                style={{ width: '100%' }}
                                            >
                                                <span>{error.status}&nbsp;{error.message}</span>
                                            </Notification>
                                        </Fade>
                                        : null
                                    }
                                    <div className="form-group">
                                        <label><strong>New Password</strong></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Enter new password"
                                            onChange={(e) => {
                                                if (error) {
                                                    setError(false)
                                                }
                                                setNewPassword(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label><strong>Confirm Password</strong></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm password"
                                            onChange={(e) => {
                                                if (error) {
                                                    setError(false)
                                                }
                                                setConfirmPassword(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-block"
                                        style={{ backgroundColor: '#343a40', color: 'white' }}
                                        onClick={resetPass}
                                    >
                                        Reset
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="2"></Col>
                </Row>
                : <Result
                    status="success"
                    title="Password reset successfully!"
                    extra={<Button primary onClick={() => history.push('/signin')}>Sign in</Button>}
                />
            }
        </div>
    );
};
export default ResetPassword;
