import React, { useState } from "react";
import { Stepper } from "@progress/kendo-react-layout";
import { Button } from '@progress/kendo-react-buttons';
import { Fade } from '@progress/kendo-react-animation';
import { Notification } from '@progress/kendo-react-notification';
import axios from 'axios';
import { Col, Container, Row, Card } from "react-bootstrap";
import { useStripe, useElements, CardNumberElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CompanyInfo from './companyInfo';
import ContactInfo from './contactInfo';
import PaymentInfo from './paymentInfo';
import '../../stylesheets/kendoNotifications.css';
import { Divider } from "antd";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB);

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const steps = [
    { label: 'Company Details', icon: 'far fa-building', style: { minHeight: '100px' }, isValid: undefined },
    { label: 'Contact Details', icon: 'far fa-user', style: { minHeight: '100px' }, isValid: undefined },
    { label: 'Payment Details', icon: 'far fa-credit-card', style: { minHeight: '100px' }, isValid: undefined }
];

const SignUpBase = ({ history }) => {
    const [error, setError] = useState({ hasError: false, message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [step, setStep] = useState(0);

    const [isStep1Valid, setStep1Valid] = useState(false)
    const [isStep2Valid, setStep2Valid] = useState(false)

    // compony info states
    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyPassword, setCompanyPassword] = useState('');
    const [companyConfirmPassword, setCompanyConfirmPassword] = useState('');
    const [companyAddress1, setCompanyAddress1] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyCountry, setCompanyCountry] = useState('');
    const [companyStateProv, setCompanyStateProv] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);

    // primary contact info states
    const [contactFirstName, setContactFirstName] = useState('');
    const [contactLastName, setContactLastName] = useState('');
    const [contactPosition, setContactPosition] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    const registerOrganization = async () => {
        if (isStep1Valid && isStep2Valid) {
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: companyEmail,
                },
            });
            if (result.error) {
                setError({ hasError: true, message: result.error.message });
                setIsLoading(false);
            } else {
                const organization = {
                    companyName,
                    companyEmail,
                    companyPostalCode,
                    companyPassword,
                    companyAddress1,
                    companyAddress2,
                    companyCountry,
                    companyStateProv,
                    companyCity,
                    contactFirstName,
                    contactLastName,
                    contactPosition,
                    contactPhone,
                    contactEmail,
                    paymentMethodId: result.paymentMethod.id,
                };
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/signup/', organization)
                    .then(res => {
                        if (res.status === 200) {
                            history.push('/signin/verifyEmailSignIn');
                        } else {
                            setIsLoading(false);
                            setError({ hasError: true, message: 'Something went wrong' });
                        }
                    }).catch(err => {
                        setIsLoading(false);
                        setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                    });
            }
        }
    };

    const validateFields = () => {
        if (step === 0) {
            if ((!companyName || companyName.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Company name can not be empty.' });
            } else if ((!companyEmail || companyEmail.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Company email can not be empty.' });
            } else if ((!companyPostalCode || companyPostalCode.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Company postal code can not be empty.' });
            } else if (!emailRegex.test(companyEmail) && !error.hasError) {
                setError({ hasError: true, message: 'Company email is not a valid email address.' });
            } else if ((!companyPassword || companyPassword.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Password can not be empty.' });
            } else if (companyPassword !== companyConfirmPassword && !error.hasError) {
                setError({ hasError: true, message: 'Password and confirm password does not match.' });
            } else if ((!companyAddress1 || companyAddress1.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Address can not be empty.' });
            } else if ((!companyCountry || companyCountry.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Please select country.' });
            } else if ((!companyStateProv || companyStateProv.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Please select state.' });
            } else if ((!companyCity || companyCity.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'City can not be empty.' });
            } else if (!error.hasError) {
                setStep1Valid(true);
                setStep(step + 1)
            }
        } else if (step === 1) {
            if ((!contactFirstName || contactFirstName.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'First name can not be empty.' });
            } else if ((!contactLastName || contactLastName.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Last name can not be empty.' });
            } else if ((!contactPosition || contactPosition.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Position can not be empty.' });
            } else if ((!contactPhone || contactPhone.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Phone # can not be empty.' });
            } else if ((!contactEmail || contactEmail.trim() === '') && !error.hasError) {
                setError({ hasError: true, message: 'Email can not be empty.' });
            } else if (!emailRegex.test(contactEmail)) {
                setError({ hasError: true, message: 'Contact email is not a valid email address.' });
            } else if (!error.hasError) {
                setStep2Valid(true);
                setStep(step + 1)
            }
        }
        else if (step === 2) {
            if (error.hasError) {
                setError({ hasError: true, message: 'Something went wrong...' });
            } else {
                registerOrganization();
            }
        }
    };

    return (
        <div
            style={{ padding: 15 }}
        >
            <Row md="12">
                <Col md="3">
                    <Container className="mt-3 mb-5 text-center">
                        <strong>
                            Sign up in 3 easy steps.
                            <br /><br />
                            Enter the organization's details and the login credentials for the administrator.
                            <br /><br />
                            Note:
                        </strong>
                        &nbsp;These credentials will need to be shared if you require more than one person to manage Check'N.
                    </Container>
                    <Container className="mt-3 mb-5 text-center">
                        <strong>
                            <Divider orientation="center">Steps {step + 1}</Divider>
                        </strong>
                    </Container>
                    <Container className="text-center">
                        <Stepper
                            value={step}
                            items={steps}
                            orientation="vertical"
                        />
                    </Container>
                </Col>
                <Col md="9">
                    <Card
                        style={{
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}
                    >
                        <Card.Header
                            style={{
                                fontSize: "30px",
                                backgroundColor: '#343a40',
                                color: 'white'
                            }}
                        >Sign Up
                        </Card.Header>
                        <Card.Body>
                            {error.hasError ?
                                <Fade
                                    enter={true}
                                    exit={true}
                                    style={{ width: '100%' }}
                                    className="inline-notification"
                                >
                                    <Notification
                                        type={{ style: 'error', icon: true }}
                                        style={{ width: '100%' }}
                                    >
                                        <span>{error.message}</span>
                                    </Notification>
                                </Fade>
                                : null
                            }
                            {step === 0 ?
                                <CompanyInfo
                                    companyName={companyName}
                                    setCompanyName={setCompanyName}
                                    companyEmail={companyEmail}
                                    setCompanyEmail={setCompanyEmail}
                                    companyPassword={companyPassword}
                                    companyConfirmPassword={companyConfirmPassword}
                                    setCompanyConfirmPassword={setCompanyConfirmPassword}
                                    setCompanyPassword={setCompanyPassword}
                                    companyAddress1={companyAddress1}
                                    setCompanyAddress1={setCompanyAddress1}
                                    companyAddress2={companyAddress2}
                                    setCompanyAddress2={setCompanyAddress2}
                                    companyCountry={companyCountry}
                                    companyPostalCode={companyPostalCode}
                                    setCompanyPostalCode={setCompanyPostalCode}
                                    setCompanyCountry={setCompanyCountry}
                                    companyStateProv={companyStateProv}
                                    setCompanyStateProv={setCompanyStateProv}
                                    companyCity={companyCity}
                                    setCompanyCity={setCompanyCity}
                                    error={error}
                                    setError={setError}
                                    tooltipOpen={tooltipOpen}
                                    setTooltipOpen={setTooltipOpen}
                                />
                                : null
                            }
                            {step === 1 ?
                                <ContactInfo
                                    contactFirstName={contactFirstName}
                                    setContactFirstName={setContactFirstName}
                                    contactLastName={contactLastName}
                                    setContactLastName={setContactLastName}
                                    contactPosition={contactPosition}
                                    setContactPosition={setContactPosition}
                                    contactPhone={contactPhone}
                                    setContactPhone={setContactPhone}
                                    contactEmail={contactEmail}
                                    setContactEmail={setContactEmail}
                                    error={error}
                                    setError={setError}
                                />
                                : null
                            }
                            {step === 2 ?
                                <PaymentInfo
                                    companyEmail={companyEmail}
                                    isLoading={isLoading}
                                />
                                : null
                            }
                            <span style={{ marginTop: '5px', marginBottom: '10px' }} className={'k-form-separator'} />
                            <div
                                style={{ justifyContent: 'space-between', alignContent: 'center' }}
                                className={'k-form-buttons k-buttons-end'}
                            >
                                <span style={{ alignSelf: 'center' }}>Step {step + 1} of {steps.length}</span>
                                <div>
                                    {
                                        step !== 0 ? (
                                            <Button
                                                style={{ marginRight: '16px' }}
                                                onClick={() => setStep(step - 1)}
                                            >
                                                Previous
                                            </Button>
                                        ) : undefined
                                    }
                                    <Button
                                        disabled={!stripe}
                                        onClick={() => {
                                            if (isLastStep)
                                                setIsLoading(true);
                                            validateFields();
                                        }}
                                        style={{
                                            backgroundColor: '#343a40',
                                            color: 'white',
                                        }}
                                    >
                                        {isLastStep ? 'Submit' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const SignUp = (props) => (
    <Elements stripe={stripePromise}>
        <SignUpBase {...props} />
    </Elements>
);

export default SignUp;
