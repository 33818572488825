/*
   Copyright 2021 Queen’s Printer for Ontario

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
*/
/*
    src/containers/home/Scanner.tsx in OpenVerify project
*/
import React, { useState, useEffect, useRef } from "react";
import isEmpty from 'lodash/isEmpty';
import {
    Card,
    Modal,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import QrReader from 'modern-react-qr-reader'
import { applyRules } from '../../../utils/validate';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import ruleJson from '../../util/verifyRulesetON';
import QRCodeValidator from '../../../methods/qrCodeValidator';
import { Fade } from "@progress/kendo-react-animation";
import { isMobile } from 'react-device-detect';
import { Button } from "@progress/kendo-react-buttons";

const VerifyQrCode = ({ setScanned, setProceedToSubmit, setQRResult, scanMethod, setInvalidQrImg }) => {
    const qrValidator = new QRCodeValidator();
    const reader = useRef(null);

    const onReadQRCode = (data) => {
        //TODO improvement development: we will fetch the ruleset instead of importing ruleJson from the verifyRulesetON file
        let response = qrValidator.validateQR(ruleJson?.publicKeys, data);
        if (
            response.valid &&
            response.complete
        ) {
            let verified = applyRules(ruleJson, response.credential);
            if (verified) {
                setQRResult({
                    dob: response.birthDate,
                    name: response.name,
                    complete: response.complete,
                    valid: response.valid
                });

            }
        }
    };
    const handleScan = data => {
        setProceedToSubmit(true);
        if (data) {
            setScanned(true);
            onReadQRCode(data);
        }
    }
    const handleScanFromImg = data => {
        setProceedToSubmit(true);
        if (data) {
            setScanned(true);
            onReadQRCode(data);
        } else {
            setInvalidQrImg(true);
        }
    }
    const handleError = err => {
        console.error(err);
    }
    const openImageDialog = () => {
        reader.current.openImageDialog();
    }
    if (scanMethod === 'usePicture') {
        return (
            <div>
                <Button onClick={openImageDialog}>
                    <i className="far fa-images mr-2" />
                    Choose QR Code
                </Button>
                <QrReader
                    ref={reader}
                    onError={handleError}
                    onScan={handleScanFromImg}
                    style={{ width: '0%' }}
                    resolution={1800}
                    legacyMode={true}
                />
            </div>
        );
    } else {
        return (
            <div>
                <QrReader
                    delay={150}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                    facingMode="environment"
                /><br/>
                <span style={{ fontSize: '10px' }}><b><i>NOTE: If the front facing camera opens, please press Cancel and try again.</i></b></span>
            </div>
        );
    }
};

const VerifyVaccination = ({
    openVerifyVaccinationPage,
    setOpenVerifyVaccinationPage,
    screeningData,
    error,
    setError,
    qrResult,
    setQRResult,
    timesToVerify,
    setTimesToVerify,
    scanned,
    setScanned,
    proceedToSubmit,
    setProceedToSubmit,
}) => {
    const [DoB, setDoB] = useState('');
    const [verifyDoB, setVerifyDoB] = useState(false);
    const [verifyLastName, setVerifyLastName] = useState(false);
    const [scanMethod, setScanMethod] = useState('');
    const [invalidQrImg, setInvalidQrImg] = useState(false);

    let msgTimesToVerify ='';
    switch (timesToVerify)
    {
        case 0:
            msgTimesToVerify = ''
            msgTimesToVerify = 'Verification failed, submit your survey and contact administration for assistance.';
            break;
        case 1:
            msgTimesToVerify= ''
            msgTimesToVerify = 'Entered birthday is incorrect, you have 1 try left.';
            break;
        default:
            msgTimesToVerify=''
    }
    if (scanned && !verifyLastName){
        msgTimesToVerify = 'Verification failed, submit your survey and contact administration for assistance.';
    }
    qrResult.name = qrResult.name && qrResult.name.toLowerCase()
    const screeningName = qrResult.name && qrResult.name.split(' ')
    useEffect(()=>{
        if ((screeningData.visitorObj && screeningData.visitorObj.lastName) || (screeningData.employeeObj && screeningData.employeeObj.lastName)) {
            const match = screeningData.employeeObj ? screeningData.employeeObj.lastName : screeningData.visitorObj.lastName;
            if (screeningName && screeningName.includes(match.toLowerCase())){
                setVerifyLastName(true);
            }
        }
    }, [qrResult, screeningData, screeningName, setQRResult, verifyDoB, verifyLastName])

    return (
        <Modal
            show={openVerifyVaccinationPage}
            onHide={() => {
                setOpenVerifyVaccinationPage(false);
            }}
            size={isMobile ? "lg" : "xl"}
        >
            <Modal.Header
                style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    color: "#ffffff",
                }}
            >
                <Modal.Title className={isMobile ? "text-center" : ""} style={{ width: '100%' }}>
                    Verify Proof of Vaccination
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isEmpty(screeningData) &&
                    <NotificationGroup
                        style={{
                            right: 0,
                            top: 0,
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse'
                        }}
                    >
                        {error.hasError &&
                            <Fade
                                enter={true}
                                exit={true}>
                                <Notification
                                    type={{ style: 'error', icon: true }}
                                    closable={true}
                                    onClose={() => setError({ hasError: false, msg: '' })}
                                >
                                    <span>{error.msg}</span>
                                </Notification>
                            </Fade>}
                    </NotificationGroup>
                }
                <div style={{ padding: 15 }}>
                        <Card
                        style={{ marginBottom: 15 }}
                        bg="Light"
                        >
                        {scanned ?    <Card.Header>
                          {verifyLastName ?
                                <span
                                    style={{ color: 'green', fontSize: '20px' }}
                                >
                                    You have scanned successfully. Please fill out your information to verify your QR code.
                                </span>
                                :
                                <span
                                style={{ color: 'red', fontSize: '20px' }}
                                >
                                    Your QR code is not valid. Ensure you are using the correct QR code and try again.
                                </span>}
                        </Card.Header>

                      :  <Card.Header>
                                <span style={{ color: invalidQrImg ? 'red' : '', fontSize: '20px' }}>
                                    {!scanMethod ? "Please choose your verfication method:" : ""}
                                    {scanMethod === "scanQrCode" ? "Please scan your QR code:" : ""}
                                    {scanMethod === "usePicture" && !invalidQrImg ? "Please use a valid QR code:" : ""}
                                    {scanMethod === "usePicture" && invalidQrImg ? "Invalid QR code, please use a valid QR code!" : ""}
                                </span>
                        </Card.Header>}

                        <Card.Body>
                            {scanned && <Button
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                    setScanned(false);
                                    setInvalidQrImg(false);
                                    setDoB('');
                                    setVerifyDoB(false);
                                    setVerifyLastName(false);
                                    setScanMethod('');
                                }}
                            >Scan again
                        </Button>}
                            {!scanned && !scanMethod &&
                                <div>
                                    <Button
                                        onClick={() => {
                                            setScanMethod('scanQrCode')
                                        }}
                                    >
                                        <i className="fas fa-camera mr-2" />
                                        Scan QR Code
                                    </Button>
                                    <span>  |  </span>
                                    <Button
                                        onClick={() => {
                                            setScanMethod('usePicture')
                                        }}
                                    >
                                        <i className="far fa-images mr-2" />
                                        Gallery
                                    </Button>
                                    </div>}
                            {!scanned && scanMethod && <VerifyQrCode
                                scanMethod={scanMethod}
                                scanned={scanned}
                                setScanned={setScanned}
                                proceedToSubmit={proceedToSubmit}
                                setProceedToSubmit={setProceedToSubmit}
                                invalidQrImg={invalidQrImg}
                                setInvalidQrImg={setInvalidQrImg}
                                qrResult={qrResult}
                                setQRResult={setQRResult}
                            />}
                        </Card.Body>
                        </Card>
                </div>
                {scanned && verifyLastName && <div style={{ padding: 15 }}>
                    <Card
                        style={{ marginBottom: 15 }}
                        bg="Light"
                    >
                        <Card.Header>
                            <span style={{ fontSize: '22px' }}>Date of Birth Verification</span>
                        </Card.Header>
                        <Card.Body>
                            <div style={{ color: 'red', fontSize: '20px', textAlign: 'center',paddingBottom:'15px' }}>{msgTimesToVerify}</div>
                            <InputGroup className="mb-5">
                                {!isMobile && <InputGroup.Text>Date of Birth:</InputGroup.Text>}
                              {isMobile ?
                                    <FormControl
                                        className='removecalendar'
                                        type="date"
                                        placeholder="yyyy-mm-dd"
                                        min="1900-01-01"
                                        max="2030-12-31"
                                        disabled={verifyDoB}
                                        aria-label="Date of Birth"
                                        onChange={(e) => {
                                            setDoB(e.target.value)
                                        }}
                                    />
                              : <FormControl
                                className='removecalendar'
                                type="date"
                                placeholder="yyyy-mm-dd"
                                min="1900-01-01"
                                max="2030-12-31"
                                disabled={verifyDoB}
                                 aria-label="Date of Birth"
                                 onChange={(e)=>{
                                        setDoB(e.target.value)
                                 }}
                                 />

                                }
                                <Button
                                    style={verifyDoB ? { backgroundColor: 'white', borderColor:'white' } : null}
                                    disabled={verifyDoB || (timesToVerify ===0)}
                                primary
                                onClick={()=>{
                                    if (qrResult.dob && qrResult.dob === DoB){
                                        setVerifyDoB(true);
                                    }
                                    else
                                        setTimesToVerify(e=>e-1)
                                }} >
                                    {verifyDoB ? <i
                                        style={verifyDoB ? { color: 'green', } : null}
                                    class="fas fa-check">
                                    </i>
                                     : 'Verify'}
                                    </Button>
                            </InputGroup>
                            <b><i>For verification purposes only.</i></b>
                        </Card.Body>
                    </Card>
                </div>}
                {scanned && !verifyLastName &&
                <div style={{ padding: 15 }}>
                    <Card
                        style={{ marginBottom: 15 }}
                        bg="Light"
                    >
                        <Card.Header>
                            <span style={{ fontSize: '22px' }}>Date of Birth Verification</span>
                        </Card.Header>
                        <Card.Body>
                            <div style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>{msgTimesToVerify}</div>
                        </Card.Body>
                    </Card>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <p className="text-right">
                    <Button
                        light
                        onClick={() => { setOpenVerifyVaccinationPage(false); }}
                    >Cancel
                    </Button>
                </p>
                {scanned && verifyLastName &&
                <p className="text-right">
                    <Button
                        disabled={!verifyDoB}
                        primary
                        onClick={() => {
                            if (verifyDoB && verifyLastName) {
                                setQRResult({ ...qrResult, qrCodeVerified: true })
                            }
                            setOpenVerifyVaccinationPage(false);
                         }}
                    >Complete
                    </Button>
                </p>}
            </Modal.Footer>
        </Modal>
    );
};

export default VerifyVaccination;
