import React, { useState } from "react";
import axios from 'axios';
import { Window } from '@progress/kendo-react-dialogs';
import moment from 'moment';
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import getTokenConfig from '../../../methods/getTokenConfig';
import '../../stylesheets/windowStyles.css';
import { DropDownList } from "@progress/kendo-react-dropdowns";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => (emailRegex.test(value));

const statusOptions = ['Active', 'Inactive'];
const verifiedOptions = ['Verified', 'Not Verified'];
const itemRender = (li, itemProps) => {
    let icon = <span className="k-icon"></span>;
    if (li.props.children === 'Active' || li.props.children === 'Verified') {
        icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
    } else if (li.props.children === 'Inactive' || li.props.children === 'Not Verified') {
        icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
    }

    const itemChildren =
        <span style={{ fontSize: 16 }}>
            {icon}&nbsp;&nbsp;{li.props.children}
        </span>;

    return React.cloneElement(li, li.props, itemChildren);
}

const valueRender = (element, value) => {
    if (!value) {
        return element;
    }
    let icon = <span className="k-icon"></span>;
    if (value === 'Active' || value === 'Verified') {
        icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
    } else if (value === 'Inactive' || value === 'Not Verified') {
        icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
    }

    const children = [
        <span key={1}>
            {icon}
        </span>,
        <span key={2}>&nbsp; {element.props.children}</span>
    ];
    return React.cloneElement(element, { ...element.props }, children);
}

const AddNewVisitorWindow = ({
    setShowWindow,
    selectedVis,
    setSelectedVis,
    setVisitorsList,
    setMgsType,
    qrCodeVerificationNeeded,
}) => {
    const [firstName, setFirstName] = useState((selectedVis && selectedVis.firstName) || '');
    const [lastName, setLastName] = useState((selectedVis && selectedVis.lastName) || '');
    const [status, setStatus] = useState((selectedVis && selectedVis.status) || 'Active');
    const [cellNo, setCellNo] = useState((selectedVis && selectedVis.cellNumber) || '');
    const [email, setEmail] = useState((selectedVis && selectedVis.email) || '');
    const [type, setType] = useState({ status: null, message: '' });
    const [edit, setEdit] = useState(false);
    const [qrCodeVerification, setQrCodeVerification] = useState(
        selectedVis && selectedVis.qrCodeVerification ? selectedVis.qrCodeVerification : {});

    const resetType = (time) => {
        setTimeout(() => {
            setType({ status: null, message: '' });
        }, time);
    }
    const validations = () => {
        if (firstName === '' || firstName.trim() === '') {
            setType({ status: 'warning', message: 'Please enter first name' });
            resetType(1800);
            return true;
        }
        else if (lastName === '' || lastName.trim() === '') {
            setType({ status: 'warning', message: 'Please enter last name' });
            resetType(1800);
            return true;
        }
        else if (
            (cellNo === ''
                || cellNo === '(+1) ___-___-____')
            && (email === '' || email.trim() === '')
        ) {
            setType({ status: 'warningBoth', message: 'Either Cell Number or Email is Required' });
            resetType(1800);
            return true;
        }
        else if (email !== '' &&
            !email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setType({ status: 'warningBottom', message: 'Email address is not valid' });
            resetType(2500);
            return true;
        }
        else if (cellNo !== '' && cellNo !== '(+1) ___-___-____' && cellNo.replace(/[^0-9]/g, "").length < 11) {
            setType({ status: 'warningBottom', message: 'Cell number is not valid' });
            resetType(1800);
            return true;
        }
        return false;
    }

    const addVisitor = (action) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const visitor = {
            firstName,
            lastName,
            status,
            cellNumber: cellNo === '(+1) ___-___-____' ? '' : cellNo,
            email,
            qrCodeVerification,
            organizationId: orgId,
        }
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/add', visitor, getTokenConfig())
            .then((res) => {
                if (action !== 'submit') {
                    setFirstName('');
                    setLastName('');
                    setStatus('Active');
                    setCellNo('');
                    setEmail('');
                    setQrCodeVerification({});
                }
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'New visitor record added successfully.' });
                        setVisitorsList([...response.data])
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in adding new visitor record.' });
                    });
            }).catch((error) => {
                setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
            });
    }

    const updateVisitor = () => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const visitor = {
            firstName,
            lastName,
            status,
            cellNumber: cellNo === '(+1) ___-___-____' ? '' : cellNo,
            email,
            qrCodeVerification,
            organizationId: orgId,
        }
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/update/' + selectedVis._id, visitor, getTokenConfig())
            .then((res) => {
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'Visitor record updated successfully.' });
                        setVisitorsList([...response.data])
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in updating visitor record.' });
                    });
            }).catch((error) => {
                setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
            });
        setSelectedVis(null);
        setEdit(false);
        setShowWindow(false);
    }

    return (<Window
        title={selectedVis ? "Edit Visitor Info" : "Add New Visitor"}
        onClose={() => {
            setShowWindow(false);
            setSelectedVis(null);
        }}
        initialHeight={!isMobile ? 'calc(85vh/1.5)' : '100%'}
        initialWidth={!isMobile ? 450 : '100%'}
        initialTop={0}
        style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
    >
        <form className="k-form">
            <fieldset>
                {(type.status === 'warning' || type.status === 'warningBoth') &&
                    <Fade
                        enter={true}
                        exit={true}>
                        {type.status !== null && <Notification
                            style={{
                                width: '400px'
                            }}
                            type={{ style: 'error', icon: true }}
                            onClose={() => setType({ status: null, message: '' })}
                        >
                            <span>{type.message}</span>
                        </Notification>}
                    </Fade>}
                <label className="k-form-field">
                    <p>First Name<span style={{ color: 'red' }}>*</span></p>
                    <Input
                        type={"text"}
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.value);
                            setEdit(true);
                        }}
                        placeholder="Visitor First Name" />
                </label>
                <label className="k-form-field">
                    <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                    <Input
                        type={"text"}
                        onChange={(e) => {
                            setLastName(e.value);
                            setEdit(true);
                        }}
                        value={lastName}
                        placeholder="Visitor Last Name" />
                </label>
                <label className="k-form-field">
                    <p>Status<span style={{ color: 'red' }}>*</span></p>
                    <DropDownList
                        data={statusOptions}
                        itemRender={itemRender}
                        valueRender={valueRender}
                        style={{ backgroundColor: 'white' }}
                        value={status}
                        onChange={(e) => {
                            setStatus(e.value);
                            setEdit(true);
                        }}
                    />
                </label>
                <label className="k-form-field">
                    <p>Cell #<span style={{ color: 'red' }}>*</span></p>
                    <MaskedTextBox
                        mask="(+1) 000-000-0000"
                        placeholder="Visitor Cell Number"
                        value={cellNo}
                        onChange={(e) => {
                            setCellNo(e.value);
                            setEdit(true);
                        }}
                    />
                </label>
                <label className="k-form-field">
                    <p>Email Address<span style={{ color: 'red' }}>*</span></p>
                    <Input
                        type={"email"}
                        validator={emailValidator}
                        placeholder="Visitor Email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.value);
                            setEdit(true);
                        }}
                    />
                </label>
                {qrCodeVerificationNeeded && <label className="k-form-field">
                    <p>Vaccination Verified<span style={{ color: 'red' }}>*</span></p>
                    <DropDownList
                        disabled={status !== 'Active'}
                        data={verifiedOptions}
                        itemRender={itemRender}
                        valueRender={valueRender}
                        style={{ backgroundColor: 'white' }}
                        value={qrCodeVerification.vaccineVerified ? 'Verified' : 'Not Verified'}
                        onChange={(e) => {
                            const changeTime = moment().format("ddd, M/D/YYYY");
                            setQrCodeVerification({
                                vaccineVerified: e.value === 'Verified' ? true : false,
                                vaccineVerifiedDate: changeTime,
                            });
                            setEdit(true);
                        }}
                    />
                </label>}
                {(type.status === 'warningBottom' || type.status === 'warningBoth') &&
                    <Fade
                        enter={true}
                        exit={true}>
                        {type.status !== null && <Notification
                            style={{
                                width: '400px'
                            }}
                            type={{ style: 'error', icon: true }}
                            onClose={() => setType({ status: null, message: '' })}
                        >
                            <span>{type.message}</span>
                        </Notification>}
                    </Fade>}
            </fieldset>

            <div className="text-right">
                <button
                    type="button"
                    className="k-button"
                    onClick={() => {
                        setSelectedVis(null);
                        setShowWindow(false);
                    }}
                >Cancel</button>
                {selectedVis ?
                    <button
                        type="button"
                        className="k-button"
                        disabled={!edit}
                        style={{
                            backgroundColor: '#343a40',
                            color: 'white',
                        }}
                        onClick={() => {
                            if (!validations())
                                updateVisitor();
                        }}
                    >Update
                        </button> :
                    <>
                        <button
                            type="button"
                            className="k-button"
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            disabled={!edit}
                            onClick={() => {
                                if (!validations()) {
                                    addVisitor('submit');
                                    setShowWindow(false);
                                }
                            }}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            className="k-button"
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            disabled={!edit}
                            onClick={() => {
                                if (!validations())
                                    addVisitor('submitAdd');
                            }}
                        >
                            Submit & Add
                        </button>
                    </>
                }
            </div>
        </form>
    </Window>);
};
export default AddNewVisitorWindow;
