import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Result } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import createTokenConfig from "../../../methods/createTokenConfig";

const VerifyEmail = ({ history, match }) => {
    const [tokenVerified, setTokenVerified] = useState(false);
    const [error, setError] = useState({ hasError: false, status: '', msg: '' });
    useEffect(() => {
        if (match.params && match.params.token) {
            axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/verifyCompanyEmail/',
                { verified: true },
                createTokenConfig(match.params.token))
                .then(res => {
                    if (res.status === 200) {
                        setTokenVerified(true);
                        setTimeout(() => {
                            history.push('/signin');
                        }, 5000);
                    } else {
                        setError({ hasError: true, status: 'Unknown', msg: 'Something went wrong' });
                    }
                }).catch(err => {
                    setError({
                        hasError: true,
                        status: err.response && err.response.status,
                        msg: err.response && err.response.data && err.response.data.msg
                    });
                });
        }
    }, [history, match.params]);
    return (
        <div>
            {tokenVerified ?
                <Result
                    status="success"
                    title="Email Verified Successfully!"
                    subTitle="You will be redirected to sign in page in 5 seconds or you can manually go to sign in page."
                    extra={[
                        <Button
                            key="signBtn"
                            primary
                            onClick={() => history.push('/signin')}
                        >Sign In</Button>
                    ]}
                />
                : null
            }
            {error.hasError ?
                <Result
                    status="error"
                    title={error.status}
                    subTitle={error.msg}
                    extra={[
                        <Button
                            key="refresh"
                            primary
                            onClick={() => window.location.reload()}
                        >Refresh
                        </Button>
                    ]}
                />
                : null
            }
        </div>
    );
};

export default VerifyEmail;
