import React, { Fragment, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import Header from './header';
import auth from "../../methods/auth";
import '../stylesheets/kendoDrawer.css';

const unauthenticatedItems = [
    { text: 'Login', icon: 'fas fa-sign-in-alt', selected: true, route: '/signin', style: { fontWeight: 'bold' } },
    { text: 'Signup', icon: 'far fa-edit', route: '/signup', style: { fontWeight: 'bold' } },
];

const authenticatedItems = [
    { text: 'Home', icon: 'fas fa-home', selected: true, route: '/' },
    { separator: true },
    { text: 'Employees', icon: 'fas fa-users', route: '/employees' },
    { text: 'Visitors', icon: 'fas fa-walking', route: '/visitors' },
    { text: 'Reports', icon: 'fas fa-chart-bar', route: '/reports' },
    { separator: true },
    { text: 'Settings', icon: 'fas fa-cogs', route: '/settings' },
];

const DrawerContainer = ({ location, history, children }) => {
    const [expanded, setExpanded] = useState(false);

    const onSelect = (e) => {
        history.push(e.itemTarget.props.route);
    }

    const items = auth.isAuthenticated() ? authenticatedItems : unauthenticatedItems;

    const setSelectedItem = (pathName) => {
        const defaultPath = auth.isAuthenticated() ? '/' : '/login';
        if (pathName === '/home')
            pathName = '/';
        const currentPath = items.find(item => item.route === pathName);
        if (currentPath && currentPath.text) {
            return currentPath.text;
        } else {
            return defaultPath;
        }
    };

    let selected = setSelectedItem(location.pathname);


    const CustomItem = (props) => {
        return (
            <DrawerItem {...props}>
                {expanded ?
                    <Container fluid style={{ paddingLeft: '0px' }}>
                        <Row xs={12} sm={12} md={12} style={{ fontWeight: 'bold' }}>
                            <Col xs={2} sm={2} md={2}>
                                <span className={props.icon} />
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                                {props.text}
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container fluid style={{ paddingLeft: '0px' }}>
                        <Row xs={12} sm={12} md={12} style={{ fontWeight: 'bold' }}>
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="tooltip-disabled">{props.text}</Tooltip>}
                            >
                                <Col xs={2} sm={2} md={2}>
                                    <span className={props.icon} />
                                </Col>
                            </OverlayTrigger>
                            <Col xs={10} sm={10} md={10}>
                                {props.text}
                            </Col>
                        </Row>
                    </Container>
                }
            </DrawerItem>
        );
    };

    return (
        <Fragment>
            <Header
                drawerExpanded={expanded}
                setDrawerExpanded={setExpanded}
                history={history}
            />
            <Drawer
                expanded={expanded}
                items={items.map(
                    (item) => ({ ...item, selected: item.text === selected }))}
                item={CustomItem}
                position='start'
                mode='push'
                mini={true}
                onSelect={onSelect}
            >
                <DrawerContent
                    style={{
                        height: !isMobile ? 'calc(100vh - 112px)' : 'calc(100vh - 75px)',
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </DrawerContent>
            </Drawer>
        </Fragment>
    );
};

export default withRouter(DrawerContainer);
