import React, { Fragment, useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Dropdown,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import axios from 'axios';
import moment from 'moment';
import { useStripe, useElements, CardNumberElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isMobile } from 'react-device-detect';
import getTokenConfig from "../../../methods/getTokenConfig";
import CompanyDetails from "./companyDetails";
import ContactDetails from "./contactDetails";
import PaymentDetails from "./paymentDetails";
import NotificationSettings from "./notificationSettings";
import AttachmentsSettings from "./attachmentsSettings";
import QRCodeSettings from "./qrCodeSettings";
import UseCaseSettings from "./useCaseSettings";
import DeactivateDetails from "./deactivateDetails";
import ConfirmDialog from "../../util/confirmDialog";
import auth from "../../../methods/auth";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB);

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const AccountSettingsBase = ({ history, useCase, setUseCase }) => {
    const [orgDetails, setOrgDetails] = useState({});

    const stripe = useStripe();
    const elements = useElements();

    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyAddress1, setCompanyAddress1] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyCountry, setCompanyCountry] = useState('');
    const [companyStateProv, setCompanyStateProv] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [contactFirstName, setContactFirstName] = useState('');
    const [contactLastName, setContactLastName] = useState('');
    const [contactPosition, setContactPosition] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [notificationTime, setNotificationTime] = useState(new Date(moment('8: 00: 00 AM', 'HH: mm: ss a')));
    const [notificationType, setNotificationType] = useState('email');
    const [timezone, setTimezone] = useState('');
    const [autoNotify, setAutoNotify] = useState(false);
    const [forceNotification, setForceNotification] = useState(false);
    const [attachmentsNeeded, setAttachmentsNeeded] = useState(false);
    const [qrCodeVerificationNeeded, setQRCodeVerificationNeeded] = useState(false);
    const [totp, setTotp] = useState(null);
    const [forceNotifyFor, setForceNotifyFor] = useState('5days');

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [display, setDisplay] = useState('company');
    const [inEditMode, setInEditMode] = useState('');

    const [message, setMessage] = useState({ status: '', msg: '' });
    const [error, setError] = useState({ hasError: false, message: '' });

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    const resetMessage = (time) => {
        setTimeout(() => {
            setMessage({ status: '', msg: '' });
        }, time);
    }

    const updatePaymentMethod = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const newPayResult = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                email: companyEmail,
            },
        });
        if (newPayResult.error) {
            setError({ hasError: true, message: newPayResult.error.message });
        } else {
            axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updatePaymentMethod/',
                { newPayMethodId: newPayResult.paymentMethod.id },
                getTokenConfig()
            ).then(res => {
                if (res.status === 200) {
                    setMessage({ status: 'success', msg: res.data.msg });
                    resetMessage(1800);
                    setInEditMode('');
                } else {
                    setMessage({ status: 'error', msg: res.data.msg });
                    resetMessage(1800);
                }
            }).catch(err => {
                setMessage({
                    status: 'error',
                    msg: err.response && err.response.data && err.response.data.msg,
                });
                resetMessage(1800);
            });
        }
    };

    const updateDB = () => {
        const newDetails = {
            companyName,
            companyPostalCode,
            companyEmail,
            companyAddress1,
            companyAddress2,
            companyCountry,
            companyStateProv,
            companyCity,
            contactFirstName,
            contactLastName,
            contactPosition,
            contactPhone,
            contactEmail,
            notificationTime,
            notificationType,
            timezone,
            autoNotify,
            forceNotification,
            attachmentsNeeded,
            totp,
            forceNotifyFor,
            qrCodeVerificationNeeded,
            useCase,
        };
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateDetails/', newDetails, getTokenConfig())
            .then(res => {
                if (res.status === 200) {
                    setMessage({ status: 'success', msg: res.data.msg });
                    resetMessage(1800);
                    setInEditMode('');
                    setOrgDetails(res.data.updatedOrg);
                } else {
                    setMessage({ status: 'error', msg: res.data.msg });
                    resetMessage(1800);
                }
            }).catch(err => {
                setMessage({
                    status: 'error',
                    msg: err.response && err.response.data && err.response.data.msg,
                });
                resetMessage(1800);
            });
    };

    const updateDetails = () => {
        if ((!companyName || companyName.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Company name can not be empty.' });
        } else if ((!companyPostalCode || companyPostalCode.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Company postal code can not be empty.' });
        } else if ((!companyEmail || companyEmail.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Company email can not be empty.' });
        } else if (!emailRegex.test(companyEmail) && !error.hasError) {
            setError({ hasError: true, message: 'Company email is not a valid email address.' });
        } else if ((!companyAddress1 || companyAddress1.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Address can not be empty.' });
        } else if ((!companyCountry || companyCountry.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Please select country.' });
        } else if ((!companyStateProv || companyStateProv.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Please select state.' });
        } else if ((!companyCity || companyCity.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'City can not be empty.' });
        } else if ((!contactFirstName || contactFirstName.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'First name can not be empty.' });
        } else if ((!contactLastName || contactLastName.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Last name can not be empty.' });
        } else if ((!contactPosition || contactPosition.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Position can not be empty.' });
        } else if ((!contactPhone || contactPhone.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Phone # can not be empty.' });
        } else if ((!contactEmail || contactEmail.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Email can not be empty.' });
        } else if (!emailRegex.test(contactEmail)) {
            setError({ hasError: true, message: 'Contact email is not a valid email address.' });
        } else if (!notificationTime && !error.hasError) {
            setError({ hasError: true, message: 'Notification time can not be empty.' });
        } else if (!notificationType && !error.hasError) {
            setError({ hasError: true, message: 'Notification type can  not be empty.' });
        } else if ((!timezone || timezone.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Time zone can not be empty.' });
        } else if ((autoNotify === null || autoNotify === undefined) && !error.hasError) {
            setError({ hasError: true, message: 'Notification status can not be empty.' });
        } else if ((forceNotification === null || forceNotification === undefined) && !error.hasError) {
            setError({ hasError: true, message: 'Force Notification status can not be empty.' });
        } else if ((attachmentsNeeded === null || attachmentsNeeded === undefined) && !error.hasError) {
            setError({ hasError: true, message: 'Attachments Needed status can not be empty.' });
        } else if ((qrCodeVerificationNeeded === null || qrCodeVerificationNeeded === undefined) && !error.hasError) {
            setError({ hasError: true, message: 'QR Code Verification status can not be empty.' });
        } else if (!forceNotifyFor && !error.hasError) {
            setError({ hasError: true, message: 'Force Notification For can not be empty.' });
        } else if (!error.hasError) {
            updateDB();
        }
    };

    const resetData = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setOrgDetails(res.data);
                    setCompanyName(res.data.companyName);
                    setCompanyPostalCode(res.data.companyPostalCode);
                    setCompanyEmail(res.data.companyEmail);
                    setCompanyAddress1(res.data.companyAddress1);
                    setCompanyAddress2(res.data.companyAddress2);
                    setCompanyCountry(res.data.companyCountry);
                    setCompanyStateProv(res.data.companyStateProv);
                    setCompanyCity(res.data.companyCity);
                    setContactFirstName(res.data.primaryContact['firstName']);
                    setContactLastName(res.data.primaryContact['lastName']);
                    setContactPosition(res.data.primaryContact['position']);
                    setContactPhone(res.data.primaryContact['phone']);
                    setContactEmail(res.data.primaryContact['email']);
                    setNotificationTime(res.data.notificationTime);
                    setNotificationType(res.data.notificationType);
                    setTimezone(res.data.timezone);
                    setAutoNotify(res.data.autoNotify);
                    setForceNotification(res.data.forceNotification);
                    setAttachmentsNeeded(res.data.attachmentsNeeded);
                    setQRCodeVerificationNeeded(res.data.qrCodeVerificationNeeded);
                    setUseCase(res.data.useCase);
                    setTotp(res.data.totp);
                    setForceNotifyFor(res.data.forceNotifyFor);
                }
            }).catch((err) => {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const deactivateAccount = () => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/deactivateAccount', { removed: true }, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    auth.logout(() => history.push('/signin'));
                }
            })
            .catch((err) => {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setOrgDetails(res.data);
                    setCompanyName(res.data.companyName);
                    setCompanyPostalCode(res.data.companyPostalCode);
                    setCompanyEmail(res.data.companyEmail);
                    setCompanyAddress1(res.data.companyAddress1);
                    setCompanyAddress2(res.data.companyAddress2);
                    setCompanyCountry(res.data.companyCountry);
                    setCompanyStateProv(res.data.companyStateProv);
                    setCompanyCity(res.data.companyCity);
                    setContactFirstName(res.data.primaryContact['firstName']);
                    setContactLastName(res.data.primaryContact['lastName']);
                    setContactPosition(res.data.primaryContact['position']);
                    setContactPhone(res.data.primaryContact['phone']);
                    setContactEmail(res.data.primaryContact['email']);
                    setNotificationTime(res.data.notificationTime);
                    setNotificationType(res.data.notificationType);
                    setTimezone(res.data.timezone);
                    setAutoNotify(res.data.autoNotify);
                    setForceNotification(res.data.forceNotification);
                    setAttachmentsNeeded(res.data.attachmentsNeeded);
                    setQRCodeVerificationNeeded(res.data.qrCodeVerificationNeeded);
                    setUseCase(res.data.useCase);
                    setTotp(res.data.totp);
                    setForceNotifyFor(res.data.forceNotifyFor);
                }
            }).catch((err) => {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }, [setUseCase]);

    return (
        <Container fluid>
            <Row>
                <Col className="text-center">
                    {!(isMobile || isNarrowScreen) ? <ButtonGroup >
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="far fa-building"
                            selected={display === 'company'}
                            onClick={() => setDisplay('company')}
                        >&nbsp;&nbsp;Company
                        </Button>
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="far fa-user"
                            selected={display === 'contact'}
                            onClick={() => setDisplay('contact')}
                        >&nbsp;&nbsp;Contact
                        </Button>
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="fas fa-toolbox"
                            selected={display === 'useCaseSettings'}
                            onClick={() => setDisplay('useCaseSettings')}
                        >&nbsp;&nbsp;Use Case
                        </Button>
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="far fa-bell"
                            selected={display === 'notificationSettings'}
                            onClick={() => setDisplay('notificationSettings')}
                        >&nbsp;&nbsp;Notification
                        </Button>
                        {useCase !== 'Sign In Management' && <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="fas fa-paperclip"
                            selected={display === 'attachmentsSettings'}
                            onClick={() => setDisplay('attachmentsSettings')}
                        >&nbsp;&nbsp;Attachments
                        </Button>}
                        {useCase === 'COVID Screening' && <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="fas fa-qrcode"
                            selected={display === 'qrCodeSetting'}
                            onClick={() => setDisplay('qrCodeSetting')}
                        >&nbsp;&nbsp;Verify QR Code
                        </Button>}
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="far fa-credit-card"
                            selected={display === 'payment'}
                            onClick={() => setDisplay('payment')}
                        >&nbsp;&nbsp;Usage & Payment
                        </Button>
                        <Button
                            style={{ borderColor: 'black' }}
                            togglable={true}
                            iconClass="fas fa-ban"
                            selected={display === 'deactivate'}
                            onClick={() => setDisplay('deactivate')}
                        >&nbsp;&nbsp;Deactivate
                        </Button>
                    </ButtonGroup > :
                    <Dropdown id="actions">
                        <Dropdown.Toggle name="history">
                            Account Settings
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('company');
                                }}
                            >
                                <i className="far fa-building"></i>
                                &nbsp;&nbsp;&nbsp;Company
                                {display === 'company' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('contact');
                                }}
                            >
                                <i className="far fa-user"></i>
                                &nbsp;&nbsp;&nbsp;Contact
                                {display === 'contact' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('useCaseSettings');
                                }}
                            >
                                <i className="fas fa-toolbox"></i>
                                &nbsp;&nbsp;&nbsp;Use Case
                                {display === 'useCaseSettings' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('notificationSettings');
                                }}
                            >
                                <i className="far fa-bell"></i>
                                &nbsp;&nbsp;&nbsp;Notification
                                {display === 'notificationSettings' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('attachmentsSettings');
                                }}
                            >
                                <i className="fas fa-paperclip"></i>
                                &nbsp;&nbsp;&nbsp;Attachments
                                {display === 'attachmentsSettings' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('qrCodeSetting');
                                }}
                            >
                                <i className="fas fa-qrcode"></i>
                                &nbsp;&nbsp;&nbsp;Verify QR Code
                                {display === 'qrCodeSetting' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('payment');
                                }}
                            >
                                <i className="far fa-credit-card"></i>
                                &nbsp;&nbsp;&nbsp;Usage & Payment
                                {display === 'payment' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setDisplay('deactivate');
                                }}
                            >
                                <i className="fas fa-ban"></i>
                                &nbsp;&nbsp;&nbsp;Deactivate
                                {display === 'deactivate' && <>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                                    </>}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
                </Col>
            </Row>

            {message.status ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: message.status, icon: true }}
                            closable={true}
                            onClose={() => setMessage({ status: '', msg: '' })}
                        >
                            <span>{message.msg}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>
                : null
            }
            {error.hasError ?
                <Fade
                    enter={true}
                    exit={true}
                    style={{ width: '100%' }}
                    className="inline-notification"
                >
                    <Notification
                        type={{ style: 'error', icon: true }}
                        style={{ width: '100%' }}
                    >
                        <span>{error.message}</span>
                    </Notification>
                </Fade>
                : null
            }
            {display === 'company' ?
                <CompanyDetails
                    inEditMode={inEditMode}
                    companyName={companyName}
                    companyPostalCode={companyPostalCode}
                    setCompanyPostalCode={setCompanyPostalCode}
                    setCompanyName={setCompanyName}
                    companyEmail={companyEmail}
                    setCompanyEmail={setCompanyEmail}
                    verified={orgDetails && orgDetails.verified}
                    companyAddress1={companyAddress1}
                    setCompanyAddress1={setCompanyAddress1}
                    companyAddress2={companyAddress2}
                    setCompanyAddress2={setCompanyAddress2}
                    companyCountry={companyCountry}
                    setCompanyCountry={setCompanyCountry}
                    companyStateProv={companyStateProv}
                    setCompanyStateProv={setCompanyStateProv}
                    companyCity={companyCity}
                    setCompanyCity={setCompanyCity}
                    error={error}
                    setError={setError}
                />
                : null
            }
            {display === 'contact' ?
                <ContactDetails
                    inEditMode={inEditMode}
                    contactFirstName={contactFirstName}
                    setContactFirstName={setContactFirstName}
                    contactLastName={contactLastName}
                    setContactLastName={setContactLastName}
                    contactPosition={contactPosition}
                    setContactPosition={setContactPosition}
                    contactPhone={contactPhone}
                    setContactPhone={setContactPhone}
                    contactEmail={contactEmail}
                    setContactEmail={setContactEmail}
                    error={error}
                    setError={setError}
                />
                : null
            }
            {display === 'notificationSettings' ?
                <NotificationSettings
                    inEditMode={inEditMode}
                    notificationTime={notificationTime}
                    notificationType={notificationType}
                    timezone={timezone}
                    autoNotify={autoNotify}
                    forceNotification={forceNotification}
                    forceNotifyFor={forceNotifyFor}
                    setNotificationTime={setNotificationTime}
                    setNotificationType={setNotificationType}
                    orgDetails={orgDetails}
                    setTimezone={setTimezone}
                    setAutoNotify={setAutoNotify}
                    setForceNotification={setForceNotification}
                    setForceNotifyFor={setForceNotifyFor}
                    error={error}
                    setError={setError}
                />
                : null
            }
            {display === 'attachmentsSettings' ?
                <AttachmentsSettings
                    attachmentsNeeded={attachmentsNeeded}
                    setAttachmentsNeeded={setAttachmentsNeeded}
                    totp={totp}
                    setTotp={setTotp}
                    inEditMode={inEditMode}
                    setInEditMode={setInEditMode}
                    setMessageParent={setMessage}
                    resetMessageParent={resetMessage}
                    history={history}
                    useCase={useCase}
                />
                : null
            }
            {display === 'useCaseSettings' ?
                <UseCaseSettings
                    useCase={useCase}
                    setUseCase={setUseCase}
                    inEditMode={inEditMode}
                    setInEditMode={setInEditMode}
                    setAttachmentsNeeded={setAttachmentsNeeded}
                    setQRCodeVerificationNeeded={setQRCodeVerificationNeeded}
                />
                : null
            }
            {display === 'qrCodeSetting' ?
                <QRCodeSettings
                    qrCodeVerificationNeeded={qrCodeVerificationNeeded}
                    setQRCodeVerificationNeeded={setQRCodeVerificationNeeded}
                    inEditMode={inEditMode}
                    setInEditMode={setInEditMode}
                />
                : null
            }
            {display === 'payment' ?
                <PaymentDetails
                    companyEmail={companyEmail}
                    inEditMode={inEditMode}
                    setMessage={setMessage}
                    resetMessage={resetMessage}
                />
                : null
            }
            {display === 'deactivate' ?
                <DeactivateDetails />
                : null
            }
            {showDeleteDialog ?
                <ConfirmDialog
                    visible={showDeleteDialog}
                    setVisible={setShowDeleteDialog}
                    title="Deactivate Account"
                    message="Are you sure you want to deactivate account?"
                    onConfirm={deactivateAccount}
                    onCancel={() => { setShowDeleteDialog(false); }}
                />
                : null
            }
            <hr />
            <Row className="mt-3 mb-3">
                <Col className="text-left">
                    {display === 'payment' || display === 'deactivate' ? <div style={{ paddingBottom: isMobile ? 50 : undefined }}>
                        <Fragment>
                            {display === 'deactivate' ?
                                <Button
                                    iconClass="fas fa-ban"
                                    look="outline"
                                    onClick={() => setShowDeleteDialog(true)}
                                >&nbsp;&nbsp;Deactivate
                                </Button>
                                : null
                            }
                            {display === 'payment' ?
                                <Fragment>
                                    {!inEditMode || inEditMode !== display ?
                                        null :
                                        <Fragment>
                                            <Button
                                                iconClass="fas fa-times"
                                                primary={true}
                                                look="flat"
                                                onClick={() => {
                                                    setInEditMode('');
                                                }}
                                            ></Button>&nbsp;
                                        </Fragment>
                                    }
                                    <Button
                                        iconClass="far fa-edit"
                                        onClick={() => setInEditMode(display)}
                                    >
                                    </Button>&nbsp;
                                    <Button
                                        iconClass="fas fa-check"
                                        disabled={inEditMode !== display}
                                        style={{
                                            backgroundColor: '#343a40',
                                            color: 'white',
                                        }}
                                        onClick={() => {
                                            updatePaymentMethod();
                                        }}
                                    ></Button>
                                </Fragment>
                                : null
                            }
                        </Fragment></div>
                        : <div style={{ paddingBottom: isMobile ? 50 : undefined }}>
                            {!inEditMode || inEditMode !== display ?
                                null :
                                <Fragment>
                                    <Button
                                        iconClass="fas fa-times"
                                        primary={true}
                                        look="flat"
                                        onClick={() => {
                                            resetData();
                                            setInEditMode('');
                                        }}
                                    ></Button>&nbsp;
                                </Fragment>
                            }
                            <Button
                                iconClass="far fa-edit"
                                onClick={() => setInEditMode(display)}
                            >
                            </Button>&nbsp;
                            <Button
                                iconClass="fas fa-check"
                                disabled={inEditMode !== display}
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    updateDetails();
                                }}
                            ></Button>
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    );
};


const AccountSettings = (props) => (
    <Elements stripe={stripePromise}>
        <AccountSettingsBase {...props} />
    </Elements>
);

export default AccountSettings;
