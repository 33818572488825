import React from 'react';
import { isMobile } from 'react-device-detect';
import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import DrawerContainer from './ui/util/drawerContainer';
import Main from './ui/pages/main';
import Footer from './ui/util/footer';
import Employees from './ui/pages/employees/employees';
import Visitors from './ui/pages/visitors/visitors';
import ReportsMain from './ui/pages/reports/reportsMain';
import Login from './ui/pages/login/login';
import Signup from './ui/pages/signup/signup';
import SettingsMain from './ui/pages/settings/settingsMain';
import { ProtectedRoute } from "./protected.route";
import { PublicOnlyRoute } from "./publicOnly.route";
import VerifyEmail from './ui/pages/signup/verifyEmail';
import ResendVerification from './ui/pages/signup/resendVerification';
import NotFound from './ui/util/notFound';
import Screening from './ui/pages/screening/screening';
import ForgotPassword from './ui/pages/signup/forgotPassword';
import ResetPassword from './ui/pages/signup/resetPassword';
import QRVisit from './ui/pages/screening/QRVisit';
import PaymentSetup from './ui/pages/login/paymentSetup';
import PreviewPage from './ui/util/previewPage';
import MfaAuthentication from './ui/pages/login/mfaAuthentication';


function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact={true} path="/screening/:notifyId" component={Screening} />
        <Route exact={true} path="/screening/newQRVisit/:token" component={QRVisit} />
        <DrawerContainer>
          <Switch>
            <PublicOnlyRoute exact={true} path="/signin" component={Login} />
            <PublicOnlyRoute exact={true} path="/signin/verifyEmailSignIn" component={Login} />
            <PublicOnlyRoute exact={true} path="/resendVerification" component={ResendVerification} />
            <PublicOnlyRoute exact={true} path="/forgot-password" component={ForgotPassword} />
            <PublicOnlyRoute exact={true} path="/organization/reset-password/:token" component={ResetPassword} />
            <PublicOnlyRoute exact={true} path="/organization/verifyEmailSignIn/:token" component={VerifyEmail} />
            <PublicOnlyRoute exact={true} path="/signup" component={Signup} />

            <ProtectedRoute exact={true} path="/" component={Main} />
            <ProtectedRoute exact={true} path="/home" component={Main} />
            <ProtectedRoute exact={true} path="/paymentSetup" component={PaymentSetup} />
            <ProtectedRoute exact={true} path="/mfaAuth" component={MfaAuthentication} />
            <ProtectedRoute exact={true} path="/employees" component={Employees} />
            <ProtectedRoute exact={true} path="/visitors" component={Visitors} />
            <ProtectedRoute exact={true} path="/reports" component={ReportsMain} />
            <ProtectedRoute exact={true} path="/settings" component={SettingsMain} />
            <ProtectedRoute exact={true} path="/empPreview/:userId/:attachId" component={PreviewPage} />
            <ProtectedRoute exact={true} path="/visPreview/:userId/:attachId" component={PreviewPage} />

            <Route path="*" component={NotFound} />
          </Switch>
          {!isMobile && <Footer />}
        </DrawerContainer>
      </Switch>
    </HashRouter>
  );
}

export default App;
