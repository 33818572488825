import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { Container, Alert, Form } from "react-bootstrap";
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import createTokenConfig from "../../../methods/createTokenConfig";
import { Button } from "@progress/kendo-react-buttons";

const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const QRVisit = ({ history, match }) => {
    const [organizationId, setOrganizationId] = useState('');
    const [notificationId, setNotificationId] = useState('');
    const [visitorId, setVisitorId] = useState('');
    const [useCase, setUseCase] = useState('');

    const [validVisitorDetails, setValidVisitorDetails] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cellNo, setCellNo] = useState('');
    const [email, setEmail] = useState('');

    const [atLeastOneNotReqQue, setAtLeastOneNotReqQue] = useState(false);

    const [fetchingData, setFetchingData] = useState(false);

    const [requiredQue, setRequiredQue] = useState([]);

    const [error, setError] = useState({ status: null, message: '' });

    const resetError = (time) => {
        setTimeout(() => {
            setError({ status: null, message: '' });
        }, time);
    };

    const saveResultsToDB = async () => {
        const visitor = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            cellNumber: cellNo && cellNo !== '(+1) ___-___-____' && cellNo.replace(/[^0-9]/g, "").length === 11 ? cellNo : '',
            email: email && email.toLowerCase().match(emailRegex) ? email : '',
            status: 'Active',
        }
        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/update/' + visitorId, visitor, createTokenConfig(match.params.token))
            .then((res) => {
                if (res.status === 200) {
                    if (useCase !== 'Sign In Management' || (useCase === 'Sign In Management' && atLeastOneNotReqQue)) {
                        history.push(`/screening/${notificationId}`);
                    }
                }
            }).catch((error) => {
                setError({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
            });
        if (notificationId && requiredQue && requiredQue.length !== 0 && useCase === 'Sign In Management' && !atLeastOneNotReqQue) {
            const screening = {
                attachedNotification: notificationId,
                notficationId: notificationId,
                organizationId: organizationId,
                visitorId: visitorId,
                answers: [{ qId: requiredQue[0]._id, ans: 'Yes' }],
                completed: true,
            };

            await axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/screening/add',
                screening,
            ).catch((err) => {
                setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
            history.push(`/screening/${notificationId}`);
        }
    };

    const validations = () => {
        if (firstName === '') {
            setError({ status: 'error', message: 'Please enter first name' });
            resetError(1800);
            return true;
        }
        else if (lastName === '') {
            setError({ status: 'error', message: 'Please enter last name' });
            resetError(1800);
            return true;
        }
        else if (!validVisitorDetails && (cellNo === '' || cellNo === '(+1) ___-___-____' || cellNo.replace(/[^0-9]/g, "").length < 11)) {
            setError({ status: 'error', message: 'Please enter cell number' });
            resetError(1800);
            return true;
        }
        else if (!validVisitorDetails && email === '') {
            setError({ status: 'error', message: 'Please enter email' });
            resetError(1800);
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (match.params && match.params.token) {
            if (firstName !== '' && lastName !== '' && ((cellNo !== '' &&
                cellNo !== '(+1) ___-___-____' && cellNo.replace(/[^0-9]/g, "").length === 11) ||
                email.toLowerCase().match(emailRegex))) {
                setValidVisitorDetails(true);
            } else {
                setValidVisitorDetails(false);
            }
        }
        if (!fetchingData && !notificationId && validVisitorDetails) {
            setFetchingData(true);
            const visitor = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                cellNumber: cellNo && cellNo !== '(+1) ___-___-____' && cellNo.replace(/[^0-9]/g, "").length === 11 ? cellNo : '',
                email: email && email.toLowerCase().match(emailRegex) ? email : '',
                status: 'Inactive',
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/addQRVisit', visitor, createTokenConfig(match.params.token))
                .then((res) => {
                    if (res.status === 200) {
                        setOrganizationId(res.data.organizationId);
                        setNotificationId(res.data.notificationId);
                        setVisitorId(res.data.visitorId);
                        setUseCase(res.data.useCase);
                        setAtLeastOneNotReqQue(res.data.atLeastOneNotReqQue);
                        // group by required
                        let {
                            required = [],
                        } = groupBy(res.data.questions, q => q.required ? 'required' : 'notRequired');

                        required.forEach((que) => {
                            if (!que.originalQuestionSortNumber && que.originalQuestionSortNumber !== 0) {
                                que.originalQuestionSortNumber = que.sortOrder;
                            }
                        })

                        // sort by originalQuestionSortNumber
                        required = sortBy(required, ['originalQuestionSortNumber']);

                        setRequiredQue([...required]);
                    } else {
                        setError({ hasError: true, status: 'Unknown', msg: 'Something went wrong' });
                    }
                }).catch((error) => {
                    setError({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        match,
        match.params,
        firstName,
        lastName,
        cellNo,
        email,
        validVisitorDetails,
        notificationId,
    ]);

    return (
        <div style={{ backgroundColor: '#d6d8d9', height: '100vh' }}>
            <Alert style={{ backgroundColor: '#343a40', color: 'white' }}>
                <Alert.Heading className="text-center">
                    Hey, there!
                </Alert.Heading>
            </Alert>
            <Fragment>
                {error.status ?
                    <Fade
                        enter={true}
                        exit={true}>
                        <Notification
                            type={{ style: error.status, icon: true }}
                            closable={true}
                            onClose={() => setError({ status: null, message: '' })}
                        >
                            <span>{error.message}</span>
                        </Notification>
                    </Fade>
                    : null
                }
                <Container
                    className="mt-5"
                    style={{
                        height: '100vh-56px',
                        overflowY: 'auto',
                    }}
                >
                    <Alert variant="light">
                        <Alert.Heading
                            className={isMobile ? "text-center" : ""}
                        >
                            <strong>Please fill out your details</strong>
                        </Alert.Heading>
                        <hr />
                        <div className="mt-3 mb-4">
                            <Form.Group controlId="firstName">
                                <Form.Label style={{ fontSize: 18 }}>
                                    <strong>First Name</strong><span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Enter First Name"
                                    value={firstName}
                                    onChange={(e) => {
                                        if (error.status) {
                                            setError({ status: null, message: '' });
                                        }
                                        setFirstName(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="mt-2 mb-4">
                            <Form.Group controlId="lastName">
                                <Form.Label style={{ fontSize: 18 }}>
                                    <strong>Last Name</strong><span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Enter Last Name"
                                    value={lastName}
                                    onChange={(e) => {
                                        if (error.status) {
                                            setError({ hasError: null, message: '' });
                                        }
                                        setLastName(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="mt-2 mb-4">
                            <Form.Group controlId="email">
                                <Form.Label style={{ fontSize: 18 }}>
                                    <strong>Email</strong><span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) => {
                                        if (error.status) {
                                            setError({ hasError: null, message: '' });
                                        }
                                        setEmail(e.target.value.trim());
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <div className="mt-2 mb-4">
                            <Form.Group controlId="cellNo">
                                <Form.Label style={{ fontSize: 18 }}>
                                    <strong>Cell #</strong>
                                </Form.Label>
                                <MaskedTextBox
                                    mask="(+1) 000-000-0000"
                                    placeholder="Enter Cell #"
                                    value={cellNo}
                                    onChange={(e) => {
                                        if (error.status) {
                                            setError({ hasError: null, message: '' });
                                        }
                                        setCellNo(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                        <strong><span style={{ color: 'red' }}>*</span>Required Fields</strong><br /><br />
                        <strong><i>Note: Either a <u>valid email address</u> OR a <u>valid cell phone number</u> is required.</i></strong>
                    </Alert>
                    <hr />
                    <p className="text-right">
                        <Button
                            primary
                            disabled={!validVisitorDetails}
                            onClick={() => {
                                if (!validations()) {
                                    saveResultsToDB();
                                }
                            }}
                        >Submit&nbsp;<span className="k-icon k-i-arrow-chevron-right"></span>
                        </Button>
                    </p>
                </Container>
            </Fragment>
        </div>
    );
};

export default QRVisit;
