import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import axios from 'axios';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Result } from 'antd';
import { Button } from '@progress/kendo-react-buttons';
import '../../stylesheets/kendoNotifications.css';

const ResendVerification = ({ history }) => {
    const [companyEmail, setCompanyEmail] = useState('');
    const [error, setError] = useState({
        hasError: false,
        message: '',
    });
    const [emailSent, setEmailSent] = useState(false);

    const resendEmail = async () => {
        if (!companyEmail) {
            setError({ hasError: true, message: 'Company email is required' });
        } else {
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/resendVerification/', { companyEmail })
                .then(res => {
                    if (res.status === 200) {
                        setEmailSent(true);
                    }
                }).catch((error) => {
                    setError({ hasError: true, message: error.response && error.response.data && error.response.data.msg });
                });
        }
    };

    return (
        <div style={{ padding: 15 }}>
            {!emailSent ?

                <Row md="12" className="justify-content-md-center">
                    <Col md="2"></Col>
                    <Col md="8">
                        <Card
                            style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                        >
                            <Card.Header
                                style={{
                                    fontSize: "30px",
                                    backgroundColor: '#343a40',
                                    color: 'white'
                                }}
                            >Resend Verification
                        </Card.Header>
                            <Card.Body>
                                <form>
                                    {error.hasError ?
                                        <Fade
                                            enter={true}
                                            exit={true}
                                            style={{ width: '100%' }}
                                            className="inline-notification"
                                        >
                                            <Notification
                                                type={{ style: 'error', icon: true }}
                                                style={{ width: '100%' }}
                                            >
                                                <span>{error.status}&nbsp;{error.message}</span>
                                            </Notification>
                                        </Fade>
                                        : null
                                    }
                                    <div className="form-group">
                                        <label>Company Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter company email"
                                            onChange={(e) => {
                                                if (error) {
                                                    setError(false)
                                                }
                                                setCompanyEmail(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-block"
                                        style={{ backgroundColor: '#343a40', color: 'white' }}
                                        onClick={resendEmail}
                                    >
                                        Resend
                                    </button>
                                </form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="2"></Col>
                </Row>
                : <Result
                    icon={<i className="fas fa-paper-plane fa-5x" style={{ color: '#1890ff' }}></i>}
                    title="Verification mail has been sent!"
                    extra={<Button primary onClick={() => history.push('/signin')}>Sign in</Button>}
                />
            }
        </div>
    );
};
export default ResendVerification;
