import React, { Fragment, useState } from "react";
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { Window } from '@progress/kendo-react-dialogs';
import { Upload } from '@progress/kendo-react-upload';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Col, Container, Row } from "react-bootstrap";
import { Result } from 'antd';
import '../../stylesheets/windowStyles.css';
import getTokenConfig from "../../../methods/getTokenConfig";

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const schema = {
    'First Name': {
        prop: 'firstName',
        type: String,
        required: true
    },
    'Last Name': {
        prop: 'lastName',
        type: String,
        required: true
    },
    'Current Status': {
        prop: 'currentStatus',
        type: String,
        required: true,
        oneOf: [
            'Active',
            'On Hold',
            'Terminated'
        ]
    },
    'Email': {
        prop: 'email',
        type: String,
        parse(value) {
            const validEmail = emailRegex.test(String(value).toLowerCase())
            if (!validEmail) {
                throw new Error('Invalid Data')
            }
            return value
        }
    },
    /* TODO: Implement bulk loading with department and site */
    // 'Department/Site': {
    //     prop: 'department',
    //     type: String,
    // },
    'Cell Number': {
        prop: 'cellNumber',
        type: String,
        parse(value) {
            let number = value.toString().replace(/[()-\s]/g, '').replace(/[^\d]/g, '');
            if (number.length !== 10) {
                throw new Error('Invalid Data - please use a string of 10 digits. (ex. 5195555555)')
            }
            const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(+1) ' + match[1] + '-' + match[2] + '-' + match[3]
            } else {
                return number;
            }
        }
    }
};

const EmpList = [
    {
        firstName: "First1",
        lastName: "Last1",
        currentStatus: "Active",
        email: "1@1.com",
        /* TODO: Implement bulk loading with department and site */
        // department: "A1",
        cellNumber: "xxxxxxxxxx",
    },
    {
        firstName: "First2",
        lastName: "Last2",
        currentStatus: "Active",
        email: "2@2.com",
        /* TODO: Implement bulk loading with department and site */
        // department: "A2",
        cellNumber: "xxxxxxxxxx",
    },
    {
        firstName: "First3",
        lastName: "Last3",
        currentStatus: "Active",
        email: "3@3.com",
        /* TODO: Implement bulk loading with department and site */
        // department: "A3",
        cellNumber: "xxxxxxxxxx",
    },
    {
        firstName: "First4",
        lastName: "Last4",
        currentStatus: "Active",
        email: "4@4.com",
        /* TODO: Implement bulk loading with department and site */
        // department: "A4",
        cellNumber: "xxxxxxxxxx",
    },
    {
        firstName: "First5",
        lastName: "Last5",
        currentStatus: "Active",
        email: "5@5.com",
        /* TODO: Implement bulk loading with department and site */
        // department: "A5",
        cellNumber: "xxxxxxxxxx",
    }
];

const EmployeeBulkLoadDialog = ({ setShowBulkLoadDialog, setEmployeesList, setMgsType }) => {
    const [stage, onStageChange] = useState('DEFAULT');
    const [files, onFilesChange] = useState([]);
    const [empList, setEmpList] = useState([]);
    const [validData, setValidData] = useState(true);
    const [errors, setErrors] = useState([]);
    const [results, setResults] = useState({ uploadCompleted: false, uploadResult: '' });

    const bulkLoadEmployees = () => {
        if (errors && errors.length === 0 && empList && empList.length > 0) {
            axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/employees/bulkLoad/',
                empList,
                getTokenConfig()
            ).then((response) => {
                if (response.status === 200) {
                    const orgId = localStorage.getItem('CheckN.orgId');
                    axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                        .then(response => {
                            setMgsType({ status: 'success', message: 'Employees uploaded successfully.' });
                            setEmployeesList([...response.data])

                        }).catch((error) => {
                            setMgsType({ status: 'error', message: 'Error in fetching employee records.' });
                        });
                    setResults({ uploadCompleted: true, uploadResult: 'success' });
                }
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                setResults({ uploadCompleted: true, uploadResult: 'error' });
            });
        }
    };

    const th = {};

    return (
        <Window
            title={"Upload Employees"}
            stage={stage}
            onStageChange={() => onStageChange('FULLSCREEN')}
            onClose={() => {
                setShowBulkLoadDialog(false);
            }}
            initialHeight={'calc(100vh/1.5)'}
            initialWidth={800}
            initialTop={0}
            style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        >
            <Container
                fluid
                className="p-4 border"
                style={{ backgroundColor: 'whitesmoke' }}
            >
                <Row>
                    <Col>
                        <Button
                            style={{ color: 'green' }}
                            onClick={() => { th._exporter.save(); }}
                        >
                            <i className="far fa-file-excel mr-2" />
                            Download Demo Template
                        </Button>
                        <ExcelExport
                            data={EmpList}
                            fileName="EmpLoad.xlsx"
                            ref={(exporter) => { th._exporter = exporter; }}
                        >
                            <ExcelExportColumn field="firstName" title="First Name" />
                            <ExcelExportColumn field="lastName" title="Last Name" />
                            <ExcelExportColumn field="currentStatus" title="Current Status" />
                            <ExcelExportColumn field="email" title="Email" />
                            {/* TODO: Implement bulk loading with department and site */}
                            {/* <ExcelExportColumn field="department" title="Department/Site" /> */}
                            <ExcelExportColumn field="cellNumber" title="Cell Number" />
                        </ExcelExport>
                    </Col>
                </Row>
            </Container>
            <Upload
                batch={false}
                restrictions={{
                    allowedExtensions: ['.xlsx']
                }}
                multiple={false}
                files={files}
                onAdd={(event) => {
                    onFilesChange(event.newState);
                    setValidData(true);
                    setEmpList([]);
                    setErrors([]);
                    setResults({ uploadCompleted: false, uploadResult: '' });
                    readXlsxFile(event.newState[0].getRawFile(), { schema }).then(async ({ rows, errors }) => {
                        if (errors.length === 0) {
                            const orgId = localStorage.getItem('CheckN.orgId');
                            const emps = await rows.map(row => ({ ...row, organizationId: orgId }));
                            emps.forEach((emp) => {
                                if (!emp.firstName ||
                                    !emp.lastName ||
                                    !emp.currentStatus ||
                                    /* TODO: Implement bulk loading with department and site */
                                    // !emp.department ||
                                    (!emp.email &&
                                        !emp.cellNumber)) {
                                    setValidData(false);
                                }
                            });
                            setEmpList(emps);
                        } else {
                            setErrors(errors);
                        }
                    })
                }}
                onProgress={(event) => onFilesChange(event.newState)}
                onStatusChange={(event) => onFilesChange(event.newState)}
                onRemove={(event) => {
                    onFilesChange(event.newState);
                    setEmpList([]);
                    setErrors([]);
                    setResults({ uploadCompleted: false, uploadResult: '' });
                }}
                autoUpload={false}
                showActionButtons={false}
                withCredentials={false}
                className="mb-3 mt-3"
            />
            {empList && empList.length > 0 && !results.uploadCompleted ?
                <ListView
                    data={empList}
                    item={(props) => (
                        <Container fluid className="p-4 border-bottom">
                            <Row style={{ width: '98%', fontSize: 14 }}>
                                <Col>
                                    <Row xs={12} sm={12} md={12} lg={12}>
                                        <Col md={2}>
                                            <strong>First Name:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.firstName}
                                        </Col>
                                    </Row>
                                    <Row md={12}>
                                        <Col md={2}>
                                            <strong>Last Name:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.lastName}
                                        </Col>
                                    </Row>
                                    <Row md={12}>
                                        <Col md={2}>
                                            <strong>Current Status:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.currentStatus}
                                        </Col>
                                    </Row>
                                    <Row md={12}>
                                        <Col md={2}>
                                            <strong>Email:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.email}
                                        </Col>
                                    </Row>
                                    {/* TODO: Implement bulk loading with department and site */}
                                    {/* <Row md={12}>
                                        <Col md={2}>
                                            <strong>Department/Site:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.department}
                                        </Col>
                                    </Row> */}
                                    <Row md={12}>
                                        <Col md={2}>
                                            <strong>Cell Number:</strong>
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.cellNumber}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    style={{
                        width: '100%',
                        height: '400px'
                    }}
                    header={() => (
                        <ListViewHeader style={{ fontSize: 24 }} className='pl-3 pb-2 pt-2'>
                            <Container fluid>
                                <Row xs={12} sm={12} md={12}>
                                    <Col md={5}>
                                        Employees List Preview
                                    </Col>
                                    <Col md={7} className="text-right">
                                        {!validData ?
                                            <Button
                                                primary
                                                onClick={() => bulkLoadEmployees()}
                                                disabled={!validData}
                                            >
                                                Please ensure either cell phone or email is filled in!
                                            </Button> :
                                            <Button
                                                primary
                                                onClick={() => bulkLoadEmployees()}
                                                disabled={!validData}
                                            >
                                                Upload
                                            </Button>}
                                    </Col>
                                </Row>
                            </Container>
                        </ListViewHeader>
                    )}
                    footer={() => (
                        <ListViewFooter style={{ fontSize: 14 }} className='pl-3 pb-2 pt-2'>
                            {empList.length} Employees
                        </ListViewFooter>
                    )}
                />
                : <Fragment>
                    {errors && errors.length > 0 && !results.uploadCompleted ?
                        <ListView
                            data={errors}
                            item={(props) => (
                                <Container fluid className="p-4 border-bottom">
                                    <Row md={12} style={{ width: '98%', fontSize: 14, color: '#B01C2E' }}>
                                        <Col md={2}>
                                            <strong>Error:</strong><br />
                                            <strong>Row:</strong><br />
                                            <strong>Column:</strong><br />
                                            <strong>Value:</strong><br />
                                        </Col>
                                        <Col md={10}>
                                            {props.dataItem.error}<br />
                                            {props.dataItem.row}<br />
                                            {props.dataItem.column}<br />
                                            {props.dataItem.value}<br />
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                            style={{
                                width: '100%',
                                height: '400px'
                            }}
                            header={() => (
                                <ListViewHeader style={{ fontSize: 20 }} className='pl-3 pb-2 pt-2'>
                                    {errors.length === 1 ? 'You have an error' : 'You have a few errors'}:
                                </ListViewHeader>
                            )}
                            footer={() => (
                                <ListViewFooter style={{ fontSize: 14 }} className='pl-3 pb-2 pt-2'>
                                    {errors.length} {errors.length === 1 ? 'Error' : 'Errors'}
                                </ListViewFooter>
                            )}
                        />
                        : null
                    }
                    {results.uploadCompleted ?
                        <Result
                            status={results.uploadResult}
                            title={
                                results.uploadResult === 'success' ?
                                    'Employees uploaded successfully'
                                    : 'Something went wrong'
                            }
                        />
                        : null
                    }
                </Fragment>
            }
        </Window>
    );
};

export default EmployeeBulkLoadDialog;
