import React from "react";
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';

const ContactInfo = ({
    contactFirstName,
    setContactFirstName,
    contactLastName,
    setContactLastName,
    contactPosition,
    setContactPosition,
    contactPhone,
    setContactPhone,
    contactEmail,
    setContactEmail,
    error,
    setError
}) => (
        <form className="k-form">
            <fieldset>
                <div className="row">
                    <label className="k-form-field col-md-6">
                        <div style={{ marginBottom: 8 }}>First Name</div>
                        <Input
                            type={"text"}
                            placeholder="Enter First Name"
                            value={contactFirstName}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setContactFirstName(e.value);
                            }}
                        />
                    </label>
                    <label className="k-form-field col-md-6">
                        <div style={{ marginBottom: 8 }}>Last Name</div>
                        <Input
                            type={"text"}
                            placeholder="Enter Last Name"
                            value={contactLastName}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setContactLastName(e.value);
                            }}
                        />
                    </label>
                </div>
                <label className="k-form-field">
                    <div style={{ marginBottom: 8 }}>Position</div>
                    <Input
                        type={"text"}
                        placeholder="Enter Contact Position"
                        value={contactPosition}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setContactPosition(e.value);
                        }}
                    />
                    <Hint>Hint: Enter contact's position in the company.</Hint>
                </label>
                <div className="row">
                    <label className="k-form-field col-md-6">
                        <div style={{ marginBottom: 8 }}>Phone #</div>
                        <MaskedTextBox
                            mask="(+1) 000-000-0000"
                            placeholder="Enter Contact Phone #"
                            value={contactPhone}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setContactPhone(e.value);
                            }}
                        />
                    </label>
                    <label className="k-form-field col-md-6">
                        <div style={{ marginBottom: 8 }}>Email</div>
                        <Input
                            type={"text"}
                            placeholder="Enter Contact Email"
                            value={contactEmail}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setContactEmail(e.value);
                            }}
                        />
                    </label>
                </div>
            </fieldset>
        </form>
    );

export default ContactInfo;
