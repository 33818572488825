import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button } from '@progress/kendo-react-buttons';
import { Sortable } from '@progress/kendo-react-sortable';
import {
    Card,
    Col,
    Row,
    OverlayTrigger,
    Tooltip,
    Dropdown,
} from 'react-bootstrap';
import sortBy from 'lodash/sortBy';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import QuestionsPreview from "./questionsPreview";
import getTokenConfig from "../../../methods/getTokenConfig";
import AddNewQuestionWindow from "./addNewQuestionWindow";
import ConfirmDialog from "../../util/confirmDialog";

const QuestionsSetup = () => {
    const [fetchData, setFetchData] = useState(true);

    const [addedQuestions, setAddedQuestions] = useState([]);
    const [preview, setPreview] = useState(false);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });
    const [selectedQue, setSelectedQue] = useState(null);
    const [showAddNewQueWindow, setShowAddNewQueWindow] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showResetDialog, setShowResetDialog] = useState(false);

    const [resetLayoutFlag, setResetLayoutFlag] = useState(false);

    const [useCase, setUseCase] = useState('');

    const [userGroupOptions, setUserGroupOptions] = useState([]);

    const [groupsWithoutQuestions, setGroupsWithoutQuestions] = useState([]);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    const onDragOver = (event) => {
        setAddedQuestions(event.newState);
        saveLayout(event.newState, false);
    };

    const onNavigate = (event) => {
        setAddedQuestions(event.newState);
    }

    const resetMessage = (time) => {
        setTimeout(() => {
            setMgsType({ status: null, message: '' });
        }, time);
    }

    const SimpleCard = (props) => {
        const { isDisabled, isActive, style, attributes, dataItem, forwardRef } = props;
        const classNames = ['mb-2'];

        if (isDisabled) {
            classNames.push('k-state-disabled');
        }

        return (
            <Card
                key={!isMobile ? dataItem._id : undefined}
                ref={!isMobile ? forwardRef : undefined}
                {...attributes}
                style={{
                    ...style,
                    borderColor: isActive ? '#27aceb' : '#fff',
                    backgroundColor: '#cce5ff',
                    color: '#004085'
                }}
                className={classNames.join(' ')}
            >
                <Card.Body>
                    <Card.Title>
                        Question
                        {((dataItem.optional === true && addedQuestions.length > 1 && useCase !== 'Sign In Management' && resetLayoutFlag) ||
                            (dataItem.optional === true && useCase === 'Sign In Management' && resetLayoutFlag)) &&
                            <Button
                                style={{ float: 'right', marginTop: '-6px' }}
                                icon="close"
                                look="flat"
                                onClick={() => {
                                    setAddedQuestions(addedQuestions.filter((question) => {
                                        // This wil filter out the question with the matching _id
                                        if (question._id === dataItem._id)
                                            return false;
                                        return true;
                                    }));
                                }}
                            />}
                    </Card.Title>
                    <Card.Body>
                        {dataItem.questionString}
                        {dataItem.questionType === 'list' &&
                            <ul>
                                {dataItem.options.map(op => <li>{op}</li>)}
                            </ul>
                        }
                        {(dataItem.questionType === 'Yes/No' || dataItem.questionType === 'text') && <><br /><br /></>}
                        {dataItem.questionType !== 'text' ?
                            <i>Answer <strong>{dataItem.failAnswer.toUpperCase()}</strong> will {useCase === 'COVID Screening' ?
                                'cause the screening to fail' : !dataItem.showSiteOptionsWithNotes ? 'allow for notes to be added' :
                                'show a list of available sites and a notes field if the individual chooses *OTHER*.'}
                                {dataItem.allowSiteUpdate ?
                                    <>
                                        <br />
                                        Automatic site updates are <strong>enabled</strong>
                                    </> :
                                    dataItem.showSiteOptionsWithNotes ?
                                        <>
                                            <br />
                                            Automatic site updates are <strong>disabled</strong>
                                        </> :
                                        ''}.
                            </i> :
                            <i>Answer greater than <strong>37.8°C or 100°F</strong> will cause the screening to fail.</i>}
                        {dataItem.queStringAboveNotesField &&
                            <>
                                <br /><br />
                                <strong>Custom question asked if answer is {dataItem.failAnswer.toUpperCase()}</strong>:
                                <br />
                                {dataItem.queStringAboveNotesField}
                            </>}
                        <br /><br />
                        <strong>Configured Group(s):</strong> {dataItem.selectedGroups.map(selectedGroup => selectedGroup.label).join(', ')}
                    </Card.Body>
                    {!dataItem.defaultQue && resetLayoutFlag &&
                        <>
                            <hr />
                            <Button
                                iconClass="far fa-edit"
                                look="outline"
                                onClick={() => {
                                    saveLayout(addedQuestions, false);
                                    setSelectedQue(dataItem);
                                    setShowAddNewQueWindow(true);
                                }}
                            >
                                &nbsp;Edit
                            </Button>&nbsp;
                            {((addedQuestions.length > 1 && resetLayoutFlag) ||
                                (useCase === 'Sign In Management' && resetLayoutFlag)) &&
                                <Button
                                    iconClass="far fa-trash-alt"
                                    look="outline"
                                    onClick={() => {
                                        saveLayout(addedQuestions, false);
                                        setSelectedQue(dataItem);
                                        setShowDeleteDialog(true);
                                    }}
                                >
                                    &nbsp;Delete
                            </Button>}
                        </>}
                </Card.Body>
            </Card>
        );
    };

    const saveLayout = async (addedQuestionArray, toggleResetLayoutFlag, saveLayoutMessage = false) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        await axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateQueOrder/' + orgId,
            addedQuestionArray.map(q => q._id), getTokenConfig())
            .then(response => {
                if (response.status === 200) {
                    if (toggleResetLayoutFlag) {
                        setResetLayoutFlag(false);
                    }
                    if (saveLayoutMessage) {
                        setMgsType({
                            status: 'success',
                            message: 'Layout saved successfully.'
                        });
                        resetMessage(1800);
                    }
                }
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
        setFetchData(true);
    };

    const deleteQuestion = () => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/delete/', { id: selectedQue._id }, getTokenConfig())
            .then((response) => {
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgOptionalQue', getTokenConfig())
                    .then(async (response) => {
                        setUseCase(response.data.useCase);
                        const questions = response.data.questions;
                        const allQuestionsNeeded = response.data.allQuestionsNeeded;
                        await questions.forEach((que) => {
                            que.order = allQuestionsNeeded.find(q => q._id.toString() === que._id.toString()).order;
                        });

                        setAddedQuestions([...sortBy(questions, ['order'])]);
                        setMgsType({
                            status: 'success',
                            message: "Question deleted successfully."
                        });
                        resetMessage(1800);
                    }).catch((err) => {
                        setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                        resetMessage(1800);
                    });
                setSelectedQue(null);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
    };

    const resetLayout = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/resetByOrg', getTokenConfig())
            .then(response => {
                setUseCase(response.data.useCase);
                const filteredQuestions = [...response.data.question.filter(q => !q.required)].filter(q => q.useCase === useCase);
                setAddedQuestions(filteredQuestions);
                setResetLayoutFlag(true);
                setMgsType({
                    status: 'success',
                    message: "Questions & Layout reset successfully."
                });
                saveLayout(filteredQuestions, true);
                resetMessage(1800);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const modifyLayout = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgOptionalQue', getTokenConfig())
            .then(async response => {
                setUseCase(response.data.useCase);
                const questions = response.data.questions;
                const allQuestionsNeeded = response.data.allQuestionsNeeded;
                await questions.forEach((que) => {
                    que.order = allQuestionsNeeded.find(q => q._id.toString() === que._id.toString()).order;
                });

                setAddedQuestions([...sortBy(questions, ['order'])].filter(q => q.useCase === useCase));
                setResetLayoutFlag(true);
                setMgsType({
                    status: 'success',
                    message: "Question(s) ready to modify."
                });
                resetMessage(1800);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const getData = async () => {
        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgOptionalQue/', getTokenConfig())
            .then(async (response) => {
                setUseCase(response.data.useCase);
                const questions = response.data.questions;
                const allQuestionsNeeded = response.data.allQuestionsNeeded;
                await questions.forEach((que) => {
                    que.order = allQuestionsNeeded.find(q => q._id.toString() === que._id.toString()).order;
                });

                setAddedQuestions([...sortBy(questions, ['order'])].filter(q => q.useCase === useCase));
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
        const orgId = localStorage.getItem('CheckN.orgId');
        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgIdMinimal/' + orgId, getTokenConfig())
            .then(response => {
                const userGroupData = response.data;
                setUserGroupOptions([{
                    _id: '000AE', name: '*ALL EMPLOYEES*',
                }, {
                    _id: '001AV', name: '*ALL VISITORS*',
                }].concat(userGroupData));
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
        setFetchData(false);
    };

    useEffect(() => {
        if (fetchData) {
            getData();
        }
        if (!fetchData && addedQuestions && addedQuestions.length > 0 && userGroupOptions && userGroupOptions.length > 0) {
            let groupsWithoutMatches = [];
            let groupsWithCounts = userGroupOptions;
            addedQuestions.forEach((que) => {
                if (que.selectedGroups.length > 0) {
                    que.selectedGroups.forEach((selectedGroup) => {
                        groupsWithCounts.forEach((group, i) => {
                            // the value property is the id of the group
                            if (group._id.toString() === selectedGroup.value) {
                                group.count = group.count ? group.count + 1 : 1;
                            } else {
                                group.count = group.count ? group.count : 0;
                            }
                            groupsWithCounts[i] = group;
                        });
                    });
                }
            });
            groupsWithoutMatches = groupsWithCounts.filter(group => group.count === 0);
            if (groupsWithoutMatches.length > 0) {
                let allEmpHaveAQuestion = true;
                let allVisHaveAQuestion = true;
                groupsWithoutMatches.forEach((group) => {
                    if (group._id.toString() === '000AE') {
                        allEmpHaveAQuestion = false;
                    }
                    if (group._id.toString() === '001AV') {
                        allVisHaveAQuestion = false;
                    }
                });
                if (!allEmpHaveAQuestion) {
                    if (allVisHaveAQuestion && groupsWithoutMatches.length === (userGroupOptions.length - 1)) {
                        setGroupsWithoutQuestions(groupsWithoutMatches.filter(group => group._id === '000AE'));
                    } else {
                        setGroupsWithoutQuestions(groupsWithoutMatches.filter(group => group._id !== '000AE'));
                    }
                } else if (!allVisHaveAQuestion) {
                    setGroupsWithoutQuestions(groupsWithoutMatches.filter(group => group._id === '001AV'));
                }
                if (allEmpHaveAQuestion && allVisHaveAQuestion) {
                    setGroupsWithoutQuestions([]);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useCase, fetchData, addedQuestions]);

    return (
        <div style={{ paddingLeft: !isMobile ? 15 : 5, paddingRight: !isMobile ? 15 : 5 }}>
            {mgsType.status !== null &&
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup>}
            <legend>
                Preview
                {addedQuestions && addedQuestions.length > 0 &&
                    <span style={{ fontSize: '14px' }}>
                        &nbsp;&nbsp;(Drag to rearrange questions - after any changes please click "Save Layout" button to save those changes.)
                    </span>}
            </legend>
            {groupsWithoutQuestions.length > 0 &&
                <>
                    <span style={{ color: 'red' }}><strong>WARNING!&nbsp;</strong></span>
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-disabled">
                                <strong>The following group(s) do not have questions:</strong>
                                <br />
                                <i>{groupsWithoutQuestions.map(group => group.name).join(', ')}</i>
                                {(groupsWithoutQuestions.filter(group => group._id === '000AE').length === 1 || groupsWithoutQuestions.length > 1) &&
                                    <>
                                        <br /><br />
                                        <strong>
                                            <i>Please either add *ALL EMPLOYEES* to a question, or add each group from the above list.</i>
                                        </strong>
                                    </>}
                            </Tooltip>}
                    >
                        <i style={{ color: 'red' }} className="fas fa-info-circle" />
                    </OverlayTrigger>
                    <br />
                    <br />
                </>
            }
            {addedQuestions && addedQuestions.length === 0 ?
                <span>Please add questions to your form from the list.</span>
                :
                !isMobile && addedQuestions.length > 1 ? <Sortable
                    idField={'_id'}
                    disabledField={'disabled'}
                    data={addedQuestions}
                    itemUI={SimpleCard}
                    onDragOver={!(isMobile || isNarrowScreen) ? (event) => onDragOver(event) : undefined}
                    onNavigate={(event) => onNavigate(event)}
                /> : addedQuestions.map((questionItem) => <SimpleCard dataItem={{...questionItem}} />)
            }
            {preview &&
                <QuestionsPreview
                    setPreview={setPreview}
                    addedQuestions={addedQuestions}
                />}
            {showAddNewQueWindow &&
                <AddNewQuestionWindow
                    selectedQue={selectedQue}
                    setSelectedQue={setSelectedQue}
                    setShowAddNewQueWindow={setShowAddNewQueWindow}
                    setMgsType={setMgsType}
                    resetMessageType={resetMessage}
                    addedQuestions={addedQuestions}
                    setAddedQuestions={setAddedQuestions}
                    useCase={useCase}
                    userGroupOptions={userGroupOptions}
                />}
            {showDeleteDialog &&
                <ConfirmDialog
                    visible={showDeleteDialog}
                    setVisible={setShowDeleteDialog}
                    title="Delete Question"
                    message="Are you sure you want to delete this question?"
                    onConfirm={deleteQuestion}
                    onCancel={() => { setSelectedQue(null); }}
                />}
            {showResetDialog &&
                <ConfirmDialog
                    visible={showResetDialog}
                    setVisible={setShowResetDialog}
                    title="Reset Layout"
                    message="Are you sure you want to reset the layout?"
                    hint="If you would like to modify the layout (add/remove/reorder questions), click 'No' and then please click 'Modify Layout' instead."
                    onConfirm={resetLayout}
                />}
            <div className="mb-5" style={{ paddingBottom: isMobile ? 50 : undefined }}>
                {groupsWithoutQuestions.length > 0 &&
                    <>
                        <span style={{ color: 'red' }}><strong>WARNING!&nbsp;</strong></span>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    <strong>The following group(s) do not have questions:</strong>
                                    <br />
                                    <i>{groupsWithoutQuestions.map(group => group.name).join(', ')}</i>
                                    {(groupsWithoutQuestions.filter(group => group._id === '000AE').length === 1 || groupsWithoutQuestions.length > 1) &&
                                        <>
                                            <br /><br />
                                            <strong>
                                                <i>Please either add *ALL EMPLOYEES* to a question, or add each group from the above list.</i>
                                            </strong>
                                        </>}
                                </Tooltip>}
                        >
                            <i style={{ color: 'red' }} className="fas fa-info-circle" />
                        </OverlayTrigger>
                        <br />
                    </>
                }
                {!isMobile ? <><Row lg={12}>
                    <Col lg={8} className="mt-3">
                        <Button
                            look="outline"
                            icon="reset"
                            onClick={() => {
                                setShowResetDialog(true);
                            }}
                        >Reset Layout
                        </Button>
                        <span>  |  </span>
                        <Button
                            look="outline"
                            icon="reset"
                            onClick={() => modifyLayout()}
                        >Modify Layout
                        </Button>
                        &nbsp;
                        <Button
                            look="outline"
                            icon="preview"
                            onClick={() => setPreview(true)}
                        >Preview
                        </Button>
                        &nbsp;
                        <Button
                            primary={resetLayoutFlag}
                            look="outline"
                            icon="save"
                            disabled={!resetLayoutFlag}
                            onClick={() => saveLayout(addedQuestions, true, true)}
                        >Save Layout
                        </Button>
                    </Col>
                    <Col lg={4} className="text-right mt-3">
                        <Button
                            look="outline"
                            icon="plus"
                            onClick={async () => {
                                await saveLayout(addedQuestions, false);
                                setShowAddNewQueWindow(true);
                            }}
                            disabled={!resetLayoutFlag}
                        >
                            Add Question(s)
                        </Button>
                    </Col>
                </Row></> :
                <Dropdown drop="up" id="actions">
                    <Dropdown.Toggle name="actionsToggle">
                        Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setShowResetDialog(true);
                            }}
                        >
                            <i class="k-icon k-i-refresh"></i>&nbsp;&nbsp;&nbsp;Reset Layout
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => modifyLayout()}
                        >
                            <i class="k-icon k-i-refresh"></i>&nbsp;&nbsp;&nbsp;Modify Layout
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={!resetLayoutFlag}
                            onClick={() => saveLayout(addedQuestions, true, true)}
                        >
                            <i class="k-icon k-i-save"></i>&nbsp;&nbsp;&nbsp;Save Layout
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={!resetLayoutFlag}
                            onClick={async () => {
                                await saveLayout(addedQuestions, false);
                                setShowAddNewQueWindow(true);
                            }}
                        >
                            <i class="k-icon k-i-plus"></i>&nbsp;&nbsp;&nbsp;Add Question(s)
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </div>
    );
};
export default QuestionsSetup;
