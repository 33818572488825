import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Divider } from 'antd';
// import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { countryOptions } from "../../util/countryStateProvience";

const CompanyDetails = ({
    inEditMode,
    companyName,
    setCompanyName,
    setCompanyPostalCode,
    companyPostalCode,
    companyEmail,
    setCompanyEmail,
    verified,
    companyAddress1,
    setCompanyAddress1,
    companyAddress2,
    setCompanyAddress2,
    companyCountry,
    setCompanyCountry,
    companyStateProv,
    setCompanyStateProv,
    companyCity,
    setCompanyCity,
    error,
    setError
}) => {
    const countryOpt = countryOptions.map(ele => ele.country);
    let stateOpt = [];
    if (companyCountry) {
        stateOpt = countryOptions.filter(ele => ele.country === companyCountry)[0].options;
    }
    return (
        <Row>
            <Col>
                <Divider orientation="left">Company Details</Divider>
                <Form>
                    <Form.Group controlId="companyName">
                        <Form.Label>
                            <strong>Company Name</strong>
                        </Form.Label>
                        <Form.Control
                            plaintext={inEditMode !== 'company'}
                            readOnly={inEditMode !== 'company'}
                            placeholder="Enter Company Name"
                            value={companyName}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setCompanyName(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyEmail">
                        <Form.Label>
                            <strong>Company Email</strong>&nbsp;&nbsp;
                            <small>{verified ? '(Verified)' : null}</small>
                        </Form.Label>
                        <Form.Control
                            plaintext={inEditMode !== 'company'}
                            readOnly
                            placeholder="Enter Company Email"
                            value={companyEmail}
                        // onChange={(e) => {
                        //     if (error.hasError) {
                        //         setError({ hasError: false, message: '' });
                        //     }
                        //     orgDetails.companyEmail = e.target.value
                        //     setOrgDetails(orgDetails);
                        // }}
                        />
                        {inEditMode === 'company' ?
                            <Form.Text className="text-muted">
                                Hint: Enter company email address.
                            </Form.Text>
                            : null
                        }
                    </Form.Group>
                    <Row>
                        <Col>
                            <Fragment>
                                <Form.Group controlId="companyCountry">
                                    <Form.Label>
                                        <strong>Country</strong>
                                    </Form.Label>
                                    {inEditMode !== 'company' ?
                                        <Form.Control
                                            plaintext
                                            readOnly
                                            value={companyCountry}
                                        />
                                        :
                                        <Fragment> <br />
                                            <DropDownList
                                                data={countryOpt}
                                                placeholder="e.g. Canada"
                                                style={{ backgroundColor: 'white', width: '100%' }}
                                                value={companyCountry}
                                                onChange={(e) => {
                                                    if (error.hasError) {
                                                        setError({ hasError: false, message: '' });
                                                    }
                                                    setCompanyCountry(e.target.value);
                                                }}
                                            />
                                        </Fragment>
                                    }
                                </Form.Group>
                            </Fragment>
                        </Col>
                        <Col>
                            <Form.Group controlId="companyAddress1">
                                <Form.Label>
                                    <strong>Address 1</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'company'}
                                    readOnly={inEditMode !== 'company'}
                                    placeholder="Enter Company Address 1"
                                    value={companyAddress1}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setCompanyAddress1(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="companyAddress2">
                                <Form.Label>
                                    <strong>Address 2</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'company'}
                                    readOnly={inEditMode !== 'company'}
                                    placeholder="Enter Company Address 2"
                                    value={companyAddress2}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setCompanyAddress2(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="companyCity">
                                <Form.Label>
                                    <strong>City</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'company'}
                                    readOnly={inEditMode !== 'company'}
                                    placeholder="Enter Company City"
                                    value={companyCity}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setCompanyCity(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="companyStateProv">
                                <Form.Label>
                                    <strong>{companyCountry ?
                                        countryOptions.filter(ele => ele.country === companyCountry)[0].stpr
                                        : 'State/Province'
                                    }</strong>
                                </Form.Label>
                                {inEditMode !== 'company' ?
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        value={companyStateProv}
                                    />
                                    :
                                    <Fragment> <br />
                                        <DropDownList
                                            data={stateOpt}
                                            placeholder="e.g. Ontario"
                                            style={{ backgroundColor: 'white', width: '100%' }}
                                            value={companyStateProv}
                                            onChange={(e) => {
                                                if (error.hasError) {
                                                    setError({ hasError: false, message: '' });
                                                }
                                                setCompanyStateProv(e.target.value);
                                            }}
                                        />
                                    </Fragment>
                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="companyPostalCode">
                                <Form.Label>
                                    <strong>{companyCountry === 'US' ? 'Zip Code' : 'Postal Code'}</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'company'}
                                    readOnly={inEditMode !== 'company'}
                                    placeholder="Enter Company Postal Code"
                                    value={companyPostalCode}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setCompanyPostalCode(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
export default CompanyDetails;
