import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Jumbotron,
    Container,
    Row,
    Col,
    CardDeck,
    Card,
} from 'react-bootstrap';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import CalenderCard from "../util/calenderCard/calenderCard";
import getTokenConfig from "../../methods/getTokenConfig";

const Main = ({ history }) => {
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState({ status: '', msg: '' });

    const resetMessage = (time) => {
        setTimeout(() => {
            setError({ status: '', msg: '' });
        }, time);
    };

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setCompanyName(res.data.companyName);
                }
            }).catch((err) => {
                setError({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }, [history]);

    return (
        <div>
            <Jumbotron
                fluid
                className="mb-5"
                style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
            >
                {error.status ?
                    <NotificationGroup
                        style={{
                            right: 0,
                            top: 0,
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse'
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: error.status, icon: true }}
                                closable={true}
                                onClose={() => setError({ status: '', msg: '' })}
                            >
                                <span>{error.msg}</span>
                            </Notification>
                        </Fade>
                    </NotificationGroup>
                    : null
                }
                <Container fluid>
                    <Row>
                        <Col className="text-center">
                            <h1>Welcome to Check'N</h1>
                            <h5>Every response counts</h5>
                            <br />
                            {companyName ?
                                <h5>
                                    <strong><i className="far fa-building" />&nbsp;&nbsp;{companyName}</strong>
                                </h5>
                                : null
                            }
                        </Col>
                        <Col>
                            <CalenderCard
                                style={{ float: 'right' }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <div style={{ padding: 15, paddingBottom: 50 }}>
                <CardDeck>
                    <Card
                        style={{
                            backgroundColor: '#cce5ff',
                            color: '#004085',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            cursor: 'pointer',
                        }}
                        onClick={() => history.push('/employees')}
                    >
                        <Card.Body>
                            <Card.Title style={{ fontSize: 40 }}>
                                <i className="fa fa-user-friends mr-3" />
                            Employees
                        </Card.Title>
                            <Card.Text>
                                Set up employees for screening notification via email or text.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card
                        style={{
                            backgroundColor: '#d4edda',
                            color: '#155724',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            cursor: 'pointer'
                        }}
                        onClick={() => history.push('/visitors')}
                    >
                        <Card.Body>
                            <Card.Title style={{ fontSize: 40 }}>
                                <i className="fa fa-walking mr-3" />
                            Visitors
                        </Card.Title>
                            <Card.Text>
                                Add visitors in advance or use the QR Code to automatically add them.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card
                        style={{
                            backgroundColor: '#f8d7da',
                            color: '#721c24',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            cursor: 'pointer'
                        }}
                        onClick={() => history.push('/reports')}
                    >
                        <Card.Body>
                            <Card.Title style={{ fontSize: 40 }}>
                                <i className="fa fa-chart-bar mr-3" />
                            Reports
                        </Card.Title>
                            <Card.Text>
                                Generate reports based on date or status and more.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </div>
        </div>
    );
};
export default Main;
