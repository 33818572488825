import React from "react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

const ConfirmDialog = ({
    visible,
    setVisible,
    title,
    message,
    hint,
    onConfirm,
    onCancel,
}) => (
    <Dialog
        width={400}
        style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        title={title? title : "Please confirm"}
        onClose={() => setVisible(false)}
    >
        <p style={{ margin: "25px", textAlign: "center" }}>
            {message ? message : 'Are you sure you want to continue?'}
            {hint && <><br /><br /><i>{hint}</i></>}
        </p>
        <DialogActionsBar>
            <Button
                look="outline"
                onClick={() => {
                    if (onCancel && typeof onCancel === 'function') {
                        onCancel();
                    }
                    setVisible(false);
                }}
            >No</Button>
            <Button
                look="outline"
                onClick={() => {
                    if (onConfirm && typeof onConfirm === 'function') {
                        onConfirm();
                    }
                    setVisible(false);
                }}
            >Yes</Button>
        </DialogActionsBar>
    </Dialog>
);

export default ConfirmDialog;
