import React, { useEffect, useState } from "react";
import { Divider } from 'antd';
import { useStripe, useElements, Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Button } from "@progress/kendo-react-buttons";
import { Spin } from 'antd';
import Axios from "axios";
import Cookies from 'js-cookie';
import getTokenConfig from "../../../methods/getTokenConfig";
import '../../stylesheets/stripeCardElementStyles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB);

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true,
};

const PaymentSetupBase = ({ history }) => {
    const [companyEmail, setCompanyEmail] = useState('');
    const [message, setMessage] = useState({ status: '', msg: '' });
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const resetMessage = (time) => {
        setTimeout(() => {
            setMessage({ status: '', message: '' });
        }, time);
    }

    const submitPaymentMethod = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const paymentMethodObj = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                email: companyEmail,
            },
        });
        if (paymentMethodObj.error) {
            setMessage({ status: 'error', msg: paymentMethodObj.error.message });
        } else {
            Axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/organization/addNewPaymentMethod/',
                { newPayMethodId: paymentMethodObj.paymentMethod.id },
                getTokenConfig()
            ).then(res => {
                if (res.status === 200) {
                    localStorage.setItem('CheckN.paymentMethodAdded', true);
                    Cookies.set('CheckN.paymentMethodAdded', true);
                    setIsLoading(false);
                    setMessage({ status: 'success', msg: res.data.msg });
                    resetMessage(1800);
                    history.push('/');
                } else {
                    setIsLoading(false);
                    setMessage({ status: 'error', msg: res.data.msg });
                    resetMessage(1800);
                }
            }).catch(err => {
                setMessage({
                    status: 'error',
                    msg: err.response && err.response.data && err.response.data.msg,
                });
                resetMessage(1800);
            });
        }
    };

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') !== 'false')
            history.push('/');
        Axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setCompanyEmail(res.data.companyEmail);
                }
            }).catch((err) => {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }, [history]);

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Setup Payment Method</span>
                </Card.Header>
            </Card>

            {message.status ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: message.status, icon: true }}
                            closable={true}
                            onClose={() => setMessage({ status: '', msg: '' })}
                        >
                            <span>{message.msg}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>
                : null
            }
            <Container fluid>
                <Card
                    style={{
                        backgroundColor: '#cce5ff',
                        color: '#004085',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        cursor: 'pointer',
                    }}
                >
                    <Card.Body>
                        <Row>
                            <Col>
                                <Card.Title>
                                    Monthly:
                                    </Card.Title>
                                    $12.00 flat fees (includes 200 text messages)
                                </Col>
                            <Col>
                                <Card.Title>
                                    Overages:
                                    </Card.Title>
                                    201 - 499 text messages - $0.07 cents each<br />
                                    500 - 999 text messages - $0.065 cents each<br />
                                    1000+ text messages - $0.06 cents each
                                </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                Note - email costs $0.01 cents each
                                </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid>
                <Card>
                    <Card.Body>
                        <Spin size="large" spinning={isLoading} >
                            <form className="k-form">
                                <fieldset>
                                    <Row>
                                        <Col>
                                            <label className="k-form-field">
                                                <div>Company Email</div>
                                                <Form.Control
                                                    plaintext
                                                    readOnly
                                                    defaultValue={companyEmail}
                                                    style={{ color: '#aab7c4' }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Divider orientation="left">
                                        <img
                                            src={require('../../stylesheets/stripe.png')}
                                            alt="stripe"
                                            height={43}
                                            width={92}
                                        />
                                    </Divider>
                                    <Row>
                                        <Col>
                                            <label className="k-form-field">
                                                <div style={{ marginBottom: 8 }}>Card Number</div>
                                                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="k-form-field">
                                                <div style={{ marginBottom: 8 }}>Exp Date</div>
                                                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                                            </label>
                                        </Col>
                                        <Col>
                                            <label className="k-form-field">
                                                <div style={{ marginBottom: 8 }}>CVC</div>
                                                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                                            </label>
                                        </Col>
                                    </Row>
                                </fieldset>
                            </form>
                        </Spin>
                        <hr />
                        <Button
                            primary
                            onClick={() => {
                                setIsLoading(true);
                                submitPaymentMethod();
                            }}
                        >
                            Submit
                            </Button>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

const PaymentSetup = (props) => (
    <Elements stripe={stripePromise}>
        <PaymentSetupBase {...props} />
    </Elements>
);

export default PaymentSetup;
