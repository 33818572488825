import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
    Card,
} from 'react-bootstrap';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import FileViewer from 'react-file-viewer';
import { saveAs } from 'file-saver';
import getTokenConfig from "../../methods/getTokenConfig";

const PreviewPage = ({ history, match }) => {
    const [link, setPreviewLink] = useState(null);
    const [mimeType, setMimeType] = useState(null);
    const [attachName, setAttachName] = useState(null);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        
        const detailsObj = { attachId: match.params.attachId,};

        if (history.location.pathname.startsWith('/empPreview/')) {
            detailsObj.employeeId = match.params.userId
        } else if (history.location.pathname.startsWith('/visPreview/')) {
            detailsObj.visitorId = match.params.userId
        }

        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/s3/getFileLink/',
            detailsObj,
            getTokenConfig(),
        ).then((response) => {
            setPreviewLink(response.data.link);
            setMimeType(response.data.mimeType);
            setAttachName(response.data.name);
        }).catch((error) => {
            setMgsType({
                status: 'error',
                message: error.response && error.response.data && error.response.data.msg,
            });
        });
        if (mgsType.status !== null) {
            setTimeout(() => {
                setMgsType({ status: null, message: '' })
            }, 2500);
        }
    }, [history, match, mgsType]);

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    {mgsType.status !== 'warning' ?
                        <NotificationGroup
                            style={{
                                right: 0,
                                top: 0,
                                alignItems: 'flex-start',
                                flexWrap: 'wrap-reverse'
                            }}
                        >
                            <Fade
                                enter={true}
                                exit={true}>
                                {mgsType.status !== null && <Notification
                                    type={{ style: mgsType.status, icon: true }}
                                    closable={true}
                                    onClose={() => setMgsType({ status: null, message: '' })}
                                >
                                    <span>{mgsType.message}</span>
                                </Notification>}
                            </Fade>
                        </NotificationGroup> : null}
                    <div>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Preview</span>
                        <button
                            style={{
                                float: "right",
                                color: 'white',
                                borderRadius: 12,
                            }}
                            title="Add new"
                            className="k-button k-primary"
                            onClick={() => saveAs(link, attachName)}
                        >
                            <strong>&nbsp;Download&nbsp;</strong>
                        </button>
                    </div>
                </Card.Header>
            </Card>

            <div style={{ padding: 15 }}>
                <FileViewer
                    filePath={link}
                    fileType={mimeType}
                />
            </div>
        </div>
    );
};

export default PreviewPage;
