import React, { useState, useEffect } from "react";
import axios from 'axios';
import { isEmpty } from "lodash";
import {
    Container,
    Row,
    Col,
    Card,
} from 'react-bootstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { isMobile } from 'react-device-detect';
import AddNewUserGroupWindow from './addEditUserGroupWindow';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import ConfirmDialog from '../../util/confirmDialog';
import getTokenConfig from '../../../methods/getTokenConfig';
import '../../stylesheets/kendoGrid.css';

const DetailComponent = ({ dataItem }) => (
    <Container fluid>
        {dataItem.primaryContact && !isEmpty(dataItem.primaryContact) && <>
            <Row><strong><u>Primary Contact Info:</u></strong></Row>
        <Row>
            <Col>
                <p><strong>First Name:</strong> {dataItem.primaryContact.firstName}</p>
                <p><strong>Last Name:</strong> {dataItem.primaryContact.lastName}</p>
            </Col>
            <Col>
                <p><strong>Cell #:</strong> {dataItem.primaryContact.cellNumber}</p>
                <p><strong>Email:</strong> {dataItem.primaryContact.email}</p>
            </Col>
        </Row></>}
        {dataItem.locationInfo && !isEmpty(dataItem.locationInfo) && <>
        <Row><strong><u>Location Info:</u></strong></Row>
        <Row>
                <Col>
                    <p><strong>Address1:</strong> {dataItem.locationInfo.address1}</p>
                    <p><strong>Address2:</strong> {dataItem.locationInfo.address2}</p>
                    <p><strong>Country:</strong> {dataItem.locationInfo.country}</p>
            </Col>
                <Col>
                    <p><strong>City:</strong> {dataItem.locationInfo.city}</p>
                    <p><strong>{dataItem.locationInfo.country === 'US' ? 'State' : 'Province'}:</strong> {dataItem.locationInfo.stateProv}</p>
                    <p><strong>{dataItem.locationInfo.country === 'US' ? 'Zip' : 'Postal'} Code:</strong> {dataItem.locationInfo.postalCode}</p>
            </Col>
        </Row></>}
        {dataItem.employeesInfo && !isEmpty(dataItem.employeesInfo) &&
            <>
            <Row><strong><u>Employees:</u></strong></Row>
                {dataItem.employeesInfo.map((employee) => {
                    return (
                    <Row>
                        <Col>
                                <p><strong>Name:</strong> {employee.firstName} {employee.lastName}</p>
                        </Col>
                        <Col>
                                {employee.email && <p><strong>Email:</strong> {employee.email}</p>}
                                {!employee.email && employee.cellNumber && <p><strong>Cell Number:</strong> {employee.cellNumber}</p>}
                                {!employee.email && !employee.cellNumber && <p><strong>No contact info!</strong></p>}
                        </Col>
                    </Row>
                    );
                })}
            </>}
    </Container>
);

const UserGroupsSetup = ({ history }) => {
    const [userGroupsList, setUserGroupsList] = useState([]);
    const [showWindow, setShowWindow] = useState(false);
    const [sort, setSort] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedUserGroup, setSelectedUserGroup] = useState(null);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });
    const [filter, setFilter] = useState();
    const [showFilterOptions, setShowFilterOptions] = useState();

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 500) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 500) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 500) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        const orgId = localStorage.getItem('CheckN.orgId');
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgId/' + orgId, getTokenConfig())
            .then(async (response) => {
                const userGroupData = response.data;
                setUserGroupsList([...userGroupData]);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
        if (mgsType.status !== null) {
            setTimeout(() => {
                setMgsType({ status: null, message: '' })
            }, 2500);
        }
    }, [history, mgsType]);

    const expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        setForceUpdate(!forceUpdate);
    };

    const deleteUserGroup = () => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/delete/', { id: selectedUserGroup._id, type: selectedUserGroup.type }, getTokenConfig())
            .then((response) => {
                const orgId = localStorage.getItem('CheckN.orgId');
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'User Group deleted successfully.' });
                        setUserGroupsList([...response.data])
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in deleting user group.' });
                    });
                setSelectedUserGroup(null);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
    };

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    {mgsType.status !== 'warning' &&
                        <NotificationGroup
                            style={{
                                right: 0,
                                top: 0,
                                alignItems: 'flex-start',
                                flexWrap: 'wrap-reverse'
                            }}
                        >
                            <Fade
                                enter={true}
                                exit={true}>
                                {mgsType.status !== null && <Notification
                                    type={{ style: mgsType.status, icon: true }}
                                    closable={true}
                                    onClose={() => setMgsType({ status: null, message: '' })}
                                >
                                    <span>{mgsType.message}</span>
                                </Notification>}
                            </Fade>
                        </NotificationGroup>}
                        <Row>
                            <Col><span style={{ fontSize: '20px', fontWeight: 'bold' }}>User Groups</span></Col>
                            <Col className="text-right">
                                <Button
                                    icon={!showFilterOptions ? "filter" : "filter-clear"}
                                    onClick={() => {
                                        setFilter(null);
                                        setShowFilterOptions(!showFilterOptions);
                                    }}
                                    primary
                                ></Button>
                                <span>  |  </span>
                                <Button
                                    title="Add new"
                                    className="k-button k-primary"
                                    onClick={() => setShowWindow(true)}
                                >
                                <strong>{!isNarrowScreen ? " Add New " : "+"}</strong>
                                </Button>
                            </Col>
                        </Row>
                </Card.Header>
            </Card>
            {showWindow &&
                <AddNewUserGroupWindow
                    setShowWindow={setShowWindow}
                    selectedUserGroup={selectedUserGroup}
                    setSelectedUserGroup={setSelectedUserGroup}
                    setUserGroupsList={setUserGroupsList}
                    setMgsType={setMgsType}
                />}
            {showDeleteDialog &&
                <ConfirmDialog
                    visible={showDeleteDialog}
                    setVisible={setShowDeleteDialog}
                    title="Delete User Group"
                    message="Are you sure you want to delete this user group?"
                    onConfirm={deleteUserGroup}
                    onCancel={() => { setSelectedUserGroup(null); }}
                />}
            <div style={{ padding: !isMobile ? 15 : undefined, paddingBottom: isMobile ? 50 : undefined }}>
                <Grid
                    style={{
                        height: 'calc(100vh - 380px)',
                        minHeight: 400,
                    }}
                    filterable={showFilterOptions}
                    filter={filter}
                    onFilterChange={(e) => {
                        setFilter(e.filter);
                    }}
                    data={filterBy(orderBy([...userGroupsList], sort), filter)}
                    sortable={{
                        allowUnsort: true,
                        mode: 'multiple',
                    }}
                    sort={sort}
                    onSortChange={(event) => {
                        setSort(event.sort);
                    }}
                    reorderable
                    detail={DetailComponent}
                    expandField="expanded"
                    onExpandChange={(event) => expandChange(event)}
                >
                    <Column
                        field="name"
                        title="Group Name"
                        cell={props => (
                            <td>
                                <i className="far fa-group" />
                                &nbsp;&nbsp;&nbsp;{props.dataItem['name']}
                            </td>
                        )}
                        footerCell={(props) => (
                            <td colSpan={props.colSpan} style={props.style}>
                                {filterBy(userGroupsList, filter).length !== userGroupsList.length && 'Filtered '}Total: {filterBy(userGroupsList, filter).length}
                            </td>
                        )}
                    />
                    {!isNarrowScreen && <Column field="type" title="Group Type" />}
                    {!isMobile && !isNarrowScreen && <Column field="primaryContact.cellNumber" title="Primary Contact Phone #" />}
                    {!isMobile && !isNarrowScreen && <Column field="primaryContact.email" title="Primary Contact Email" />}
                    {!isNarrowScreen && <Column field="employeeCount" title="Employee Count" filter="numeric" width={!isMobile && !isNarrowScreen ? "190px" : ""} />}
                    <Column
                        filterable={false}
                        width="105px"
                        cell={props => (
                            <td>
                                <ButtonGroup >
                                    <Button
                                        iconClass="far fa-edit"
                                        onClick={() => {
                                            setSelectedUserGroup(props.dataItem);
                                            setShowWindow(true);
                                        }}
                                    ></Button>
                                    <Button
                                        iconClass="far fa-trash-alt"
                                        style={{
                                            backgroundColor: '#B01C2E',
                                            borderColor: '#B01C2E',
                                            color: 'white',
                                        }}
                                        onClick={() => {
                                            setSelectedUserGroup(props.dataItem);
                                            setShowDeleteDialog(true);
                                        }}
                                    ></Button>
                                </ButtonGroup>
                            </td>
                        )}
                    />
                </Grid>
            </div>
        </div>
    );
};

export default UserGroupsSetup;
