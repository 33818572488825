import React, { Fragment } from "react";
import moment from 'moment';
import { Row, Col, Form } from "react-bootstrap";
import  Switch  from "react-switch";
import { Divider } from 'antd';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TimePicker } from '@progress/kendo-react-dateinputs';

const NotificationSettings = ({
    inEditMode,
    notificationTime,
    setNotificationTime,
    notificationType,
    autoNotify,
    setAutoNotify,
    forceNotification,
    setForceNotification,
    attachmentsNeeded,
    setAttachmentsNeeded,
    forceNotifyFor,
    setForceNotifyFor,
    setNotificationType,
    setTimezone,
    timezone,
    orgDetails,
    error,
    setError
}) => {
    const USTimeZones = ["US/Alaska", "US/Aleutian", "US/Arizona", "US/Central", "US/East-Indiana", "US/Eastern", "US/Hawaii", "US/Indiana-Starke", "US/Michigan", "US/Mountain", "US/Pacific", "US/Pacific-New", "US/Samoa", "UTC"]
    const CanadaTimeZones = ["Canada/Atlantic", "Canada/Central", "Canada/Eastern", "Canada/Mountain", "Canada/Newfoundland", "Canada/Pacific", "Canada/Saskatchewan", "Canada/Yukon", "UTC"]
    return (
        <Row>
            <Col>
                <Divider orientation="left">Notification Settings</Divider>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="notificationTime">
                                <Form.Label>
                                    <strong>Notification Status</strong>&nbsp;&nbsp;
                                    <small>(Turning ON will set auto notifications that will be sent to employees according to scheduled time)</small>
                                </Form.Label><br/>
                                <Switch
                                    checked={autoNotify ? true : false}
                                    disabled={inEditMode !== 'notificationSettings'}
                                    onChange={() => setAutoNotify(!autoNotify)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {autoNotify ? 
                    <Fragment>
                        <Row>
                            <Col>
                                <Form.Group controlId="notificationTime">
                                    <Form.Label>
                                        <strong>Notification Time</strong>&nbsp;&nbsp;
                                    <small>(Auto notification will be sent to employees at this time every day)</small>
                                    </Form.Label>
                                    {inEditMode !== 'notificationSettings' ?
                                        <Fragment>
                                            <Row md={12}>
                                                <Col md={1}>
                                                    <Form.Control
                                                        plaintext
                                                        readOnly
                                                        value={notificationTime || '8:00:00 AM'}
                                                    />
                                                </Col>
                                                <Col md={11} style={{padding: "8px"}}>
                                                        <small> It may take 24 hours for changes to take place.</small>
                                                </Col>
                                            </Row>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <br />
                                            <TimePicker
                                                format="hh:mm a"
                                                value={new Date(moment(notificationTime || '8:00:00 AM', 'HH:mm:ss a'))}
                                                onChange={(e) => {
                                                    setNotificationTime(e.target.value && e.target.value.toLocaleTimeString());
                                                }}
                                            />
                                        </Fragment>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="notificationTime">
                                    <Form.Label>
                                        <strong>Time Zone</strong>
                                    </Form.Label>
                                    {inEditMode !== 'notificationSettings' ?
                                        <Form.Control
                                            plaintext
                                            readOnly
                                                value={timezone}
                                        />
                                        :
                                        <Fragment>
                                            <br />
                                                <DropDownList
                                                    data={
                                                        orgDetails && orgDetails.companyCountry === "US" ? 
                                                        USTimeZones :
                                                        CanadaTimeZones 
                                                    }
                                                    style={{ backgroundColor: 'white', width: '50%' }}
                                                    value={timezone}
                                                    required
                                                    onChange={(e) => {
                                                        if (error.hasError) {
                                                            setError({ hasError: false, message: '' });
                                                        }
                                                        setTimezone(e.target.value);
                                                    }}
                                                />
                                        </Fragment>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="notificationTime">
                                    <Form.Label>
                                        <strong>Notification Type</strong>&nbsp;&nbsp;
                                    <small>(Type of auto notification that will be sent at selected time every morning)</small>
                                    </Form.Label>
                                    <Form.Check
                                        type={'radio'}
                                        label={'Email'}
                                        disabled={inEditMode !== 'notificationSettings'}
                                        onChange={(e) => {
                                            setNotificationType('email');
                                        }}
                                        checked={notificationType && notificationType !== 'text'}
                                    /> <Form.Check
                                        type={'radio'}
                                        label={'Text'}
                                        disabled={inEditMode !== 'notificationSettings'}
                                        onChange={(e) => {
                                            setNotificationType('text')
                                        }}
                                        checked={notificationType && notificationType !== 'email'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="notificationTime">
                                    <Form.Label>
                                        <strong>Force Notification</strong>&nbsp;&nbsp;
                                        <small>(Force auto notification to all employees)</small>
                                        </Form.Label><br />
                                        <Switch
                                            checked={forceNotification ? true : false}
                                            disabled={inEditMode !== 'notificationSettings'}
                                            onChange={() => setForceNotification(!forceNotification)}
                                        />
                                </Form.Group>
                            </Col>
                        </Row>
                        {forceNotification?
                            <Row>
                                <Col>
                                    <Form.Group controlId="notificationTime">
                                        <Form.Label>
                                            <strong>Force Notification For</strong>&nbsp;&nbsp;
                                        </Form.Label>
                                        <Form.Check
                                            type={'radio'}
                                            label={'5 Working Days (Mon-Fri)'}
                                            disabled={inEditMode !== 'notificationSettings' || !forceNotification}
                                            onChange={(e) => {
                                                setForceNotifyFor('5days');
                                            }}
                                                checked={forceNotifyFor && forceNotifyFor !== '7days'}
                                        /> <Form.Check
                                            type={'radio'}
                                            label={'Everyday'}
                                            disabled={inEditMode !== 'notificationSettings' || !forceNotification}
                                            onChange={(e) => {
                                                setForceNotifyFor('7days')
                                            }}
                                                checked={forceNotifyFor && forceNotifyFor !== '5days'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            : null
                        }
                    </Fragment>
                    : null}
                </Form>
            </Col>
        </Row>
    );
};
export default NotificationSettings;
