import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import auth from "../../methods/auth";


const Header = ({ drawerExpanded, setDrawerExpanded, history, screening }) => (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{ paddingLeft: '5px' }}>
        {!screening ?
            <Button
                iconClass={drawerExpanded ? "fas fa-chevron-left" : "fas fa-bars"}
                look="flat"
                style={{ color: 'white' }}
                onClick={() => setDrawerExpanded(!drawerExpanded)}
            />
            : null
        }
        &nbsp;
        {auth.isAuthenticated() ?
            <>
                <Navbar.Brand href="/" className='justify-content-md-center'>Check'N</Navbar.Brand>
                <Nav className="mr-auto" />
                <Nav>
                    <Button
                        look="flat"
                        style={{ color: 'white' }}
                        onClick={() => {
                            auth.logout(() => history.push('/signin'));
                        }}
                    >Logout&nbsp;&nbsp;<i className="fas fa-sign-out-alt" />
                    </Button>
                </Nav>
            </>
            : <Navbar.Brand href="https://www.checkn.ca" className='justify-content-md-center'>Check'N</Navbar.Brand>
        }
    </Navbar>
);

export default Header;
