import React from "react";
import { Divider } from 'antd';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Spin } from 'antd';
import '../../stylesheets/stripeCardElementStyles.css';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true,
};

const PaymentInfo = ({
    companyEmail,
    isLoading
}) => (
        <Spin size="large" spinning={isLoading} >
            <Container fluid>
                <Card
                    style={{
                        backgroundColor: '#cce5ff',
                        color: '#004085',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        cursor: 'pointer',
                    }}
                >
                    <Card.Body>
                        <Card.Title
                            style={{ fontSize: 22 }}
                        >
                            Pricing
                        </Card.Title>
                        <hr />
                        <Row>
                            <Col>
                                <Card.Title>
                                    Monthly:
                                    </Card.Title>
                                    $12.00 flat fees (includes 200 text messages)
                                </Col>
                            <Col>
                                <Card.Title>
                                    Overages:
                                    </Card.Title>
                                    201 - 499 text messages - $0.07 cents each<br />
                                    500 - 999 text messages - $0.065 cents each<br />
                                    1000+ text messages - $0.06 cents each
                                </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                Note - email costs $0.01 cents each
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Card.Title>Understand Pricing:&nbsp;</Card.Title>
                                <div className="mb-1">
                                    If your organization has 15 employees and they each work 20 days a month,
                                    they will get a text each day, which means you will use 15 X 20 = 300 text messages.
                                </div>
                                <div className="mb-1">
                                    Assume you have 100 visitors throughout the month,
                                    you use emails to send out the screening notification for 75 of
                                    those visitors and for the rest you use the QR code to complete the screening.
                                </div>
                                <div className="mb-1">
                                    Based on the above scenario your charges for the month will be:
                                </div>
                                Flat fee = $12.00
                                <br />
                                Usage:
                                <br />
                                Text: 300 - 200 (included messages) = 100 X $0.07 = $7.00<br />
                                Email: 75 X $0.01 = $0.75<br />
                                Total charges: $12.00 + $7.00 + $0.75 = $19.75 + applicable taxes
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <br />
            <form className="k-form">
                <fieldset>
                    <Row>
                        <Col>
                            <label className="k-form-field">
                                <div>Company Email</div>
                                <Form.Control
                                    plaintext
                                    readOnly
                                    defaultValue={companyEmail}
                                    style={{ color: '#aab7c4' }}
                                />
                            </label>
                        </Col>
                    </Row>
                    <Divider orientation="left">
                        <img
                            src={require('../../stylesheets/stripe.png')}
                            alt="stripe"
                            height={43}
                            width={92}
                        />
                    </Divider>
                    <Row>
                        <Col>
                            <label className="k-form-field">
                                <div style={{ marginBottom: 8 }}>Card Number</div>
                                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="k-form-field">
                                <div style={{ marginBottom: 8 }}>Exp Date</div>
                                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                            </label>
                        </Col>
                        <Col>
                            <label className="k-form-field">
                                <div style={{ marginBottom: 8 }}>CVC</div>
                                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                            </label>
                        </Col>
                    </Row>
                </fieldset>
            </form>
        </Spin>
    );

export default PaymentInfo;
