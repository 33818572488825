import React, { useEffect, useState } from "react";
import axios from 'axios';
import moment from 'moment';
import { isArray } from "lodash";
import {
    Card, Col, Row, Container, OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { orderBy } from '@progress/kendo-data-query';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import getTokenConfig from "../../../methods/getTokenConfig";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

let _pdfExport;

const activeFilter = {
    "logic": "and",
    "filters": [
        {
            "field": "status",
            "operator": "eq",
            "value": "Verified"
        }
    ]
};

const deactivatedFilter = {
    "logic": "or",
    "filters": [
        {
            "field": "status",
            "operator": "eq",
            "value": "Deactivated/Removed"
        },
        {
            "field": "status",
            "operator": "eq",
            "value": "Not Verified"
        }
    ]
};

const activeSort = [
    {
        "field": "companyName",
        "dir": "asc"
    },
];

const deactivatedSort = [
    {
        "field": "updatedAt",
        "dir": "desc"
    },
];

const initialDataState = {
  skip: 0,
  take: 100,
};

const SuperUserView = ({ isSuperUser }) => {
    const [url, setUrl] = useState('');

    const [orgId, setOrgId] = useState('');

    const [allOrgDetails, setAllOrgDetails] = useState([]);
    const [allUserDetails, setAllUserDetails] = useState([]);

    const [numberOfDaysForRecentUsage, setNumberOfDaysForRecentUsage] = useState(7);
    const [totalTextForPeriod, setTotalTextForPeriod] = useState(0);
    const [totalEmailForPeriod, setTotalEmailForPeriod] = useState(0);

    const [sort1, setSort1] = useState([]);
    const [sort2, setSort2] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [filter1, setFilter1] = useState();
    const [filter2, setFilter2] = useState();
    const [showFilter1Options, setShowFilter1Options] = useState();
    const [showFilter2Options, setShowFilter2Options] = useState();

    const [message, setMessage] = useState({ status: null, message: '' });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cellNo, setCellNo] = useState('');
    const [email, setEmail] = useState('');

    const [notificationId, setNotificationId] = useState('');

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const [fetchingData, setFetchingData] = useState(false);

    const [page, setPage] = useState(initialDataState);

    const pageChange = (event) => {
      setPage(event.page);
    };

    const _excelExportActive = React.useRef(null);
    const _excelExportDeactivated = React.useRef(null);
    const _excelExportUsers = React.useRef(null);

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 500) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 500) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 500) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    const excelExportActive = () => {
        if (_excelExportActive.current !== null) {
            _excelExportActive.current.save();
        }
    };

    const excelExportDeactivated = () => {
        if (_excelExportDeactivated.current !== null) {
            _excelExportDeactivated.current.save();
        }
    };

    const excelExportUsers = () => {
        if (_excelExportUsers.current !== null) {
            _excelExportUsers.current.save();
        }
    };

    const resetError = (time) => {
        setTimeout(() => {
            setMessage({ status: null, message: '' });
        }, time);
    };

    const sendEmailNotification = async (selectedEmps) => {
        if (selectedEmps.length > 0) {
            const totalEmailNotifications = selectedEmps.filter(
                emp => emp.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                selectedEmps
                    .filter(emp => emp.email !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'email',
                            email: employee.email,
                            count: i + 1,
                            total: totalEmailNotifications,
                            method: isSuperUser ? 'Super User' : 'Admin User',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMessage({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMessage({
                    status: 'success',
                    message: 'Emails sent successfully.'
                });
            }
        }
    };

    const sendTextNotification = (selectedEmps) => {
        if (selectedEmps.length > 0) {
            const totalTextNotifications = selectedEmps.filter(
                emp => emp.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                selectedEmps
                    .filter(emp => emp.cellNumber !== '')
                    .forEach(async (employee, i) => {
                        const notificationObj = {
                            employeeId: employee._id,
                            organizationId: employee.organizationId,
                            type: 'text',
                            phone: employee.cellNumber,
                            count: i + 1,
                            total: totalTextNotifications,
                            method: isSuperUser ? 'Super User' : 'Admin User',
                        };
                        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMessage({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMessage({
                    status: 'success',
                    message: 'Texts sent successfully.'
                });
            }
        }
    };

    useEffect(() => {
        const orgId = localStorage.getItem('CheckN.orgId');
        setOrgId(orgId);
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/getAllOrgDetails/', getTokenConfig())
            .then(async (res) => {
                if (res.status === 200) {
                    const allOrg = res.data;
                    let orgsCheckedTwice = 0;
                    await allOrg.forEach(async (org) => {
                        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/activeByOrgIdWithRecentPastNextNotifications/' + orgId, {
                            params: {
                                orgIdToCheck: org._id.toString(),
                                type: org.notificationType ? org.notificationType : '',
                                numberOfDays: numberOfDaysForRecentUsage,
                            }, headers: getTokenConfig().headers
                        }).then(response => {
                            const empData = response.data;
                            if (org.verified && !org.removed && org.autoNotify) {
                                org.activeEmpsOrgSched = empData.filter(emp => emp.missedType === 'orgScheduled');
                                org.empsThatMissedOrgSchedNotifications = empData.filter(emp => emp.missedType === 'orgScheduled').length;
                            } else {
                                org.empsThatMissedOrgSchedNotifications = 0;
                            }
                            if (org.verified && !org.removed) {
                                org.activeEmpsAdminSchedEmail = empData.filter(emp => emp.missedType === 'adminScheduled' && emp.nextAdminSchedNotificationType === 'email');
                                org.activeEmpsAdminSchedText = empData.filter(emp => emp.missedType === 'adminScheduled' && emp.nextAdminSchedNotificationType === 'text');
                                org.empsThatMissedAdminSchedEmailNotifications = empData.filter(emp => emp.missedType === 'adminScheduled' && emp.nextAdminSchedNotificationType === 'email').length;
                                org.empsThatMissedAdminSchedTextNotifications = empData.filter(emp => emp.missedType === 'adminScheduled' && emp.nextAdminSchedNotificationType === 'text').length;
                            } else {
                                org.empsThatMissedAdminSchedEmailNotifications = 0;
                                org.empsThatMissedAdminSchedTextNotifications = 0;
                            }
                            org.empsThatMissedNotifications =
                                org.empsThatMissedOrgSchedNotifications +
                                org.empsThatMissedAdminSchedEmailNotifications +
                                org.empsThatMissedAdminSchedTextNotifications;
                            orgsCheckedTwice++;
                        }).catch((error) => {
                            setMessage({
                                status: 'error',
                                message: error.response && error.response.data && error.response.data.msg,
                            });
                        });

                        // Visitors do not have "org scheduled notifications"
                        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/activeByOrgIdWithRecentPastNextNotifications/' + orgId, {
                            params: {
                                orgIdToCheck: org._id.toString(),
                            }, headers: getTokenConfig().headers
                        }).then(response => {
                            const visData = response.data;
                            if (org.verified && !org.removed) {
                                org.activeVisAdminSchedEmail = visData.filter(vis => vis.missedType === 'adminScheduled' && vis.nextAdminSchedNotificationType === 'email');
                                org.activeVisAdminSchedText = visData.filter(vis => vis.missedType === 'adminScheduled' && vis.nextAdminSchedNotificationType === 'text');
                                org.visThatMissedAdminSchedEmailNotifications = visData.filter(vis => vis.missedType === 'adminScheduled' && vis.nextAdminSchedNotificationType === 'email').length;
                                org.visThatMissedAdminSchedTextNotifications = visData.filter(vis => vis.missedType === 'adminScheduled' && vis.nextAdminSchedNotificationType === 'text').length;
                            } else {
                                org.visThatMissedAdminSchedEmailNotifications = 0;
                                org.visThatMissedAdminSchedTextNotifications = 0;
                            }
                            org.visThatMissedNotifications =
                                org.visThatMissedAdminSchedEmailNotifications +
                                org.visThatMissedAdminSchedTextNotifications;
                            orgsCheckedTwice++;
                        }).catch((error) => {
                            setMessage({
                                status: 'error',
                                message: error.response && error.response.data && error.response.data.msg,
                            });
                        });

                        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + `/notifications/getRecentUsage/`, {
                            params: {
                                orgIdToCheck: org._id.toString(),
                                numberOfDays: numberOfDaysForRecentUsage,
                            }, headers: getTokenConfig().headers
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    org.numOfTextNotifications = res.data.textNotifications;
                                    org.numOfEmailNotifications = res.data.emailNotifications;
                                }
                            }).catch((error) => {
                                setMessage({
                                    status: 'error',
                                    message: error.response && error.response.data && error.response.data.msg,
                                });
                            });

                        org.totalMissedNotifications = org.empsThatMissedNotifications + org.visThatMissedNotifications;
                        if (org.removed)
                            org.status = 'Deactivated/Removed';
                        else if (org.verified)
                            org.status = 'Verified';
                        else
                            org.status = 'Not Verified';
                        if (orgsCheckedTwice === allOrg.length * 2) {
                            setAllOrgDetails(allOrg);
                            let totalText = 0;
                            let totalEmail = 0;
                            allOrg.forEach(org => {
                                totalText += org.numOfTextNotifications;
                                totalEmail += org.numOfEmailNotifications;
                            })
                            setTotalTextForPeriod(totalText);
                            setTotalEmailForPeriod(totalEmail);
                        }
                    });
                }
            }).catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/infrastructureInfo', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setUrl(res.data.url);
                }
            }).catch((err) => {
                setMessage({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
        if (message.status !== null) {
            setTimeout(() => {
                setMessage({ status: null, message: '' })
            }, 2500);
        }
    }, [message, numberOfDaysForRecentUsage]);

    const expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        setForceUpdate(!forceUpdate);
    };

    const getAllOrgDetails = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/getAllOrgDetails/', getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    const allOrg = res.data;
                    allOrg.forEach((org) => {
                        if (org.removed)
                            org.status = 'Deactivated/Removed';
                        else if (org.verified)
                            org.status = 'Verified';
                        else
                            org.status = 'Not Verified';
                    });
                    setAllOrgDetails(allOrg);
                }
            }).catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    const sendNotificationsForOrg = (id) => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/notifications/sendToAllEmpsForOrg/${id}`, { isSuperUser }, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    setMessage({ status: 'success', message: res.data });
                    resetError(3800);
                }
            })
            .catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    const deactivateOrgAccount = (id) => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/organization/deactivateOrgAccount/${id}`, { removed: true }, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    setMessage({ status: 'success', message: res.data });
                    getAllOrgDetails();
                    resetError(1800);
                }
            })
            .catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    const deactivateOrgAccountAndDoNotBill = (id) => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/organization/deactivateOrgAccountAndDoNotBill/${id}`, { removed: true }, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    setMessage({ status: 'success', message: res.data });
                    getAllOrgDetails();
                    resetError(1800);
                }
            })
            .catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    const resetMFA = (id) => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/mfa/resetMFA`, { orgToResetId: id }, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    setMessage({ status: 'success', message: res.data.msg });
                    resetError(1800);
                    getAllOrgDetails();
                }
            })
            .catch((err) => {
                setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    const searchForUser = async () => {
        const searchDetails = notificationId ? {
            notificationId,
        } : {
            firstName,
            lastName,
            cellNo,
            email,
        }
        let empError = false;
        let visError = false;
        let returnedEmpResults = [];
        let returnedVisResults = [];
        setFetchingData(true);
        if (notificationId) {
            await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + `/employees/getMatchingEmpDetailsByNotificationId/`, { params: {
                notificationId: searchDetails.notificationId,
            }, headers: getTokenConfig().headers })
                .then((res) => {
                    if (res.status === 200) {
                        if (isArray(res.data)) {
                            returnedEmpResults = res.data;
                        } else {
                            returnedEmpResults = [res.data];
                        }
                        returnedEmpResults.forEach(emp => {
                            emp.userType = 'Employee';
                            emp.fullName = `${emp.firstName} ${emp.lastName}`;
                        });
                    }
                }).catch((err) => {
                    empError = true;
                });
        } else {
            await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + `/employees/getMatchingEmpDetails/`, { params: {
                firstName: searchDetails.firstName,
                lastName: searchDetails.lastName,
                cellNo: searchDetails.cellNo,
                email: searchDetails.email,
            }, headers: getTokenConfig().headers })
                .then((res) => {
                    if (res.status === 200) {
                        if (isArray(res.data)) {
                            returnedEmpResults = res.data;
                        } else {
                            returnedEmpResults = [res.data];
                        }
                        returnedEmpResults.forEach(emp => {
                            emp.userType = 'Employee';
                            emp.fullName = `${emp.firstName} ${emp.lastName}`;
                        });
                    }
                }).catch((err) => {
                    empError = true;
                });
        }
        if (notificationId) {
            await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + `/visitors/getMatchingVisDetailsByNotificationId/`, { params: {
                notificationId: searchDetails.notificationId,
            }, headers: getTokenConfig().headers })
            .then((res) => {
                if (res.status === 200) {
                    if (isArray(res.data)) {
                        returnedVisResults = res.data;
                    } else {
                        returnedVisResults = [res.data];
                    }
                    returnedVisResults.forEach(vis => {
                        vis.userType = 'Visitor';
                        vis.fullName = `${vis.firstName} ${vis.lastName}`;
                    });
                    setFetchingData(false);
                }
            }).catch((err) => {
                visError = true;
                setFetchingData(false);
            });
        } else {
            await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + `/visitors/getMatchingVisDetails/`, { params: {
                firstName: searchDetails.firstName,
                lastName: searchDetails.lastName,
                cellNo: searchDetails.cellNo,
                email: searchDetails.email,
            }, headers: getTokenConfig().headers })
                .then((res) => {
                    if (res.status === 200) {
                        if (isArray(res.data)) {
                            returnedVisResults = res.data;
                        } else {
                            returnedVisResults = [res.data];
                        }
                        returnedVisResults.forEach(vis => {
                            vis.userType = 'Visitor';
                            vis.fullName = `${vis.firstName} ${vis.lastName}`;
                        });
                        setFetchingData(false);
                    }
                }).catch((err) => {
                    visError = true;
                    setFetchingData(false);
                });
        }
        if (empError && visError) {
            setMessage({ status: 'error', message: 'Error: No results found.' });
            resetError(1800);
        }
        const mergedUserResults = returnedEmpResults.concat(returnedVisResults);
        const sortedUserResults = mergedUserResults
            .sort((a, b) => {
                const typeOfA = a.userType.toLowerCase();
                const typeOfB = b.userType.toLowerCase();
                if (typeOfA < typeOfB) {
                    return -1;
                } else if (typeOfA > typeOfB) {
                    return 1;
                } return 0;
            })
            .sort((a, b) => {
                const firstNameA = a.firstName ? a.firstName.toLowerCase() : '';
                const firstNameB = b.firstName ? b.firstName.toLowerCase() : '';
                if (firstNameA < firstNameB) {
                    return -1;
                } else if (firstNameA > firstNameB) {
                    return 1;
                } return 0;
            })
            .sort((a, b) => {
                const lastNameA = a.lastName ? a.lastName.toLowerCase() : '';
                const lastNameB = b.lastName ? b.lastName.toLowerCase() : '';
                if (lastNameA < lastNameB) {
                    return -1;
                } else if (lastNameA > lastNameB) {
                    return 1;
                } return 0;
            })
            .sort((a, b) => {
                const orgNameA = a.orgObj && a.orgObj.companyName ? a.orgObj.companyName.toLowerCase() : '';
                const orgNameB = b.orgObj && b.orgObj.companyName ? b.orgObj.companyName.toLowerCase() : '';
                if (orgNameA < orgNameB) {
                    return -1;
                } else if (orgNameA > orgNameB) {
                    return 1;
                } return 0;
            });
        setAllUserDetails(sortedUserResults);
    }

    const DetailComponent = ({ dataItem }) => (
        <section>
            <Row>
                <Col>
                    <strong>Company Details</strong>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <strong>Name: </strong>{dataItem.companyName}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Email: </strong>{dataItem.companyEmail}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Address 1: </strong>{dataItem.companyAddress1}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Address 2: </strong>{dataItem.companyAddress2}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>City: </strong>{dataItem.companyCity}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>State/Province: </strong>{dataItem.companyStateProv}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Country: </strong>{dataItem.companyCountry}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <strong>Created Date: </strong>{moment(dataItem.createdAt).format("MM/DD/YYYY, h:mm a")}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Verified: </strong>{dataItem.verified ? 'True' : 'False'}
                        </Col>
                    </Row>
                    {dataItem.verified ?
                        <>
                            <Row>
                                <Col>
                                    <strong>Verified Date: </strong>{moment(dataItem.accountVerificationDate).format("MM/DD/YYYY, h:mm a")}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Auto Notify: </strong>{dataItem.autoNotify ? 'True' : 'False'}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Auto Notification Time: </strong>{dataItem.notificationTime}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <strong>Auto Notification Type: </strong>{dataItem.notificationType}
                                </Col>
                            </Row>
                        </>
                        : null
                    }
                    {dataItem.removed ?
                        <Row>
                            <Col>
                                <strong>Removed Date: </strong>{moment(dataItem.updatedAt).format("MM/DD/YYYY, h:mm a")}
                            </Col>
                        </Row>
                        : null
                    }
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <strong>Attachments Needed: </strong>{dataItem.attachmentsNeeded ? 'True' : 'False'}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>MFA setup: </strong>{dataItem.totp && dataItem.totp.key && dataItem.totp.key !== null ? 'True' : 'False'}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            {orgId !== dataItem._id.toString() &&
                                <Button
                                    primary
                                    disabled={
                                        !dataItem.verified
                                        || dataItem.removed
                                        || !dataItem.totp
                                        || !dataItem.totp.resetRequested
                                        || dataItem.totp.resetCompleted
                                    }
                                    onClick={() => {
                                        resetMFA(dataItem._id);
                                    }}
                                >
                                    Reset MFA
                                </Button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <strong>Contact Details</strong>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <strong>First Name: </strong>{dataItem.primaryContact && dataItem.primaryContact['firstName']}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Last Name: </strong>{dataItem.primaryContact && dataItem.primaryContact['lastName']}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Position: </strong>{dataItem.primaryContact && dataItem.primaryContact['position']}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <strong>Email: </strong>{dataItem.primaryContact && dataItem.primaryContact['email']}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong>Phone: </strong>{dataItem.primaryContact && dataItem.primaryContact['phone']}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <p><strong>Employee Notifications:</strong></p>
            <Button
                primary
                disabled={!dataItem.verified || dataItem.removed}
                onClick={() => {
                    sendNotificationsForOrg(dataItem._id);
                }}
            >
                Send {dataItem.notificationType[0].toUpperCase()}{dataItem.notificationType.substring(1)} Notifications To All Active
            </Button>
            {dataItem.empsThatMissedNotifications === 0 && <br />}
            {dataItem.empsThatMissedNotifications > 0 && <>
                {dataItem.empsThatMissedOrgSchedNotifications > 0 && <>
                    &nbsp;
                    <Button
                        primary
                        disabled={!dataItem.verified || dataItem.removed}
                        onClick={() => {
                            if (dataItem.notificationType === 'email') {
                                sendEmailNotification(dataItem.activeEmpsOrgSched);
                            } else if (dataItem.notificationType === 'text') {
                                sendTextNotification(dataItem.activeEmpsOrgSched);
                            }
                        }}
                    >
                        Send {dataItem.empsThatMissedOrgSchedNotifications} Missed {dataItem.notificationType[0].toUpperCase()}{dataItem.notificationType.substring(1)} Notification(s)
                    </Button>
                    <br />
                </>}
                {(dataItem.empsThatMissedAdminSchedEmailNotifications > 0 || dataItem.empsThatMissedAdminSchedTextNotifications > 0) && <>
                    <br />
                    <p><strong>Employee Notifications (Admin Scheduled):</strong></p>
                    {dataItem.empsThatMissedAdminSchedEmailNotifications > 0 && <>
                        <Button
                            primary
                            disabled={!dataItem.verified || dataItem.removed}
                            onClick={() => {
                                sendEmailNotification(dataItem.activeEmpsAdminSchedEmail);
                            }}
                        >
                            Send {dataItem.empsThatMissedAdminSchedEmailNotifications} Missed Email Notification(s)
                        </Button>
                    </>}
                    {dataItem.empsThatMissedAdminSchedTextNotifications > 0 && <>
                        &nbsp;
                        <Button
                            primary
                            disabled={!dataItem.verified || dataItem.removed}
                            onClick={() => {
                                sendEmailNotification(dataItem.activeEmpsAdminSchedText);
                            }}
                        >
                            Send {dataItem.empsThatMissedAdminSchedTextNotifications} Missed Text Notification(s)
                        </Button>
                    </>}
                    <br />
                </>}
            </>}
            {dataItem.visThatMissedNotifications > 0 && <>
                <br />
                <p><strong>Visitor Notifications (Admin Scheduled):</strong></p>
                {dataItem.visThatMissedAdminSchedEmailNotifications > 0 && <>
                    <Button
                        primary
                        disabled={!dataItem.verified || dataItem.removed}
                        onClick={() => {
                            sendEmailNotification(dataItem.activeVisAdminSchedEmail);
                        }}
                    >
                        Send {dataItem.visThatMissedAdminSchedEmailNotifications} Missed Email Notification(s)
                    </Button>
                </>}
                {dataItem.visThatMissedAdminSchedTextNotifications > 0 && <>
                    &nbsp;
                    <Button
                        primary
                        disabled={!dataItem.verified || dataItem.removed}
                        onClick={() => {
                            sendEmailNotification(dataItem.activeVisAdminSchedText);
                        }}
                    >
                        Send {dataItem.visThatMissedAdminSchedTextNotifications} Missed Text Notification(s)
                    </Button>
                </>}
                <br />
            </>}
            {isSuperUser && orgId !== dataItem._id.toString() && <>
                <br />
                <p><strong>Organization Actions:</strong></p>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            <i>
                                Deactivate the organization.<br/>
                                <br/>
                                This <b>WILL</b> bill the client for their current/final month of usage and will cancel their subscriptions in Stripe.
                            </i>
                        </Tooltip>}
                >
                    <Button
                        primary
                        disabled={!dataItem.verified || dataItem.removed}
                        onClick={() => {
                            deactivateOrgAccount(dataItem._id);
                        }}
                    >
                        Deactivate Account
                    </Button>
                </OverlayTrigger>&nbsp;
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            <i>
                                Deactivate the organization.<br/>
                                <br/>
                                This will <b>NOT</b> bill the client for their current/final month of usage; however, it will cancel their subscriptions in Stripe.
                            </i>
                        </Tooltip>}
                >
                    <Button
                        primary
                        disabled={!dataItem.verified || dataItem.removed}
                        onClick={() => {
                            deactivateOrgAccountAndDoNotBill(dataItem._id);
                        }}
                    >
                        Deactivate Account (No Bill)
                    </Button>
                </OverlayTrigger>
            </>}
        </section>
    );

    return (
        <Container fluid className="mt-3">
            {message.status &&
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: message.status, icon: true }}
                            closable={true}
                            onClose={() => setMessage({ status: null, message: '' })}
                        >
                            <span>{message.message}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>}
            <PanelBar style={{ backgroundColor: '#e5e7eb', borderWidth: 0 }} expandMode={"single"}>
                <PanelBarItem title={"Organizations"}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col className="text-right">
                                    {!isNarrowScreen && <><strong>Number of Days to go Back</strong>&nbsp;
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                <i>
                                                    This will set the number of days ({numberOfDaysForRecentUsage}) to go back to count the number of "recent" <b>text and email notifications sent</b>.
                                                    <br /><br />
                                                    This will also set the period to go back when looking for <b>missed notifications</b>.
                                                </i>
                                            </Tooltip>}
                                    >
                                        <i className="fas fa-info-circle" />
                                    </OverlayTrigger>:&nbsp;&nbsp;
                                    <DropDownList
                                        data={[1, 7, 30, 91, 182, "ALL"]}
                                        style={{ backgroundColor: 'white' }}
                                        value={numberOfDaysForRecentUsage}
                                        onChange={(e) => {
                                            setNumberOfDaysForRecentUsage(e.value);
                                        }}
                                    />
                                    {!isMobile && <span> | </span>}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                                <Tooltip id="tooltip-disabled">
                                                    <i>
                                                        Export to Excel the list of active organizations with their recent usage as defined by the value set for <b>Number of Days to go Back For Recent Usage & Missed Notifications</b>.
                                                    </i>
                                            </Tooltip>}
                                    >
                                        <Button
                                            icon="download"
                                            onClick={excelExportActive}
                                            primary
                                        >Active Orgs</Button>
                                    </OverlayTrigger>
                                    <span>  </span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                                <Tooltip id="tooltip-disabled">
                                                    <i>
                                                        Export to Excel the list of deactivated or not verified organizations.
                                                    </i>
                                            </Tooltip>}
                                    >
                                        <Button
                                            icon="download"
                                            onClick={excelExportDeactivated}
                                            primary
                                        >Deactivated Orgs</Button>
                                    </OverlayTrigger>
                                    <span>  |  </span></>}
                                    <ButtonGroup>
                                        <OverlayTrigger
                                            placement={showFilter1Options ? "left" : "top"}
                                            overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                        <i>
                                                            {!showFilter1Options ? 'Enable' : 'Disable and clear'} filters.
                                                    </i>
                                                </Tooltip>}
                                        >
                                            <Button
                                                disabled={[...allOrgDetails].length === 0 || [...allOrgDetails].length === 1}
                                                icon={!showFilter1Options ? "filter" : "filter-clear"}
                                                onClick={() => {
                                                    setShowFilter1Options(!showFilter1Options);
                                                    setFilter1(null);
                                                }}
                                                primary
                                            />
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-disabled">
                                                    <i>
                                                        Export to PDF.
                                                    </i>
                                                </Tooltip>}
                                        >
                                            <Button
                                                disabled={filterBy(orderBy([...allOrgDetails], sort1), filter1).length === 0}
                                                icon="download"
                                                onClick={() => _pdfExport.save()}
                                                primary
                                            />
                                        </OverlayTrigger>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Grid
                            style={{
                                height: 'calc(100vh - 290px)',
                                minHeight: 400,
                            }}
                            filterable={showFilter1Options}
                            filter={filter1}
                            onFilterChange={(e) => {
                                setFilter1(e.filter);
                            }}
                            data={filterBy(orderBy([...allOrgDetails], sort1), filter1)}
                            sortable={{
                                allowUnsort: true,
                                mode: 'multiple',
                            }}
                            sort={sort1}
                            onSortChange={(event) => {
                                setSort1(event.sort);
                            }}
                            detail={DetailComponent}
                            expandField="expanded"
                            onExpandChange={(event) => expandChange(event)}
                        >
                            <Column
                                field="companyName"
                                title="Name"
                                footerCell={(props) => (
                                    <td colSpan={props.colSpan} style={props.style}>
                                        {filterBy(allOrgDetails, filter1).length !== allOrgDetails.length && 'Filtered '}Total: {filterBy(allOrgDetails, filter1).length}
                                    </td>
                                )}
                            />
                            {!isNarrowScreen && <Column
                                field="numOfTextNotifications"
                                title={!isMobile ? "# of Recent Text Sent" : "Recent Text"}
                                filter="numeric"
                                footerCell={(props) => (
                                    <td colSpan={props.colSpan} style={props.style}>
                                        Total Text Sent: {totalTextForPeriod}
                                    </td>
                                )}
                            />}
                            {!isNarrowScreen && <Column
                                field="numOfEmailNotifications"
                                title={!isMobile ? "# of Recent Email Sent" : "Recent Email"}
                                filter="numeric"
                                footerCell={(props) => (
                                    <td colSpan={props.colSpan} style={props.style}>
                                        Total Email Sent: {totalEmailForPeriod}
                                    </td>
                                )}
                            />}
                            {!isMobile && !isNarrowScreen && <Column
                                field="totalMissedNotifications"
                                title="# of Missed Notifications"
                                filter="numeric"
                                width={250}
                            />}
                            {!isNarrowScreen && <Column
                                field="status"
                                title="Status"
                                width={!(isMobile || isNarrowScreen) ? 250 : undefined}
                            />}
                        </Grid>
                        <GridPDFExport
                            ref={(element) => { _pdfExport = element; }}
                            margin="1cm"
                        >
                            {<Grid
                                data={filterBy(orderBy([...allOrgDetails], sort1), filter1)}
                                detail={DetailComponent}
                                expandField="expanded"
                                onExpandChange={(event) => expandChange(event)}
                            >
                                <Column
                                    field="companyName"
                                    title="Name"
                                    footerCell={(props) => (
                                        <td colSpan={props.colSpan} style={props.style}>
                                            {filterBy(allOrgDetails, filter1).length !== allOrgDetails.length && 'Filtered '}Total: {filterBy(allOrgDetails, filter1).length}
                                        </td>
                                    )}
                                />
                                <Column
                                    field="numOfTextNotifications"
                                    title={`# of Recent Text Sent (Last ${numberOfDaysForRecentUsage} Days)`}
                                    filter="numeric"

                                />
                                <Column
                                    field="numOfEmailNotifications"
                                    title={`# of Recent Email Sent (Last ${numberOfDaysForRecentUsage} Days)`}
                                    filter="numeric"

                                />
                                <Column
                                    field="totalMissedNotifications"
                                    title="# of Missed Notifications"
                                    width={250}
                                />
                                <Column
                                    field="status"
                                    title="Status"
                                    width={250}
                                />
                            </Grid>}
                        </GridPDFExport>
                        <ExcelExport fileName="Check'N Active Clients.xlsx" data={filterBy(orderBy([...allOrgDetails], activeSort), activeFilter)} ref={_excelExportActive}>
                            <ExcelExportColumn field="companyName" title="Name" width="275px" />
                            <ExcelExportColumn field="companyEmail" title="Company Email" width="200px" />
                            <ExcelExportColumn field="primaryContact['firstName']" title="PC First Name" width="80px" />
                            <ExcelExportColumn field="primaryContact['lastName']" title="PC Last Name" width="90px" />
                            <ExcelExportColumn field="primaryContact['position']" title="PC Position" width="175px" />
                            <ExcelExportColumn field="primaryContact['email']" title="PC Email" width="200px" />
                            <ExcelExportColumn field="primaryContact['phone']" title="PC Phone" width="120px" />
                            <ExcelExportColumn field="numOfTextNotifications" title={`# of Recent Text Sent (Last ${numberOfDaysForRecentUsage} Days)`} />
                            <ExcelExportColumn field="numOfEmailNotifications" title={`# of Recent Email Sent (Last ${numberOfDaysForRecentUsage} Days)`} />
                            <ExcelExportColumn field="totalMissedNotifications" title="# of Missed Notifications" width="140px" />
                            <ExcelExportColumn field="status" title="Status" />
                        </ExcelExport>
                        <ExcelExport fileName="Check'N Deactivated Clients.xlsx" data={filterBy(orderBy([...allOrgDetails], deactivatedSort), deactivatedFilter)} ref={_excelExportDeactivated}>
                            <ExcelExportColumn field="companyName" title="Name" width="275px" />
                            <ExcelExportColumn field="companyEmail" title="Company Email" width="200px" />
                            <ExcelExportColumn field="primaryContact['firstName']" title="PC First Name" width="80px" />
                            <ExcelExportColumn field="primaryContact['lastName']" title="PC Last Name" width="90px" />
                            <ExcelExportColumn field="primaryContact['position']" title="PC Position" width="175px" />
                            <ExcelExportColumn field="primaryContact['email']" title="PC Email" width="200px" />
                            <ExcelExportColumn field="primaryContact['phone']" title="PC Phone" width="120px" />
                            <ExcelExportColumn field="updatedAt" title="Date Deactivated" width="175px" />
                            <ExcelExportColumn field="status" title="Status" />
                        </ExcelExport>
                    </Card>
                </PanelBarItem>
            </PanelBar><br />
            <div style={{ paddingBottom: isMobile ? 50 : undefined }}><PanelBar style={{ backgroundColor: '#e5e7eb', borderWidth: 0 }} expandMode={"single"}>
                <PanelBarItem title={"Users"}>
                    <form className="k-form">
                        <br />
                        <p><strong>Search By Multiple Fields:</strong></p>
                        <fieldset>
                            <Row>
                                <Col className="text-left">
                                    <label className="k-form-field">
                                        <p>First Name</p>
                                        <Input
                                            disabled={notificationId}
                                            type={"text"}
                                            value={firstName}
                                            onChange={(e) => {
                                                setFirstName(e.value);
                                            }}
                                            placeholder="First Name"
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <p>Last Name</p>
                                        <Input
                                            disabled={notificationId}
                                            type={"text"}
                                            onChange={(e) => {
                                                setLastName(e.value);
                                            }}
                                            value={lastName}
                                            placeholder="Last Name"
                                        />
                                    </label>
                                </Col>
                                <Col className="text-left">
                                    <label className="k-form-field">
                                        <p>Cell #</p>
                                        <MaskedTextBox
                                            disabled={notificationId}
                                            mask="(+1) 000-000-0000"
                                            placeholder="Cell Number"
                                            value={cellNo}
                                            onChange={(e) => {
                                                setCellNo(e.value);
                                            }}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <p>Email Address</p>
                                        <Input
                                            disabled={notificationId}
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.value);
                                            }}
                                        />
                                        <p><i><small>Note: this field allows for regular expression</small></i></p>
                                    </label>
                                </Col>
                            </Row>
                            <span><strong>OR By Single Field:</strong></span>
                            <Row>
                                <Col className="text-left">
                                    <label className="k-form-field">
                                        <p>Notification ID</p>
                                        <Input
                                            type={"text"}
                                            value={notificationId}
                                            disabled={firstName || lastName || cellNo || email}
                                            onChange={(e) => {
                                                setNotificationId(e.value);
                                            }}
                                            placeholder="Notification ID"
                                        />
                                    </label>
                                </Col>
                            </Row>
                        </fieldset>

                        <Row>
                            {notificationId && allUserDetails.length === 1 &&
                                <Col className="text-right">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${url}/?#/screening/${notificationId}`}
                                    >
                                        <strong>Link to {allUserDetails[0].firstName} {allUserDetails[0].lastName}'s Screening</strong>
                                    </a>
                                </Col>}
                            <Col className="text-right">
                            <button
                                type="button"
                                className="k-button"
                                onClick={() => {
                                    setFirstName('');
                                    setLastName('');
                                    setCellNo('');
                                    setEmail('');
                                    setNotificationId('');
                                    setAllUserDetails([]);
                                    setShowFilter2Options(false);
                                    setFilter2(null);
                                }}
                            >Clear All
                            </button>
                            <button
                                type="button"
                                className="k-button"
                                disabled={!firstName && !lastName && !cellNo && !email && !notificationId}
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    searchForUser();
                                }}
                            >Search
                            </button>
                        </Col>
                        </Row>
                    </form>
                    <br />
                    <Card>
                        <Card.Header>
                            <Row>
                                {fetchingData && <Col style={{ color: 'red' }} className="text-right"><strong>FETCHING DATA! Please wait...</strong></Col>}
                                <Col className="text-right">
                                    <ButtonGroup>
                                        <Button
                                            disabled={[...allUserDetails].length === 0 || [...allUserDetails].length === 1}
                                            icon={!showFilter2Options ? "filter" : "filter-clear"}
                                            onClick={() =>{
                                                setShowFilter2Options(!showFilter2Options);
                                                setFilter2(null);
                                            }}
                                            primary
                                        ></Button>
                                        <Button
                                            disabled={filterBy(orderBy([...allUserDetails], sort2), filter2).length === 0}
                                            icon="download"
                                            onClick={excelExportUsers}
                                            primary
                                        ></Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Grid
                            style={{
                                height: 'calc(100vh - 290px)',
                                minHeight: 400,
                            }}
                            filterable={showFilter2Options}
                            filter={filter2}
                            onFilterChange={(e) => {
                                setFilter2(e.filter);
                            }}
                            data={filterBy(orderBy([...allUserDetails], sort2), filter2).slice(page.skip, page.take + page.skip)}
                            sortable={{
                                allowUnsort: true,
                                mode: 'multiple',
                            }}
                            sort={sort2}
                            onSortChange={(event) => {
                                setSort2(event.sort);
                            }}
                            skip={page.skip}
                            take={page.take}
                            total={filterBy(orderBy([...allUserDetails], sort2), filter2).length}
                            pageable={{ pageSizes: [50, 100, 250] }}
                            pageSize={page.pageSize}
                            onPageChange={pageChange}
                        >
                            <Column
                                field="orgObj.companyName"
                                title="Organization Name"
                                footerCell={(props) => (
                                    <td colSpan={props.colSpan} style={props.style}>
                                        {filterBy(allUserDetails, filter2).length !== allUserDetails.length && 'Filtered '}Total: {filterBy(allUserDetails, filter2).length}
                                    </td>
                                )}
                            />
                            {(isMobile || isNarrowScreen) && <Column
                                field="fullName"
                                title="Name"
                            />}
                            {!(isMobile || isNarrowScreen) && <Column
                                field="firstName"
                                title="First Name"
                            />}
                            {!(isMobile || isNarrowScreen) && <Column
                                field="lastName"
                                title="Last Name"
                            />}
                            {!(isMobile || isNarrowScreen) && <Column
                                field="email"
                                title="Email"
                            />}
                            {!(isMobile || isNarrowScreen) && <Column
                                field="cellNumber"
                                title="Cell Number"
                            />}
                            {!(isMobile || isNarrowScreen) && <Column
                                field="orgObj.primaryContactEmail"
                                title="Org Contact Email"
                            />}
                            {!isNarrowScreen && <Column
                                field="orgObj.companyEmail"
                                title="Org Email"
                            />}
                            {!isNarrowScreen && <Column
                                field="userType"
                                title="User Type"
                            />}
                        </Grid>
                        <ExcelExport fileName="Check'N User Query Results.xlsx" data={filterBy(orderBy([...allUserDetails], sort2), filter2)} ref={_excelExportUsers}>
                            <ExcelExportColumn field="orgObj.companyName" title="Organization Name" width="275px" />
                            <ExcelExportColumn field="firstName" title="First Name" width="90px" />
                            <ExcelExportColumn field="lastName" title="Last Name" width="100px" />
                            <ExcelExportColumn field="email" title="Email" width="225px" />
                            <ExcelExportColumn field="cellNumber" title="Cell Number" width="105px" />
                            <ExcelExportColumn field="orgObj.primaryContactEmail" title="Org Contact Email" width="200px" />
                            <ExcelExportColumn field="orgObj.companyEmail" title="Org Email" width="200px" />
                            <ExcelExportColumn field="userType" title="User Type" />
                        </ExcelExport>
                    </Card>
                </PanelBarItem>
            </PanelBar></div>
        </Container>
    );
};

export default SuperUserView;
