import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import QRCode from 'qrcode'
import { Splitter } from '@progress/kendo-react-layout';
import { PDFExport } from '@progress/kendo-react-pdf';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import getTokenConfig from "../../../methods/getTokenConfig";
import { Button } from "@progress/kendo-react-buttons";
import { Divider } from "antd";

let pdfExportComponent;

const QrCodePrint = ({ history }) => {
    const [panes, setPanes] = useState([
        {},
        { size: '50%', resizable: false }
    ]);
    const [url, setUrl] = useState('');
    const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState({ hasError: false, message: '' });

    const resetError = (time) => {
        setTimeout(() => {
            setError({ hasError: false, message: '' });
        }, time);
    };

    useEffect(() => {
        if (!url) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/infrastructureInfo', getTokenConfig())
                .then((res) => {
                    if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                        setUrl(res.data.url);
                    }
                }).catch((err) => {
                    setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                    resetError(1800);
                });
        } else {
            const token = localStorage.getItem('CheckN.loginToken');
            const fullUrl = `${url}/?#/screening/newQRVisit/${token}`;
            QRCode.toDataURL(fullUrl)
                .then(fullUrl => {
                    setQrCodeDataUrl(fullUrl);
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }, [url]);

    return (
        <Container fluid className="mt-3" style={{ paddingBottom: isMobile ? 15 : undefined }}>
            <PDFExport ref={(component) => pdfExportComponent = component} paperSize="A4">
                <Row className="mb-5">
                    <Splitter
                        style={{ border: 'none' }}
                        panes={panes}
                        onChange={(event) => setPanes(event.newState)}
                    >
                        <div className="pr-3 text-center">
                            <Divider orientation="left"><strong>QR Code</strong></Divider>
                            {qrCodeDataUrl ?
                                <img
                                    alt="qrCode"
                                    src={qrCodeDataUrl}
                                />
                                : null
                            }
                        </div>
                        <div className="pl-3">
                            <Divider orientation="left"><strong>Instructions</strong></Divider><br />
                            <strong>
                                <ol>
                                    <li>Scan this QR Code using your phone's camera.</li>
                                    <li>Fill out your information.</li>
                                    <li>Go through the questionnaire and complete the screening.</li>
                                </ol>
                            </strong>
                        </div>
                    </Splitter>
                </Row>
            </PDFExport>
            <div style={{ paddingBottom: isMobile ? 25 : undefined }}><Row>
                <Col className="text-center">
                    <Divider orientation="center"><strong>Get this page as PDF</strong></Divider><br />
                    <Button
                        primary
                        onClick={() => pdfExportComponent.save()}
                    >
                        Download
                    </Button>
                </Col>
            </Row></div>
        </Container>
    );
};

export default QrCodePrint;
