import React from "react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import '../../stylesheets/smartPhoneStyles.css'

const QuestionsPreview = ({ setPreview, addedQuestions }) => (
    <Dialog
        onClose={() => setPreview(false)}
    >
        <div className="smartphone">
            <div className="content">
                <div style={{ width: "100%", border: "none", height: "100%", overflow: 'scroll' }}>
                    {addedQuestions.map((q, i) =>
                        (
                            <div style={{ textAlign: 'left' }}>
                                <strong>Question {i + 1}</strong> <br />
                                {q.questionString} <br />
                                {(q.sortOrder === 0 || q.originalQuestionSortNumber === 0) &&
                                    <>
                                        {moment().format("ddd, M/D/YYYY")}
                                        <br />
                                    </>}
                                {q.questionType === 'list' ?
                                    <span>
                                        <ul>
                                            {q.options.map(op => <li>{op}</li>)}
                                        </ul>
                                    </span>
                                    : null
                                }
                                {q.questionType !== 'text' && q.questionType !== 'date' ?
                                    <>
                                        <Button look="outline">No</Button>
                                        <Button look="outline" className="ml-2">Yes</Button>
                                    </>
                                    : null
                                }
                                {q.questionType === 'text' ?
                                    <>
                                        <input type='text' />
                                    </>
                                    : null
                            }
                            {q.questionType === 'date' ?
                                <span>
                                        <DatePicker
                                            min={new Date(moment().add(2, 'days'))}
                                        />
                                </span>
                                : null
                            }
                                <br />
                                <br />
                            </div>
                        ))}
                </div>
            </div>
        </div>
        <DialogActionsBar>
            <button className="k-button" onClick={() => setPreview(false)}>Cancel</button>
            <button className="k-button" onClick={() => setPreview(false)}>Save</button>
        </DialogActionsBar>
    </Dialog>
);

export default QuestionsPreview;
