import React from "react";
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Notification } from '@progress/kendo-react-notification';
import getTokenConfig from '../../../methods/getTokenConfig';
import auth from "../../../methods/auth";


const RequestMFAModal = ({
    showRequestMFAModal,
    setShowRequestMFAModal,
    setMessageParent,
    resetMessageParent,
    history,
}) => {
    const requestResetMFA = async () => {
        await axios.get(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/requestResetMFA',
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setMessageParent({ status: 'success', msg: res && res.data && res.data.msg });
                resetMessageParent(1800);
                auth.logout(() => history.push('/signin'));
            }
        }).catch((err) => {
            setMessageParent({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
            resetMessageParent(1800);
        });
        setShowRequestMFAModal(false);
    };

    return (
        <Modal
            show={showRequestMFAModal}
            onHide={() => {
                setShowRequestMFAModal(false);
            }}
            size="lg"
        >
            <Modal.Header
                style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    color: "#ffffff",
                }}
            >
                <Modal.Title style={{ width: '100%' }}>
                    Request to reset MFA token
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ backgroundColor: "#e4e7eb" }}
            >
                <h6>Are you sure you want to request Check'n team to reset your MFA?</h6>
                <div style={{ marginBottom: 10 }}>
                    <Notification
                        type={{
                            style: "info",
                        }}
                    >
                        <ul style={{ marginBottom: 0 }}>
                            <li>
                                Requesting reset of MFA will log you out and you will not get access until
                                you set up new MFA token. Please make sure no one is logged in through any other device.
                            </li>
                            <li>
                                The Check'n support team will contact you to reset your MFA,
                                this could take up to 24 hours.
                            </li>
                        </ul>
                    </Notification>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="k-button"
                    onClick={() => {
                        setShowRequestMFAModal(false);
                    }}
                >Cancel
                </button>
                <button
                    type="button"
                    className="k-button"
                    style={{
                        backgroundColor: '#343a40',
                        color: 'white',
                    }}
                    onClick={() => {
                        requestResetMFA();
                    }}
                >
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default RequestMFAModal;
