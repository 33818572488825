import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Button as Btn, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Button } from "@progress/kendo-react-buttons";
import { Spin } from 'antd';
import axios from "axios";
import QRCode from 'qrcode'
import notp from 'notp';
import Cookies from 'js-cookie';
import RequestMFAModal from '../settings/requestMFAModal';
import getTokenConfig from "../../../methods/getTokenConfig";


const MfaAuthentication = ({ history }) => {
    const [token, setToken] = useState(null);
    const [message, setMessage] = useState({ status: '', msg: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showRequestMFAModal, setShowRequestMFAModal] = useState(false);
    const [url, setUrl] = useState('');
    const [showSetupMFA, setShowSetupMFA] = useState(false);
    const [base32, setBase32] = useState(null);
    const [keyAscii, setKeyAscii] = useState(null);
    const [qrCode, setQrCodeDataUrl] = useState(null);
    const [newToken, setNewToken] = useState(null);
    const [newTokenValidated, setNewTokenValidated] = useState(false);

    const [attemptWarning, setAttemptWarning] = useState(null);

    const resetMessage = (time) => {
        setTimeout(() => {
            setMessage({ status: '', message: '' });
        }, time);
    }

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') !== 'false'
            && localStorage.getItem('CheckN.paymentMethodAdded') !== 'false'
        )
            history.push('/');
        else if (
            localStorage.getItem('CheckN.mfaAuthenticated') !== 'false'
            && localStorage.getItem('CheckN.paymentMethodAdded') === 'false'
        )
            history.push('/paymentSetup');

        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/infrastructureInfo', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setUrl(res.data.url);
                }
            });
        if (url) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
                .then(async (res) => {
                    if (res.data._id === localStorage.getItem('CheckN.orgId')
                        && res.data.totp
                        && res.data.totp.resetCompleted
                        && res.data.totp.key === null
                    ) {
                        setShowSetupMFA(true);
                        await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/generateNewTOTPKey/', getTokenConfig())
                            .then((response) => {
                                setBase32(response.data.base32);
                                setKeyAscii(response.data.key);
                                QRCode.toDataURL(`otpauth://totp/${url}/?secret=${response.data.base32}`)
                                    .then(url => {
                                        setQrCodeDataUrl(url);
                                    })
                                    .catch(err => {
                                        setMessage({
                                            status: 'error',
                                            message: err.response && err.response.data && err.response.data.msg,
                                        });
                                    })
                            }).catch((error) => {
                                setMessage({
                                    status: 'error',
                                    message: error.response && error.response.data && error.response.data.msg,
                                });
                                resetMessage(1800);
                            });
                    } else if (res.data._id === localStorage.getItem('CheckN.orgId')
                        && res.data.totp
                        && res.data.totp.attempts > 4
                        && res.data.totp.attempts < 10
                    ) {
                        setAttemptWarning(`You will be locked out after 10 unsuccessful attempts.
                                    You have already completed ${res.data.totp.attempts} attempts.`);
                    } else if (res.data._id === localStorage.getItem('CheckN.orgId')
                        && res.data.totp
                        && res.data.totp.attempts > 9
                        && res.data.totp.lockedOut
                        && moment().subtract(10, 'minutes').toDate() < res.data.totp.lockedTime
                    ) {
                        setAttemptWarning(`You are locked out from the system because of 10 unsuccessful attempts.
                                    Please re-try after few minutes.`);
                    }
                }).catch((err) => {
                    setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                    resetMessage(1800);
                });
        }
    }, [history, url]);

    const authenticateToken = () => {
        axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/verifyMfaLogin',
            { token },
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                localStorage.setItem('CheckN.mfaAuthenticated', true);
                Cookies.set('CheckN.mfaAuthenticated', true);
                history.push('/');
            }
        }).catch((err) => {
            if (err.response
                && err.response.data
                && (err.response.data.msg === 'LockedOut' || err.response.data.attempts > 4)) {
                if (err.response.data.attempts > 9) {
                    setMessage({ status: 'error', msg: 'You are locked out.' });
                    setAttemptWarning(`You are locked out from the system because of 10 unsuccessful attempts.
                            Please re-try after few minutes.`);
                } else {
                    setAttemptWarning(`You will be locked out after 10 unsuccessful attempts.
                        You have already completed ${err.response.data.attempts} attempts.`);
                }
            } else {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
            }
            resetMessage(1800);
            setIsLoading(false);
        });
    };

    const setNewTotp = async () => {
        await axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/mfa/setNewTotpUser',
            { key: keyAscii },
            getTokenConfig()
        ).then((res) => {
            if (res.status === 200) {
                setMessage({
                    status: 'success',
                    msg: res.data.msg,
                });
                localStorage.setItem('CheckN.mfaAuthenticated', true);
                Cookies.set('CheckN.mfaAuthenticated', true);
                history.push('/');
            }
        }).catch((err) => {
            setMessage({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            resetMessage(1800);
        });
    };

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Multi-Factor Authentication</span>
                </Card.Header>
            </Card>

            {message.status ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: message.status, icon: true }}
                            closable={true}
                            onClose={() => setMessage({ status: '', msg: '' })}
                        >
                            <span>{message.msg}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>
                : null
            }

            {showRequestMFAModal ?
                <RequestMFAModal
                    showRequestMFAModal={showRequestMFAModal}
                    setShowRequestMFAModal={setShowRequestMFAModal}
                    setMessageParent={setMessage}
                    resetMessageParent={resetMessage}
                    history={history}
                />
                : null
            }

            <Container fluid>
                <Card>
                    <Card.Body>
                        <Spin size="large" spinning={isLoading} >
                            <form className="k-form">
                                {attemptWarning ?
                                    <Fade
                                        enter={true}
                                        exit={true}
                                        style={{ width: '100%' }}
                                        className="inline-notification"
                                    >
                                        <Notification
                                            type={{ style: 'error', icon: true }}
                                            style={{ width: '100%' }}
                                        >
                                            <span>
                                                {attemptWarning}
                                            </span>
                                        </Notification>
                                    </Fade>
                                    : null
                                }
                                {showSetupMFA ?
                                    <Row>
                                        <Col>
                                            <div className="text-center" style={{ marginBottom: 15 }}>
                                                <div style={{ marginBottom: 5 }}><b>QR Code:</b></div>
                                                <div style={{ marginBottom: 10 }}>
                                                    <img
                                                        src={qrCode}
                                                        alt="qrCode"
                                                        height="300"
                                                        width="300"
                                                    />
                                                </div>
                                                <div style={{ marginBottom: 5 }}><b>Key:</b></div>
                                                <div><b>{base32}</b></div>
                                            </div>
                                            <p>
                                                Once you have setup your authenticator using the above QR code or key,
                                                enter the 6-digit token that it generates so we can be sure
                                                you&#39;re setup correctly:
                                            </p>
                                            <Form.Group controlId="validationToken">
                                                <Form.Label><b>Token:</b></Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter New Token"
                                                    required
                                                    value={newToken}
                                                    onChange={(e) => {
                                                        setNewToken(e.target.value);
                                                        const verified = notp.totp.verify(e.target.value, keyAscii, { window: 2 });
                                                        if (verified)
                                                            setNewTokenValidated(true);
                                                        else
                                                            setNewTokenValidated(false);
                                                    }}
                                                    isInvalid={!newTokenValidated}
                                                    isValid={newTokenValidated}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    : <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>MFA Token</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter MFA token"
                                                    onChange={(e) => {
                                                        if (message.status) {
                                                            setMessage({ status: '', message: '' });
                                                        }
                                                        setToken(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </form>
                        </Spin>
                        <hr />
                        <Button
                            primary
                            onClick={() => {
                                setIsLoading(true);
                                !showSetupMFA ? authenticateToken() : setNewTotp();
                            }}
                        >
                            Submit
                        </Button>
                        {!showSetupMFA ?
                            <>
                                <hr />
                                <strong>
                                    If you do not have current 6-digit token, please
                                    <Btn
                                        variant="link"
                                        size="sm"
                                        onClick={() => {
                                            setShowRequestMFAModal(true);
                                        }}
                                    >click here
                                    </Btn>to request reset.
                                </strong>
                            </>
                            : null
                        }
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default MfaAuthentication;
