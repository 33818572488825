// Setup config/headers and token
export default () => {
    // Get token from localstorage
    const token = localStorage.getItem('CheckN.loginToken');

    if (!token)
        window.location.replace('/');

    // Headers
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    // If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
};