import axios from 'axios';
import Cookies from 'js-cookie';

class Auth {
    constructor() {
        this.authenticated = false;
    }

    async login(email, password, cb) {
        // Headers
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // Request body
        const body = JSON.stringify({ email, password });

        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/login', body, config)
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem('CheckN.loginToken', res.data.token);
                    localStorage.setItem('CheckN.orgId', res.data.organizationId);
                    localStorage.setItem('CheckN.paymentMethodAdded', res.data.paymentMethodAdded);
                    Cookies.set('CheckN.loginToken', res.data.token);
                    Cookies.set('CheckN.orgId', res.data.organizationId);
                    Cookies.set('CheckN.paymentMethodAdded', res.data.paymentMethodAdded);
                    if (res.data.attachmentsNeeded) {
                        localStorage.setItem('CheckN.mfaAuthenticated', false);
                        Cookies.set('CheckN.mfaAuthenticated', false);
                    } else {
                        localStorage.setItem('CheckN.mfaAuthenticated', true);
                        Cookies.set('CheckN.mfaAuthenticated', true);
                    }
                    this.authenticated = true;
                    cb({
                        authenticated: this.authenticated,
                        paymentMethodAdded: res.data.paymentMethodAdded,
                        attachmentsNeeded: res.data.attachmentsNeeded,
                    });
                }
            }).catch(err => {
                cb({
                    error: {
                        status: err.response && err.response.status,
                        msg: err.response && err.response.data && err.response.data.msg
                    }
                });
            });
    }

    logout(cb) {
        localStorage.removeItem('CheckN.loginToken');
        localStorage.removeItem('CheckN.orgId');
        localStorage.removeItem('CheckN.paymentMethodAdded');
        localStorage.removeItem('CheckN.mfaAuthenticated');
        Cookies.remove('CheckN.loginToken');
        Cookies.remove('CheckN.orgId');
        Cookies.remove('CheckN.paymentMethodAdded');
        Cookies.remove('CheckN.mfaAuthenticated');
        this.authenticated = false;
        cb();
    }

    isAuthenticated() {
        this.authenticated = false;

        if (localStorage.getItem('CheckN.loginToken') !== null
            && localStorage.getItem('CheckN.loginToken') !== ''
            && localStorage.getItem('CheckN.loginToken') === Cookies.get('CheckN.loginToken')
            && localStorage.getItem('CheckN.orgId') !== null
            && localStorage.getItem('CheckN.orgId') !== ''
            && localStorage.getItem('CheckN.orgId') === Cookies.get('CheckN.orgId')) {
            if (localStorage.getItem('CheckN.paymentMethodAdded') === null
                || localStorage.getItem('CheckN.paymentMethodAdded') === undefined
                || Cookies.get('CheckN.paymentMethodAdded') === null
                || Cookies.get('CheckN.paymentMethodAdded') === undefined
            ) {
                this.authenticated = false;
            } else
                this.authenticated = true;
        }

        return this.authenticated;
    }
}

export default new Auth();
