import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Link } from "react-router-dom";
import auth from "../../../methods/auth";
import '../../stylesheets/kendoNotifications.css';

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const Login = ({ history }) => {
    const [userName, setUserName] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState({
        hasError: false,
        message: '',
    });
    const [checked, setChecked] = useState(localStorage.getItem('checkn.userName'))
    const [showVerifyEmailNoti, setShowVerifyEmailNoti] = useState(false);

    useEffect(() => {
        if (history.location.pathname.startsWith('/signin/verifyEmailSignIn'))
            setShowVerifyEmailNoti(true);

        if (localStorage.getItem('checkn.userName'))
            setUserName(localStorage.getItem('checkn.userName'));

        setTimeout(() => { setShowVerifyEmailNoti(false); }, 5000);
    }, [history.location.pathname]);

    const signIn = async (e) => {
        e.preventDefault();
        if ((!userName || userName.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Company email can not be empty.' });
        } else if (!emailRegex.test(userName) && !error.hasError) {
            setError({ hasError: true, message: 'Company email is not a valid email address.' });
        } else if ((!pass || pass.trim() === '') && !error.hasError) {
            setError({ hasError: true, message: 'Company password can not be empty.' });
        } else if (!error.hasError) {
            auth.login(userName, pass, (res) => {
                if (res.error) {
                    setError({
                        hasError: true,
                        message: `${res.error.status}: ${res.error.msg}`
                    });
                }
                if (res.authenticated && res.paymentMethodAdded && !res.attachmentsNeeded)
                    history.push('/');
                else if (res.attachmentsNeeded)
                    history.push('/mfaAuth');
                else
                    history.push('/paymentSetup');
            });
        }
    }

    const rememberMe = () => {
        const checkElement = document.getElementById('customCheck1');
        const exists = localStorage.getItem('checkn.userName');
        setChecked(checkElement.checked)
        if (checkElement.checked && userName !== '' && !exists) {
            localStorage.setItem('checkn.userName', userName)
        }
        else {
            localStorage.removeItem('checkn.userName');
        }
    };

    return (
        <div style={{ padding: 15 }}>
            {showVerifyEmailNoti
                && <NotificationGroup style={{ top: 0, left: '50%', transform: 'translateX(-50%)' }}>
                    <Fade enter={true} exit={true} style={{ width: '100%' }}>
                        <Notification
                            key="info"
                            type={{ style: 'info', icon: true }}
                            closable={true}
                            onClose={() => setShowVerifyEmailNoti(false)}
                        >
                            Please verify your email address
                    </Notification>
                    </Fade>
                </NotificationGroup>
            }
            <Row md="12" className="justify-content-md-center">
                <Col md="2"></Col>
                <Col md="8">
                    <Card
                        style={{
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}
                    >
                        <Card.Header
                            style={{
                                fontSize: "40px",
                                backgroundColor: '#343a40',
                                color: 'white'
                            }}
                        >Sign In
                        </Card.Header>
                        <Card.Body>
                            <form>
                                {error.hasError ?
                                    <Fade
                                        enter={true}
                                        exit={true}
                                        style={{ width: '100%' }}
                                        className="inline-notification"
                                    >
                                        <Notification
                                            type={{ style: 'error', icon: true }}
                                            style={{ width: '100%' }}
                                        >
                                            <span>{(error.status || error.message) ?
                                                (<span>{error.status ? `${error.status} : ` : null} {error.message}</span>) :
                                                'Error in connection to server'}
                                            </span>
                                        </Notification>
                                    </Fade>
                                    : null
                                }
                                <div className="form-group">
                                    <label>Company Email</label>
                                    <input
                                        type="text"
                                        defaultValue={localStorage.getItem('checkn.userName') || ''}
                                        className="form-control"
                                        placeholder="Enter company email"
                                        onChange={(e) => {
                                            if (error.hasError) {
                                                setError({ hasError: false, message: '' })
                                            }
                                            setUserName(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={(e) => {
                                            if (error.hasError) {
                                                setError({ hasError: false, message: '' })
                                            }
                                            setPass(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck1"
                                            checked={checked}
                                            onChange={rememberMe}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck1">Remember user name</label>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-block"
                                    style={{ backgroundColor: '#343a40', color: 'white' }}
                                    onClick={signIn}
                                >
                                    Sign in
                                    </button>
                                <Button
                                    style={{ marginTop: 5 }}
                                    variant="link"
                                    onClick={() => history.push('/forgot-password')}
                                >Forgot password?
                                    </Button>
                                <Button
                                    style={{ float: 'right', marginTop: 5 }}
                                    variant="link"
                                    onClick={() => history.push('/resendVerification')}
                                >Resend Verification
                                    </Button>
                                <br />
                                <p style={{ marginLeft: '12px' }}>
                                    Don't have account? <Link to="/signup">Sign up</Link> here.
                                    </p>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="2"></Col>
            </Row>
        </div>
    );
};
export default Login;

