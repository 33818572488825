import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from '@progress/kendo-react-buttons';
import Switch from "react-switch";
import { Divider } from 'antd';
import SetUpMFAWindow from './setUpMFAWindow';
import AttachmentTurnOffModal from './attachmentTurnOffModal';
import RequestMFAModal from './requestMFAModal';
import VerifyOldTokenModal from './verifyOldTokenModal';

const AttachmentsSettings = ({
    inEditMode,
    setAttachmentsNeeded,
    attachmentsNeeded,
    totp,
    setTotp,
    setInEditMode,
    setMessageParent,
    resetMessageParent,
    history,
    useCase,
}) => {
    const [showSetupMFAModal, setShowSetupMFAModal] = useState(false);
    const [showResetMFAModal, setShowResetMFAModal] = useState(false);
    const [showRequestMFAModal, setShowRequestMFAModal] = useState(false);
    const [showVerifyOldTokenModal, setShowVerifyOldTokenModal] = useState(false);

    const [showAttachmentTurnOffModal, setShowAttachmentTurnOffModal] = useState(false);

    return (
        <>
            {showSetupMFAModal || showResetMFAModal ?
                <SetUpMFAWindow
                    setAttachmentsNeeded={setAttachmentsNeeded}
                    showSetupMFAModal={showSetupMFAModal}
                    setShowSetupMFAModal={setShowSetupMFAModal}
                    showResetMFAModal={showResetMFAModal}
                    setShowResetMFAModal={setShowResetMFAModal}
                    setShowRequestMFAModal={setShowRequestMFAModal}
                    setTotp={setTotp}
                    setInEditMode={setInEditMode}
                    setMessageParent={setMessageParent}
                    resetMessageParent={resetMessageParent}
                />
                : null
            }
            {showAttachmentTurnOffModal ?
                <AttachmentTurnOffModal
                    showAttachmentTurnOffModal={showAttachmentTurnOffModal}
                    setShowAttachmentTurnOffModal={setShowAttachmentTurnOffModal}
                    setShowRequestMFAModal={setShowRequestMFAModal}
                    setAttachmentsNeeded={setAttachmentsNeeded}
                    setTotp={setTotp}
                    setInEditMode={setInEditMode}
                    setMessageParent={setMessageParent}
                    resetMessageParent={resetMessageParent}
                    history={history}
                />
                : null
            }
            {showRequestMFAModal ?
                <RequestMFAModal
                    showRequestMFAModal={showRequestMFAModal}
                    setShowRequestMFAModal={setShowRequestMFAModal}
                    setMessageParent={setMessageParent}
                    resetMessageParent={resetMessageParent}
                    history={history}
                />
                : null
            }
            {showVerifyOldTokenModal ?
                <VerifyOldTokenModal
                    showVerifyOldTokenModal={showVerifyOldTokenModal}
                    setShowVerifyOldTokenModal={setShowVerifyOldTokenModal}
                    setShowRequestMFAModal={setShowRequestMFAModal}
                    setAttachmentsNeeded={setAttachmentsNeeded}
                    setTotp={setTotp}
                    setInEditMode={setInEditMode}
                    setMessageParent={setMessageParent}
                    resetMessageParent={resetMessageParent}
                    history={history}
                />
                : null
            }
            <Row>
                <Col>
                    <Divider orientation="left">Attachments Settings</Divider>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="attachmentsNeeded">
                                    <Form.Label>
                                        <strong>Attachment Needed</strong>
                                        <br />
                                        <small className="text-muted">
                                            <i>
                                            This setting will show a button to add attachments on the final step of a {useCase === 'COVID Screening' ? 'screening' :
                                                'survey'} where the Submit button is found.
                                            <br />
                                            This setting will also required you to setup MFA.
                                            <br />
                                            <b>Additional charges apply when activated.</b>
                                            </i>
                                        </small>
                                    </Form.Label><br />
                                    <Switch
                                        checked={attachmentsNeeded ? true : false}
                                        disabled={inEditMode !== 'attachmentsSettings'}
                                        onChange={() => {
                                            if (!attachmentsNeeded && !totp) {
                                                setShowSetupMFAModal(true);
                                            } else if (!attachmentsNeeded && !!totp) {
                                                setShowVerifyOldTokenModal(true);
                                            } else {
                                                setShowAttachmentTurnOffModal(true);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {attachmentsNeeded ?
                            <>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="mfaSetup">
                                            <Form.Label>
                                                <strong>MFA setup:&nbsp;
                                                {totp && totp.key ?
                                                    <span style={{ color: 'green' }}>Success</span>
                                                    : <p style={{ color: '#B01C2E' }}>Fail</p>
                                                }
                                                </strong>
                                            </Form.Label>
                                            <br/>
                                            <Button
                                                look="outline"
                                                iconClass="fas fa-sync-alt"
                                                disabled={inEditMode !== 'attachmentsSettings'}
                                                onClick={() => { setShowResetMFAModal(true); }}
                                            >&nbsp;&nbsp;Reset MFA
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                            : null
                        }
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default AttachmentsSettings;
