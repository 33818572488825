import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Container, Alert, Form } from "react-bootstrap";
import { MaskedTextBox, TextArea, Input } from "@progress/kendo-react-inputs";
import { Hint } from '@progress/kendo-react-labels';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import getTokenConfig from "../../../methods/getTokenConfig";
import { canadaHelplineNumbers } from '../../util/countryStateProvience';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => (emailRegex.test(value));

const Guidances = ({ useCase }) => {
    const [companyCountry, setCompanyCountry] = useState('');
    const [companyStateProv, setCompanyStateProv] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyContactEmail, setCompanyContactEmail] = useState('');
    const [inEditMode, setInEditMode] = useState(false);

    const [companyLinkInstructions, setCompanyLinkInstructions] = useState('');

    const [passMsg, setPassMsg] = useState('');
    const [failMsg, setFailMsg] = useState('');

    const [message, setMessage] = useState({ status: '', msg: '' });
    const [error, setError] = useState({ hasError: false, message: '' });

    const resetError = (time) => {
        setTimeout(() => {
            setError({ hasError: false, message: '' });
        }, time);
    };

    const resetMessage = (time) => {
        setTimeout(() => {
            setMessage({ status: '', msg: '' });
        }, time);
    };

    const resetData = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setCompanyCountry(res.data.companyCountry);
                    setCompanyStateProv(res.data.companyStateProv);
                    setCompanyContactEmail(res.data.companyContactEmail || '');
                    setCompanyLinkInstructions(res.data.linkInstructions ?
                        res.data.linkInstructions
                        : `Please click the link to start your Check'N ${useCase === 'COVID Screening' ?
                            'Screening for COVID - 19' : useCase === 'General Survey' ? 'Survey': 'Sign In Process'}.`);
                    setPassMsg(res.data.screeningPassMsg ?
                        res.data.screeningPassMsg
                        : 'Reach out to your company if you need any further instruction.');
                    setFailMsg(res.data.screeningFailMsg ?
                        res.data.screeningFailMsg :
                        'Reach out to your company if you need any further instruction.');
                    setCompanyPhone(res.data.companyPhone || '');
                }
            }).catch((err) => {
                setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
            .then((res) => {
                if (res.data._id === localStorage.getItem('CheckN.orgId')) {
                    setCompanyCountry(res.data.companyCountry);
                    setCompanyStateProv(res.data.companyStateProv);
                    setCompanyContactEmail(res.data.companyContactEmail || '');
                    setCompanyLinkInstructions(res.data.linkInstructions ?
                        res.data.linkInstructions
                        : `Please click the link to start your Check'N ${useCase === 'COVID Screening' ?
                            'Screening for COVID - 19' : useCase === 'General Survey' ? 'Survey' : 'Sign In Process'}.`);
                    setPassMsg(res.data.screeningPassMsg ?
                        res.data.screeningPassMsg
                        : 'Reach out to your company if you need any further instruction.');
                    setFailMsg(res.data.screeningFailMsg ?
                        res.data.screeningFailMsg :
                        'Reach out to your company if you need any further instruction.');
                    setCompanyPhone(res.data.companyPhone || '');
                }
            }).catch((err) => {
                setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    }, [useCase]);

    const updateGuidance = () => {
        const guidanceDetails = {
            linkInstructions: companyLinkInstructions,
            screeningPassMsg: passMsg,
            screeningFailMsg: failMsg,
            companyPhone,
            companyContactEmail,
        };
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateGuidances/', guidanceDetails, getTokenConfig())
            .then(res => {
                if (res.status === 200) {
                    setInEditMode(false);
                    setMessage({ status: 'success', msg: res.data.msg });
                    resetMessage(1800);
                } else {
                    setError({ hasError: true, message: 'Error in updating the details' });
                    resetError(1800);
                }
            }).catch(err => {
                setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
                resetError(1800);
            });
    };

    return (
        <Container fluid className="mt-3">
            {error.hasError ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            onClose={() => setError({ status: false, message: '' })}
                        >
                            <span>{error.message}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>
                : null
            }
            {message.status ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade enter={true} exit={true}>
                        <Notification
                            type={{ style: message.status, icon: true }}
                            closable={true}
                            onClose={() => setMessage({ status: '', msg: '' })}
                        >
                            <span>{message.msg}</span>
                        </Notification>
                    </Fade>
                </NotificationGroup>
                : null
            }
            <Row>
                <Col>
                    <Alert
                        variant="danger"
                        className="text-center"
                        style={{
                            boxShadow:
                                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            cursor: "pointer",
                        }}
                    >
                        <Alert.Heading>For Emergency</Alert.Heading>
                        <hr />
                        {companyStateProv && companyCountry && companyCountry !== 'US' ?
                            <div>
                                {canadaHelplineNumbers[companyStateProv] && canadaHelplineNumbers[companyStateProv].phNo.map((number, i) => (
                                    <Fragment key={'ph' + i}>
                                        <strong> {number} </strong> <br />
                                    </Fragment>
                                ))}
                            </div>
                            : <p>Please contact admin</p>
                        }
                    </Alert>
                </Col>
            </Row>
                <Divider orientation={!isMobile ? "left" : "center"}>
                    <strong>
                        {!isMobile ? "Your Provincial Government's Contact Information" :
                            <div style={{ whiteSpace: 'normal' }}>Your Provincial Government's Contact Information</div>}
                    </strong>
                </Divider>
            <Row md="12" className="text-center mt-4 mb-4">
                <Col md="4">
                    <p>
                        <u>Your Company Location</u>
                    </p>
                    <p>{companyStateProv}</p>
                </Col>
                <Col md="4">
                    <p>
                        <u>Helpline Phone Number</u>
                    </p>
                    {companyStateProv && companyCountry && companyCountry !== 'US' ?
                        <div>
                            {canadaHelplineNumbers[companyStateProv] && canadaHelplineNumbers[companyStateProv].phNo.map((number, i) => (
                                <Fragment key={'ph' + i}>
                                    <span> {number} </span> <br />
                                </Fragment>
                            ))}
                        </div>
                        : <span>Please contact admin</span>
                    }
                </Col>
                <Col md="4">
                    <p>
                        {" "}
                        <u>Government COVID-19 Website</u>
                    </p>
                    {companyStateProv && companyCountry && companyCountry !== 'US' ?
                        <div>
                            <a
                                target='_blank'
                                rel="noopener noreferrer"
                                href={canadaHelplineNumbers[companyStateProv]
                                    && canadaHelplineNumbers[companyStateProv].website}
                            >Go to the website
              </a>
                        </div>
                        : <span>Please contact admin</span>
                    }
                </Col>
            </Row>

            <Divider orientation={!isMobile ? "left" : "center"}>
                <strong>
                    {!isMobile ? "Configure Message for Employees and Visitors" :
                        <div style={{ whiteSpace: 'normal' }}>Configure Message for Employees and Visitors</div>}
                </strong>
            </Divider>
            <Row>
                <Col>
                    <label>What instructions would you like to provide in email notifications?</label>
                    <TextArea
                        style={{ width: "100%", maxWidth: "100%" }}
                        disabled={!inEditMode}
                        value={companyLinkInstructions}
                        onChange={(e) => {
                            if (e.value === '') {
                                setCompanyLinkInstructions(`Please click the link to start your Check'N ${useCase === 'COVID Screening' ?
                                    'Screening for COVID - 19' : useCase === 'General Survey' ? 'Survey' : 'Sign In Process'}.`);
                            } else {
                                setCompanyLinkInstructions(e.value);
                            }
                        }}
                        rows={2}
                    />
                    <Hint>
                        Hint: Click on the edit button to edit the message
                        or the default message will be displayed.
                    </Hint>
                    <br />
                    <label>What you want to show your employees and visitors, if they <strong>{useCase === 'COVID Screening' ? 'fail' :
                        useCase === 'General Survey' ? 'DO NOT answer' : 'ARE NOT'}</strong> {useCase === 'COVID Screening' ? 'screening' :
                        useCase === 'General Survey' ? 'the survey' : 'attending'}?</label>
                    <TextArea
                        style={{ width: "100%", maxWidth: "100%" }}
                        disabled={!inEditMode}
                        value={failMsg}
                        onChange={(e) => {
                            setFailMsg(e.value);
                        }}
                        rows={2}
                    />
                    <Hint>
                        Hint: Click on the edit button to edit the message
                        or the default message will be displayed.
                    </Hint>
                    <br />
                    <label>What you want to show your employees and visitors, if they <strong>{useCase === 'COVID Screening' ? 'pass' :
                        useCase === 'General Survey' ? 'answer' : 'ARE'}</strong> {useCase === 'COVID Screening' ? 'screening' :
                        useCase === 'General Survey' ? 'the survey' : 'attending'}?</label>
                    <TextArea
                        style={{ width: "100%", maxWidth: "100%" }}
                        disabled={!inEditMode}
                        value={passMsg}
                        onChange={(e) => {
                            setPassMsg(e.value);
                        }}
                        rows={2}
                    />
                    <Hint>
                        Hint: Click on the edit button to edit the message
                        or the default message will be displayed.
                    </Hint>
                    <br />
                    <label>Company contact number?</label>
                    <Form.Group controlId="companyContact">
                        {!inEditMode ?
                            <Form.Control
                                readOnly
                                value={companyPhone || '(+1) ___-___-____'}
                            />
                            :
                            <MaskedTextBox
                                mask="(+1) 000-000-0000"
                                placeholder="Enter company contact #"
                                value={companyPhone}
                                onChange={(e) => {
                                    if (error.hasError) {
                                        setError({ hasError: false, message: '' });
                                    }
                                    setCompanyPhone(e.value);
                                }}
                            />
                        }
                        <Hint>
                            Hint: Enter company contact number
                            if you want to show the number on results page.
                        </Hint>
                    </Form.Group>
                    <label>Company contact email address?</label>
                    <Form.Group controlId="companyContactEmail">
                        {!inEditMode ?
                            <Form.Control
                                readOnly
                                value={companyContactEmail || 'username@domain.com'}
                            />
                            :
                            <Input
                                type={"email"}
                                validator={emailValidator}
                                placeholder="Enter email address"
                                value={companyContactEmail}
                                onChange={(e) => {
                                    if (error.hasError) {
                                        setError({ hasError: false, message: '' });
                                    }
                                    setCompanyContactEmail(e.value);
                                }}
                            />
                        }
                        <Hint>
                            Hint: Enter company contact email address
                            if you want to show the email address on the results page.
                        </Hint>
                    </Form.Group>
                    {inEditMode ?
                        <Fragment>
                            <Button
                                iconClass="fas fa-times"
                                primary={true}
                                look="flat"
                                onClick={() => {
                                    resetData();
                                    setInEditMode(false);
                                }}
                            ></Button>&nbsp;
                        </Fragment>
                        : false
                    }
                    <Button
                        iconClass="far fa-edit"
                        onClick={() => setInEditMode(true)}
                    >
                    </Button>&nbsp;
                    <Button
                        iconClass="fas fa-check"
                        style={{
                            backgroundColor: '#343a40',
                            color: 'white',
                        }}
                        onClick={() => {
                            updateGuidance();
                        }}
                    ></Button>
                </Col>
            </Row>

            <Divider orientation={!isMobile ? "left" : "center"}>
                <strong>
                    {!isMobile ? "References to handle COVID-19 situation" :
                        <div style={{ whiteSpace: 'normal' }}>References to handle COVID-19 situation</div>}
                </strong>
            </Divider>
            <div style={{ paddingBottom: isMobile ? 25 : undefined }}><Row md="12" className="mt-3 mb-3">
                <Col md="12">
                    <ol>
                        <Row md="12" className="text-left">
                            <Col md="1"></Col>
                            <Col md="5">
                                <li>
                                    <i className="fab fa-canadian-maple-leaf"></i>{" "}
                                    <i className="fas fa-chevron-right"></i>{" "}
                                    <a
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        href="https://www.canada.ca/en/public-health/services/diseases/2019-novel-coronavirus-infection/symptoms.html"
                                    >
                                        Canada public-health recommendations{" "}
                                    </a>{" "}
                                </li>
                            </Col>
                            <Col md="5">
                                {" "}
                                <li>
                                    <i className="fas fa-flag-usa"></i>{" "}
                                    <i className="fas fa-chevron-right"></i>{" "}
                                    <a
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html"
                                    >
                                        CDC US recommendations{" "}
                                    </a>{" "}
                                </li>
                            </Col>
                            <Col md="1"></Col>
                        </Row>
                    </ol>
                </Col>
            </Row></div>
        </Container>
    );
};

export default Guidances;
