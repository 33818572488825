import React from "react";
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Hint } from '@progress/kendo-react-labels';
import { countryOptions } from "../../util/countryStateProvience";

const CompanyInfo = ({
    companyName,
    setCompanyName,
    companyEmail,
    setCompanyEmail,
    companyPostalCode,
    setCompanyPostalCode,
    companyPassword,
    companyConfirmPassword,
    setCompanyConfirmPassword,
    setCompanyPassword,
    companyAddress1,
    setCompanyAddress1,
    companyAddress2,
    setCompanyAddress2,
    companyCountry,
    setCompanyCountry,
    companyStateProv,
    setCompanyStateProv,
    companyCity,
    setCompanyCity,
    error,
    setError,
    tooltipOpen,
    setTooltipOpen,
}) => {
    const countryOpt = countryOptions.map(ele => ele.country);
    let stateOpt = [];
    if (companyCountry) {
        stateOpt = countryOptions.filter(ele => ele.country === companyCountry)[0].options;
    }
    return (
        <form className="k-form">
            <label className="k-form-field">
                <div style={{ marginBottom: 5 }}>Company Name</div>
                <Input
                    type={"text"}
                    placeholder="Enter Company Name"
                    value={companyName}
                    onChange={(e) => {
                        if (error.hasError) {
                            setError({ hasError: false, message: '' });
                        }
                        setCompanyName(e.value);
                    }}
                />
            </label>
            <label className="k-form-field">
                <div style={{ marginBottom: 5 }}>Company Email&nbsp;&nbsp;
                        <small>(You will use this email for sign in.)</small>
                </div>
                <Input
                    type={"text"}
                    placeholder="Enter Company Email"
                    value={companyEmail}
                    onChange={(e) => {
                        if (error.hasError) {
                            setError({ hasError: false, message: '' });
                        }
                        setCompanyEmail(e.value);
                    }}
                />
                <Hint>Hint: Enter company email address.</Hint>
            </label>
            <div className="row">
                <label className="k-form-field col-md-6">
                    <div style={{ marginBottom: 5 }}>Set Password</div>
                    <Input
                        type={"password"}
                        placeholder="Enter Password"
                        value={companyPassword}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyPassword(e.value);
                        }}
                    />
                </label>
                <label className="k-form-field col-md-6">
                    <div style={{ marginBottom: 5 }}>Confirm Password</div>
                    <Input
                        type={"password"}
                        placeholder="Confirm Password"
                        value={companyConfirmPassword}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyConfirmPassword(e.value);
                        }}
                    />
                </label>
            </div>
            <div className="row">
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>Country</div>
                    <DropDownList
                        data={countryOpt}
                        placeholder="e.g. Canada"
                        style={{ backgroundColor: 'white' }}
                        value={companyCountry}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyCountry(e.value);
                        }}
                    />
                </label>
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>Address 1</div>
                    <Input
                        type={"text"}
                        placeholder="Enter Company Address 1"
                        value={companyAddress1}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyAddress1(e.value);
                        }}
                    />
                </label>
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>Address 2</div>
                    <Input
                        type={"text"}
                        placeholder="Enter Company Address 2"
                        value={companyAddress2}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyAddress2(e.value);
                        }}
                    />
                </label>
            </div>
            <div className="row">
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>City</div>
                    <Input
                        type={"text"}
                        placeholder="Enter City"
                        value={companyCity}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyCity(e.value);
                        }}
                    />
                </label>
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>
                        {companyCountry ?
                            countryOptions.filter(ele => ele.country === companyCountry)[0].stpr
                            : 'State/Province'
                        }
                    </div>
                    <DropDownList
                        data={stateOpt}
                        placeholder="e.g. Ontario"
                        style={{ backgroundColor: 'white' }}
                        value={companyStateProv}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyStateProv(e.value);
                        }}
                    />
                </label>
                <label className="k-form-field col-md-4">
                    <div style={{ marginBottom: 5 }}>{companyCountry === 'US' ? 'Zip Code' : 'Postal Code'}</div>
                    <Input
                        type={"text"}
                        placeholder="Enter Postal Code"
                        value={companyPostalCode}
                        onChange={(e) => {
                            if (error.hasError) {
                                setError({ hasError: false, message: '' });
                            }
                            setCompanyPostalCode(e.value);
                        }}
                    />
                </label>
            </div>
        </form>
    );
};
export default CompanyInfo;
