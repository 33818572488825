/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Window } from '@progress/kendo-react-dialogs';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import sortBy from 'lodash/sortBy';
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import getTokenConfig from '../../../methods/getTokenConfig';
import '../../stylesheets/windowStyles.css';

const AddNewQuestionWindow = ({
    setShowAddNewQueWindow,
    selectedQue,
    setSelectedQue,
    setMgsType,
    resetMessageType,
    addedQuestions,
    setAddedQuestions,
    useCase,
    userGroupOptions,
}) => {
    const [queString, setQueString] = useState((selectedQue && selectedQue.questionString) || '');
    const [queStringAboveNotesField, setQueStringAboveNotesField] = useState((selectedQue && selectedQue.queStringAboveNotesField) || '');

    const [showDateWithFirstReqQue, setShowDateWithFirstReqQue] = useState(selectedQue && selectedQue.showDateWithFirstReqQue)

    const [failAnswer, setFailAnswer] = useState((selectedQue && selectedQue.failAnswer) || 'Yes');
    const [queOptions, setQueOptions] = useState((selectedQue && selectedQue.options && selectedQue.options.join(";")) || '');

    const [variableUse, setVariableUse] = useState('');
    const [siteVariableUsed, setSiteVariableUsed] = useState(selectedQue && selectedQue.questionString &&
        selectedQue.questionString.includes('${Site}'));
    // eslint-disable-next-line no-unused-vars
    const [thisWasTheQueThatUsedSiteVariable, setThisWasTheQueThatUsedSiteVariable] = useState(selectedQue && selectedQue.questionString &&
        selectedQue.questionString.includes('${Site}'));
    const [otherQuestionUsedSiteVariable, setOtherQuestionUsedSiteVariable] = useState(addedQuestions && addedQuestions.filter(que => que.questionString.includes('${Site}')).length > 0);

    const [showSiteOptionsWithNotes, setShowSiteOptionsWithNotes] = useState(selectedQue && selectedQue.showSiteOptionsWithNotes);
    const [showSiteOptions, setShowSiteOptions] = useState((selectedQue && selectedQue.questionString &&
        selectedQue.questionString.includes('${Site}')) || false);
    const [allowSiteUpdate, setAllowSiteUpdate] = useState(selectedQue && selectedQue.allowSiteUpdate);
    
    const [message, setMessage] = useState({ status: null, message: '' });
    const [edit, setEdit] = useState(false);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const [selectedGroups, setSelectedGroups] = useState((selectedQue && selectedQue.selectedGroups) || [{
        value: '000AE', label: '*ALL EMPLOYEES*',
    }, {
        value: '001AV', label: '*ALL VISITORS*',
    }]);

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    const resetMessage = (time) => {
        setTimeout(() => {
            setMessage({ status: null, message: '' });
        }, time);
    }

    const validations = () => {
        if (queString === '' || queString.trim() === '') {
            setMessage({ status: 'warning', message: 'Please enter question string' });
            resetMessage(1800);
            return true;
        }
        return false;
    }

    const addNewQueToDb = () => {
        let options = [];
        if (queOptions === '' || queOptions.trim() === '') {
            options = null;
        } else {
            options = queOptions.split(";");
        }
        const questionObj = {
            questionString: queString.trim(),
            queStringAboveNotesField: queStringAboveNotesField.trim(),
            showSiteOptionsWithNotes,
            allowSiteUpdate,
            options: options,
            useCase,
            failAnswer,
            questionType: options ? 'list' : 'Yes/No',
            selectedGroups,
        }

        const questionObjRequired = {
            questionString: queString.trim(),
            options: selectedQue ? selectedQue.options : [],
            questionType: selectedQue ? selectedQue.questionType : '',
            originalQuestionSortNumber: selectedQue ? selectedQue.sortOrder : 99999999,
            showDateWithFirstReqQue,
        }

        const addQuestionRouteToUse = selectedQue && selectedQue.required ? 'addRequiredQueForOrg' : 'addQueForOrg';

        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/' + addQuestionRouteToUse, selectedQue && selectedQue.required ?
            questionObjRequired : questionObj, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    if (selectedQue && selectedQue.required) {
                        const orgId = localStorage.getItem('CheckN.orgId');
                        axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateReqQueOrder/' + orgId, {
                            addedQuestions: addedQuestions.map(q => q._id),
                            _id: res.data._id,
                            sortOrderOfNewQue: selectedQue.sortOrder,
                        }, getTokenConfig())
                            .then(response => {
                                if (response.status === 200) {
                                    axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgRequiredQue', getTokenConfig())
                                        .then(async response => {
                                            const questions = response.data.questions;
                                            const allQuestionsNeeded = response.data.allQuestionsNeeded;
                                            await questions.forEach((que) => {
                                                que.order = allQuestionsNeeded.find(q => q._id.toString() === que._id.toString()).order;
                                            });
                                            setAddedQuestions([...sortBy(questions, ['order'])]);
                                            setMgsType({ status: 'success', message: 'Question updated successfully.' });
                                            resetMessageType(1800);
                                        }).catch((err) => {
                                            setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                            resetMessage(1800);
                                        });
                                }
                            }).catch((err) => {
                                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                resetMessage(1800);
                            })

                    } else {
                        const orgId = localStorage.getItem('CheckN.orgId');
                        axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateAddedQueOrder/' + orgId, {
                            addedQuestions: addedQuestions.map(q => q._id),
                            _id: res.data._id,
                        }, getTokenConfig())
                            .then(response => {
                                if (response.status === 200) {
                                    axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgOptionalQue', getTokenConfig())
                                        .then(response => {
                                            setAddedQuestions([...sortBy(response.data.questions.filter(q => !q.required), ['order'])]);
                                            setMgsType({ status: 'success', message: 'New question added.' });
                                            resetMessageType(1800);
                                        }).catch((err) => {
                                            setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                            resetMessage(1800);
                                        });
                                }
                            }).catch((err) => {
                                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                resetMessage(1800);
                            })
                    }
                }
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessageType(1800);
            });
    }

    const updateQue = () => {
        let options = [];
        if (queOptions === '' || queOptions.trim() === '') {
            options = null;
        } else {
            options = queOptions.split(";");
        }
        const questionObj = {
            _id: selectedQue._id,
            questionString: queString.trim(),
            queStringAboveNotesField: queStringAboveNotesField.trim(),
            showSiteOptionsWithNotes,
            allowSiteUpdate,
            options: options,
            failAnswer,
            selectedGroups,
            questionType: options ? 'list' : 'Yes/No'
        }

        const questionObjRequired = {
            _id: selectedQue._id,
            questionString: queString.trim(),
            showDateWithFirstReqQue,
        }

        const updateRouteToUse = selectedQue && selectedQue.required ? 'updateRequired/' : 'update/';

        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/' + updateRouteToUse, selectedQue && selectedQue.required ?
            questionObjRequired : questionObj, getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    if (selectedQue && selectedQue.required) {
                        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/requiredByOrg', {
                            params: {
                                addedQuestions: addedQuestions.map(que => que._id),
                            },
                            headers: getTokenConfig().headers,
                        })
                            .then(response => {
                                let returnedQuestions = [...response.data.filter(q => q.required)];
                                returnedQuestions.forEach((que) => {
                                    que.originalQuestionSortNumber = que.originalQuestionSortNumber || que.originalQuestionSortNumber === 0 ?
                                    que.originalQuestionSortNumber : que.sortOrder;
                                });
                                setAddedQuestions(returnedQuestions.sort((a, b) => {
                                    if (a.originalQuestionSortNumber > b.originalQuestionSortNumber) {
                                        return 1;
                                    } return -1;
                                }));
                                setMgsType({ status: 'success', message: 'Question updated successfully.' });
                                resetMessageType(1800);
                            }).catch((err) => {
                                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                resetMessage(1800);
                            });
                    } else {
                        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgOptionalQue', getTokenConfig())
                            .then(response => {
                                setAddedQuestions([...response.data.questions.filter(q => !q.required)]);
                                setMgsType({ status: 'success', message: 'Question updated successfully.' });
                                resetMessageType(1800);
                            }).catch((err) => {
                                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                resetMessage(1800);
                            });
                    }
                }
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessageType(1800);
            });

        setSelectedQue(null);
        setEdit(false);
        setShowAddNewQueWindow(false);
    }

    return (
        <Window
            title={selectedQue ? "Edit Question" : "Add New Yes/No Question"}
            onClose={() => {
                setSelectedQue(null);
                setShowAddNewQueWindow(false);
            }}
            initialHeight={!isMobile ? 'calc(100vh/1.5)' : '80%'}
            initialWidth={!isMobile ? 450 : !isNarrowScreen ? '70%' : '100%'}
            initialTop={isNarrowScreen || !isMobile ? 80 : 0}
            initialLeft={isMobile || isNarrowScreen ? 70 : undefined}
            style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        >
            <form className="k-form">
                <fieldset>
                    {message.status === 'warning' ?
                        <Fade
                            enter={true}
                            exit={true}>
                            {message.status !== null && <Notification
                                style={{
                                    width: '400px'
                                }}
                                type={{ style: 'error', icon: true }}
                                onClose={() => setMessage({ status: null, message: '' })}
                            >
                                <span>{message.message}</span>
                            </Notification>}
                        </Fade>
                        : null}
                    {selectedQue && selectedQue.required &&
                        <>
                            <div className="mb-2"><strong>Default Question:</strong></div>
                            <div>
                                <i>{selectedQue.originalQuestionSortNumber === 0 ? 'Are you working today?' :
                                    selectedQue.originalQuestionSortNumber === 1 ? 'Is tomorrow your next working day?' :
                                    selectedQue.originalQuestionSortNumber === 2 ? 'Please select what day you are scheduled to work next.' :
                                    'You are using the default question.'}</i><br /><br />
                                <i><b>Note:</b> The entered question must be answered with {selectedQue.originalQuestionSortNumber === 2 ||
                                selectedQue.sortOrder === 2 ? 'a date' : 'Yes/No'} regarding {selectedQue.originalQuestionSortNumber === 0 || selectedQue.sortOrder === 0 ?
                                        'the need for a survey today' : selectedQue.originalQuestionSortNumber === 1 || selectedQue.sortOrder === 1 ?
                                            'the need for a survey tomorrow' : 'the next day a survey is required'
                                }.</i>
                            </div>
                        </>}
                    {(!selectedQue || (selectedQue && !selectedQue.required)) &&
                        <label className="k-form-field">
                            <p>Department/Site<span style={{ color: 'red' }}>*</span>
                                {showSiteOptions &&
                                    <>&nbsp;
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-disabled">
                                                    <i>
                                                        The <strong>Site</strong> variable <strong>cannot</strong> be used for Visitors.
                                                    </i>
                                                </Tooltip>}
                                        >
                                            <i style={{ color: 'red' }} className="fas fa-info-circle" />
                                        </OverlayTrigger>
                                    </>}
                            </p>
                                <Select
                                    placeholder="Select A Department/Site"
                                    value={selectedGroups}
                                    isMulti
                                    options={userGroupOptions.map(userGroupOption => ({ label: userGroupOption.name, value: userGroupOption._id })).filter(option => {
                                        if (showSiteOptions) {
                                            return option.value !== '001AV';
                                        } return true;
                                    })}
                                    onChange={(selection) => {
                                        setSelectedGroups(selection);
                                        setEdit(true);
                                    }}
                                />
                        </label>}
                    <label className="k-form-field">
                        <p>{selectedQue && selectedQue.required && !selectedQue.defaultQue ? 'Modified ' : ''}Question <span style={{ color: 'red' }}>*</span></p>
                        <TextArea
                            placeholder="Question"
                            value={queString}
                            onChange={(e) => {
                                if (!otherQuestionUsedSiteVariable || (thisWasTheQueThatUsedSiteVariable && otherQuestionUsedSiteVariable)) {
                                    setQueString(e.value);
                                } else {
                                    setQueString(e.value.replace('${Site}', ''));
                                }
                                if (e.value.includes('${Site}')) {
                                    if (!otherQuestionUsedSiteVariable || (thisWasTheQueThatUsedSiteVariable && otherQuestionUsedSiteVariable)) {
                                        setShowSiteOptions(true);
                                        setSiteVariableUsed(true);
                                        setSelectedGroups(selectedGroups.filter(group => group.value !== '001AV'));
                                    }
                                } else {
                                    if (thisWasTheQueThatUsedSiteVariable) {
                                        setOtherQuestionUsedSiteVariable(false);
                                    }
                                    setSiteVariableUsed(false);
                                    setShowSiteOptions(false);
                                    setShowSiteOptionsWithNotes(false);
                                    setAllowSiteUpdate(false);
                                }
                                setEdit(true);
                            }}
                            rows={2}
                        />
                    </label>
                    {selectedQue && selectedQue.required && (selectedQue.originalQuestionSortNumber === 0 || selectedQue.sortOrder === 0) &&
                        <>
                            <label className="k-form-field">
                                <p>
                                    Would you like the current date to appear under the question?&nbsp;
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                <i>
                                                    If you select No, the date that the individual is answering the {useCase === 'COVID Screening' ? 'Screening' : 'Survey'} will not be printed on a new line after this question.
                                                </i>
                                            </Tooltip>}
                                    >
                                        <i className="fas fa-info-circle" />
                                    </OverlayTrigger>
                                </p>
                                <DropDownList
                                    data={["Yes", "No"]}
                                    placeholder="e.g. Yes"
                                    style={{ backgroundColor: 'white', width: '100%' }}
                                    value={showDateWithFirstReqQue ? "Yes" : "No"}
                                    onChange={(e) => {
                                        setEdit(true);
                                        setShowDateWithFirstReqQue(e.target.value === 'Yes');
                                    }}
                                />
                            </label>
                        </>}
                    {(!selectedQue || (selectedQue && !selectedQue.required)) && <>
                        <label className="k-form-field">
                            <p>
                                Additional Information&nbsp;
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                                <b>Site:</b>&nbsp;use when you want to confirm the site an employee is working at.
                                                <br /><br />
                                                <b>Email/Cell Phone:</b>&nbsp;use when you want to confirm if this information is still current for the employee/visitor.
                                                <br /><br />
                                                <b><u>NOTES</u></b>
                                                <br />
                                                These will be appended with a leading space.
                                                <br /><br />
                                                These can be removed by deleting them from the Question.
                                                <br /><br />
                                                Please <b>do not</b> modify the added option itself unless removing it from the Question.
                                                <br /><br />
                                                The Site option can only be used in one question.
                                            </i>
                                        </Tooltip>}
                                >
                                    <i className="fas fa-info-circle" />
                                </OverlayTrigger>
                            </p>
                            <DropDownList
                                data={!otherQuestionUsedSiteVariable && !siteVariableUsed ? ["Site", "Email", "Cell Phone"] : ["Email", "Cell Phone"]}
                                style={{ backgroundColor: 'white', width: '100%' }}
                                value={variableUse}
                                onChange={(e) => {
                                    setEdit(true);
                                    setVariableUse(e.target.value);
                                    setQueString(`${queString} \${${e.target.value}}`);
                                    if (e.target.value === 'Site') {
                                        setSiteVariableUsed(true);
                                        setShowSiteOptions(true);
                                        setSelectedGroups(selectedGroups.filter(group => group.value !== '001AV'));
                                    }
                                }}
                            />
                        </label>
                        <label className="k-form-field">
                            <p>
                                List Options&nbsp;
                                <span
                                    style={{ fontSize: 12 }}
                                >(Semicolon separated. Example - One;Two;Three)
                                </span>
                            </p>
                            <Input
                                type={"text"}
                                placeholder="Options"
                                value={queOptions}
                                onChange={(e) => {
                                    setQueOptions(e.value);
                                    setEdit(true);
                                }}
                            />
                        </label>
                        <div className="mb-2">
                            <strong>{useCase === 'COVID Screening' ? 'Answering -' : 'Select answer that will allow for notes to be added: '}</strong>
                        </div>
                        <div>
                            <strong>
                                <DropDownList
                                    data={["Yes", "No"]}
                                    placeholder="e.g. Yes"
                                    style={{ backgroundColor: 'white', width: '100%' }}
                                    value={failAnswer}
                                    onChange={(e) => {
                                        setEdit(true);
                                        setFailAnswer(e.target.value);
                                    }}
                                />
                            </strong>{useCase === 'COVID Screening' ? '  will fail screening' : ''}
                        </div>
                        <label className="k-form-field">
                            <p>
                                Question Above Notes Field&nbsp;
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                                This question will be asked above the Notes field if the user selects {failAnswer} when answering this {useCase === 'COVID Screening' ? 'Screening' : 'Survey'} question.
                                                <br /><br />
                                                This can be left blank.
                                            </i>
                                        </Tooltip>}
                                >
                                    <i className="fas fa-info-circle" />
                                </OverlayTrigger>
                            </p>
                            <TextArea
                                placeholder={!variableUse ? "Question Above Notes Field" : variableUse === 'Site' ?
                                    "ex. What site are you attending?" : variableUse === 'Email' ?
                                    "ex. What is your correct email address?" : "ex. What is your correct cell phone number?"}
                                value={queStringAboveNotesField}
                                onChange={(e) => {
                                    setQueStringAboveNotesField(e.value);
                                    setEdit(true);
                                }}
                                rows={2}
                            />
                        </label>
                        {showSiteOptions &&
                            <>
                                <label className="k-form-field">
                                <p>
                                    Show Deparment/Site Options?&nbsp;
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                <i>
                                                    If the user selects {failAnswer} when answering this {useCase === 'COVID Screening' ? 'Screening' : 'Survey'} question, the list of Sites will populate for the user to select from if you set this value to 'Yes'.
                                                    <br /><br />
                                                    If the Site that the individual is looking for is not listed - they should choose '*OTHER*' after which the Notes field will show for the user to enter that information.
                                                    <br /><br />
                                                    Otherwise, it has the ability to update the user's Site for you!
                                                </i>
                                            </Tooltip>}
                                    >
                                        <i className="fas fa-info-circle" />
                                    </OverlayTrigger>
                                </p>
                                <DropDownList
                                    data={["Yes", "No"]}
                                    placeholder="e.g. Yes"
                                    style={{ backgroundColor: 'white', width: '100%' }}
                                    value={showSiteOptionsWithNotes ? "Yes" : "No"}
                                    onChange={(e) => {
                                        setEdit(true);
                                        setShowSiteOptionsWithNotes(e.target.value === 'Yes');
                                    }}
                                />
                            </label>
                            <label className="k-form-field">
                                <p>
                                    Allow Updates to User's Site?&nbsp;
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-disabled">
                                                <i>
                                                    If the user answers that they are not working at their configured site and they choose a site that isn't '*OTHER*', it will update their profile for you should you set this to 'Yes'.
                                                </i>
                                            </Tooltip>}
                                    >
                                        <i className="fas fa-info-circle" />
                                    </OverlayTrigger>
                                </p>
                                <DropDownList
                                    data={["Yes", "No"]}
                                    placeholder="e.g. Yes"
                                    style={{ backgroundColor: 'white', width: '100%' }}
                                    value={allowSiteUpdate ? "Yes" : "No"}
                                    onChange={(e) => {
                                        setEdit(true);
                                        setAllowSiteUpdate(e.target.value === 'Yes');
                                    }}
                                />
                            </label>
                        </>}
                    </>}
                    <br />
                </fieldset>
                <div className="mt-1 mb-3" style={{ fontSize: 12 }}>
                    {!selectedQue || (selectedQue && !selectedQue.required) ?
                        'Please click "Save Layout" button to ensure newly added/modified questions are added to screenings.' :
                        'Please click "Save Custom Questions" button to save newly modified questions.'}
                </div>
                <div className="text-right">
                    <button
                        type="button"
                        className="k-button"
                        onClick={() => {
                            setSelectedQue(null);
                            setShowAddNewQueWindow(false);
                        }}
                    >Cancel
                    </button>
                    {selectedQue ?
                        <button
                            type="button"
                            className="k-button"
                            disabled={!edit || selectedGroups.length < 1 || queString.trim().length === 0}
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            onClick={() => {
                                if (!validations() && !selectedQue.defaultQue) {
                                    updateQue();
                                } else {
                                    addNewQueToDb();
                                    setShowAddNewQueWindow(false);
                                }
                            }}
                        >Update
                        </button> :
                        <button
                            type="button"
                            className="k-button"
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            disabled={!edit || selectedGroups.length < 1 || queString.trim().length === 0}
                            onClick={() => {
                                if (!validations()) {
                                    addNewQueToDb();
                                    setShowAddNewQueWindow(false);
                                }
                            }}
                        >
                            Add
                        </button>
                    }
                </div>
            </form>
        </Window>
    );
};

export default AddNewQuestionWindow;
