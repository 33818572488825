import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Divider } from 'antd';
import '../../stylesheets/kendoMaskedText.css';

const ContactDetails = ({
    inEditMode,
    contactFirstName,
    setContactFirstName,
    contactLastName,
    setContactLastName,
    contactPosition,
    setContactPosition,
    contactPhone,
    setContactPhone,
    contactEmail,
    setContactEmail,
    error,
    setError
}) => {
    return (
        <Row>
            <Col>
                <Divider orientation="left">Contact Details</Divider>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="contactFirstName">
                                <Form.Label>
                                    <strong>First Name</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'contact'}
                                    readOnly={inEditMode !== 'contact'}
                                    placeholder="Enter Contact First Name"
                                    value={contactFirstName}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setContactFirstName(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="contactLastName">
                                <Form.Label>
                                    <strong>Last Name</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'contact'}
                                    readOnly={inEditMode !== 'contact'}
                                    placeholder="Enter Contact Last Name"
                                    value={contactLastName}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setContactLastName(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="contactPosition">
                        <Form.Label>
                            <strong>Position</strong>
                        </Form.Label>
                        <Form.Control
                            plaintext={inEditMode !== 'contact'}
                            readOnly={inEditMode !== 'contact'}
                            placeholder="Enter Contact Position"
                            value={contactPosition}
                            onChange={(e) => {
                                if (error.hasError) {
                                    setError({ hasError: false, message: '' });
                                }
                                setContactPosition(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group controlId="contactPhone">
                                <Form.Label>
                                    <strong>Phone #</strong>
                                </Form.Label>
                                {inEditMode !== 'contact' ?
                                    <Form.Control
                                        plaintext
                                        readOnly
                                        value={contactPhone}
                                    />
                                    :
                                    <Fragment style={{
                                        width: '100%'
                                    }}> <br />
                                        <MaskedTextBox
                                            mask="(+1) 000-000-0000"
                                            placeholder="Enter Contact Phone #"
                                            value={contactPhone}
                                            onChange={(e) => {
                                                if (error.hasError) {
                                                    setError({ hasError: false, message: '' });
                                                }
                                                setContactPhone(e.target.value);
                                            }}
                                        />
                                    </Fragment>
                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="contactEmail">
                                <Form.Label>
                                    <strong>Email</strong>
                                </Form.Label>
                                <Form.Control
                                    plaintext={inEditMode !== 'contact'}
                                    readOnly={inEditMode !== 'contact'}
                                    placeholder="Enter Contact Email"
                                    value={contactEmail}
                                    onChange={(e) => {
                                        if (error.hasError) {
                                            setError({ hasError: false, message: '' });
                                        }
                                        setContactEmail(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
export default ContactDetails;
