import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import axios from 'axios';
import getTokenConfig from "../../../methods/getTokenConfig";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Divider } from 'antd';

const UseCaseSettings = ({
    inEditMode,
    setUseCase,
    useCase,
    setAttachmentsNeeded,
    setQRCodeVerificationNeeded,
    setInEditMode,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [addedQuestions, setAddedQuestions] = useState([]);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });

    const saveLayout = async (addedQuestionArray) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        try {
            await axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateQueOrder/' + orgId,
                addedQuestionArray.map(q => q._id), getTokenConfig());
        } catch (err) {
            setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            resetMessage(1800);
        };
    };

    const changeUseCaseQuestions = (newUseCase) => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/resetByOrg', getTokenConfig())
            .then(response => {
                const filteredQuestions = [...response.data.question.filter(q => !q.required)]
                    .filter(q => q.useCase === newUseCase ||
                        (newUseCase === 'COVID Screening' && q.useCase === '') ||
                        (newUseCase === 'COVID Screening' && !q.useCase));
                setAddedQuestions(filteredQuestions);
                saveLayout(filteredQuestions);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }

    const resetMessage = (time) => {
        setTimeout(() => {
            setMgsType({ status: null, message: '' });
        }, time);
    }

    return (
        <>
            {mgsType.status !== null ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup> : null}
            <Row>
                <Col>
                    <Divider orientation="left">Use Case Setting</Divider>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="useCase">
                                    <Form.Label>
                                        <strong>Use Case</strong>&nbsp;&nbsp;
                                        <small>(The selected Use Case Setting will enable or disable specific functionality across Check'N)</small>
                                    </Form.Label>
                                    <Form.Check
                                        type={'radio'}
                                        label={'COVID Screening'}
                                        disabled={inEditMode !== 'useCaseSettings'}
                                        onChange={(e) => {
                                            setUseCase('COVID Screening');
                                            changeUseCaseQuestions('COVID Screening');
                                        }}
                                        checked={useCase && useCase !== 'Sign In Management' && useCase !== 'General Survey'}
                                    />
                                    <Form.Check
                                        type={'radio'}
                                        label={'General Survey'}
                                        disabled={inEditMode !== 'useCaseSettings'}
                                        onChange={(e) => {
                                            setQRCodeVerificationNeeded(false);
                                            setUseCase('General Survey');
                                            changeUseCaseQuestions('General Survey');
                                        }}
                                        checked={useCase && useCase !== 'Sign In Management' && useCase !== 'COVID Screening'}
                                    />
                                    <Form.Check
                                        type={'radio'}
                                        label={'Sign In Management'}
                                        disabled={inEditMode !== 'useCaseSettings'}
                                        onChange={(e) => {
                                            setAttachmentsNeeded(false);
                                            setQRCodeVerificationNeeded(false);
                                            setUseCase('Sign In Management');
                                            changeUseCaseQuestions('Sign In Management');
                                        }}
                                        checked={useCase && useCase !== 'COVID Screening' && useCase !== 'General Survey'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default UseCaseSettings;
