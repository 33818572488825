// Setup config/headers and token
export default (token) => {
    // Headers
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    // If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    } else {
        throw new Error({ msg: 'No token found!' });
    }

    return config;
};