import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';
import { isEmpty, isNull } from "lodash";
import {
    Button as Btn,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    Form,
    Dropdown,
} from 'react-bootstrap';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Divider } from 'antd';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { filterBy } from '@progress/kendo-data-query';
import { isMobile } from 'react-device-detect';
import AddEditVisitorWindow from "./addEditVisitorWindow";
import ConfirmDialog from '../../util/confirmDialog';
import getTokenConfig from '../../../methods/getTokenConfig';
import SendVisNotificationDialog from "./sendVisNotificationDialog";
import VisitorBulkLoadDialog from "./visitorBulkLoadDialog";
import AttachmentsPreviewModal from "../../util/attachmentsPreviewModal";
import '../../stylesheets/kendoGrid.css';

const initialDataState = {
  skip: 0,
  take: 100,
};

const DetailComponent = ({ dataItem }) => (
    <Container fluid>
        <Row>
            <Col>
                <p><strong>First Name:</strong> {dataItem.firstName}</p>
                <p><strong>Last Name:</strong> {dataItem.lastName}</p>
                <p><strong>Status:</strong> {dataItem.status}</p>
            </Col>
            <Col>
                <p><strong>Cell Number:</strong> {dataItem.cellNumber}</p>
                <p><strong>Email:</strong> {dataItem.email}</p>
            </Col>
            {dataItem.qrCodeVerificationNeeded && dataItem.useCase === 'COVID Screening' && <Col>
                <p><strong>QR Code Verificaton:</strong> {dataItem.qrCodeVerification && dataItem.qrCodeVerification.vaccineVerified ? 'Verified' : 'Not Verified'}</p>
                <p><strong>QR Code Verified Date:</strong> {dataItem.qrCodeVerification && dataItem.qrCodeVerification.vaccineVerifiedDate ? dataItem.qrCodeVerification.vaccineVerifiedDate : ''}</p>
            </Col>}
        </Row>
        {dataItem.attachmentsNeeded && dataItem.useCase === 'COVID Screening' &&
            <>
                <Divider orientation="left">Attachments</Divider>
                <Row>
                    <Col>
                        {dataItem.attachments && !isEmpty(dataItem.attachments) ?
                            Object.keys(dataItem.attachments)
                                .map(attach =>
                                    <p>
                                        <strong>{attach}:</strong>&nbsp;
                                        <Btn
                                            variant="link"
                                            style={{ color: 'green' }}
                                            onClick={() => window.open(`/#/visPreview/${dataItem._id}/${dataItem.attachments[`${attach}`].id}`)}
                                        >
                                            {dataItem.attachments[`${attach}`].name}
                                        </Btn>
                                        <br />
                                        <small className="text-muted">
                                            <b>Uploaded: </b>
                                            <i>{moment(dataItem.attachments[`${attach}`].created).format('lll')}</i>
                                        </small>
                                    </p>)
                            : <p><i>No attachments added yet</i></p>
                        }
                    </Col>
                </Row>
            </>
        }
    </Container>
);

const Visitors = ({ history }) => {
    const [visitorsList, setVisitorsList] = useState([]);
    const [visitorsListDefault, setVisitorsListDefault] = useState([]);

    const [dateAccordionOpen, setDateAccordionOpen] = useState(false);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const [page, setPage] = useState(initialDataState);

    const [totalResults, setTotalResults] = useState(0);

    const [fetchingData, setFetchingData] = useState(true);

    const [showWindow, setShowWindow] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedVis, setSelectedVis] = useState(null);
    const [selectedVisitors, setSelectedVisitors] = useState([]);
    const [sendToAll, setSendToAll] = useState(false);
    const [sort, setSort] = useState([]);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });
    const [showSendDialog, setShowSendDialog] = useState(false);
    const [bulkLoadDialog, setShowBulkLoadDialog] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [previewAttachments, setPreviewAttachments] = useState([]);
    const [qrCodeVerificationNeeded, setQrCodeVerificationNeeded] = useState(false);
    const [attachmentsNeeded, setAttachmentsNeeded] = useState(false);
    const [useCase, setUseCase] = useState('');

    const [filter, setFilter] = useState();
    const [showFilterOptions, setShowFilterOptions] = useState();

    const [dateToFilterOn, setDateToFilterOn] = useState('Last Notification');

    const [afterFilter, setAfterFilter] = useState(null);
    const [beforeFilter, setBeforeFilter] = useState(null);

    const handleDateAccordionToggle = () => {
        setDateAccordionOpen(!dateAccordionOpen);
    };

    const pageChange = (event) => {
      setPage(event.page);
    };
    
    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }

        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
            setIsNarrowScreen(true);
            } else {
            setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
            setIsNarrowScreen(true);
            } else {
            setIsNarrowScreen(false);
            }
        });
    }, []);

    useEffect(() => {
        if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
            history.push('/mfaAuth');
        if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
            history.push('/paymentSetup');
        const orgId = localStorage.getItem('CheckN.orgId');
        setFetchingData(true);
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgIdCount/' + orgId, getTokenConfig())
            .then(res => {
                if (res.status === 200) {
                    setTotalResults(res.data.numOfResults);
                }
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg,
                });
            });
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
            .then(response => {
                const visData = response.data;
                visData.forEach(vis => {
                    vis.fullName = `${vis.firstName} ${vis.lastName}`;
                    vis.noOfAttachments =
                        vis.attachments ?
                            Object.keys(vis.attachments).length : 0;
                    if (vis.qrCodeVerificationNeeded && (!vis.qrCodeVerification ||
                        (vis.qrCodeVerification && !vis.qrCodeVerification.vaccineVerified))) {
                        vis.qrCodeVerification = {
                            vaccineVerified: false,
                        }
                    }
                });
                if (isNull(afterFilter) && isNull(beforeFilter)) {
                    setVisitorsList([...visData]);
                } else {
                    dateFiltering('visChange', null, [...visData]);
                }
                setVisitorsListDefault([...visData]);
                setQrCodeVerificationNeeded(visData && visData[0] ? visData[0].qrCodeVerificationNeeded : false);
                setAttachmentsNeeded(visData && visData[0] ? visData[0].attachmentsNeeded : false);
                setUseCase(visData && visData[0] ? visData[0].useCase : 'COVID Screening');
                setFetchingData(false);
            }).catch((error) => {
                setMgsType({
                    status: 'error',
                    message: error.response && error.response.data && error.response.data.msg
                });
            });
        if (mgsType.status !== null) {
            setTimeout(() => {
                setMgsType({ status: null, message: '' })
            }, 2500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, mgsType, dateToFilterOn, afterFilter, beforeFilter]);

    const expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;
        setForceUpdate(!forceUpdate);
    };

    const dateFiltering = (filterOn, dateValue = null, visList = []) => {
        if (filterOn === 'visChange') {
            let dateToFilterOnOverride;
            if (!isNull(dateValue)) {
                dateToFilterOnOverride = dateValue;
            } else {
                dateToFilterOnOverride = dateToFilterOn;
            }
            setVisitorsList(
                [...visList
                    .filter(visData => {
                        let filterValue;
                        if (dateToFilterOnOverride === 'Last Notification') {
                            if (visData.lastNotificationObj && visData.lastNotificationObj.createdAt) {
                                filterValue = new Date(visData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOnOverride === 'Visitor Created') {
                            filterValue = new Date(visData.createdAt);
                        } else if (dateToFilterOnOverride === 'Visitor Updated') {
                            filterValue = new Date(visData.updatedAt);
                        }
                        if (afterFilter && isNull(beforeFilter)) {
                            return filterValue > afterFilter;
                        } else if (beforeFilter && isNull(afterFilter)) {
                            return filterValue < beforeFilter;
                        } else if (afterFilter && beforeFilter) {
                            return filterValue > afterFilter &&
                                filterValue < beforeFilter;
                        } return false;
                    })
                ]
            );
        } else if ((beforeFilter && filterOn === 'after') || (afterFilter && filterOn === 'before')) {
            setVisitorsList(
                [...visitorsListDefault
                    .filter(visData => {
                        let filterValue;
                        if (dateToFilterOn === 'Last Notification') {
                            if (visData.lastNotificationObj && visData.lastNotificationObj.createdAt) {
                                filterValue = new Date(visData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOn === 'Visitor Created') {
                            filterValue = new Date(visData.createdAt);
                        } else if (dateToFilterOn === 'Visitor Updated') {
                            filterValue = new Date(visData.updatedAt);
                        }
                        if (filterOn === 'after') {
                            return filterValue > dateValue
                                && filterValue < beforeFilter;
                        } else if (filterOn === 'before') {
                            return filterValue < dateValue
                                && filterValue > afterFilter;
                        } return false;
                    })
                ]
            );
        } else {
            setVisitorsList(
                [...visitorsListDefault
                    .filter(visData => {
                        let filterValue;
                        if (dateToFilterOn === 'Last Notification') {
                            if (visData.lastNotificationObj && visData.lastNotificationObj.createdAt) {
                                filterValue = new Date(visData.lastNotificationObj.createdAt);
                            } else {
                                return false;
                            }
                        } else if (dateToFilterOn === 'Visitor Created') {
                            filterValue = new Date(visData.createdAt);
                        } else if (dateToFilterOn === 'Visitor Updated') {
                            filterValue = new Date(visData.updatedAt);
                        }
                        if (filterOn === 'after') {
                            return filterValue > dateValue;
                        } else if (filterOn === 'before') {
                            return filterValue < dateValue;
                        } return false;
                    })
                ]
            );
        }
    };

    const deleteVisitor = () => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/delete', { id: selectedVis._id }, getTokenConfig())
            .then((response) => {
                const orgId = localStorage.getItem('CheckN.orgId');
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'Visitor record deleted successfully.' });
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in deleting visitor record.' });
                    });
                setSelectedVis(null);
            }).catch((error) => {
                setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
            });
    };

    const deleteFilteredVisitors = () => {
        if (selectedVisitors.length > 0) {
            selectedVisitors.forEach((vis) => {
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/delete', { id: vis._id }, getTokenConfig())
                    .catch((error) => {
                        setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
                    });
            });
            const orgId = localStorage.getItem('CheckN.orgId');
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
                .then(response => {
                    setMgsType({ status: 'success', message: 'Visitor record(s) deleted successfully.' });
                }).catch((error) => {
                    setMgsType({ status: 'error', message: 'Error in deleting visitor record(s).' });
                });
            setSelectedVisitors([]);
        };
    };

    const changeAllVisitorsStatus = (newStatus) => {
        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/changeAllStatus', { newStatus: newStatus }, getTokenConfig())
            .then((response) => {
                const orgId = localStorage.getItem('CheckN.orgId');
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'Visitors status updated successfully.' });
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in updating visitors status.' });
                    });
            }).catch((error) => {
                setMgsType({ status: 'error', message: error.response && error.response.data && error.response.data.msg });
            });
    };

    const changeVisitorsStatus = async ({ newStatus, selectedVisitorIds }) => {
        if (selectedVisitorIds.length > 0) {
            let error = false;
            selectedVisitorIds.forEach(async (visitorId) => {
                const visitorToUpdate = {
                    _id: visitorId,
                    newStatus,
                }
                try {
                    await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/changeStatus', visitorToUpdate, getTokenConfig());
                } catch (err) {
                    error = true;
                };
            });
            const orgId = localStorage.getItem('CheckN.orgId');
            try {
                await axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/byOrgId/' + orgId, getTokenConfig());
            } catch (err) {
                error = true;
            }
            if (!error) {
                setMgsType({ status: 'success', message: 'Visitors status updated successfully.' });
            } else {
                setMgsType({ status: 'error', message: 'Error in updating visitors status.' });
            }
        };
    };

    const sendEmailNotification = () => {
        if (sendToAll) {
            const totalEmailNotifications = visitorsList.filter(visit => visit.status !== 'Inactive' && visit.email !== '').length;
            let error = false;
            if (totalEmailNotifications === 0) {
                error = true;
            }
            if (!error) {
                visitorsList
                    .filter(visit => visit.status !== 'Inactive' && visit.email !== '')
                    .forEach(async (visitor, i) => {
                        const notificationObj = {
                            visitorId: visitor._id,
                            organizationId: visitor.organizationId,
                            type: 'email',
                            email: visitor.email,
                            count: i + 1,
                            total: totalEmailNotifications,
                            method: 'everyone',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedVisitors.length > 0) {
            const totalEmailNotifications = selectedVisitors.filter(visit => visit.status !== 'Inactive' && visit.email !== '').length;
            let error = false;
            if (totalEmailNotifications === 0) {
                error = true;
            }
            if (!error) {
                selectedVisitors
                    .filter(visit => visit.status !== 'Inactive' && visit.email !== '')
                    .forEach(async (visitor, i) => {
                        const notificationObj = {
                            visitorId: visitor._id,
                            organizationId: visitor.organizationId,
                            type: 'email',
                            email: visitor.email,
                            count: i + 1,
                            total: totalEmailNotifications,
                            method: 'filtered',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSelectedVisitors([]);
        } else {
            const notificationObj = {
                visitorId: selectedVis._id,
                organizationId: selectedVis.organizationId,
                type: 'email',
                email: selectedVis.email,
                count: 1,
                total: 1,
                method: 'single',
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendEmailNotification',
                notificationObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Email sent successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedVis(null);
        }
    };

    const scheduleEmailNotification = async (notificationDateTime) => {
        if (sendToAll) {
            const totalEmailNotifications = visitorsList.filter(visit => visit.status !== 'Inactive' && visit.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    visitorIds: visitorsList
                        .filter(visit => visit.status !== 'Inactive' && visit.email !== '')
                        .map(visit => visit._id),
                    method: 'scheduleEveryone',
                    notificationDateTime,
                };
                await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedVisitors.length > 0) {
            const totalEmailNotifications = selectedVisitors.filter(visit => visit.status !== 'Inactive' && visit.email !== '').length;
            let error = false;
            error = totalEmailNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    visitorIds: selectedVisitors
                        .filter(visit => visit.status !== 'Inactive' && visit.email !== '')
                        .map(visit => visit._id),
                    method: 'scheduledFiltered',
                    notificationDateTime,
                };
                await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalEmailNotifications} Email${totalEmailNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSelectedVisitors([]);
        } else {
            const notificationsToScheduleObj = {
                visitorIds: [selectedVis._id],
                method: 'scheduledSingle',
                notificationDateTime,
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleEmailNotification',
                notificationsToScheduleObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Email scheduled successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedVis(null);
        }
    };

    const sendTextNotification = () => {
        if (sendToAll) {
            const totalTextNotifications = visitorsList.filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '').length;
            let error = false;
            if (totalTextNotifications === 0) {
                error = true;
            }
            if (!error) {
                visitorsList
                    .filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '')
                    .forEach(async (visitor, i) => {
                        const notificationObj = {
                            visitorId: visitor._id,
                            organizationId: visitor.organizationId,
                            type: 'text',
                            phone: visitor.cellNumber,
                            count: i + 1,
                            total: totalTextNotifications,
                            method: 'everyone',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedVisitors.length > 0) {
            const totalTextNotifications = selectedVisitors.filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '').length;
            let error = false;
            if (totalTextNotifications === 0) {
                error = true;
            }
            if (!error) {
                selectedVisitors
                    .filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '')
                    .forEach(async (visitor, i) => {
                        const notificationObj = {
                            visitorId: visitor._id,
                            organizationId: visitor.organizationId,
                            type: 'text',
                            phone: visitor.cellNumber,
                            count: i + 1,
                            total: totalTextNotifications,
                            method: 'filtered',
                        };
                        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                            notificationObj,
                            getTokenConfig())
                            .catch((err) => {
                                error = true;
                            });
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} sent successfully.`
                });
            }
            setSelectedVisitors([]);
        } else {
            const notificationObj = {
                visitorId: selectedVis._id,
                organizationId: selectedVis.organizationId,
                type: 'text',
                phone: selectedVis.cellNumber,
                count: 1,
                total: 1,
                method: 'single',
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/sendTextNotification',
                notificationObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Text sent successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedVis(null);
        }
    };

    const scheduleTextNotification = (notificationDateTime) => {
        if (sendToAll) {
            const totalTextNotifications = visitorsList.filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    visitorIds: visitorsList
                        .filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '')
                        .map(visit => visit._id),
                    method: 'scheduleEveryone',
                    notificationDateTime,
                };
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSendToAll(false);
        } else if (selectedVisitors.length > 0) {
            const totalTextNotifications = selectedVisitors.filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '').length;
            let error = false;
            error = totalTextNotifications === 0;
            if (!error) {
                const notificationsToScheduleObj = {
                    visitorIds: selectedVisitors.filter(visit => visit.status !== 'Inactive' && visit.cellNumber !== '')
                        .map(visit => visit._id),
                    method: 'scheduledFiltered',
                    notificationDateTime,
                };
                axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                    notificationsToScheduleObj,
                    getTokenConfig())
                    .catch((err) => {
                        error = true;
                    });
            }
            if (error) {
                setMgsType({
                    status: 'error',
                    message: 'Something went wrong.'
                });
            } else {
                setMgsType({
                    status: 'success',
                    message: `${totalTextNotifications} Text${totalTextNotifications > 1 ? 's' : ''} scheduled successfully.`
                });
            }
            setSelectedVisitors([]);
        } else {
            const notificationsToScheduleObj = {
                visitorIds: [selectedVis._id],
                method: 'scheduledSingle',
                notificationDateTime,
            };
            axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/notifications/scheduleTextNotification',
                notificationsToScheduleObj,
                getTokenConfig())
                .then((res) => {
                    if (res.status === 200)
                        setMgsType({
                            status: 'success',
                            message: 'One Text scheduled successfully.'
                        });
                }).catch((err) => {
                    setMgsType({
                        status: 'error',
                        message: 'Something went wrong.'
                    });
                });
            setSelectedVis(null);
        }
    };

    return (
        <div>
            <Card style={{ marginBottom: 15 }}>
                <Card.Header>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Visitors</span>
                </Card.Header>
            </Card>
            {mgsType.status !== 'warning' &&
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup>}

            <div style={{ padding: 15 }}>
                <Accordion defaultActiveKey="1" className="mb-3">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }} onClick={() => handleDateAccordionToggle()}>
                            <strong>Date Filtering</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Container>
                                    <Row md={12}>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>Filter on Date of:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DropDownList
                                                    value={dateToFilterOn}
                                                    data={["Last Notification", "Visitor Created", "Visitor Updated"]}
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => {
                                                        setDateToFilterOn(e.value);
                                                        if (!isNull(afterFilter) || !isNull(beforeFilter)) {
                                                            dateFiltering('visChange', e.value);
                                                        }
                                                    }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>On/After:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={afterFilter}
                                                    onChange={(event) => {
                                                        setAfterFilter(event.target.value);
                                                        dateFiltering('after', event.target.value);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    <strong>By/Before:</strong>
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    value={beforeFilter}
                                                    onChange={(event) => {
                                                        setBeforeFilter(event.target.value);
                                                        dateFiltering('before', event.target.value);
                                                    }}
                                                />&nbsp;
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setBeforeFilter(null);
                                                    }}
                                                ></Button>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Form.Label>
                                                    &nbsp;
                                                </Form.Label>
                                            </Row>
                                            <Row>
                                                <Button
                                                    iconClass="fas fa-times"
                                                    onClick={() => {
                                                        setAfterFilter(null);
                                                        setBeforeFilter(null);
                                                        setVisitorsList(visitorsListDefault);
                                                    }}
                                                >&nbsp;Clear Dates</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <div style={{ paddingBottom: isMobile ? 50 : undefined }}><Card>
                    <Card.Header>
                        <Row>
                            <Col md="auto"><strong>Visitor List</strong></Col>
                            {fetchingData && <Col style={{ color: 'red' }} className="text-right"><strong>FETCHING DATA! Please wait...</strong></Col>}
                            <Col style={{ display: 'flex', justifyContent: 'right' }} className="input-group">
                                    <Dropdown id="actions">
                                        <Dropdown.Toggle name="history">
                                            {filterBy(visitorsList, filter).length === visitorsListDefault.length ? 'All' : 'Filtered'} Visitors Actions
                                        </Dropdown.Toggle>
                                        {filterBy(visitorsList, filter).length === visitorsListDefault.length ?
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    disabled={visitorsListDefault.filter(vis => vis.status !== 'Active').length === 0}
                                                    onClick={() => {
                                                        changeAllVisitorsStatus('Active');
                                                    }}
                                                >
                                                    <i className="fas fa-check-circle" style={{ color: 'green' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Active
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    disabled={visitorsListDefault.filter(vis => vis.status !== 'Inactive').length === 0}
                                                    onClick={() => changeAllVisitorsStatus('Inactive')}
                                                >
                                                    <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Inactive
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        :
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                disabled={filterBy(visitorsList, filter).filter(vis => vis.status !== 'Active').length === 0}
                                                onClick={() => {
                                                    changeVisitorsStatus({
                                                        newStatus: 'Active',
                                                        selectedVisitorIds: filterBy(visitorsList, filter).map(vis => vis._id),
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-check-circle" style={{ color: 'green' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Active
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={filterBy(visitorsList, filter).filter(vis => vis.status !== 'Inactive').length === 0}
                                                onClick={() => {
                                                    changeVisitorsStatus({
                                                        newStatus: 'Inactive',
                                                        selectedVisitorIds: filterBy(visitorsList, filter).map(vis => vis._id),
                                                    });
                                                }}
                                            >
                                                <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>&nbsp;&nbsp;&nbsp;Set Status to Inactive
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={filterBy(visitorsList, filter).length === 0}
                                                onClick={() => {
                                                    setShowDeleteDialog(true);
                                                    setSelectedVisitors(filterBy(visitorsList, filter));
                                                }}
                                            >
                                                <i className="far fa-trash-alt" style={{ color: '#B01C2E' }}></i>&nbsp;&nbsp;&nbsp;Delete Visitor(s)
                                            </Dropdown.Item>
                                        </Dropdown.Menu>}
                                </Dropdown>
                                &nbsp;
                                <Button
                                    icon={!showFilterOptions ? "filter" : "filter-clear"}
                                    onClick={() => {
                                        setFilter(null);
                                        setShowFilterOptions(!showFilterOptions);
                                    }}
                                    primary
                                ></Button>
                                &nbsp;
                                <Button
                                    title="Add new"
                                    className="k-button k-primary"
                                    onClick={() => setShowWindow(true)}
                                >
                                    <strong>&nbsp;Add New&nbsp;</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    {showWindow &&
                        <AddEditVisitorWindow
                            setShowWindow={setShowWindow}
                            setVisitorsList={setVisitorsList}
                            selectedVis={selectedVis}
                            setSelectedVis={setSelectedVis}
                            setMgsType={setMgsType}
                            qrCodeVerificationNeeded={qrCodeVerificationNeeded}
                        />}
                    {showDeleteDialog &&
                        <ConfirmDialog
                            visible={showDeleteDialog}
                            setVisible={setShowDeleteDialog}
                            title="Delete Visitor(s)"
                            message={`Are you sure you want to delete ${selectedVisitors.length === 0 ? 'this' : 'these'} visitor${selectedVisitors.length > 0 ? 's' : ''}?`}
                            onConfirm={() => {
                                if (selectedVisitors.length === 0) {
                                    deleteVisitor();
                                } else {
                                    deleteFilteredVisitors();
                                }
                            }}
                            onCancel={() => {
                                setSelectedVis(null);
                                setSelectedVisitors([]);
                            }}
                        />}
                    {showSendDialog &&
                        <SendVisNotificationDialog
                            visible={showSendDialog}
                            setVisible={setShowSendDialog}
                            disableEmail={(selectedVis && selectedVis.email === '') || (selectedVisitors &&
                                selectedVisitors.length > 0 && selectedVisitors.filter(vis => vis.email !== '').length === 0)}
                            disableText={(selectedVis && selectedVis.cellNumber === '') || (selectedVisitors &&
                                selectedVisitors.length > 0 && selectedVisitors.filter(vis => vis.cellNumber !== '').length === 0)}
                            setSelectedVis={setSelectedVis}
                            setSelectedVisitors={setSelectedVisitors}
                            setSendToAll={setSendToAll}
                            sendEmail={sendEmailNotification}
                            scheduleEmail={scheduleEmailNotification}
                            sendText={sendTextNotification}
                            scheduleText={scheduleTextNotification}
                        />}
                    {bulkLoadDialog &&
                        <VisitorBulkLoadDialog
                            setShowBulkLoadDialog={setShowBulkLoadDialog}
                            setVisitorsList={setVisitorsList}
                            setMgsType={setMgsType}
                        />}
                    {previewAttachments.length !== 0 &&
                        <AttachmentsPreviewModal
                            previewAttachments={previewAttachments}
                            setPreviewAttachments={setPreviewAttachments}
                        />}
                    <Grid
                        style={{
                            height: !dateAccordionOpen ? 'calc(100vh - 222px - 123px)' : 'calc(100vh - 222px - 230px)',
                            minHeight: 400,
                        }}
                        filterable={showFilterOptions}
                        filter={filter}
                        onFilterChange={(e) => {
                            if (filterBy(orderBy([...visitorsList], sort), filter).length < page.skip) {
                                setPage({ skip: Math.floor(filterBy(orderBy([...visitorsList], sort), filter).length / page.take) * page.take, take: page.take });
                            }
                            setFilter(e.filter);
                        }}
                        data={filterBy(orderBy([...visitorsList], sort), filter).slice(page.skip, page.take + page.skip)}
                        skip={page.skip}
                        take={page.take}
                        total={filterBy(orderBy([...visitorsList], sort), filter).length}
                        pageable={{ pageSizes: [50, 100, 250] }}
                        pageSize={page.pageSize}
                        onPageChange={pageChange}
                        sortable={{
                            allowUnsort: true,
                            mode: 'multiple',
                        }}
                        sort={sort}
                        onSortChange={(event) => {
                            setSort(event.sort);
                        }}
                        reorderable
                        detail={DetailComponent}
                        expandField="expanded"
                        onExpandChange={(event) => expandChange(event)}
                    >
                        {isNarrowScreen && <Column
                            field="fullName"
                            title="Name"
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={props.style}>
                                    {filterBy(visitorsList, filter).length !== visitorsListDefault.length && 'Filtered '}Total: {filterBy(visitorsList, filter).length !== visitorsListDefault.length ? filterBy(visitorsList, filter).length : totalResults}
                                </td>
                            )}
                        />}
                        {!isNarrowScreen && <Column
                            field="firstName"
                            title="First Name"
                            cell={props => (
                                <td>
                                    {!isMobile && <i className="far fa-user" />}
                                    {!isMobile && <>&nbsp;&nbsp;&nbsp;</>}{props.dataItem['firstName']}
                                </td>
                            )}
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={props.style}>
                                    {filterBy(visitorsList, filter).length !== visitorsListDefault.length && 'Filtered '}Total: {filterBy(visitorsList, filter).length !== visitorsListDefault.length ? filterBy(visitorsList, filter).length : totalResults}
                                </td>
                            )}
                        />}
                        {!isNarrowScreen && <Column field="lastName" title="Last Name" />}
                        {!isNarrowScreen && <Column
                            field="status"
                            title="Status"
                            cell={props => {
                                const value = props.dataItem[props.field];
                                let icon = <i className="k-icon"></i>;
                                if (value === 'Active') {
                                    icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
                                } else if (value === 'Inactive') {
                                    icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
                                }
                                return (
                                    <td>
                                        {!isMobile && !isNarrowScreen && icon}
                                        {!isMobile && !isNarrowScreen && <>&nbsp;&nbsp;&nbsp;</>}{props.dataItem[props.field]}
                                    </td>
                                );
                            }}
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            field="cellNumber"
                            title="Phone #"
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            field="email"
                            title="Email"
                        />}
                        {!isMobile && !isNarrowScreen && qrCodeVerificationNeeded && useCase === 'COVID Screening' && <Column field="qrCodeVerification.vaccineVerified"
                            title="Vaccine Verified"
                            filter="boolean"
                            cell={props => {
                                let label = ''
                                if (props.dataItem && props.dataItem.qrCodeVerification && props.dataItem.qrCodeVerification.vaccineVerified) {
                                    label = 'Verified'
                                }
                                return (
                                    <td>
                                        {label}
                                    </td>
                                );
                            }}
                        />}
                        {!isMobile && !isNarrowScreen && attachmentsNeeded && useCase === 'COVID Screening' && <Column
                            field="noOfAttachments"
                            title="Attachments"
                            cell={props => {
                                const value = props.dataItem[props.field];
                                return (
                                    <td>
                                        {value === 0 ?
                                            <p
                                                style={{
                                                    margin: 0,
                                                    paddingLeft: 12,
                                                }}
                                            >{value}
                                            </p> :
                                            <Btn
                                                variant="link"
                                                onClick={() => {
                                                    const attachmentsList = Object.values(props.dataItem['attachments'])
                                                    attachmentsList.forEach(att => att.visId = props.dataItem['_id']);
                                                    setPreviewAttachments(attachmentsList);
                                                }}
                                            >
                                                {value}
                                            </Btn>
                                        }
                                    </td>
                                );
                            }}
                        />}
                        {!isMobile && !isNarrowScreen && <Column
                            filterable={false}
                            field="lastNotificationObj.createdAt"
                            title="Last Notification"
                            cell={props => {
                                if (props.dataItem.lastNotificationObj && props.dataItem.lastNotificationObj.createdAt) {
                                    let backColor = "";
                                    if (props.dataItem.lastNotificationObj && props.dataItem.lastNotificationObj.completed)
                                        backColor = "#cce5ff";
                                    else if (!props.dataItem.lastNotificationObj.completed)
                                        backColor = "#fff3cd";

                                    return (
                                        <td style={{ backgroundColor: backColor }}>
                                            <strong>Date:&nbsp;</strong>
                                            {moment(props.dataItem.lastNotificationObj["createdAt"]).format('L')}
                                            <br />
                                            <strong>Time:&nbsp;</strong>
                                            {moment(props.dataItem.lastNotificationObj["createdAt"]).format('LT')}
                                            {props.dataItem.lastNotificationObj["error"] &&
                                                <small style={{ color: '#B01C2E' }}>
                                                    <br />
                                                    <strong>Error:&nbsp;</strong>
                                                    <i>{props.dataItem.lastNotificationObj["error"]}</i>
                                                </small>}
                                        </td>
                                    );
                                } else {
                                    return (<td />);
                                }
                            }}
                            footerCell={(props) => (
                                <td colSpan={props.colSpan} style={{ ...props.style, fontSize: '12px' }}>
                                    <i
                                        className="far fa-square"
                                        style={{ backgroundColor: '#cce5ff' }}
                                    /> &nbsp;Survey Submitted <br />
                                    <i
                                        className="far fa-square"
                                        style={{ backgroundColor: '#fff3cd' }}
                                    /> &nbsp;Survey Not Submitted
                                </td>
                            )}
                        />}
                        <Column
                            filterable={false}
                            width="200px"
                            headerCell={(props) => (
                                <div className="text-center">
                                    {filterBy(visitorsList, filter).length === visitorsListDefault.length ?
                                        <Button
                                            disabled={visitorsListDefault.filter(vis => vis.status !== 'Inactive').length === 0}
                                            onClick={() => {
                                                setSendToAll(true);
                                                setShowSendDialog(true);
                                            }}
                                        >
                                            Notify Everyone
                                        </Button> :
                                        <Button
                                            disabled={filterBy(visitorsList, filter).filter(vis => vis.status !== 'Inactive').length === 0}
                                            onClick={() => {
                                                setSelectedVisitors(filterBy(visitorsList, filter).filter(vis => vis.status !== 'Inactive'));
                                                setShowSendDialog(true);
                                            }}
                                        >
                                            Notify Filtered
                                        </Button>}
                                </div>
                            )}
                            cell={props => (
                                <td>
                                    <ButtonGroup >
                                        <Button
                                            iconClass="far fa-edit"
                                            onClick={() => {
                                                setSelectedVis(props.dataItem);
                                                setShowWindow(true);
                                            }}
                                        ></Button>
                                        <Button
                                            iconClass="far fa-trash-alt"
                                            style={{
                                                backgroundColor: '#B01C2E',
                                                borderColor: '#B01C2E',
                                                color: 'white',
                                            }}
                                            onClick={() => {
                                                setSelectedVis(props.dataItem);
                                                setShowDeleteDialog(true);
                                            }}
                                        ></Button>
                                    </ButtonGroup>
                                    <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>
                                    <Button
                                        disabled={props.dataItem.status === 'Inactive'}
                                        onClick={() => {
                                            setSelectedVis(props.dataItem);
                                            setShowSendDialog(true);
                                        }}
                                    >
                                        Notify
                                    </Button>
                                </td>
                            )}
                            footerCell={(props) => {
                                if (isMobile || isNarrowScreen) {
                                    return null;
                                }
                                return (<td colSpan={props.colSpan} style={props.style} className="text-center">
                                    <Button
                                        onClick={() => {
                                            setShowBulkLoadDialog(true);
                                        }}
                                    >
                                        Upload Visitors
                                    </Button>
                                </td>);
                            }}
                        />
                    </Grid>
                </Card></div>
            </div>
        </div>
    );
};

export default Visitors;
