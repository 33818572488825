import React, { useEffect, useState } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import {
  Card,
  Dropdown,
} from 'react-bootstrap';
import axios from 'axios';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import QuestionsSetup from "./questionsSetupTemp";
import UserGroupsSetup from "./userGroupsSetup";
import RequiredQuestionsSetup from "./requiredQuestionsSetup";
import AccountSettings from "./accountSettings";
import Guidances from "./guidanceSetup";
import QrCodePrint from "./qrCodePrint";
import getTokenConfig from "../../../methods/getTokenConfig";
import SuperUserView from "./superUserView";

const SettingsMain = ({ history }) => {
  const [selected, setSelected] = useState(0);
  const [useCase, setUseCase] = useState('');
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);

  const [settingsPage, setSettingsPage] = useState('account');

  const [isNarrowScreen, setIsNarrowScreen] = useState(false);

  const [error, setError] = useState({ hasError: false, message: '' });

  const resetError = (time) => {
    setTimeout(() => {
      setError({ hasError: false, message: '' });
    }, time);
  };

  useEffect(() => {
      let initialWidth = document.documentElement.clientWidth;
      if (initialWidth < 700) {
        setIsNarrowScreen(true);
      } else {
        setIsNarrowScreen(false);
      }

      window.addEventListener('resize', (event) => {
          let gridWidth = document.documentElement.clientWidth;
          if (gridWidth < 700) {
            setIsNarrowScreen(true);
          } else {
            setIsNarrowScreen(false);
          }
      });
      return () => window.removeEventListener('resize', (event) => {
          let gridWidth = document.documentElement.clientWidth;
          if (gridWidth < 700) {
            setIsNarrowScreen(true);
          } else {
            setIsNarrowScreen(false);
          }
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('CheckN.mfaAuthenticated') === 'false')
      history.push('/mfaAuth');
    if (localStorage.getItem('CheckN.paymentMethodAdded') === 'false')
      history.push('/paymentSetup');
    axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/organization', getTokenConfig())
      .then((res) => {
        if (res.data._id === localStorage.getItem('CheckN.orgId')) {
          setIsSuperUser(res.data.isSuperUser);
          setIsAdminUser(res.data.isAdminUser);
        }
        setUseCase(res.data.useCase);
      }).catch((err) => {
        setError({ hasError: true, message: err.response && err.response.data && err.response.data.msg });
        resetError(1800);
      });
  }, [history]);

  return (
    <div>
      <Card style={{ marginBottom: 15 }}>
        <Card.Header>
          {!isNarrowScreen && !isMobile ? <span style={{ fontSize: "20px", fontWeight: "bold" }}>Settings</span> :
            <Dropdown id="actions">
              <Dropdown.Toggle name="history">
                  Settings
              </Dropdown.Toggle>
              <Dropdown.Menu>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('account');
                      }}
                  >
                      Account
                      {settingsPage === 'account' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('groups');
                      }}
                  >
                      Group Setup
                      {settingsPage === 'groups' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('preQuestions');
                      }}
                  >
                      Pre-{useCase === 'COVID Screening' ? "Screening " : useCase === 'General Survey' ? 'Survey ' : 'Entry '}Questions
                      {settingsPage === 'preQuestions' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('questionnaire');
                      }}
                  >
                      Questionnaire
                      {settingsPage === 'questionnaire' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('guidance');
                      }}
                  >
                      Guidance
                      {settingsPage === 'guidance' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('qrCodePrint');
                      }}
                  >
                      QR Code Print
                      {settingsPage === 'qrCodePrint' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>
                  {(isSuperUser || isAdminUser) && <Dropdown.Item
                      onClick={() => {
                          setSettingsPage('superUserPanel');
                      }}
                  >
                      Super User Panel
                      {settingsPage === 'superUserPanel' && <>
                          &nbsp;&nbsp;&nbsp;
                          <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          </>}
                  </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>}
        </Card.Header>
      </Card>
      {error.hasError ?
        <NotificationGroup
          style={{
            right: 0,
            top: 0,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse'
          }}
        >
          <Fade enter={true} exit={true}>
            <Notification
              type={{ style: 'error', icon: true }}
              closable={true}
              onClose={() => setError({ hasError: false, message: '' })}
            >
              <span>{error.message}</span>
            </Notification>
          </Fade>
        </NotificationGroup>
        : null
      }
      <div style={{ padding: 15, backgroundColor: !isMobile ? "#f8f9fa" : undefined }}>
        {!isNarrowScreen && !isMobile && <TabStrip
          animation={false}
          selected={selected}
          onSelect={(e) => setSelected(e.selected)}
        >
          <TabStripTab title={<strong>Account</strong>}>
            <div style={{ height: 'calc(100vh - 275px)' }}>
              <AccountSettings
                useCase={useCase}
                setUseCase={setUseCase}
                history={history}
              />
            </div>
          </TabStripTab>
          <TabStripTab title={<strong>Department/Site Setup</strong>}>
            <div style={{ height: "calc(100vh - 275px)" }}>
              <UserGroupsSetup useCase={useCase} />
            </div>
          </TabStripTab>
          <TabStripTab title={<strong>Pre-{useCase === 'COVID Screening' ? "Screening " : useCase === 'General Survey' ? 'Survey ' : 'Entry '}Questions</strong>}>
            <div style={{ height: "calc(100vh - 275px)" }}>
              <RequiredQuestionsSetup useCase={useCase} />
            </div>
          </TabStripTab>
          <TabStripTab title={<strong>Questionnaire</strong>}>
            <div style={{ height: "calc(100vh - 275px)" }}>
              <QuestionsSetup useCase={useCase}/>
            </div>
          </TabStripTab>
          <TabStripTab title={<strong>Guidance</strong>}>
            <div style={{ height: "calc(100vh - 275px)" }}>
              <Guidances useCase={useCase} />
            </div>
          </TabStripTab>
          <TabStripTab title={<strong>QR Code Print</strong>}>
            <div style={{ height: "calc(100vh - 275px)" }}>
              <QrCodePrint />
            </div>
          </TabStripTab>
          {isSuperUser || isAdminUser ?
            <TabStripTab title={<strong>Super User Panel</strong>}>
              <div style={{ height: "calc(100vh - 275px)" }}>
                <SuperUserView isSuperUser={isSuperUser} />
              </div>
            </TabStripTab>
            : null
          }
        </TabStrip>}
        {(isNarrowScreen || isMobile) && settingsPage === 'account' &&
          <div style={{ height: 'calc(100vh - 275px)' }}>
            <AccountSettings
              useCase={useCase}
              setUseCase={setUseCase}
              history={history}
            />
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'groups' &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <UserGroupsSetup useCase={useCase} />
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'preQuestions' &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <RequiredQuestionsSetup useCase={useCase} />
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'questionnaire' &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <QuestionsSetup useCase={useCase}/>
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'guidance' &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <Guidances useCase={useCase} />
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'qrCodePrint' &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <QrCodePrint />
          </div>}
        {(isNarrowScreen || isMobile) && settingsPage === 'superUserPanel' && (isSuperUser || isAdminUser) &&
          <div style={{ height: "calc(100vh - 275px)" }}>
            <SuperUserView isSuperUser={isSuperUser} />
          </div>}
      </div>
    </div>
  );
};

export default SettingsMain;
