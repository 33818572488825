import React, { Fragment, useEffect, useState } from "react";
import axios from 'axios';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Container, Alert, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { Button } from "@progress/kendo-react-buttons";
import Select from 'react-select';
import { NotFound } from '../../util/notFound'
import ResultsPage from "./resultsPage";
import AttachmentsPage from "./attachmentsPage";
import VerifyVaccinationPage from "./verifyVaccinationPage";

// not required question answers
const nrqAns = [];

const firstNextDayOption = new Date();
firstNextDayOption.setDate(firstNextDayOption.getDate() + 2);
const secondNextDayOption = new Date();
secondNextDayOption.setDate(secondNextDayOption.getDate() + 3);
const thirdNextDayOption = new Date();
thirdNextDayOption.setDate(thirdNextDayOption.getDate() + 4);
const nextMondayOption = new Date();
if (((7 - nextMondayOption.getDay()) % 7 + 1) % 7 < 5) {
    nextMondayOption.setDate(nextMondayOption.getDate() + 7 + ((7 - nextMondayOption.getDay()) % 7 + 1) % 7);
} else {
    nextMondayOption.setDate(nextMondayOption.getDate() + ((7 - nextMondayOption.getDay()) % 7 + 1) % 7);
}

const Screening = ({ match }) => {
    const [screeningData, setScreeningData] = useState({});
    const [requiredQue, setRequiredQue] = useState([]);
    const [notRequiredQue, setNotRequiredQue] = useState([]);
    const [error, setError] = useState({ hasError: false, msg: '' });

    const [useReqQue1, setUseReqQue1] = useState(true);
    const [useReqQue2, setUseReqQue2] = useState(true);

    const [rq0Ans, setRq0Ans] = useState({ qId: '', ans: '' });
    const [rq1Ans, setRq1Ans] = useState({ qId: '', ans: '' });
    const [rq2Ans, setRq2Ans] = useState({ qId: '', ans: '' });

    const [result, setResult] = useState({ status: '', msg: '', screeningTime: null, screeningResult: null });
    const [companyName, setCompanyName] = useState('');
    const [companyCountry, setCompanyCountry] = useState();
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyContactEmail, setCompanyContactEmail] = useState('');
    const [passMsg, setPassMsg] = useState('');
    const [failMsg, setFailMsg] = useState('');

    const [notRequiredQueStep, setNotRequiredQueStep] = useState(0);

    const [nextSetOfQue, setNextSetOfQue] = useState(false);
    const [openAttachmentsPage, setOpenAttachmentsPage] = useState(false);
    const [openVerifyVaccinationPage, setOpenVerifyVaccinationPage] = useState(false);
    const [verifyProofOfVaccineNeeded, setVerifyProofOfVaccineNeeded] = useState(false);
    const [attachmentsNeeded, setAttachmentsNeeded] = useState(false);

    const [makeItRerender, setMakeItRerender] = useState(false);

    const [qrResult, setQRResult] = useState({});
    const [timesToVerify, setTimesToVerify] = useState(3);
    const [scanned, setScanned] = useState(false);
    const [proceedToSubmit, setProceedToSubmit] = useState(false);

    const [useCase, setUseCase] = useState('');

    const [dataReturned, setDataReturned] = useState(false);
    const [zeroNotRequiredQuestions, setZeroNotRequiredQuestions] = useState(false);

    const [getGroups, setGetGroups] = useState(true);
    const [allowSiteUpdate, setAllowSiteUpdate] = useState(false);
    const [user, setUser] = useState({});
    const [userGroupName, setUserGroupName] = useState('');
    const [userSiteName, setUserSiteName] = useState('');
    const [previousSiteName, setPreviousSiteName] = useState('');
    const [userDepartmentName, setUserDepartmentName] = useState('');
    const [otherUserSiteName, setOtherUserSiteName] = useState('');
    const [userSiteId, setUserSiteId] = useState('');
    const [oldUserSiteId, setOldUserSiteId] = useState('');
    const [siteOptions, setSiteOptions] = useState([]);
    const [userGroupUpdate, setUserGroupUpdate] = useState(false);

    const [notificationType, setNotificationType] = useState('');
    const [atLeastOneNotReqQueForVisitorsForQrVisit, setAtLeastOneNotReqQueForVisitorsForQrVisit] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [userEmail, setUserEmail] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [userCellNumber, setUserCellNumber] = useState('');

    const resetError = (time) => {
        setTimeout(() => {
            setError({ status: null, message: '' });
        }, time);
    };

    const selectedYes = nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans === 'Yes' ? true : false;
    const selectedNo = nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans === 'No' ? true : false;
    const selectedTemp = nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].temp ? nrqAns[notRequiredQueStep].temp : undefined;
    const selectedTempType = nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].tempType ? nrqAns[notRequiredQueStep].tempType : undefined;

    const addScreening = () => {
        const submitTime = moment().format("ddd, M/D/YYYY");
        const screening = screeningData.employeeId ?
            {
                attachedNotification: screeningData.attachedNotification,
                organizationId: screeningData.organizationId,
                employeeId: screeningData.employeeId,
                answers: [rq0Ans, rq1Ans, rq2Ans, ...nrqAns],
                completed: true,
                qrCodeVerification: { vaccineVerified: qrResult.qrCodeVerified || false, vaccineVerifiedDate: submitTime },
                siteAttended: userSiteId !== '000ot' ? userSiteName : otherUserSiteName.length > 29 ?
                    `${otherUserSiteName.slice(0, 27)}...` : otherUserSiteName,
                allowSiteUpdate,
                previousSite: previousSiteName,
                notificationObj: screeningData.notificationObj,
                employeeObj: screeningData.employeeObj,
                userSite: screeningData.userSite,
                userDepartment: screeningData.userDepartment,
            } : {
                useCase,
                notificationType,
                atLeastOneNotReqQueForVisitorsForQrVisit,
                attachedNotification: screeningData.attachedNotification,
                notficationId: screeningData.notficationId,
                organizationId: screeningData.organizationId,
                visitorId: screeningData.visitorId,
                answers: useCase !== 'Sign In Management' ? [...nrqAns] : [atLeastOneNotReqQueForVisitorsForQrVisit ?
                    { qId: requiredQue[0]._id, ans: 'Yes' } : rq0Ans, ...nrqAns],
                completed: true,
                qrCodeVerification: { vaccineVerified: qrResult.qrCodeVerified || false, vaccineVerifiedDate: submitTime },
                notificationObj: screeningData.notificationObj,
                visitorObj:  screeningData.visitorObj,
            };

        if (screeningData.employeeId && screeningData.userGroup && Object.keys(screeningData.userGroup).length > 0) {
            screening.userGroup = screeningData.userGroup;
        };

        axios.post(
            process.env.REACT_APP_BACKEND_CONN_IP + '/screening/add',
            screening,
        ).then((res) => {
            if (res.status === 200) {
                setResult({
                    status: res.data.result === 'failed' || res.data.result === 'Not attending' ? 'error' : 'success',
                    msg: res.data.msg,
                    screeningTime: res.data.screeningTime
                });
            }
        }).catch((err) => {
            setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
        });
        if (qrResult.qrCodeVerified && qrResult.qrCodeVerified === true) {
            if (screeningData.employeeId) {
                const employee = {
                    organizationId: screeningData.organizationId,
                    qrCodeVerification: {
                        vaccineVerified: qrResult.qrCodeVerified || false,
                        vaccineVerifiedDate: submitTime
                    }
                }
                axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/employees/updateQRCode/' + screeningData.employeeId,
                    employee
                ).then().catch((err) => {
                    console.log('err', err)
                });
            }
            else if (screeningData.visitorId) {
                const visitor = {
                    organizationId: screeningData.organizationId,
                    qrCodeVerification: {
                        vaccineVerified: qrResult.qrCodeVerified || false,
                        vaccineVerifiedDate: submitTime
                    }
                }
                axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/visitors/updateQRCode/' + screeningData.visitorId,
                    visitor
                ).then().catch((err) => {
                    setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                });
            }
        }
    }

    const saveResultsToDB = () => {
        if (match.params && match.params.notifyId) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/screening/getNotificationInfo/' + match.params.notifyId)
                .then(async res => {
                    if (res.status === 200) {
                        if (userGroupUpdate && allowSiteUpdate) {
                            let collectionToUse;
                            if (user.type === 'employee') {
                                collectionToUse = 'employees';
                            } else if (user.type === 'visitor') {
                                collectionToUse = 'visitors';
                            }

                            await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/${collectionToUse}/updateUserSite/${user._id}`, {
                                notificationId: match.params.notifyId,
                                userSiteId,
                                orgId: screeningData.organizationId,
                            })
                                .then(async res => {
                                    await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + `/userGroups/updateSites/${userSiteId}`, {
                                        notificationId: match.params.notifyId,
                                        orgId: screeningData.organizationId,
                                        oldUserSiteId,
                                        user,
                                    })
                                        .then(res => {
                                            addScreening();
                                        }).catch((err) => {
                                            setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                        });
                                }).catch((err) => {
                                    setError({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                                });
                        }
                        if (!userGroupUpdate || !allowSiteUpdate) {
                            addScreening();
                        }
                    }
                }).catch(err => {
                    if (err.response
                        && err.response.status === 400) {
                        if (err.response.data.msg === 'Link is expired') {
                            setResult({ status: 'error', msg: 'Link is expired' });
                        } else if (err.response.data.msg === 'Screening already completed') {
                            setResult({
                                status: 'error',
                                msg: 'Screening already completed',
                                screeningTime: err.response.data.screeningTime,
                                screeningResult: err.response.data.screeningResult
                            });
                        }
                    } else {
                        setError({
                            hasError: true,
                            status: err.response && err.response.status,
                            msg: err.response && err.response.data
                        });
                    }
                });
        }
    };

    const handleNextSetOfQue = () => {
        if (match.params && match.params.notifyId) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/screening/getNotificationInfo/' + match.params.notifyId)
                .then(res => {
                    if (res.status === 200) {
                        if (!rq0Ans.ans || (!rq1Ans.ans && ((user.type === 'employee') || (useCase !== 'Sign In Management' && user.type === 'visitor')) && useReqQue1)) {
                            setError({ hasError: true, msg: 'Please answer all of the questions.' });
                            resetError(1800);
                        } else if (rq1Ans.ans === 'No' && !rq2Ans.ans && useReqQue2) {
                            setError({ hasError: true, msg: 'Please answer all of the questions.' });
                            resetError(1800);
                        } else if (rq1Ans.ans === 'No' && rq2Ans.ans <= new Date() && useReqQue2) {
                            setError({ hasError: true, msg: 'Please select a valid date.' });
                            resetError(1800);
                        } else if (rq0Ans.ans === 'No') {
                            saveResultsToDB();
                        } else {
                            setNextSetOfQue(true);
                        }
                    } else {
                        setError({ hasError: true, status: 'Unknown', msg: 'Something went wrong' });
                    }
                }).catch(err => {
                    if (err.response
                        && err.response.status === 400) {
                        if (err.response.data.msg === 'Link is expired') {
                            setResult({ status: 'error', msg: 'Link is expired' });
                        } else if (err.response.data.msg === 'Screening already completed') {
                            setResult({
                                status: 'error',
                                msg: 'Screening already completed',
                                screeningTime: err.response.data.screeningTime,
                                screeningResult: err.response.data.screeningResult
                            });
                        }
                    } else {
                        setError({
                            hasError: true,
                            status: err.response && err.response.status,
                            msg: err.response && err.response.data
                        });
                    }
                });
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (match.params && match.params.notifyId) {
            axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/screening/getNotificationInfo/' + match.params.notifyId)
                .then(res => {
                    if (res.status === 200) {
                        setScreeningData(res.data);
                        setCompanyName(res.data.companyName);
                        setCompanyCountry(res.data.companyCountry);
                        setCompanyPhone(res.data.companyPhone);
                        setCompanyContactEmail(res.data.companyContactEmail);
                        setPassMsg(res.data.screeningPassMsg);
                        setFailMsg(res.data.screeningFailMsg);
                        if (res.data.qrCodeVerificationNeeded) {
                            setVerifyProofOfVaccineNeeded(true);
                        } else {
                            setProceedToSubmit(true);
                        }
                        if (res.data.vaccineVerified) {
                            setProceedToSubmit(true);
                        }
                        if (res.data.attachmentsNeeded) {
                            setAttachmentsNeeded(true);
                        }
                        setUseCase(res.data.useCase);
                        setUser(res.data.employeeId ? { _id: res.data.employeeId, type: 'employee' } : { _id: res.data.visitorId, type: 'visitor' });
                        setUserGroupName(res.data.employeeId && res.data.userGroup ?
                            res.data.userGroup.name : '');
                        setUserSiteName(res.data.employeeId && res.data.userSite ?
                            res.data.userSite.name : '');
                        setPreviousSiteName(res.data.employeeId && res.data.userSite ?
                            res.data.userSite.name : '');
                        setUserDepartmentName(res.data.employeeId && res.data.userDepartment ?
                            res.data.userDepartment.name : '');
                        setUserSiteId(res.data.employeeId && res.data.userSite ? res.data.userSite._id : '');
                        setOldUserSiteId(res.data.employeeId && res.data.userSite ?
                            res.data.userSite._id : '');
                        setUserEmail(res.data.employeeId && res.data.employeeObj.email ? res.data.employeeObj.email :
                            res.data.visitorId && res.data.visitorObj.email ? res.data.visitorObj.email : '*No Email Entered*');
                        setUserCellNumber(res.data.employeeId && res.data.employeeObj.cellNumber ? res.data.employeeObj.cellNumber :
                            res.data.visitorId && res.data.visitorObj.cellNumber && res.data.visitorObj.cellNumber ? res.data.visitorObj.cellNumber : '*No Cell Number Entered*');

                        // group by required and not required
                        let {
                            required = [],
                            notRequired = [],
                        } = groupBy(res.data.questions, q => q.required ? 'required' : 'notRequired');

                        // sort by order
                        required = sortBy(required, ['order']);
                        notRequired = sortBy(notRequired, ['order']);

                        if (required.length === 3) {
                            setUseReqQue1(true);
                            setUseReqQue2(true);
                        } else if (required.length === 1) {
                            setUseReqQue1(false);
                            setUseReqQue2(false);
                        } else if (required.length === 2) {
                            // The default 3rd pre-screening/pre-entry question has a questionType of date
                            // which helps us distinguish between the 2nd and 3rd pre-screening questions
                            // The default 2nd pre-screening/pre-entry question has a Yes/No type
                            if (required.filter(que => que.questionType === 'date').length > 0) {
                                setUseReqQue1(false);
                                setUseReqQue2(true);
                                const d = new Date();
                                d.setDate(d.getDate() + 2);
                                setRq2Ans({ qId: required[1]._id, ans: d });
                            } else {
                                setUseReqQue1(true);
                                setUseReqQue2(false);
                            }
                        }
                        // This will first filter by use case, then filter by the selected group
                        const filteredNotRequiredQuestions = [...notRequired].filter(question => question.useCase === useCase)
                            .filter((question) => {
                                let selectedGroupMatches;
                                if (res.data.employeeId) {
                                    selectedGroupMatches = question.selectedGroups.filter(selectedGroup => selectedGroup.value === res.data.employeeUserGroupId ||
                                        selectedGroup.value === res.data.employeeSiteId || selectedGroup.value === res.data.employeeDepartmentId ||
                                        selectedGroup.value === '000AE');
                                } else if (res.data.visitorId) {
                                    selectedGroupMatches = question.selectedGroups.filter(selectedGroup => selectedGroup.value === '001AV');
                                }
                                return selectedGroupMatches.length > 0;
                            }).map((question) => {
                                if (question.allowSiteUpdate) {
                                    setAllowSiteUpdate(true);
                                }
                                // eslint-disable-next-line no-template-curly-in-string
                                if (question.questionString.includes('${Site}')) {
                                    const employeeSiteName = res.data.employeeId && res.data.userSite && res.data.userSite.name ?
                                        res.data.userSite.name : '*No Site Entered*';
                                    const visitorSiteName = res.data.visitorId && res.data.visitorSite && res.data.visitorSite.name ?
                                        res.data.visitorSite.name : '*No Site Entered*';
                                    // eslint-disable-next-line no-template-curly-in-string
                                    question.questionString = question.questionString.replace('${Site}', res.data.employeeId ?
                                        employeeSiteName : visitorSiteName);
                                }
                                // eslint-disable-next-line no-template-curly-in-string
                                if (question.questionString.includes('${Email}')) {
                                    const employeeEmail = res.data.employeeId && res.data.employeeObj.email ?
                                        res.data.employeeObj.email : '*No Email Entered*';
                                    const visitorEmail = res.data.visitorId && res.data.visitorObj.email ?
                                        res.data.visitorObj.email : '*No Email Entered*';
                                    // eslint-disable-next-line no-template-curly-in-string
                                    question.questionString = question.questionString.replaceAll('${Email}', res.data.employeeId ?
                                        employeeEmail : visitorEmail);
                                }
                                // eslint-disable-next-line no-template-curly-in-string
                                if (question.questionString.includes('${Cell Phone}')) {
                                    const employeeCellNumber = res.data.employeeId && res.data.employeeObj.cellNumber ?
                                        res.data.employeeObj.cellNumber : '*No Cell Number Entered*';
                                    const visitorCellNumber = res.data.visitorId && res.data.visitorObj.cellNumber ?
                                        res.data.visitorObj.cellNumber : '*No Cell Number Entered*';
                                    // eslint-disable-next-line no-template-curly-in-string
                                    question.questionString = question.questionString.replaceAll('${Cell Phone}', res.data.employeeId ?
                                        employeeCellNumber : visitorCellNumber);
                                }
                                return question;
                            });

                        setRequiredQue([...required]);
                        setNotRequiredQue(filteredNotRequiredQuestions);

                        if (dataReturned && filteredNotRequiredQuestions.length === 0 && useCase !== 'Sign In Management') {
                            setZeroNotRequiredQuestions(true);
                        }
                        setNotificationType(res.data.notificationObj.type);
                        setAtLeastOneNotReqQueForVisitorsForQrVisit(res.data.atLeastOneNotReqQueForVisitors && res.data.notificationObj.type === 'qrCode');

                        if (res.data.useCase === 'Sign In Management' && res.data.notificationObj.type === 'qrCode' && !res.data.atLeastOneNotReqQueForVisitors) {
                            setResult({
                                status: res.data.result === 'failed' || res.data.result === 'Not attending' ? 'error' : 'success',
                                msg: 'Attending',
                                screeningTime: res.data.screeningTime
                            });
                        }
                        setDataReturned(true);
                    } else {
                        setError({ hasError: true, status: 'Unknown', msg: 'Something went wrong' });
                    }
                }).catch(err => {
                    if (err.response
                        && err.response.status === 400) {
                        if (err.response.data.msg === 'Link is expired') {
                            setResult({ status: 'error', msg: 'Link is expired' });
                        } else if (err.response.data.msg === 'Screening already completed') {
                            const screeningResult = err.response.data.screeningResult;
                            setResult({
                                status: screeningResult === 'passed' || screeningResult === 'Attending' ? 'success' : 'error',
                                msg: 'Screening already completed',
                                screeningTime: err.response.data.screeningTime,
                                screeningResult: screeningResult,
                                qrCodeVerification: err.response.data.qrCodeVerification
                            });
                            if (err.response.data.qrCodeVerification){
                                setVerifyProofOfVaccineNeeded(true)
                            }
                        }
                    } else {
                        setError({
                            hasError: true,
                            status: err.response && err.response.status,
                            msg: err.response && err.response.data
                        });
                    }
                });
            if (dataReturned && getGroups) {
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgIdMinimalPublic/' + match.params.notifyId)
                    .then(response => {
                        const siteData = response.data;
                        setSiteOptions(siteData.concat([{
                            _id: '000ot', name: '*OTHER*',
                        }]));
                        setGetGroups(false);
                    }).catch((err) => {
                        setError({
                            hasError: true,
                            status: err.response && err.response.status,
                            msg: err.response && err.response.data
                        });
                    });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataReturned, match, match.params, useCase]);

    return (
        <div style={{ backgroundColor: '#d6d8d9', height: '100vh' }}>
            <Alert style={{ backgroundColor: '#343a40', color: 'white' }}>
                <Alert.Heading className="text-center">Hey, nice to see you!<br />
                    {!isEmpty(screeningData) ?
                        <Fragment>
                            {screeningData.employeeId ?
                                `${screeningData.employeeObj.firstName} ${screeningData.employeeObj.lastName}`
                                : `${screeningData.visitorObj.firstName} ${screeningData.visitorObj.lastName}`
                            }
                        </Fragment>
                        : null
                    }
                </Alert.Heading>
            </Alert>
            {!result || !result.status ?
                <Fragment>
                    {!isEmpty(screeningData) ?
                        <NotificationGroup
                            style={{
                                right: 0,
                                top: 0,
                                alignItems: 'flex-start',
                                flexWrap: 'wrap-reverse'
                            }}
                        >
                            {error.hasError ?
                                <Fade
                                    enter={true}
                                    exit={true}>
                                    <Notification
                                        type={{ style: 'error', icon: true }}
                                        closable={true}
                                        onClose={() => setError({ hasError: false, msg: '' })}
                                    >
                                        <span>{error.msg}</span>
                                    </Notification>
                                </Fade> : null}
                        </NotificationGroup>
                        : null
                    }
                    <Container
                        className="mt-3"
                        style={{
                            height: '100vh-56px',
                            overflowY: 'visible',
                        }}
                    >
                        {isMobile ?
                            <p style={{ fontSize: 20, fontWeight: 'bold' }} className="text-center">
                                <img
                                    src={require(useCase === 'COVID Screening' ? './checknLogo.png' : './checknLogo-minimal.png')}
                                    alt="Check'N"
                                />
                            </p>
                            : <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                                <img
                                    src={require(useCase === 'COVID Screening' ? './checknLogo.png' : './checknLogo-minimal.png')}
                                    alt="Check'N"
                                />
                            </p>
                        }
                        {!zeroNotRequiredQuestions && ((screeningData
                            && screeningData.employeeId
                            && requiredQue
                            && requiredQue.length > 0
                            && !nextSetOfQue) || (screeningData
                            && screeningData.visitorId
                            && requiredQue
                            && requiredQue.length > 0
                            && !nextSetOfQue
                            && useCase === 'Sign In Management' && !atLeastOneNotReqQueForVisitorsForQrVisit)) ?
                            <Fragment>
                                <Alert variant="light" className={isMobile ? "text-center" : ""}>
                                    <Alert.Heading>
                                        {requiredQue[0].questionString}
                                        {requiredQue[0].showDateWithFirstReqQue && <><br />
                                        {moment().format("ddd, M/D/YYYY")}
                                        </>}
                                    </Alert.Heading>
                                    <p className="mt-5 mb-5">
                                        <Button
                                            look="outline"
                                            className="mr-5"
                                            togglable={true}
                                            onClick={() => setRq0Ans({ qId: requiredQue[0]._id, ans: 'Yes' })}
                                            selected={rq0Ans.ans === 'Yes'}
                                            style={rq0Ans.ans === 'Yes' ?
                                                {
                                                    backgroundColor: '#ffc107',
                                                    color: 'black',
                                                }
                                                : {}
                                            }
                                        >Yes
                                        </Button>
                                        <Button
                                            look="outline"
                                            togglable={true}
                                            onClick={() => setRq0Ans({ qId: requiredQue[0]._id, ans: 'No' })}
                                            selected={rq0Ans.ans === 'No'}
                                            style={rq0Ans.ans === 'No' ?
                                                {
                                                    backgroundColor: '#28a745',
                                                }
                                                : {}
                                            }
                                        >No
                                        </Button>
                                    </p>
                                    {useReqQue1 && rq0Ans.ans && screeningData.employeeId ?
                                        <Fragment>
                                            <hr />
                                            <Alert.Heading className="mt-5">
                                                {requiredQue[1].questionString}
                                                <br />
                                                {moment().add(1, 'days').format("ddd, M/D/YYYY")}
                                            </Alert.Heading>
                                            <p className="mt-5  mb-4">
                                                <Button
                                                    look="outline"
                                                    className="mr-5"
                                                    disabled={!rq0Ans.ans}
                                                    togglable={true}
                                                    onClick={() => {
                                                        const d = new Date();
                                                        d.setDate(d.getDate() + 1);
                                                        if (!useReqQue2) {
                                                            setRq1Ans({ qId: requiredQue[1]._id, ans: d });
                                                        } else {
                                                            setRq1Ans({ qId: requiredQue[1]._id, ans: 'Yes' });
                                                            setRq2Ans({ qId: requiredQue[2]._id, ans: d });
                                                        }
                                                    }}
                                                    selected={rq1Ans.ans !== 'No' && rq1Ans.ans !== ''}
                                                    style={rq1Ans.ans !== 'No' && rq1Ans.ans !== '' ?
                                                        {
                                                            backgroundColor: '#ffc107',
                                                            color: 'black',
                                                        }
                                                        : {}
                                                    }
                                                >Yes
                                                </Button>
                                                <Button
                                                    look="outline"
                                                    disabled={!rq0Ans.ans}
                                                    togglable={true}
                                                    onClick={() => {
                                                        setRq1Ans({ qId: requiredQue[1]._id, ans: 'No' });
                                                        setRq2Ans({ qId: requiredQue[2]._id, ans: null });
                                                    }}
                                                    selected={rq1Ans.ans === 'No'}
                                                    style={rq1Ans.ans === 'No' ?
                                                        {
                                                            backgroundColor: '#28a745',
                                                        }
                                                        : {}
                                                    }
                                                >No
                                                </Button>
                                            </p>
                                        </Fragment>
                                        : null
                                    }
                                    {((useReqQue1 && useReqQue2 && rq1Ans.ans === 'No') || (!useReqQue1 && useReqQue2 && rq0Ans.ans !== '')) && screeningData.employeeId ?
                                        <Fragment>
                                            <hr />
                                            <Alert.Heading className="mt-5">{useReqQue1 && useReqQue2 ? requiredQue[2].questionString : requiredQue[1].questionString}</Alert.Heading>
                                            <p className="mt-5  mb-4">
                                                <Button
                                                    onClick={() => {
                                                        setRq2Ans({ qId: useReqQue1 && useReqQue2 ? requiredQue[2]._id : requiredQue[1]._id, ans: firstNextDayOption})
                                                    }}
                                                    style={{ width: '140px', marginRight: '20px' }}
                                                >
                                                    {moment(firstNextDayOption).format('ddd, M/D/YYYY')}
                                                </Button>
                                                
                                                <Button
                                                    onClick={() => {
                                                        setRq2Ans({ qId: useReqQue1 && useReqQue2 ? requiredQue[2]._id : requiredQue[1]._id, ans: secondNextDayOption})
                                                    }}
                                                    style={{ width: '140px', marginRight: '20px' }}
                                                >
                                                    {moment(secondNextDayOption).format('ddd, M/D/YYYY')}
                                                </Button>
                                                
                                                <Button
                                                    onClick={() => {
                                                        setRq2Ans({ qId: useReqQue1 && useReqQue2 ? requiredQue[2]._id : requiredQue[1]._id, ans: thirdNextDayOption})
                                                    }}
                                                    style={{ width: '140px', marginRight: '20px' }}
                                                >
                                                    {moment(thirdNextDayOption).format('ddd, M/D/YYYY')}
                                                </Button>
    
                                            <Button
                                                onClick={() => {
                                                        setRq2Ans({ qId: useReqQue1 && useReqQue2 ? requiredQue[2]._id : requiredQue[1]._id, ans: nextMondayOption })
                                                }}
                                                style={{ width: '140px', marginRight: '20px' }}
                                            >
                                                    {moment(nextMondayOption).format('ddd, M/D/YYYY')}
                                            </Button>
                                                <br /><br />
                                                <strong>Other:</strong><br />
                                                <DatePicker
                                                    min={new Date(moment().add(2, 'days'))}
                                                    value={rq2Ans.ans}
                                                    onChange={(e) => setRq2Ans({ qId: useReqQue1 && useReqQue2 ? requiredQue[2]._id : requiredQue[1]._id, ans: e.target.value })}
                                                />
                                            </p>
                                        </Fragment>
                                        : null
                                    }
                                </Alert>
                                <hr />
                                <p className="text-right">
                                    {notRequiredQue && notRequiredQue.length > 0 ?
                                    <Button
                                        primary
                                        onClick={handleNextSetOfQue}
                                    >Next&nbsp;<span className="k-icon k-i-arrow-chevron-right"></span>
                                    </Button>
                                    :
                                        <Col className="text-right">
                                            <Button
                                                primary
                                                disabled={!rq0Ans.ans ||
                                                    (useReqQue1 && useReqQue2 && rq1Ans.ans !== 'Yes' && !rq2Ans.ans && screeningData.employeeId) ||
                                                    (!useReqQue1 && useReqQue2 && !rq2Ans.ans && screeningData.employeeId) ||
                                                    (useReqQue1 && !useReqQue2 && !rq1Ans.ans && screeningData.employeeId)}
                                                onClick={saveResultsToDB}
                                            >Submit&nbsp;<span className="k-icon k-i-arrow-chevron-right" />
                                            </Button>
                                        </Col>
                                    }
                                </p>
                            </Fragment>
                            : null
                        }
                        {!zeroNotRequiredQuestions && notRequiredQue && notRequiredQue.length > 0 &&
                            (nextSetOfQue ||
                                (screeningData && screeningData.visitorId && useCase !== 'Sign In Management') ||
                                (screeningData && screeningData.visitorId && nextSetOfQue) ||
                                (screeningData && screeningData.visitorId && atLeastOneNotReqQueForVisitorsForQrVisit)) ?
                            <Fragment key={'q' + notRequiredQueStep}>
                                <Alert variant="light">
                                    <div className={isMobile ? "text-center" : ""}>
                                        <Alert.Heading className="mt-5">{notRequiredQue[notRequiredQueStep].questionString}</Alert.Heading>
                                        {notRequiredQue[notRequiredQueStep].questionType === 'list' ?
                                            <Fragment>
                                                <br />
                                                <ul
                                                    style={{
                                                        listStyleType: 'none',
                                                        padding: 0,
                                                    }}
                                                >
                                                    {notRequiredQue[notRequiredQueStep].options
                                                        .map((op, i) => {
                                                            const optionsWithNotes = op.split('///');
                                                            return (
                                                                <li key={'li' + i} className="mb-2">
                                                                    <strong>{optionsWithNotes[0].trim()}</strong>
                                                                    {optionsWithNotes.length > 1 ?
                                                                        <><br/><i>&nbsp;{optionsWithNotes[1].trim()}</i></>
                                                                        : null
                                                                    }
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </Fragment>
                                            : null
                                        }
                                        {notRequiredQue &&
                                            notRequiredQue[notRequiredQueStep] &&
                                            notRequiredQue[notRequiredQueStep].questionType !== 'text' ?
                                            <Fragment>
                                                <p className="mt-5  mb-4">
                                                    <Button
                                                        look="outline"
                                                        className="mr-5"
                                                        togglable={true}
                                                        disabled={notRequiredQue[notRequiredQueStep].failAnswer === 'No' && notRequiredQue[notRequiredQueStep].questionString.match(/\*No\s(\w+\s){1,2}Entered\*/)}
                                                        onClick={() => {
                                                            const style = notRequiredQue[notRequiredQueStep].failAnswer !== 'Yes' ?
                                                                {
                                                                    backgroundColor: '#28a745',
                                                                } : {
                                                                    backgroundColor: '#ffc107',
                                                                    color: 'black',
                                                                };
                                                            nrqAns[notRequiredQueStep] = { qId: notRequiredQue[notRequiredQueStep]._id, ans: 'Yes', failAnswer: notRequiredQue[notRequiredQueStep].failAnswer, style };
                                                            if (nrqAns[notRequiredQueStep].ans !== notRequiredQue[notRequiredQueStep].failAnswer) {
                                                                if (notRequiredQueStep + 1 === notRequiredQue.length) {
                                                                    setNotRequiredQueStep(notRequiredQueStep);
                                                                    setMakeItRerender(!makeItRerender);
                                                                }
                                                                else {
                                                                    setMakeItRerender(!makeItRerender);
                                                                    setTimeout(() => {
                                                                        setNotRequiredQueStep(notRequiredQueStep + 1);
                                                                    }, 1000);
                                                                }
                                                            } else {
                                                                setMakeItRerender(!makeItRerender);
                                                            }
                                                        }}
                                                        selected={selectedYes}
                                                        style={selectedYes ? nrqAns[notRequiredQueStep].style : {}}
                                                    >Yes
                                                    </Button>
                                                    <Button
                                                        look="outline"
                                                        togglable={true}
                                                        disabled={notRequiredQue[notRequiredQueStep].failAnswer === 'Yes' && notRequiredQue[notRequiredQueStep].questionString.match(/\*No\s(\w+\s){1,2}Entered\*/)}
                                                        onClick={() => {
                                                            const style = notRequiredQue[notRequiredQueStep].failAnswer !== 'No' ?
                                                                {
                                                                    backgroundColor: '#28a745',
                                                                } : {
                                                                    backgroundColor: '#ffc107',
                                                                    color: 'black',
                                                                };
                                                            nrqAns[notRequiredQueStep] = { qId: notRequiredQue[notRequiredQueStep]._id, ans: 'No', failAnswer: notRequiredQue[notRequiredQueStep].failAnswer, style };
                                                            if (nrqAns[notRequiredQueStep].ans !== notRequiredQue[notRequiredQueStep].failAnswer) {
                                                                if (notRequiredQueStep + 1 === notRequiredQue.length) {
                                                                    setNotRequiredQueStep(notRequiredQueStep);
                                                                    setMakeItRerender(!makeItRerender);
                                                                }
                                                                else {
                                                                    setMakeItRerender(!makeItRerender);
                                                                    setTimeout(() => {
                                                                        setNotRequiredQueStep(notRequiredQueStep + 1);
                                                                    }, 1000);
                                                                }
                                                            } else {
                                                                setMakeItRerender(!makeItRerender);
                                                            }
                                                        }}
                                                        selected={selectedNo}
                                                        style={selectedNo ? nrqAns[notRequiredQueStep].style : {}}
                                                    >No
                                                    </Button>
                                                </p>
                                                {nrqAns[notRequiredQueStep]
                                                    && nrqAns[notRequiredQueStep].ans
                                                    && nrqAns[notRequiredQueStep].ans === notRequiredQue[notRequiredQueStep].failAnswer ?
                                                    <>
                                                        {notRequiredQue[notRequiredQueStep].queStringAboveNotesField &&
                                                            <>
                                                                <Alert.Heading className="mt-5">
                                                                    {notRequiredQue[notRequiredQueStep].queStringAboveNotesField}
                                                                </Alert.Heading>
                                                                <br /><br />
                                                            </>}
                                                        {notRequiredQue[notRequiredQueStep].showSiteOptionsWithNotes &&
                                                            <div style={!isMobile ? {
                                                                width: '300px',
                                                                fontSize: 'medium',
                                                            } : {
                                                                width: '300px',
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto',
                                                                fontSize: '16px',
                                                            }}>
                                                                <Select
                                                                    placeholder="Select A Site"
                                                                    value={{ label: userSiteName, value: userSiteId }}
                                                                    options={siteOptions.map(userGroupOption => ({ label: userGroupOption.name, value: userGroupOption._id }))}
                                                                    onChange={(e) => {
                                                                        nrqAns[notRequiredQueStep].previousSite = previousSiteName;
                                                                        nrqAns[notRequiredQueStep].siteAttended = e.label;
                                                                        nrqAns[notRequiredQueStep].allowSiteUpdate = allowSiteUpdate;
                                                                        setUserSiteName(e.label);
                                                                        setUserSiteId(e.value);
                                                                        if (e.value !== '000ot') {
                                                                            setUserGroupUpdate(true);
                                                                        }
                                                                    }}
                                                                />
                                                                {userSiteName === previousSiteName &&
                                                                    <small><i>Please choose *OTHER* if your site is not listed.</i></small>}
                                                            </div>}
                                                            {userSiteId === '000ot' && <br />}
                                                        {(!notRequiredQue[notRequiredQueStep].showSiteOptionsWithNotes || userSiteId === '000ot') &&
                                                            <p>
                                                                <TextArea
                                                                    rows={2}
                                                                    placeholder={userSiteId === '000ot' ? "Please enter the site name here..." : "Notes"}
                                                                    value={nrqAns[notRequiredQueStep].notes || ''}
                                                                    onChange={(e) => {
                                                                        nrqAns[notRequiredQueStep].notes = e.value;
                                                                        if (userSiteId === '000ot' && nrqAns[notRequiredQueStep].siteAttended === '*OTHER*') {
                                                                            setOtherUserSiteName(`${userSiteName} - ${e.value}`);
                                                                        }
                                                                        setMakeItRerender(!makeItRerender);
                                                                    }}
                                                                />
                                                            </p>}
                                                    </>
                                                    : null
                                                }
                                            </Fragment>
                                            : null}
                                        {notRequiredQue &&
                                            notRequiredQue[notRequiredQueStep] &&
                                            notRequiredQue[notRequiredQueStep].questionType === 'text' ?
                                            <p className="mt-5  mb-4">
                                                <Input
                                                    type={"number"}
                                                    validityStyles={false}
                                                    value={selectedTemp}
                                                    onChange={(e) => {
                                                        if (!nrqAns[notRequiredQueStep]) {
                                                            nrqAns[notRequiredQueStep] = {
                                                                qId: notRequiredQue[notRequiredQueStep]._id,
                                                                temp: Number(e.value)
                                                            };
                                                        } else {
                                                            nrqAns[notRequiredQueStep].qId = notRequiredQue[notRequiredQueStep]._id;
                                                            nrqAns[notRequiredQueStep].temp = Number(e.value);
                                                        }
                                                        if (
                                                            nrqAns[notRequiredQueStep] &&
                                                            nrqAns[notRequiredQueStep].temp &&
                                                            nrqAns[notRequiredQueStep].tempType
                                                        ) {
                                                            if (companyCountry === 'Canada' && nrqAns[notRequiredQueStep].tempType === "°F") {
                                                                nrqAns[notRequiredQueStep].ans = `${((nrqAns[notRequiredQueStep].temp - 32) * 5 / 9).toFixed(1)}°C`
                                                            } else if (companyCountry === 'US' && nrqAns[notRequiredQueStep].tempType === "°C") {
                                                                nrqAns[notRequiredQueStep].ans = `${((nrqAns[notRequiredQueStep].temp * 9 / 5) + 32).toFixed(1)}°F`
                                                            } else {
                                                                nrqAns[notRequiredQueStep].ans = `${nrqAns[notRequiredQueStep].temp} ${nrqAns[notRequiredQueStep].tempType} `
                                                            }
                                                        }
                                                        setMakeItRerender(!makeItRerender);

                                                    }}
                                                />
                                                <DropDownList
                                                    data={["°C", "°F", "N/A"]}
                                                    style={{ backgroundColor: 'white' }}
                                                    value={selectedTempType}
                                                    onChange={(e) => {
                                                        if (e.target.value === "N/A") {
                                                            nrqAns[notRequiredQueStep] = {
                                                                qId: notRequiredQue[notRequiredQueStep]._id,
                                                                ans: e.target.value
                                                            };
                                                            setMakeItRerender(!makeItRerender);
                                                            return;
                                                        }
                                                        if (!nrqAns[notRequiredQueStep]) {
                                                            nrqAns[notRequiredQueStep] = {
                                                                qId: notRequiredQue[notRequiredQueStep]._id,
                                                                tempType: e.target.value
                                                            };
                                                        } else {
                                                            nrqAns[notRequiredQueStep].qId = notRequiredQue[notRequiredQueStep]._id;
                                                            nrqAns[notRequiredQueStep].tempType = e.target.value;
                                                        }
                                                        if (
                                                            nrqAns[notRequiredQueStep] &&
                                                            nrqAns[notRequiredQueStep].temp &&
                                                            nrqAns[notRequiredQueStep].tempType
                                                        ) {
                                                            if (companyCountry === 'Canada' && nrqAns[notRequiredQueStep].tempType === "°F") {
                                                                nrqAns[notRequiredQueStep].ans = `${((nrqAns[notRequiredQueStep].temp - 32) * 5 / 9).toFixed(1)}°C`
                                                            } else if (companyCountry === 'US' && nrqAns[notRequiredQueStep].tempType === "°C") {
                                                                nrqAns[notRequiredQueStep].ans = `${((nrqAns[notRequiredQueStep].temp * 9 / 5) + 32).toFixed(1)}°F`
                                                            } else {
                                                                nrqAns[notRequiredQueStep].ans = `${nrqAns[notRequiredQueStep].temp} ${nrqAns[notRequiredQueStep].tempType} `
                                                            }
                                                        }
                                                        setMakeItRerender(!makeItRerender);
                                                    }}
                                                />
                                            </p>
                                            : null}
                                        <hr />
                                    </div>
                                    <Row>
                                        <Col className="text-left">
                                            <Button
                                                className={!isMobile ? "mr-5" : ''}
                                                primary
                                                icon="arrow-chevron-left"
                                                disabled={notRequiredQueStep === 0}
                                                onClick={() => {
                                                    setNotRequiredQueStep(notRequiredQueStep - 1);
                                                }}
                                            >Previous</Button>
                                            {notRequiredQueStep + 1 !== notRequiredQue.length && !isMobile ?
                                                <Button
                                                    primary
                                                    disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                    onClick={() => {
                                                        setNotRequiredQueStep(notRequiredQueStep + 1);
                                                    }}
                                                >Next&nbsp;<span className="k-icon k-i-arrow-chevron-right" />
                                                </Button>
                                                : null
                                            }
                                            {notRequiredQueStep + 1 === notRequiredQue.length && !isMobile && attachmentsNeeded ?
                                                <Button
                                                    primary
                                                    onClick={() => { setOpenAttachmentsPage(true); }}
                                                ><i className="fas fa-paperclip" />&nbsp;Attachments
                                                </Button>
                                                : null
                                            }
                                        </Col>
                                        <Col className="text-right">
                                            {notRequiredQueStep + 1 === notRequiredQue.length && !isMobile && verifyProofOfVaccineNeeded ?
                                                <Button
                                                    style={{ backgroundColor: screeningData.vaccineVerified || qrResult.qrCodeVerified ? '#28a745' : '#e23a10', color: 'white' }}
                                                    onClick={() => { setOpenVerifyVaccinationPage(true); }}
                                                ><i className="fas fa-qrcode" />&nbsp;{screeningData.vaccineVerified || qrResult.qrCodeVerified ? 'Update Proof of Vaccination' : 'Verify Proof of Vaccination'}
                                                </Button>
                                                : null
                                            }
                                            {notRequiredQueStep + 1 === notRequiredQue.length && !isMobile && verifyProofOfVaccineNeeded && <span>  |  </span>}
                                        {notRequiredQueStep + 1 === notRequiredQue.length && !isMobile && verifyProofOfVaccineNeeded && !proceedToSubmit ?
                                                <Button
                                                    style={{ backgroundColor: '#ffc207' }}
                                                    primary
                                                    disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                    onClick={setProceedToSubmit}
                                                ><b>Proceed&nbsp;</b>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                                <strong>
                                                                    <i>If you would like to proceed without verifying your vaccination records, please click this button.</i>
                                                                </strong>
                                                            </Tooltip>}
                                                    >
                                                    <span className="k-icon k-i-warning" />
                                                </OverlayTrigger>
                                                </Button>
                                            : null
                                        }
                                        {notRequiredQueStep + 1 === notRequiredQue.length && !isMobile && proceedToSubmit ?
                                            <Button
                                                primary
                                                disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                onClick={saveResultsToDB}
                                            >Submit&nbsp;<span className="k-icon k-i-arrow-chevron-right" />
                                            </Button>
                                            : null
                                        }
                                        </Col>
                                        {notRequiredQueStep + 1 !== notRequiredQue.length && isMobile ?
                                            <Col className="text-right">
                                                <Button
                                                    primary
                                                    disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                    onClick={() => {
                                                        setNotRequiredQueStep(notRequiredQueStep + 1)
                                                    }}
                                                >Next&nbsp;<span className="k-icon k-i-arrow-chevron-right" />
                                                </Button>
                                            </Col>
                                            : null
                                        }
                                        {notRequiredQueStep + 1 === notRequiredQue.length && isMobile && verifyProofOfVaccineNeeded && !proceedToSubmit ?
                                            <Col className="text-right">
                                                <Button
                                                    style={{ backgroundColor: '#ffc207' }}
                                                    primary
                                                    disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                    onClick={setProceedToSubmit}
                                                ><b>No Verify</b>&nbsp;<span className="k-icon k-i-warning" />
                                                </Button>
                                            </Col>
                                            : null
                                        }
                                        {notRequiredQueStep + 1 === notRequiredQue.length && isMobile && proceedToSubmit ?
                                            <Col className="text-right">
                                                <Button
                                                    primary
                                                    disabled={!(nrqAns[notRequiredQueStep] && nrqAns[notRequiredQueStep].ans)}
                                                    onClick={saveResultsToDB}
                                                >Submit&nbsp;<span className="k-icon k-i-arrow-chevron-right" />
                                                </Button>
                                            </Col>
                                            : null
                                        }
                                    </Row>
                                    {notRequiredQueStep + 1 === notRequiredQue.length && isMobile && (attachmentsNeeded || verifyProofOfVaccineNeeded) &&
                                        <>
                                            <hr />
                                            <Row>
                                            {attachmentsNeeded && verifyProofOfVaccineNeeded &&
                                                <Col className="text-left">
                                                    <Button
                                                        primary
                                                        onClick={() => { setOpenAttachmentsPage(true); }}
                                                    ><i className="fas fa-paperclip" />&nbsp;Attachments
                                                    </Button>
                                                </Col>}
                                            {attachmentsNeeded && !verifyProofOfVaccineNeeded &&
                                                <Col className="text-center">
                                                    <Button
                                                        primary
                                                        onClick={() => { setOpenAttachmentsPage(true); }}
                                                    ><i className="fas fa-paperclip" />&nbsp;Attachments
                                                    </Button>
                                                </Col>}
                                            {verifyProofOfVaccineNeeded && attachmentsNeeded &&
                                                <Col className="text-right">
                                                    <Button
                                                        style={{ backgroundColor: screeningData.vaccineVerified || qrResult.qrCodeVerified ? '#28a745' : '#e23a10', color: 'white' }}
                                                        primary
                                                        onClick={() => { setOpenVerifyVaccinationPage(true); }}
                                                    ><i className="fas fa-qrcode" />&nbsp;{screeningData.vaccineVerified || qrResult.qrCodeVerified ? 'Update Proof' : 'Verify Proof'}
                                                    </Button>
                                                </Col>}
                                            {verifyProofOfVaccineNeeded && !attachmentsNeeded &&
                                                <Col className="text-center">
                                                    <Button
                                                        style={{ backgroundColor: screeningData.vaccineVerified || qrResult.qrCodeVerified ? '#28a745' : '#e23a10', color: 'white' }}
                                                        primary
                                                        onClick={() => { setOpenVerifyVaccinationPage(true); }}
                                                    ><i className="fas fa-qrcode" />&nbsp;{screeningData.vaccineVerified || qrResult.qrCodeVerified ? 'Update Proof' : 'Verify Proof'}
                                                    </Button>
                                                </Col>}
                                            </Row>
                                        </>
                                    }
                                </Alert>
                            </Fragment> : null
                        }
                        {!zeroNotRequiredQuestions && openAttachmentsPage ?
                            <AttachmentsPage
                                openAttachmentsPage={openAttachmentsPage}
                                setOpenAttachmentsPage={setOpenAttachmentsPage}
                                screeningData={screeningData}
                                error={error}
                                setError={setError}
                            />
                            : null
                        }
                        {!zeroNotRequiredQuestions && openVerifyVaccinationPage ?
                            <VerifyVaccinationPage
                                openVerifyVaccinationPage={openVerifyVaccinationPage}
                                setOpenVerifyVaccinationPage={setOpenVerifyVaccinationPage}
                                screeningData={screeningData}
                                error={error}
                                setError={setError}
                                setQRResult={setQRResult}
                                qrResult={qrResult}
                                timesToVerify={timesToVerify}
                                setTimesToVerify={setTimesToVerify}
                                scanned={scanned}
                                setScanned={setScanned}
                                proceedToSubmit={proceedToSubmit}
                                setProceedToSubmit={setProceedToSubmit}
                            />
                            : null
                        }
                        {zeroNotRequiredQuestions &&
                            <>
                                <NotFound useMessage={false} />
                                <p>Please contact the individual or organization ({companyName}) that issued your {useCase === 'General Survey' ? 'survey' : 'screening'} and mention{user.type === 'visitor' &&
                                    <>
                                        &nbsp;that you are a visitor.
                                        <br />
                                        <br />
                                    </>}
                                    {user.type === 'employee' && (userGroupName || userSiteName || userDepartmentName) ? 
                                        <>
                                            &nbsp;your:
                                            <ul>
                                            {userGroupName &&
                                                    <li><strong>User Group:</strong>&nbsp;{userGroupName}</li>}
                                            {userSiteName &&
                                                    <li><strong>Site:</strong>&nbsp;{userSiteName}</li>}
                                            {userDepartmentName &&
                                                    <li><strong>Department:</strong>&nbsp;{userDepartmentName}</li>}
                                            </ul>
                                        </> : user.type === 'employee' &&
                                        <>
                                            &nbsp;that you are an employee without a site or department configured.
                                            <br />
                                            <br />
                                        </>}
                                    It appears no questions are configured for your {useCase === 'General Survey' ? 'survey' : 'screening'}.</p>
                                {companyPhone && companyPhone !== '(+1) ___-___-____' && <p><strong>Phone Number:</strong> {companyPhone}</p>}
                                {companyContactEmail && <p><strong>Email Address:</strong> {companyContactEmail}</p>}
                            </>}
                    </Container>
                </Fragment>
                :
                <Fragment>
                    <ResultsPage
                        useCase={useCase}
                        alreadyVerified={screeningData.vaccineVerified}
                        timesToVerify={timesToVerify}
                        resultObj={result}
                        companyPhone={companyPhone}
                        companyContactEmail={companyContactEmail}
                        passMsg={passMsg}
                        failMsg={failMsg}
                        qrResult={qrResult}
                        verifyProofOfVaccineNeeded={verifyProofOfVaccineNeeded}
                    />
                </Fragment>
            }
        </div>
    );
};
export default Screening;
