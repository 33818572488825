import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button } from '@progress/kendo-react-buttons';
import {
    Card,
    Col,
    Row,
    Dropdown,
} from 'react-bootstrap';
import sortBy from 'lodash/sortBy';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import QuestionsPreview from "./questionsPreview";
import getTokenConfig from "../../../methods/getTokenConfig";
import AddNewQuestionWindow from "./addNewQuestionWindow";

const RequiredQuestionsSetup = ({ useCase }) => {
    const [addedQuestions, setAddedQuestions] = useState([]);
    const [preview, setPreview] = useState(false);
    const [mgsType, setMgsType] = useState({ status: null, message: '' });
    const [selectedQue, setSelectedQue] = useState(null);
    const [showAddNewQueWindow, setShowAddNewQueWindow] = useState(false);

    const [resetLayoutFlag, setResetLayoutFlag] = useState(false);
    
    const resetMessage = (time) => {
        setTimeout(() => {
            setMgsType({ status: null, message: '' });
        }, time);
    }

    const SimpleCard = (props) => {
        const { isDisabled, isActive, style, attributes, dataItem } = props;
        const classNames = ['mb-2'];

        if (isDisabled) {
            classNames.push('k-state-disabled');
        }

        return (
            <Card
                {...attributes}
                style={{
                    ...style,
                    borderColor: isActive ? '#27aceb' : '#fff',
                    backgroundColor: '#cce5ff',
                    color: '#004085'
                }}
                className={classNames.join(' ')}
            >
                <Card.Body>
                    <Card.Title>
                        Pre-{useCase === 'COVID Screening' ? 'Screening' : useCase === 'General Survey' ? 'Survey' : 'Entry'} Question #{
                            dataItem && (dataItem.order === 0 || dataItem.order === 1 || dataItem.order === 2) ?
                            dataItem.order + 1 : dataItem.sortOrder === 0 || dataItem.sortOrder === 1 || dataItem.sortOrder === 2 ?
                            dataItem.sortOrder + 1 : dataItem.originalQuestionSortNumber === 0 || dataItem.originalQuestionSortNumber === 1 ||
                            dataItem.originalQuestionSortNumber === 2 ? dataItem.originalQuestionSortNumber + 1 : '#'}
                        {dataItem.sortOrder !== 0 && dataItem.originalQuestionSortNumber !== 0 && resetLayoutFlag &&
                            <Button
                                style={{ float: 'right', marginTop: '-6px' }}
                                icon="close"
                                look="flat"
                                onClick={() => {
                                    const filteredAddedQuestions = addedQuestions.filter((question) => {
                                        // This wil filter out the question with the matching _id
                                        if (question._id === dataItem._id)
                                            return false;
                                        return true;
                                    });
                                    setAddedQuestions(filteredAddedQuestions);
                                    saveLayout(filteredAddedQuestions);
                                    setMgsType({
                                        status: 'success',
                                        message: `Question removed successfully.`
                                    });
                                    resetMessage(1800);
                                }}
                            />}
                    </Card.Title>
                    <Card.Body>
                        {dataItem.questionString}
                        {dataItem.questionType === 'list' ?
                            <ul>
                                {dataItem.options.map(op => <li>{op}</li>)}
                            </ul>
                            : null
                        }
                        {(dataItem.sortOrder === 0 || dataItem.originalQuestionSortNumber === 0) &&
                            <>
                                <br /><br />
                                <p><i>The current date <strong>{dataItem.showDateWithFirstReqQue ? 'WILL' : 'WILL NOT'}</strong> print under this question.</i></p>
                            </>}
                    </Card.Body>
                    {resetLayoutFlag ?
                        <>
                            <hr />
                            <Button
                                iconClass="far fa-edit"
                                look="outline"
                                onClick={() => {
                                    setSelectedQue(dataItem);
                                    setShowAddNewQueWindow(true);
                                }}
                            >
                                &nbsp;Edit
                            </Button>&nbsp;
                        </>
                        : null
                    }
                </Card.Body>
            </Card>
        );
    };

    const saveLayout = async (addedQuestionArray, reset = false) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        axios.put(process.env.REACT_APP_BACKEND_CONN_IP + '/organization/updateReqQueOrder/' + orgId,
            addedQuestionArray.map(q => q._id), getTokenConfig())
            .then(response => {
                if (response.status === 200) {
                    if (reset) {
                        setMgsType({
                            status: 'success',
                            message: `Questions reset successfully.`
                        });
                        resetMessage(1800);
                    }
                }
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const resetLayout = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/resetRequiredByOrg', getTokenConfig())
            .then(response => {
                setAddedQuestions([...response.data.filter(q => q.required)]);
                saveLayout([...response.data.filter(q => q.required)], true);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const modifyLayout = () => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/requiredByOrg', getTokenConfig())
            .then(response => {
                let returnedQuestions = [...response.data.filter(q => q.required)];
                returnedQuestions.forEach((que) => {
                    que.originalQuestionSortNumber = que.originalQuestionSortNumber || que.originalQuestionSortNumber === 0 ? que.originalQuestionSortNumber : que.sortOrder;
                });
                setAddedQuestions(returnedQuestions.sort((a, b) => {
                    if (a.originalQuestionSortNumber > b.originalQuestionSortNumber) {
                        return 1;
                    } return -1;
                }));
                setResetLayoutFlag(true);
                setMgsType({
                    status: 'success',
                    message: "Questions ready to modify."
                });
                resetMessage(1800);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    };

    const doneClick = () => {
        setResetLayoutFlag(false);
        setMgsType({
            status: 'success',
            message: `Question modification disabled.`
        });
        resetMessage(1800);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/questions/getAllOrgRequiredQue/', getTokenConfig())
            .then(async (response) => {
                const questions = response.data.questions;
                const allQuestionsNeeded = response.data.allQuestionsNeeded;
                await questions.forEach((que) => {
                    que.order = allQuestionsNeeded.find(q => q._id.toString() === que._id.toString()).order;
                });

                setAddedQuestions([...sortBy(questions, ['order'])]);
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }, []);

    return (
        <div style={{ paddingLeft: !isMobile ? 15 : 5, paddingRight: !isMobile ? 15 : 5 }}>
            {mgsType.status !== null ?
                <NotificationGroup
                    style={{
                        right: 0,
                        top: 0,
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse'
                    }}
                >
                    <Fade
                        enter={true}
                        exit={true}>
                        {mgsType.status !== null && <Notification
                            type={{ style: mgsType.status, icon: true }}
                            closable={true}
                            onClose={() => setMgsType({ status: null, message: '' })}
                        >
                            <span>{mgsType.message}</span>
                        </Notification>}
                    </Fade>
                </NotificationGroup> : null}
            <legend>
                Preview
                {addedQuestions && addedQuestions.length > 0 ?
                    <span style={{ fontSize: '14px' }}>
                        &nbsp;&nbsp;(When you are finished making changes, please click the "Done" button.)
                    </span>
                    : null
                }
            </legend>
            {addedQuestions && addedQuestions.length === 0 ?
                <span>Please click "Modify Layout" to change the pre-{useCase === 'COVID Screening' ? 'screening' : 'entry'} questions.</span>
                : addedQuestions.map((questionItem) => <SimpleCard dataItem={{...questionItem}} />)
            }
            {preview ?
                <QuestionsPreview
                    setPreview={setPreview}
                    addedQuestions={addedQuestions}
                />
                : null
            }
            {showAddNewQueWindow ?
                <AddNewQuestionWindow
                    selectedQue={selectedQue}
                    setSelectedQue={setSelectedQue}
                    addedQuestions={addedQuestions}
                    setShowAddNewQueWindow={setShowAddNewQueWindow}
                    setMgsType={setMgsType}
                    resetMessageType={resetMessage}
                    setAddedQuestions={setAddedQuestions}
                />
                : null
            }
            <div className="mb-5" style={{ paddingBottom: isMobile ? 50 : undefined }}>
                <Row lg={12}>
                    <Col lg={8} className="mt-3">
                        {!isMobile ? <><Button
                            look="outline"
                            icon="reset"
                            onClick={() => resetLayout()}
                        >Reset Layout
                        </Button>
                        <span>  |  </span>
                        <Button
                            look="outline"
                            icon="reset"
                            onClick={() => modifyLayout()}
                        >Modify Layout
                        </Button>
                        &nbsp;
                        <Button
                            look="outline"
                            icon="preview"
                            onClick={() => setPreview(true)}
                        >Preview
                        </Button>
                        &nbsp;
                        <Button
                            primary={resetLayoutFlag}
                            look="outline"
                            icon="save"
                            disabled={!resetLayoutFlag}
                            onClick={() => doneClick()}
                        >Done
                        </Button></> :
                        <Dropdown drop="up" id="actions">
                            <Dropdown.Toggle name="actionsToggle">
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => resetLayout()}
                                >
                                    <i class="k-icon k-i-refresh"></i>&nbsp;&nbsp;&nbsp;Reset Layout
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => modifyLayout()}
                                >
                                    <i class="k-icon k-i-refresh"></i>&nbsp;&nbsp;&nbsp;Modify Layout
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={!resetLayoutFlag}
                                    onClick={() => doneClick()}
                                >
                                    <i class="k-icon k-i-save"></i>&nbsp;&nbsp;&nbsp;Done
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RequiredQuestionsSetup;
