import React from "react";
import { Button as Btn, Modal } from 'react-bootstrap';
import { ListView } from "@progress/kendo-react-listview";
import moment from "moment";

const MyItemRender = (props) => {
    const item = props.dataItem;
    const url = item.empId ?
        `/#/empPreview/${item.empId}/${item.id}`
        : `/#/visPreview/${item.visId}/${item.id}`;
    return (
        <div
            className="row p-2 border-bottom align-middle"
            style={{ margin: 0 }}
        >
            <div className="col-4">
                <h2
                    style={{
                        fontSize: 14,
                        color: "#454545",
                        marginBottom: 0,
                    }}
                    className="text-uppercase"
                >
                    {item.documentType}
                </h2>
            </div>
            <div className="col-6">
                <h2
                    style={{
                        fontSize: 14,
                        color: "#454545",
                        marginBottom: 0,
                    }}
                    className="text-uppercase"
                >
                    {item.name}
                </h2>
                <div
                    style={{
                        fontSize: 12,
                        color: "#a0a0a0",
                    }}
                >
                    Uploaded: <i>{moment(item.created).format('lll')}</i>
                </div>
            </div>
            <div className="col-2">
                <Btn
                    variant="link"
                    style={{ fontSize: 14 }}
                    onClick={() => window.open(url)}
                >
                    PREVIEW
                </Btn>
            </div>
        </div>
    );
};

const AttachmentsPreviewModal = ({
    previewAttachments,
    setPreviewAttachments,
}) => (
    <Modal
        show={previewAttachments.length > 0}
        onHide={() => {
            setPreviewAttachments([]);
        }}
        size="lg"
    >
        <Modal.Header
            style={{
                backgroundColor: "#343a40",
                borderColor: "#343a40",
                color: "#ffffff",
            }}
        >
            <Modal.Title style={{ width: '100%' }}>
                Attachments
            </Modal.Title>
        </Modal.Header>
        <Modal.Body
            style={{ backgroundColor: "#e4e7eb" }}
        >
            <div>
                <ListView
                    data={previewAttachments}
                    item={MyItemRender}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button
                type="button"
                className="k-button"
                style={{
                    backgroundColor: '#343a40',
                    color: 'white',
                }}
                onClick={() => {
                    setPreviewAttachments([]);
                }}
            >
                Done
            </button>
        </Modal.Footer>
    </Modal>
);

export default AttachmentsPreviewModal;
