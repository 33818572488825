import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Divider } from 'antd';
import { Accordion, Row, Col, Card } from "react-bootstrap";
import axios from 'axios';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Form } from "react-bootstrap";
import { Spin } from 'antd';
import getTokenConfig from "../../../methods/getTokenConfig";
import '../../stylesheets/stripeCardElementStyles.css';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true,
};

const PaymentDetails = ({
    companyEmail,
    inEditMode,
    isLoading = false,
    setMessage,
    resetMessage,
}) => {
    const [cardType, setCardType] = useState('');
    const [last4, setLast4] = useState('**** **** **** ****');
    const [upcommingInvoice, setUpcommingInvoice] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/auth/getPaymentInfo', getTokenConfig())
            .then((res) => {
                if (res.status === 200) {
                    setCardType(res.data.cardType);
                    setLast4(`**** **** **** ${res.data.last4}`);
                    setUpcommingInvoice(res.data.upcommingInvoice);
                }
            }).catch((err) => {
                setMessage({ status: 'error', msg: err.response && err.response.data && err.response.data.msg });
                resetMessage(1800);
            });
    }, [resetMessage, setMessage]);

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: '#cce5ff',
                            color: '#004085',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            cursor: 'pointer',
                        }}
                    >
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title>
                                        Monthly:
                                    </Card.Title>
                                    $12.00 flat fees (includes 200 text messages)
                                </Col>
                                <Col>
                                    <Card.Title>
                                        Overages:
                                    </Card.Title>
                                    201 - 499 text messages - $0.07 cents each<br />
                                    500 - 999 text messages - $0.065 cents each<br />
                                    1000+ text messages - $0.06 cents each
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    Note - email costs $0.01 cents each
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Spin size="large" spinning={isLoading} >
                        {inEditMode === 'payment' ?
                            <Card>
                                <Card.Body>
                                    <form className="k-form">
                                        <fieldset>
                                            <Row>
                                                <Col>
                                                    <label className="k-form-field">
                                                        <div>Company Email</div>
                                                        <Form.Control
                                                            plaintext
                                                            readOnly
                                                            defaultValue={companyEmail}
                                                            style={{ color: '#aab7c4' }}
                                                        />
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Divider orientation="left">
                                                <img
                                                    src={require('../../stylesheets/stripe.png')}
                                                    alt="stripe"
                                                    height={43}
                                                    width={92}
                                                />
                                            </Divider>
                                            <Row>
                                                <Col>
                                                    <label className="k-form-field">
                                                        <div style={{ marginBottom: 8 }}>Card Number</div>
                                                        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label className="k-form-field">
                                                        <div style={{ marginBottom: 8 }}>Exp Date</div>
                                                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                                                    </label>
                                                </Col>
                                                <Col>
                                                    <label className="k-form-field">
                                                        <div style={{ marginBottom: 8 }}>CVC</div>
                                                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                                                    </label>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </form>
                                </Card.Body>
                            </Card>
                            : <Card>
                                <Card.Body>
                                    <strong>Card Type:</strong> {cardType}
                                    <br />
                                    <br />
                                    <strong>Card #:</strong> {last4}
                                </Card.Body>
                            </Card>
                        }
                    </Spin>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Accordion className="mb-3">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: 'pointer' }}>
                                <strong>Upcoming Payments</strong>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <strong>Pay Period:&nbsp;</strong><br />
                                            {moment.unix(upcommingInvoice.period_start).format("M/D/YYYY h:mm a")} - {moment.unix(upcommingInvoice.period_end).format("M/D/YYYY h:mm a")}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <strong>Next Payment Date:&nbsp;</strong><br />
                                            {moment.unix(upcommingInvoice.next_payment_attempt).format("M/D/YYYY h:mm a")}
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <strong>Amount: (as of {moment().subtract(1, 'days').format("M/D/YYYY")})</strong><br />
                                            Subtotal - ${(upcommingInvoice.subtotal / 100).toFixed(2)}<br />
                                            Tax - ${(upcommingInvoice.tax / 100).toFixed(2)}<br />
                                            Total - ${(upcommingInvoice.total / 100).toFixed(2)}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </>
    );
};
export default PaymentDetails;
