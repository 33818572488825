import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import Switch from "react-switch";
import { Divider } from 'antd';

const QRCodeSettings = ({
    inEditMode,
    setQRCodeVerificationNeeded,
    qrCodeVerificationNeeded,
    setInEditMode,
}) => {
    return (
        <>
            <Row>
                <Col>
                    <Divider orientation="left">Verify QR Code Settings</Divider>
                    <Form>
                        <Row md='12'>
                            <Col md='6'>
                                <Form.Group controlId="qrCodeVerificationNeeded">
                                    <Form.Label>
                                        <strong>QR Code Verification Required</strong>
                                        <br />
                                        <br />
                                        <small className="text-muted">
                                            <i>
                                                Enabling this setting will show a button on the page of the screening
                                            <br />
                                                survey with the last question for users to verify their Vaccination
                                            <br />
                                                records.
                                                <br />
                                                <br />
                                                Users will have two methods of verification to choose from:
                                            <br />
                                                {">"} Scanning their QR code (<i className="fas fa-camera" /> Scan QR Code)
                                            <br />
                                                {">"} Choosing an image on their device (<i className="far fa-images" /> Gallery)
                                                <br />
                                                <br />
                                                <b>Additional charges will apply when enabled.</b>
                                            </i>
                                        </small>
                                    </Form.Label><br />
                                    <Switch
                                        checked={qrCodeVerificationNeeded ? true : false}
                                        disabled={inEditMode !== 'qrCodeSetting'}
                                        onChange={() => {
                                            setQRCodeVerificationNeeded(e=>!e)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md='6'>
                                <strong>Legend showing the status when employees/visitors verify their QR Code:</strong>
                                        <Row md='12' className="mb-3 mt-3">
                                            <Col md='3' style={{textAlign:'center'}}>
                                          <svg style={{ fontSize: '40px', color: '#52c41a' }} viewBox="64 64 896 896" focusable="false" class="" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg>
                                                </Col>
                                            <Col md='9' style={{margin:'auto'}}>Vaccination QR Code Verified.</Col>
                                        </Row>
                                <Row md='12' className="mb-3">
                                    <Col md='3' style={{
                                        textAlign: 'center', margin:'auto'  }}>
                                        <svg style={{ fontSize: '40', color: 'grey'}} viewBox="64 64 896 896" focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg>
                                    </Col>
                                    <Col md='9' style={{ margin: 'auto' }}>Vaccination QR Code NOT Verified. <br/>
                                    Employee or Visitor did not attempt to verify.</Col>
                                        </Row> 
                                <Row md='12' className="mb-3">
                                    <Col md='3' style={{ textAlign: 'center' }}>
                                        <svg style={{ fontSize: '40', color: '#fc4d4f' }} viewBox="64 64 896 896" focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg>
                                        </Col>
                                    <Col md='9' style={{ margin: 'auto' }}>Vaccination QR Code NOT Verified. <br/>
                                    Employee or Visitor attempted to verify but failed verification.</Col>
                                        </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default QRCodeSettings;
