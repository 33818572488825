const US = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];

const canada = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
];

export const countryOptions = [
    { country: 'Canada', stpr: 'Province', options: canada },
    { country: 'US', stpr: 'State', options: US },
];

export const canadaHelplineNumbers = {
    "Alberta":
    {
        phNo: ["8-1-1"],
        website: "https://www.alberta.ca/coronavirus-info-for-albertans.aspx"
    },
    "British Columbia":
    {
        phNo: ["1-888-268-4319"],
        website: "http://www.bccdc.ca/health-info/diseases-conditions/covid-19"
    },
    "Manitoba":
    {
        phNo: ["1-888-315-9257", "204-788-8200"],
        website: "https://www.gov.mb.ca/covid19/contact.html"
    },
    "New Brunswick":
    {
        phNo: ["1-833-475-0724"],
        website: "https://www2.gnb.ca/content/gnb/en/corporate/promo/covid-19/contact_info.html"
    },
    "Newfoundland and Labrador":
    {
        phNo: ["8-1-1", "1-855-753-2560"],
        website: "https://www.gov.nl.ca/covid-19/contact-us/"
    },
    "Northwest Territories":
    {
        phNo: ["8-1-1"],
        website: "https://www.gov.nt.ca/covid-19/en/contact"
    },
    "Nova Scotia":
    {
        phNo: ["8-1-1", "1-833-784-4397"],
        website: "https://811.novascotia.ca/"
    },
    "Nunavut":
    {
        phNo: ["1-888-975-8601"],
        website: "https://www.gov.nu.ca/health/information/covid-19-novel-coronavirus"
    },
    "Ontario":
    {
        phNo: ["647-260-7100"],
        website: "https://www.publichealthontario.ca/en/contact"
    },
    "Prince Edward Island":
    {
        phNo: ["1-833-533-9333", "1-866-222-1751"],
        website: "https://www.princeedwardisland.ca/en/information/health-and-wellness/covid-19-inquiries"
    },
    "Quebec":
    {
        phNo: ["1-877-644-4545"],
        website: "https://www.quebec.ca/en/health/health-issues/a-z/2019-coronavirus/"
    },
    "Saskatchewan":
    {
        phNo: ["1855-559-5502", "306-787-8539"],
        website: "https://www.saskatchewan.ca/government/health-care-administration-and-provider-resources/treatment-procedures-and-guidelines/emerging-public-health-issues/2019-novel-coronavirus/contact#:~:text=Saskatchewan%20Public%20Safety%20Agency%20(SPSA,306%2D787%2D8539)."
    },
    "Yukon":
    {
        phNo: ["1-877-374-0425"],
        website: "https://yukon.ca/en/health-and-wellness/covid-19-information/contact-list-covid-19"
    }
};
