import React, { useState } from "react";
import axios from 'axios';
import { Window } from '@progress/kendo-react-dialogs';
import moment from 'moment';
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification } from '@progress/kendo-react-notification';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { filterBy } from "@progress/kendo-data-query";
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import getTokenConfig from '../../../methods/getTokenConfig';
import '../../stylesheets/windowStyles.css';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => (emailRegex.test(value));

const currentStatusOptions = ['Active', 'On Hold', 'Terminated'];
const verifiedOptions = ['Verified', 'Not Verified'];
const itemRender = (li, itemProps) => {
    let icon = <span className="k-icon"></span>;
    if (li.props.children === 'Active' || li.props.children === 'Verified') {
        icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
    } else if (li.props.children === 'On Hold') {
        icon = <i className="far fa-pause-circle"></i>
    } else if (li.props.children === 'Terminated' || li.props.children === 'Not Verified') {
        icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
    }

    const itemChildren =
        <span style={{ fontSize: 16 }}>
            {icon}&nbsp;&nbsp;{li.props.children}
        </span>;

    return React.cloneElement(li, li.props, itemChildren);
}

const valueRender = (element, value) => {
    if (!value) {
        return element;
    }
    let icon = <span className="k-icon"></span>;
    if (value === 'Active' || value === 'Verified') {
        icon = <i className="fas fa-check-circle" style={{ color: 'green' }}></i>;
    } else if (value === 'On Hold') {
        icon = <i className="far fa-pause-circle"></i>
    } else if (value === 'Terminated' || value === 'Not Verified') {
        icon = <i className="fas fa-ban" style={{ color: '#B01C2E' }}></i>
    }

    const children = [
        <span key={1}>
            {icon}
        </span>,
        <span key={2}>&nbsp; {element.props.children}</span>
    ];
    return React.cloneElement(element, { ...element.props }, children);
}

const AddNewEmployeeWindow = ({
    setShowWindow,
    selectedEmp,
    setSelectedEmp,
    setEmployeesList,
    setMgsType,
    qrCodeVerificationNeeded,
    userGroupOptions,
}) => {
    const [firstName, setFirstName] = useState((selectedEmp && selectedEmp.firstName) || '');
    const [lastName, setLastName] = useState((selectedEmp && selectedEmp.lastName) || '');
    // eslint-disable-next-line no-unused-vars
    const [userGroupObj, setUserGroupObj] = useState((selectedEmp && selectedEmp.userGroup) || {});
    const [userSiteObj, setUserSiteObj] = useState((selectedEmp && selectedEmp.userSite) || {});
    const [userDepartmentObj, setUserDepartmentObj] = useState((selectedEmp && selectedEmp.userDepartment) || {});
    const [currentStatus, setCurrentStatus] = useState((selectedEmp && selectedEmp.currentStatus) || '');
    const [cellNo, setCellNo] = useState((selectedEmp && selectedEmp.cellNumber) || '');
    const [email, setEmail] = useState((selectedEmp && selectedEmp.email) || '');
    const [type, setType] = useState({ status: null, message: '' });
    const [edit, setEdit] = useState(false);
    const [qrCodeVerification, setQrCodeVerification] = useState(
        selectedEmp && selectedEmp.qrCodeVerification ? selectedEmp.qrCodeVerification : {});

    const [departmentData, setDepartmentData] = useState([{ _id: '', name: '' }].concat(userGroupOptions.filter(group => group.type === 'Department').slice()) || []);
    const [siteData, setSiteData] = useState([{ _id: '', name: '' }].concat(userGroupOptions.filter(group => group.type === 'Site').slice()) || []);

    const resetType = (time) => {
        setTimeout(() => {
            setType({ status: null, message: '' });
        }, time);
    }
    const validations = () => {
        if (firstName === '' || firstName.trim() === '') {
            setType({ status: 'warning', message: 'Please enter first name' });
            resetType(1800);
            return true;
        }
        else if (lastName === '' || lastName.trim() === '') {
            setType({ status: 'warning', message: 'Please enter last name' });
            resetType(1800);
            return true;
        }
        else if (currentStatus === '' || currentStatus.trim() === '') {
            setType({ status: 'warning', message: 'Please enter status' });
            resetType(1800);
            return true;
        }
        else if (
            (cellNo === ''
                || cellNo === '(+1) ___-___-____')
            && (email === '' || email.trim() === '')
        ) {
            setType({ status: 'warningBoth', message: 'Either Cell Number or Email is Required' });
            resetType(1800);
            return true;
        }
        else if (email !== '' &&
            !email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setType({ status: 'warningBottom', message: 'Email address is not valid' });
            resetType(2500);
            return true;
        }
        else if (cellNo !== '' && cellNo !== '(+1) ___-___-____' && cellNo.replace(/[^0-9]/g, "").length < 11) {
            setType({ status: 'warningBottom', message: 'Cell number is not valid' });
            resetType(1800);
            return true;
        }
        return false;
    }

    const addNewEmployee = async (action) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const employee = {
            firstName,
            lastName,
            userSiteId: userSiteObj._id,
            userDepartmentId: userDepartmentObj._id,
            currentStatus,
            cellNumber: cellNo === '(+1) ___-___-____' ? '' : cellNo,
            email,
            qrCodeVerification,
            organizationId: orgId,
        }

        let newEmpId;
        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/add', employee, getTokenConfig())
            .then((res) => {
                if (action !== 'submit') {
                    setFirstName('');
                    setLastName('');
                    setUserSiteObj({});
                    setUserDepartmentObj({});
                    setCurrentStatus('');
                    setCellNo('');
                    setEmail('');
                    setQrCodeVerification({});
                }
                newEmpId = res.data;
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'New employee record added successfully.' });
                        setEmployeesList([...response.data])
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in adding new employee record.' });
                    });
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
        if (employee.userSiteId) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + employee.userSiteId,
                    { empIdToAdd: newEmpId },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
        if (employee.userDepartmentId) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + employee.userDepartmentId,
                    { empIdToAdd: newEmpId },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
    }

    const updateEmployee = async () => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const employee = {
            firstName,
            lastName,
            userGroupId: userSiteObj._id && userDepartmentObj._id ? '' : userGroupObj._id,
            userSiteId: userSiteObj._id,
            userDepartmentId: userDepartmentObj._id,
            currentStatus,
            cellNumber: cellNo === '(+1) ___-___-____' ? '' : cellNo,
            email,
            qrCodeVerification,
            organizationId: orgId,
        }

        const userSiteIdChange = (selectedEmp.userSiteId !== '' && selectedEmp.userSiteId !== undefined &&
            selectedEmp.userSiteId !== null && employee.userSiteId !== '' && employee.userSiteId !== undefined &&
            employee.userSiteId !== null) && selectedEmp.userSiteId !== employee.userSiteId;

        const userDepartmentIdChange = (selectedEmp.userDepartmentId !== '' && selectedEmp.userDepartmentId !== undefined &&
            selectedEmp.userDepartmentId !== null && employee.userDepartmentId !== '' && employee.userDepartmentId !== undefined &&
            employee.userDepartmentId !== null) && selectedEmp.userDepartmentId !== employee.userDepartmentId;

        await axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/update/' + selectedEmp._id, employee, getTokenConfig())
            .then(res => {
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/employees/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'Employee record updated successfully.' });
                        setEmployeesList([...response.data]);
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in updating employee record.' });
                    });
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
        if (userDepartmentIdChange) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + selectedEmp.userDepartmentId,
                    { empIdToRemove: selectedEmp._id },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
        if (userSiteIdChange) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + selectedEmp.userSiteId,
                    { empIdToRemove: selectedEmp._id },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
        if (employee.userSiteId && selectedEmp.userSiteId !== employee.userSiteId) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + employee.userSiteId,
                    { empIdToAdd: selectedEmp._id },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
        if (employee.userDepartmentId && selectedEmp.userDepartmentId !== employee.userDepartmentId) {
            try {
                await axios.post(
                    process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + employee.userDepartmentId,
                    { empIdToAdd: selectedEmp._id },
                    getTokenConfig(),
                );
            } catch (err) {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            }
        }
        setSelectedEmp(null);
        setEdit(false);
        setShowWindow(false);
    }

    const filterDepartmentData = (filter) => {
        const filteredUserGroupOptions = [{ _id: '', name: '' }].concat(userGroupOptions.filter(group => group.type === 'Department').slice());
        return filterBy(filteredUserGroupOptions, filter);
    };

    const filterSiteData = (filter) => {
        const filteredUserGroupOptions = [{ _id: '', name: '' }].concat(userGroupOptions.filter(group => group.type === 'Site').slice());
        return filterBy(filteredUserGroupOptions, filter);
    };

    const filterDepartmentChange = (event) => {
        setDepartmentData(filterDepartmentData(event.filter));
    };

    const filterSiteChange = (event) => {
        setSiteData(filterSiteData(event.filter));
    };

    return (
        <Window
            title={selectedEmp ? "Edit Employee Info" : "Add New Employee"}
            onClose={() => {
                setSelectedEmp(null);
                setShowWindow(false);
            }}
            initialHeight={!isMobile ? 'calc(85vh/1.5)' : '100%'}
            initialWidth={!isMobile ? 450 : '100%'}
            initialTop={0}
            style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        >
            <form className="k-form">
                <fieldset>
                    {(type.status === 'warning' || type.status === 'warningBoth') &&
                        <Fade
                            enter={true}
                            exit={true}>
                            {type.status !== null && <Notification
                                style={{
                                    width: '400px'
                                }}
                                type={{ style: 'error', icon: true }}
                                onClose={() => setType({ status: null, message: '' })}
                            >
                                <span>{type.message}</span>
                            </Notification>}
                        </Fade>}

                    <label className="k-form-field">
                        <p>First Name <span style={{ color: 'red' }}>*</span></p>
                        <Input
                            type={"text"}
                            placeholder="Employee First Name"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>
                    <label className="k-form-field">
                        <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                        <Input
                            type={"text"}
                            placeholder="Employee Last Name"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>
                    <label className="k-form-field">
                        <p>Department</p>
                        <DropDownList
                            data={departmentData}
                            textField="name"
                            dataItemKey="_id"
                            style={{ backgroundColor: 'white' }}
                            value={userDepartmentObj}
                            onChange={(e) => {
                                setUserDepartmentObj(e.value);
                                setEdit(true);
                            }}
                            filterable={true}
                            onFilterChange={filterDepartmentChange}
                        />
                    </label>
                    <label className="k-form-field">
                        <p>Site</p>
                        <DropDownList
                            data={siteData}
                            textField="name"
                            dataItemKey="_id"
                            style={{ backgroundColor: 'white' }}
                            value={userSiteObj}
                            onChange={(e) => {
                                setUserSiteObj(e.value);
                                setEdit(true);
                            }}
                            filterable={true}
                            onFilterChange={filterSiteChange}
                        />
                    </label>
                    {selectedEmp && selectedEmp.userGroup &&
                        <label className="k-form-field">
                            <p>Other Group&nbsp;
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            <i>
                                                This field will not be displayed once the group type of 'Site' or 'Department' has been set for <strong>{selectedEmp.userGroup.name}</strong>.
                                                <br /><br />
                                                Once the group type is set, <strong>{selectedEmp.userGroup.name}</strong> will be displayed in the appropriate dropdown list above.
                                                <br /><br />
                                                However, if prior to changing the group type, you set both a Site and a Department for this user, Other Group will not be displayed and the user will be removed from <strong>{selectedEmp.userGroup.name}</strong>.
                                                <br /><br />
                                                Values for 'Other Group' <strong>cannot</strong> be modified and are for informational purposes only.
                                            </i>
                                        </Tooltip>}
                                >
                                    <i className="fas fa-info-circle" />
                                </OverlayTrigger></p>
                            <DropDownList
                                disabled={true}
                                textField="name"
                                dataItemKey="_id"
                                style={{ backgroundColor: 'white' }}
                                value={userGroupObj}
                            />
                        </label>}
                    <label className="k-form-field">
                        <p>Current Status<span style={{ color: 'red' }}>*</span></p>
                        <DropDownList
                            data={currentStatusOptions}
                            placeholder="e.g. Active"
                            itemRender={itemRender}
                            valueRender={valueRender}
                            style={{ backgroundColor: 'white' }}
                            value={currentStatus}
                            onChange={(e) => {
                                setCurrentStatus(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>
                    <label className="k-form-field">
                        <p>Cell #<span style={{ color: 'red' }}>*</span></p>
                        <MaskedTextBox
                            mask="(+1) 000-000-0000"
                            placeholder="Employee Cell Number"
                            value={cellNo}
                            onChange={(e) => {
                                setCellNo(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>
                    <label className="k-form-field">
                        <p>Email Address<span style={{ color: 'red' }}>*</span></p>
                        <Input
                            type={"email"}
                            validator={emailValidator}
                            placeholder="Employee Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.value)
                                setEdit(true);
                            }}
                        />
                    </label>
                    {qrCodeVerificationNeeded && <label className="k-form-field">
                        <p>Vaccination Verified<span style={{ color: 'red' }}>*</span></p>
                        <DropDownList
                            disabled={currentStatus !== 'Active' && currentStatus !== 'On Hold'}
                            data={verifiedOptions}
                            itemRender={itemRender}
                            valueRender={valueRender}
                            style={{ backgroundColor: 'white' }}
                            value={qrCodeVerification.vaccineVerified ? 'Verified' : 'Not Verified'}
                            onChange={(e) => {
                                const changeTime = moment().format("ddd, M/D/YYYY");
                                setQrCodeVerification({
                                    vaccineVerified: e.value === 'Verified' ? true : false,
                                    vaccineVerifiedDate: changeTime,
                                });
                                setEdit(true);
                            }}
                        />
                    </label>}
                    {(type.status === 'warningBottom' || type.status === 'warningBoth') &&
                        <Fade
                            enter={true}
                            exit={true}>
                            {type.status !== null && <Notification
                                style={{
                                    width: '400px'
                                }}
                                type={{ style: 'error', icon: true }}
                                onClose={() => setType({ status: null, message: '' })}
                            >
                                <span>{type.message}</span>
                            </Notification>}
                        </Fade>}
                </fieldset>

                <div className="text-right">
                    <button
                        type="button"
                        className="k-button"
                        onClick={() => {
                            setSelectedEmp(null);
                            setShowWindow(false);
                        }}
                    >Cancel
                    </button>
                    {selectedEmp ?
                        <button
                            type="button"
                            className="k-button"
                            disabled={!edit}
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            onClick={() => {
                                if (!validations())
                                    updateEmployee();
                            }}
                        >Update
                        </button> :
                        <>
                            <button
                                type="button"
                                className="k-button"
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                disabled={!edit}
                                onClick={() => {
                                    if (!validations()) {
                                        addNewEmployee('submit');
                                        setShowWindow(false);
                                    }
                                }}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="k-button"
                                disabled={!edit}
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    if (!validations())
                                        addNewEmployee('submitAdd');
                                }}
                            >
                                Submit & Add
                            </button>
                        </>
                    }
                </div>
            </form>
        </Window>
    );
};

export default AddNewEmployeeWindow;
