import React from "react";
import { Row, Col } from "react-bootstrap";
import { Divider } from 'antd';

const DeactivateDetails = () => (
    <Row>
        <Col>
            <Divider orientation="left">Deactivation</Divider>
            <p>
                Do you really want to deactivate your account?
            </p>
            <p>
                Once you deactivate the account, you will not be able to reactivate account.<br />
                Also, you will not be able to get any of the data back.<br />
                Make sure you save all your data locally before you deactivate your account.
            </p>
            <hr />
            <p>
                <strong>
                    You will get charged for the flat fee and the usage as of now for the current billing period.
                </strong>
            </p>
        </Col>
    </Row>
);

export default DeactivateDetails;
