import React from "react";
import { Navbar, Nav } from 'react-bootstrap';

const Footer = () => (
    <Navbar collapseOnSelect fixed="bottom" expand="lg" bg="dark" variant="dark">
        <Navbar.Text>
            Created By: IWA
        </Navbar.Text>
        <Nav className="mr-auto" />
        <Nav>
            <Nav.Link
                href="https://checkn.ca/user-manual"
                target="_blank"
            >
                User Manual
            </Nav.Link>
            <Nav.Link
                href="https://checkn.ca/faq-section/"
                target="_blank"
            >
                F.A.Q
            </Nav.Link>
        </Nav>
    </Navbar>
);

export default Footer;
