import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Window } from '@progress/kendo-react-dialogs';
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { isMobile } from 'react-device-detect';
import { countryOptions } from "../../util/countryStateProvience";
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import getTokenConfig from '../../../methods/getTokenConfig';
import '../../stylesheets/windowStyles.css';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) => {
    return (emailRegex.test(value));
};

const currentGroupTypes = ['Department', 'Site'];
const countryOpt = countryOptions.map(ele => ele.country);

const  AddNewUserGroupWindow = ({
    setShowWindow,
    selectedUserGroup,
    setSelectedUserGroup,
    setUserGroupsList,
    setMgsType,
}) => {
    const [groupName, setGroupName] = useState((selectedUserGroup && selectedUserGroup.name) || '');
    const [groupType, setGroupType] = useState((selectedUserGroup && selectedUserGroup.type) || '');

    const [groupCountry, setGroupCountry] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.country) || '');
    const [groupAddress1, setGroupAddress1] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.address1) || '');
    const [groupAddress2, setGroupAddress2] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.address2) || '');
    const [groupCity, setGroupCity] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.city) || '');
    const [groupStateProv, setGroupStateProv] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.stateProv) || '');
    const [groupPostalCode, setGroupPostalCode] = useState((selectedUserGroup && selectedUserGroup.locationInfo && selectedUserGroup.locationInfo.postalCode) || '');

    const [primConFirstName, setPrimConFirstName] = useState((selectedUserGroup && selectedUserGroup.primaryContact && selectedUserGroup.primaryContact.firstName) || '');
    const [primConLastName, setPrimConLastName] = useState((selectedUserGroup && selectedUserGroup.primaryContact && selectedUserGroup.primaryContact.lastName) || '');
    const [primConCellNo, setPrimConCellNo] = useState((selectedUserGroup && selectedUserGroup.primaryContact && selectedUserGroup.primaryContact.cellNumber) || '');
    const [primConEmail, setPrimConEmail] = useState((selectedUserGroup && selectedUserGroup.primaryContact && selectedUserGroup.primaryContact.email) || '');

    const [type, setType] = useState({ status: null, message: '' });
    const [edit, setEdit] = useState(false);

    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    let stateOpt = [];
    if (groupCountry) {
        stateOpt = countryOptions.filter(ele => ele.country === groupCountry)[0].options;
    }

    useEffect(() => {
        let initialWidth = document.documentElement.clientWidth;
        if (initialWidth < 700) {
            setIsNarrowScreen(true);
        } else {
            setIsNarrowScreen(false);
        }
  
        window.addEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
        return () => window.removeEventListener('resize', (event) => {
            let gridWidth = document.documentElement.clientWidth;
            if (gridWidth < 700) {
                setIsNarrowScreen(true);
            } else {
                setIsNarrowScreen(false);
            }
        });
    }, []);

    const resetType = (time) => {
        setTimeout(() => {
            setType({ status: null, message: '' });
        }, time);
    }

    const validations = () => {
        if (groupName === '' || groupName.trim() === '') {
            setType({ status: 'warning', message: 'Please enter a user group name.' });
            resetType(2500);
            return true;
        }
        else if (groupType === '' || groupType.trim() === '') {
            setType({ status: 'warningBoth', message: 'Please select a group type.' });
            resetType(2500);
            return true;
        }
        else if (primConEmail !== '' &&
            !primConEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setType({ status: 'warningBottom', message: 'Email address is not valid.' });
                resetType(2500);
                return true;
        }
        return false;
    }

    const addNewUserGroup = (action) => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const userGroup = {
            name: groupName.trim(),
            type: groupType,
            organizationId: orgId,
        };

        const locationInfo = {};
        let nonEmptyProperties = 0;
        if (groupCountry !== '') {
            locationInfo.country = groupCountry;
            nonEmptyProperties++;
        }
        if (groupAddress1 !== '') {
            locationInfo.address1 = groupAddress1;
            nonEmptyProperties++;
        }
        if (groupAddress2 !== '') {
            locationInfo.address2 = groupAddress2;
            nonEmptyProperties++;
        }
        if (groupCity !== '') {
            locationInfo.city = groupCity;
            nonEmptyProperties++;
        }
        if (groupStateProv !== '') {
            locationInfo.stateProv = groupStateProv;
            nonEmptyProperties++;
        }
        if (groupPostalCode !== '') {
            locationInfo.postalCode = groupPostalCode;
            nonEmptyProperties++;
        }
        if (nonEmptyProperties > 0) {
            userGroup.locationInfo = locationInfo;
        }

        const primaryContact = {};
        nonEmptyProperties = 0;
        if (primConFirstName !== '') {
            primaryContact.firstName = primConFirstName;
            nonEmptyProperties++;
        }
        if (primConLastName !== '') {
            primaryContact.lastName = primConLastName;
            nonEmptyProperties++;
        }
        if (primConCellNo !== '(+1) ___-___-____' && primConCellNo !== '') {
            primaryContact.cellNumber = primConCellNo;
            nonEmptyProperties++;
        }
        if (primConEmail !== '') {
            primaryContact.email = primConEmail;
            nonEmptyProperties++;
        }
        if (nonEmptyProperties > 0) {
            userGroup.primaryContact = primaryContact;
        }

        axios.post(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/add', userGroup, getTokenConfig())
            .then((res) => {
                if (action !== 'submit') {
                    setGroupName('');
                    setGroupType('');
                    setGroupCountry('');
                    setGroupAddress1('');
                    setGroupAddress2('');
                    setGroupCity('');
                    setGroupStateProv('');
                    setGroupPostalCode('');
                    setPrimConFirstName('');
                    setPrimConLastName('');
                    setPrimConCellNo('');
                    setPrimConEmail('');
                }
                axios.get(process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgId/' + orgId, getTokenConfig())
                    .then(response => {
                        setMgsType({ status: 'success', message: 'New user group added successfully.' });
                        setUserGroupsList([...response.data])
                    }).catch((error) => {
                        setMgsType({ status: 'error', message: 'Error in adding new user group.' });
                    });
            }).catch((err) => {
                setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
            });
    }

    const updateUserGroup = async () => {
        const orgId = localStorage.getItem('CheckN.orgId');
        const userGroup = {
            name: groupName.trim(),
            type: groupType,
            organizationId: orgId,
        };

        const locationInfo = {};
        let nonEmptyProperties = 0;
        if (groupCountry !== '') {
            locationInfo.country = groupCountry;
            nonEmptyProperties++;
        }
        if (groupAddress1 !== '') {
            locationInfo.address1 = groupAddress1;
            nonEmptyProperties++;
        }
        if (groupAddress2 !== '') {
            locationInfo.address2 = groupAddress2;
            nonEmptyProperties++;
        }
        if (groupCity !== '') {
            locationInfo.city = groupCity;
            nonEmptyProperties++;
        }
        if (groupStateProv !== '') {
            locationInfo.stateProv = groupStateProv;
            nonEmptyProperties++;
        }
        if (groupPostalCode !== '') {
            locationInfo.postalCode = groupPostalCode;
            nonEmptyProperties++;
        }
        if (nonEmptyProperties > 0) {
            userGroup.locationInfo = locationInfo;
        }

        const primaryContact = {};
        nonEmptyProperties = 0;
        if (primConFirstName !== '') {
            primaryContact.firstName = primConFirstName;
            nonEmptyProperties++;
        }
        if (primConLastName !== '') {
            primaryContact.lastName = primConLastName;
            nonEmptyProperties++;
        }
        if (primConCellNo !== '(+1) ___-___-____' && primConCellNo !== '') {
            primaryContact.cellNumber = primConCellNo;
            nonEmptyProperties++;
        }
        if (primConEmail !== '') {
            primaryContact.email = primConEmail;
            nonEmptyProperties++;
        }
        if (nonEmptyProperties > 0) {
            userGroup.primaryContact = primaryContact;
        }

        try {
            await axios.post(
                process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/update/' + selectedUserGroup._id,
                userGroup,
                getTokenConfig(),
            );
            const byOrgIdRes = await axios.get(
                process.env.REACT_APP_BACKEND_CONN_IP + '/userGroups/byOrgId/' + orgId,
                getTokenConfig(),
            );
            setMgsType({ status: 'success', message: 'User Group updated successfully.' });
            setUserGroupsList([...byOrgIdRes.data]);
        } catch (err) {
            setMgsType({ status: 'error', message: err.response && err.response.data && err.response.data.msg });
        }
        setSelectedUserGroup(null);
        setEdit(false);
        setShowWindow(false);
    }

    return (
        <Window
            title={selectedUserGroup ? "Edit User Group" : "Add User Group"}
            onClose={() => {
                setSelectedUserGroup(null);
                setShowWindow(false);
            }}
            initialHeight={!isMobile ? 'calc(100vh/1.5)' : '80%'}
            initialWidth={!isMobile ? 450 : !isNarrowScreen ? '70%' : '100%'}
            initialTop={isNarrowScreen || !isMobile ? 80 : 0}
            initialLeft={isMobile || isNarrowScreen ? 70 : undefined}
            style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
        >
            <form className="k-form">
                <fieldset>
                    {(type.status === 'warningBoth' || type.status === 'warning') &&
                        <Fade
                            enter={true}
                            exit={true}>
                            {type.status !== null && <Notification
                                style={{
                                    width: '400px'
                                }}
                                type={{ style: 'error', icon: true }}
                                onClose={() => setType({ status: null, message: '' })}
                            >
                                <span>{type.message}</span>
                            </Notification>}
                        </Fade>}

                    <label className="k-form-field">
                        <p><strong>Group Name</strong> <span style={{ color: 'red' }}>*</span></p>
                        <Input
                            type={"text"}
                            placeholder="Group Name"
                            value={groupName}
                            onChange={(e) => {
                                setGroupName(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>
                    <label className="k-form-field">
                        <p><strong>Group Type</strong> <span style={{ color: 'red' }}>*</span></p>
                        <DropDownList
                            data={currentGroupTypes}
                            placeholder="e.g. Site"
                            style={{ backgroundColor: 'white' }}
                            value={groupType}
                            onChange={(e) => {
                                setGroupType(e.value);
                                setEdit(true);
                            }}
                        />
                    </label>

                    <p><strong>Other Info</strong></p>
                    <PanelBar style={{ backgroundColor: '#e5e7eb', borderWidth: 0 }} expandMode={"single"}>
                        <PanelBarItem title={"More Group Details..."}>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>Country</strong></p>
                                <DropDownList
                                    data={countryOpt}
                                    placeholder="e.g. Canada"
                                    style={{ backgroundColor: 'white', width: '100%' }}
                                    value={groupCountry}
                                    onChange={(e) => {
                                        setGroupCountry(e.target.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>Address 1</strong></p>
                                <Input
                                    placeholder="Enter Address 1"
                                    value={groupAddress1}
                                    onChange={(e) => {
                                        setGroupAddress1(e.target.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>Address 2</strong></p>
                                <Input
                                    placeholder="Enter Address 2"
                                    value={groupAddress2}
                                    onChange={(e) => {
                                        setGroupAddress2(e.target.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>City</strong></p>
                                <Input
                                    placeholder="Enter City"
                                    value={groupCity}
                                    onChange={(e) => {
                                        setGroupCity(e.target.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>{groupCountry === 'US' ? 'State' : 'Province'}</strong></p>
                                    <DropDownList
                                        data={stateOpt}
                                        placeholder="e.g. Ontario"
                                        style={{ backgroundColor: 'white', width: '100%' }}
                                        value={groupStateProv}
                                        onChange={(e) => {
                                            setGroupStateProv(e.target.value);
                                            setEdit(true);
                                        }}
                                    />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p><strong>{groupCountry === 'US' ? 'Zip Code' : 'Postal Code'}</strong></p>
                                <Input
                                    placeholder="Enter Postal Code"
                                        value={groupPostalCode}
                                    onChange={(e) => {
                                        setGroupPostalCode(e.target.value);
                                        setEdit(true);
                                    }}
                                />
                                <br />
                                <small><i>End of Group Details</i></small>
                                <hr />
                            </label>
                        </PanelBarItem>

                        <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field" />

                        <PanelBarItem title={"Primary Contact Info..."}>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p>First Name</p>
                                <Input
                                    type={"text"}
                                    placeholder="Primary Contact First Name"
                                    value={primConFirstName}
                                    onChange={(e) => {
                                        setPrimConFirstName(e.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p>Last Name</p>
                                <Input
                                    type={"text"}
                                    placeholder="Primary Contact Last Name"
                                    value={primConLastName}
                                    onChange={(e) => {
                                        setPrimConLastName(e.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p>Cell #</p>
                                <MaskedTextBox
                                    mask="(+1) 000-000-0000"
                                    placeholder="Primary Contact Cell Number"
                                    value={primConCellNo}
                                    onChange={(e) => {
                                        setPrimConCellNo(e.value);
                                        setEdit(true);
                                    }}
                                />
                            </label>
                            <label style={{ backgroundColor: '#e5e7eb' }} className="k-form-field">
                                <p>Email Address</p>
                                <Input
                                    type={"email"}
                                    validator={emailValidator}
                                    placeholder="Primary Contact Email"
                                    value={primConEmail}
                                    onChange={(e) => {
                                        setPrimConEmail(e.value)
                                        setEdit(true);
                                    }}
                                />
                                <br />
                                <small><i>End of Primary Contact Info</i></small>
                                <hr />
                            </label>
                        </PanelBarItem>
                    </PanelBar>
                    <br />

                    {(type.status === 'warningBoth' || type.status === 'warningBottom') &&
                        <Fade
                            enter={true}
                            exit={true}>
                            {type.status !== null && <Notification
                                style={{
                                    width: '400px'
                                }}
                                type={{ style: 'error', icon: true }}
                                onClose={() => setType({ status: null, message: '' })}
                            >
                                <span>{type.message}</span>
                            </Notification>}
                        </Fade>}
                </fieldset>

                <div className="text-right">
                    <button
                        type="button"
                        className="k-button"
                        onClick={() => {
                            setSelectedUserGroup(null);
                            setShowWindow(false);
                        }}
                    >Cancel
                    </button>
                    {selectedUserGroup ?
                        <button
                            type="button"
                            className="k-button"
                            disabled={!edit || !groupName || !groupType}
                            style={{
                                backgroundColor: '#343a40',
                                color: 'white',
                            }}
                            onClick={() => {
                                if (!validations())
                                    updateUserGroup();
                            }}
                        >Update
                        </button> :
                        <>
                            <button
                                type="button"
                                className="k-button"
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                disabled={!groupName || !groupType}
                                onClick={() => {
                                    if (!validations()) {
                                        addNewUserGroup('submit');
                                        setShowWindow(false);
                                    }
                                }}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="k-button"
                                disabled={!groupName || !groupType}
                                style={{
                                    backgroundColor: '#343a40',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    if (!validations())
                                        addNewUserGroup('submitAdd');
                                }}
                            >
                                Submit & Add
                            </button>
                        </>
                    }
                </div>
            </form>
        </Window>
    );
};

export default  AddNewUserGroupWindow;
