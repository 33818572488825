import React from "react";
import { Result } from 'antd';

const NotFound = ({ useMessage = true }) => (
    useMessage ?
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
        /> :
        <Result
            status="404"
            title="404"
        />
);

export default NotFound;

export { NotFound };
